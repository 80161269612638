/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { DraggableFloatingIcon } from '../_components/Draggable/DraggableWrapper';

export function Test() {
  const [time, setTime] = useState('');

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };

  // Function to convert time to a cron expression
  const timeToCronExpression = (time) => {
    const [hours, minutes] = time.split(':');

    return `${minutes} ${hours} * * *`; // Cron format: 'MM HH * * *'
  };

  timeToCronExpression('10:40');

  return (
    <div>
      {/* <label htmlFor='startTime'>Select time:</label>
      <input type='time' id='startTime' value={time} onChange={handleTimeChange} /> */}

      <DraggableFloatingIcon />
    </div>
  );
}
