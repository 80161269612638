/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { Button, ToggleButton } from '../_components';
import { ErrorComponent } from '../_components/ErrorComponent';
import UserDropDown from '../_components/UserDropDown';
import { alertConstants } from '../_constants';
import { userService } from '../_services';
import { teamService } from '../_services/team.service';
import { DropDownStyle, TeamState, teamSelect, userSelect } from './team.constants';
import './team.css';

//-- Team Add Component --//
const TeamAdd = ({ id, handleClose = () => {} }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [team, setTeam] = useState(TeamState);
  const [error, setError] = useState({ message: '' });
  const [userSearch, setUserSearch] = useState('');
  const [query, setQuery] = useState({
    select: teamSelect,
  });
  const [editTeamData, setEditTeamData] = useState({});

  useEffect(() => {
    if (id && id != '0') {
      query.id = id;
      getAllTeam(query);
    }
    getAllMembers();
  }, [id]);

  const getAllTeam = async (queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    setQuery(queryNew);
    setIsLoading(true);
    try {
      const team = await teamService.teamGet(queryNew);
      if (team?.data?.length) {
        setTeam(team?.data?.[0]);
        setEditTeamData(team?.data?.[0]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllMembers = async (search) => {
    setIsLoading(true);
    setUserSearch(search);
    const payload = {
      searchKey: search,
      select: userSelect,
    };
    try {
      const users = await userService.userList(payload);
      // if (users?.data?.rows.length) {
      setMembers(users.data.rows);
      // }
    } catch (error) {
      setError({
        message: error?.message || alertConstants.SERVER_ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name == 'members') {
      setTeam({
        ...team,
        [name]: value.map((item) => parseInt(item.id)),
        members_details: value.map((item) => item),
      });
    } else {
      setTeam({
        ...team,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    setIsSubmitting(true);

    if (
      team?.name.toString().trim() === '' ||
      team?.members?.length <= 0 ||
      team?.members_details?.length <= 0
    ) {
      setIsSubmitting(false);
      return null;
    }

    if (team.id && !editTeamData.group_required && team.group_required) {
      team.enabled_when_edit = true;
    }

    try {
      await teamService.teamAdd(team).then(() => {
        setIsSubmitting(false);
      });
      handleClose();
    } catch (error) {
      setError({
        message: error?.message || alertConstants.SERVER_ERROR,
      });
    } finally {
      setIsSubmitting(false);
      setSubmitted(false);
    }
  };

  const handleToggle = () => {
    setTeam((prev) => ({ ...prev, group_required: !prev.group_required }));
  };

  return (
    <div className='px-4  py-3' style={{ fontSize: '13px' }}>
      <ErrorComponent error={error?.message} />

      <form action='' className='d-flex flex-column gap-2' onSubmit={handleSubmit}>
        <div className='row mb-2'>
          <div className='form-group'>
            <label htmlFor='name'>
              Name<span style={{ color: 'red' }}> *</span>
            </label>
            <input
              style={{ fontSize: '13px' }}
              type='text'
              className='form-control mt-2'
              id='name'
              name='name'
              placeholder='Enter team name '
              value={team?.name}
              onChange={handleChange}
            />
            {submitted && !team?.name.trim() && (
              <div className='help-block'>Team name is required</div>
            )}
          </div>
        </div>

        {/* Members select */}
        <div>
          <div className='mb-2'>
            Members
            <span style={{ color: 'red' }}> *</span>
          </div>
          <ReactSelect
            name='members'
            styles={DropDownStyle}
            getOptionValue={(option) => {
              return option?.id;
            }}
            getOptionLabel={(option) => {
              return option?.first_name;
            }}
            formatOptionLabel={(data, metaData) => {
              return <UserDropDown data={data} metaData={metaData} />;
            }}
            options={members}
            isMulti
            onInputChange={(inputString) => {
              getAllMembers(inputString);
            }}
            inputValue={userSearch}
            onChange={(selectedOption) => {
              handleChange({
                target: {
                  name: 'members',
                  value: selectedOption,
                },
              });
            }}
            value={team?.members_details}
          />
          {submitted && team?.members.length <= 0 && (
            <div className='help-block'> Members selection is required</div>
          )}
        </div>
        {/* Members select end */}

        {(!id || (id && !editTeamData.group_required)) && (
          <div className='group-confirm-contain'>
            <span>Create group for discussion</span>
            <ToggleButton
              key={1}
              id={1}
              isChecked={team?.group_required}
              handleToggle={handleToggle}
              size={35}
            />
          </div>
        )}

        <div className='row '>
          <div className=' d-flex justify-content-end gap-2'>
            <Button.Container
              className={'cancel_btn'}
              type='reset'
              handleOk={() => {
                handleClose();
              }}
            >
              <Button.Title title={'Cancel'} />
            </Button.Container>

            <Button.Container type='submit' isDisabled={isSubmitting}>
              <Button.Title title={id ? 'update' : 'Add'} />
              <Button.Loading isLoading={isSubmitting} />
            </Button.Container>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TeamAdd;
