/* eslint-disable no-unused-vars */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { settingsService } from '../_services';
import { TIME_LABELS } from './SettingsLabels';
import { ToggleWithHeading } from './UtilsInSettings';

export function TimeSettings() {
  const [query, setQuery] = useState({ select: ['name', 'labels'], name: 'time-settings' });

  const {
    data: timeSettingsData,
    error,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ['time-settings'],
    queryFn: () => settingsService.settingsGet(query),
    select: (data) => {
      return data?.data[0];
    },
  });

  return (
    <section className='common_contain'>
      {isLoading && <div>Loading...</div>}
      {isSuccess && <TimeForm initialData={timeSettingsData?.labels} />}
    </section>
  );
}

const TimeForm = ({ initialData }) => {
  const queryClient = useQueryClient();

  const [timeSettings, setTimeSettings] = useState(initialData || TIME_LABELS);

  const handleToggle = (id, value) => {
    setTimeSettings((prevLabels) =>
      prevLabels.map((label) => (label.id === id ? { ...label, value: !value } : label)),
    );

    setTimeSettings((prevLabels) => {
      handleMutate(prevLabels);
      return prevLabels;
    });
  };

  // time settings update
  const handleSettingUpdate = (data) => {
    let newQuery = {};
    newQuery.labels = data;
    newQuery.name = 'time-settings';

    settingsService.settingsAdd(newQuery);
    return newQuery;
  };

  // --react query fot mutation function
  const { mutate: handleMutate, isError } = useMutation({
    mutationFn: handleSettingUpdate,

    onSuccess: (data) => {
      queryClient.setQueryData(['time-settings'], (old) => {
        let oldData = { ...old };
        oldData.data[0] = data;
        return oldData;
      });
    },
  });

  return (
    <>
      {timeSettings?.map((TIME) => {
        if (TIME.type === 'toggle') {
          return (
            <ToggleWithHeading
              key={TIME?.id}
              id={TIME?.id}
              handleToggle={() => handleToggle(TIME?.id, TIME.value)}
              isChecked={TIME.value}
              heading={TIME.label}
            />
          );
        }
      })}
    </>
  );
};
