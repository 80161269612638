import React, { useEffect } from 'react';
import '../ai.css';

export const AnimatedLoader = ({ start }) => {
  useEffect(() => {
    const loader = document.querySelector('.loader');
    const delay = +loader.dataset.delay || 200;
    const dots = loader.querySelectorAll('.loader .dot');

    dots.forEach((dot, index) => {
      dot.style.animationDelay = `${delay * index}ms`; // Apply delay for each dot
    });

    if (start) {
      // Start the animation
      loader.classList.add('is-animating');
    } else {
      // Stop the animation
      loader.classList.remove('is-animating');
    }
  }, [start]);

  return (
    <main>
      <div className='loader js-loader' data-delay='200'>
        <div className='dot'></div>
        <div className='dot'></div>
        <div className='dot'></div>
        <div className='dot'></div>
        <div className='dot'></div>
        <div className='dot'></div>
        <div className='dot'></div>
        <div className='dot'></div>
        <div className='dot'></div>
      </div>
    </main>
  );
};
