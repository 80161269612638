/***
 * @callback DebounceCallback
 * @param clickCount - The no of times user clicked the debounce function within the debounce period
 */

/*** For doing debounced action,
 * @param {DebounceCallback} callback
 * @param {number} time - The time in milliseconds to wait for calling the request after the click
 */
function useDebounceAction(callback, time) {
  let clickCount = 0;
  let debounceTimeout;
  function runDebounceFunction(props) {
    // Increment the click count on each click within the debounce period
    clickCount++;

    // Clear any existing timeout to prevent multiple rapid calls
    clearTimeout(debounceTimeout);

    debounceTimeout = setTimeout(() => {
      callback(clickCount, props);
      clickCount = 0;
    }, time);
  }

  const clearDebounceTimeout = () => {
    clearTimeout(debounceTimeout);
  };

  return { runDebounceFunction, clickCount, clearDebounceTimeout };
}

export default useDebounceAction;
