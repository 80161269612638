import { keepPreviousData, useQuery } from '@tanstack/react-query';
import {
  BriefcaseBusiness,
  Building2,
  ContactRound,
  Ellipsis,
  GitFork,
  Home,
  Mail,
  Phone,
  Users,
} from 'lucide-react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { STATUS } from '../Task/taskConstants';
import { GlobalContext, MyTeamIcon, fullNameUser } from '../Utils';
import { LoadingIcon } from '../Utils/SvgIcons';
import { ContextMenu, Modal } from '../_components';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import StackedAvatars from '../_components/Avatar/StackedAvatars';
import IconLabelSet from '../_components/IconLabelSet';
import { RT, apiConstants } from '../_constants';
import { userService } from '../_services';
import { MyTeamAddModal } from './MyTeamAddModal';

export function MyTeam() {
  const { makeAlert } = useContext(GlobalContext);
  const params = useParams();
  const navigate = useNavigate();
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [userAddModal, setUserAddModal] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = useState({
    select: [
      'id',
      'first_name',
      'last_name',
      'address',
      'phone_no',
      'email_id',
      'department_id',
      'designation_id',
      'employee_code',
      'role',
      'reported_users',
      'image_url',
      'is_sub_admin',
    ],
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
    id: params.id,
  });

  const {
    data: userData,
    error,
    isPending,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ['user', params.id],
    queryFn: () => userService.userGet(query),
    placeholderData: keepPreviousData,
  });
  useEffect(() => {
    if (error?.message) {
      makeAlert(error?.message);
    }
  }, [error?.message]);

  console.log(userData, 'userDetails');

  const isMale = userData?.data?.gender === 1;
  const isNeutral = userData?.data?.gender === 3;
  const url = userData?.data?.image_url;
  const finalUrl = url ? apiConstants.imgUrlS3 + url : null;
  const userName = fullNameUser(userData?.data?.first_name, userData?.data?.last_name);
  return (
    <div>
      {isPending && <LoadingIcon color='#d2d2d2' size={70} />}
      {isSuccess && (
        <>
          <div className='d-flex align-items-center url-container cursor-pointer breadcrumbs'>
            <IconLabelSet
              label={'Home'}
              fontWeight={400}
              icon={<Home size={16} />}
              onClick={() => navigate(`/`)}
            />
            <img className='slash-img-icon-size ms-1 mt-1' src={MyTeamIcon.Slash} alt='' />
            <IconLabelSet
              label={'My Team'}
              fontWeight={400}
              icon={<Users size={16} />}
              onClick={
                () => navigate(`/${RT.MY_TEAM_LIST}?status=active-users`)

                // navigate(`/${RT.MY_TEAM_LIST_MAIN}/${RT.MY_TEAM_LIST}?status=active-users`)
              }
            />
            <img className='slash-img-icon-size ms-1 mt-1' src={MyTeamIcon.Slash} alt='' />
            <div className='value-font-13 mt-1'>{userName}</div>
          </div>
          <div className='TaskList sub_padding'>
            <div className='row'>
              <div>
                <div className='card regular-table-with-color myTeam-container border-0 col-md-6 p-3 '>
                  <div
                    className='p-3 '
                    style={{
                      borderBottom: '1px dashed #DCDCDC',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <div className='mb-2'>
                      <CircularAvatar
                        size={55}
                        className='fz-none zoom-in employee-img-with-low-border'
                        source={finalUrl}
                        isMale={isMale}
                        isNeutral={isNeutral}
                      />
                    </div>
                    <div className='ml-4 mt-2' style={{ flex: 1, marginLeft: 10 }}>
                      <div style={{ fontSize: 15, fontWeight: 500, cursor: 'default' }}>
                        {userName.charAt(0).toUpperCase() + userName.slice(1)}
                      </div>
                      <div className='d-flex gap-4'>
                        {userData?.data?.email_id && (
                          <div>
                            <Mail size={15} />
                            <span className='ms-2 value-font-13'>{userData?.data?.email_id}</span>
                          </div>
                        )}
                        {userData?.data?.phone_no && (
                          <div>
                            <Phone size={15} />
                            <span className='ms-2 value-font-13'>
                              +91 {userData?.data?.phone_no}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    {userData?.data?.isPrivileged &&
                      userData?.data?.is_sub_admin &&
                      userData.data?.status == STATUS.ACTIVE && (
                        <div className='ellipsis_icon'>
                          <Ellipsis
                            onClick={() => {
                              setContextMenuVisible((prev) => !prev);
                            }}
                          />
                          {contextMenuVisible && (
                            <ContextMenu
                              isContextVisible={true}
                              onRequestClose={() => setContextMenuVisible(false)}
                            >
                              <div
                                className='menu_items'
                                onClick={() => {
                                  // navigate(`/${RT.MY_TEAM_ADD}/${userData?.data?.id}`);
                                  setUserAddModal(true);
                                }}
                              >
                                Edit
                              </div>
                            </ContextMenu>
                          )}
                        </div>
                      )}
                  </div>
                  <div className='mt-3 mx-2' style={{ fontFamily: 'inter', fontWeight: 400 }}>
                    <div className='row'>
                      <div
                        className='col-md-3 '
                        style={{ textAlign: 'start', fontSize: 13, color: 'gray' }}
                      >
                        <IconLabelSet icon={<ContactRound size={16} />} label={' Employee Code'} />
                      </div>
                      <div className='col-md-6 value-font-13'>
                        {' '}
                        {userData?.data?.employee_code ? userData?.data?.employee_code : '---'}
                      </div>
                    </div>{' '}
                    {userData?.data?.address && (
                      <div className='row mt-3'>
                        <div className='col-md-3 '>
                          <IconLabelSet
                            label={'Address'}
                            icon={<img className='img-icon-size' src={MyTeamIcon.Address} />}
                          />
                        </div>
                        <div className='col-md-6 value-font-13'>{userData?.data?.address}</div>
                      </div>
                    )}
                    <div className='row mt-3'>
                      <div
                        className='col-md-3 '
                        style={{ textAlign: 'start', fontSize: 13, color: 'gray' }}
                      >
                        <IconLabelSet label={'Organization'} icon={<Building2 size={16} />} />
                      </div>
                      <div className='col-md-6 value-font-13'> {userData?.data?.Company?.name}</div>
                    </div>
                    <div className='row mt-3'>
                      <div
                        className='col-md-3 '
                        style={{ textAlign: 'start', fontSize: 13, color: 'gray' }}
                      >
                        <IconLabelSet label={'Department'} icon={<GitFork size={16} />} />
                      </div>
                      <div className='col-md-6 value-font-13'>
                        {' '}
                        {userData?.data?.department?.name
                          ? userData?.data?.department?.name
                          : '---'}
                      </div>
                    </div>
                    <div className='row mt-3'>
                      <div
                        className='col-md-3 '
                        style={{ textAlign: 'start', fontSize: 13, color: 'gray' }}
                      >
                        <IconLabelSet
                          label={'Designation'}
                          icon={<BriefcaseBusiness size={16} />}
                        />
                      </div>
                      <div className='col-md-6 value-font-13'>
                        {' '}
                        {userData?.data?.designation?.name
                          ? userData?.data?.designation?.name
                          : '---'}
                      </div>
                    </div>
                    {userData?.data?.reported_users && (
                      <div className='row mt-3'>
                        <div className='col-md-3 '>
                          {' '}
                          <IconLabelSet
                            label={'  Reported to'}
                            icon={<img className='img-icon-size' src={MyTeamIcon.ReportTo} />}
                          />
                        </div>
                        <div className='col-md-6  d-flex align-items-start'>
                          <StackedAvatars
                            getSourceByKey={(user) => user.image_url}
                            getNameByKey={(user) => user.first_name}
                            getGenderByKey={(user) => user.gender === 1}
                            imageList={userData?.data?.reported_users_details}
                            showInitials={false}
                            size={25}
                          />
                        </div>
                      </div>
                    )}
                    {userData?.data?.reportees.length > 0 && (
                      <div className='row mt-3'>
                        <div className='col-md-3 '>
                          {' '}
                          <IconLabelSet
                            label={'Reportees'}
                            icon={<img className='img-icon-size' src={MyTeamIcon.ReportTo} />}
                          />
                        </div>
                        <div className='col-md-6  d-flex align-items-start'>
                          {' '}
                          <StackedAvatars
                            getSourceByKey={(user) => user.image_url}
                            getNameByKey={(user) => user.first_name}
                            getGenderByKey={(user) => user.gender === 1}
                            imageList={userData?.data?.reportees}
                            showInitials={false}
                            size={25}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <section>
        {userAddModal && (
          <Modal.Container>
            <Modal.View className={'team-list modal_view'}>
              <MyTeamAddModal
                editUserID={params.id}
                handleClose={() => {
                  setUserAddModal(false);
                  refetch();
                }}
              />
            </Modal.View>
          </Modal.Container>
        )}
      </section>
    </div>
  );
}
