import { z } from 'zod';
import { PROJECT_ERROR_MSG, PROJECT_REQUIRED_FIELD_MSG } from './project.constants';

export const projectSchema = () => {
  return (
    z
      .object({
        name: z
          .string({
            required_error: PROJECT_REQUIRED_FIELD_MSG.NAME,
            invalid_type_error: PROJECT_ERROR_MSG.NAME,
          })
          .min(1, { message: PROJECT_ERROR_MSG.NAME }),

        allocated_users: z
          .array(z.any())
          .min(1, { message: PROJECT_REQUIRED_FIELD_MSG.ALLOCATED_USERS }),

        // start_date: hasExist('Start Date')
        //   ? z
        //       .number({
        //         required_error: PROJECT_REQUIRED_FIELD_MSG.START_DATE,
        //         invalid_type_error: PROJECT_ERROR_MSG.START_DATE,
        //         coerce: true,
        //       })
        //       .gt(0, { message: PROJECT_ERROR_MSG.START_DATE })
        //   : z.number().optional(),

        // end_date: hasExist('End Date')
        //   ? z
        //       .number({
        //         required_error: PROJECT_REQUIRED_FIELD_MSG.END_DATE,
        //         invalid_type_error: PROJECT_ERROR_MSG.END_DATE,
        //         coerce: true,
        //       })
        //       .gt(0, { message: PROJECT_ERROR_MSG.END_DATE })
        //   : z.number().optional(),

        // client_id: z.union([
        //   z.string().nonempty({ message: PROJECT_REQUIRED_FIELD_MSG.CLIENT }),
        //   z.number().positive({ message: PROJECT_ERROR_MSG.CLIENT }),
        // ]),

        project_owner_id: z.union([
          z.string().nonempty({ message: PROJECT_REQUIRED_FIELD_MSG.PROJECT_OWNER }),
          z.number().positive({ message: PROJECT_ERROR_MSG.PROJECT_OWNER }),
        ]),

        template_id: z.array(z.any()).min(1, { message: PROJECT_REQUIRED_FIELD_MSG.TEMPLATE }),
      })
      // .refine(
      //   (data) => {
      //     // if (hasExist('Start Date') && hasExist('End Date')) {
      //     return data.end_date >= data.start_date;
      //     // }
      //     // return true;
      //   },
      //   {
      //     message: PROJECT_ERROR_MSG.DATE_RANGE,
      //     path: ['end_date'],
      //   },
      // );
      .refine(
        (data) => {
          if (data.start_date && data.end_date) {
            return data.end_date >= data.start_date;
          }
          return true;
        },
        {
          message: PROJECT_ERROR_MSG.DATE_RANGE,
          path: ['end_date'],
        },
      )
  );
};
