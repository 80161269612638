import { apiConstants } from '../_constants';
import { authHeader, handleResponse } from '../_helpers';
export const roleService = {
  roleList,

};


function roleList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/role_list', requestOptions).then(handleResponse);
}
