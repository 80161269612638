import React from 'react';
import useOutsideClickHandler from '../Hook/useOutsideClickHandler';
const PositionedContextMenu = ({
  isContextVisible,
  onRequestClose = () => {},
  children,
  left = 0,
  top = 'calc(100% + 10px)',
}) => {
  const { containerRef } = useOutsideClickHandler({
    onClose: () => onRequestClose(),
  });

  if (!isContextVisible) {
    return null;
  }

  return (
    <div
      ref={containerRef}
      style={{ top, left, position: 'fixed' }}
      onBlur={() => onRequestClose()}
      className={`menu_popup context-container`}
    >
      {children}
    </div>
  );
};

export default PositionedContextMenu;
