/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { DepartmentState } from '../Utils/States';
import { Button } from '../_components/CustomButton';
import { ErrorComponent } from '../_components/ErrorComponent';
import { alertConstants } from '../_constants';
import { departmentService } from '../_services/department.service';

export function DepartmentAdd({ id, handleClose = () => {}, afterAdd = () => {} }) {
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState({ common: '' });
  // const [search, setSearch] = useState('');
  const [department, setDepartment] = useState(DepartmentState);
  const [query, setQuery] = useState({
    select: ['id', 'name', 'company_id'],
  });

  useEffect(() => {
    if (id && id != '0') {
      query.id = id;
      getAllDeparment(query);
    }
  }, [id]);

  /**
   * Get deaprtment
   * */
  const getAllDeparment = async (queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;

    setQuery(queryNew);
    setIsLoading(true);

    try {
      const organization = await departmentService.departmentGet(queryNew);
      if (organization?.data?.length) {
        setDepartment(organization.data[0]);
      }
    } catch (error) {
      setError({
        message: error?.message || alertConstants.SERVER_ERROR,
      });
    } finally {
      setIsLoading(false);
    }

    console.log(error);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDepartment({
      ...department,
      [name]: value,
    });
  };

  // const debounce = useDebounce(search, 500);
  // const { data: organizationData, error: organizationError } = useQuery({
  //   queryKey: ['organization', debounce],
  //   queryFn: () => organizationService.organizationList({ searchKey: search }),
  //   placeholderData: keepPreviousData,
  // });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setSubmitted(true);

    if (
      department?.name.toString().trim() === ''
      // || department?.company_id == 0
    ) {
      setIsLoading(false);
      return null;
    }

    try {
      await departmentService.departmentAdd(department).then((data) => {
        setSubmitted(false);
        afterAdd(data?.data);
      });
      handleClose();
    } catch (error) {
      setError({
        common: error?.message || alertConstants.SERVER_ERROR,
      });
    } finally {
      setIsLoading(false);
      setSubmitted(false);
    }
  };
  return (
    <div style={{ padding: '10px 16px 16px' }}>
      {/* component will active when error occurs   */}
      <ErrorComponent error={error?.message} />

      <form action='' className='d-flex flex-column gap-2' onSubmit={handleSubmit}>
        <div className='row mb-2'>
          <div>
            <label htmlFor='name' className='form-input-label'>
              Name<span className='field-required'> *</span>
            </label>
            <input
              type='text'
              className='input-box'
              id='name'
              name='name'
              value={department?.name}
              placeholder='Enter department name '
              onChange={(event) => {
                handleChange(event);
              }}
            />
            {submitted && !department?.name.trim() && (
              <div className='help-block'>Department name is required</div>
            )}
          </div>
        </div>

        {/* company selection  */}
        {/* <div>
          <div className='mb-1'>
            Organization
            <span style={{ color: 'red' }}> *</span>
          </div>
          <ReactSelect
            name='company_id'
            styles={{
              container: (styles) => {
                return {
                  ...styles,
                  fontSize: '13px',
                  minHeight: '35px',
                  minWidth: '170px',
                };
              },
              control: (styles) => {
                return {
                  ...styles,
                  minHeight: '30px',
                };
              },
              indicatorsContainer: (styles) => {
                return {
                  ...styles,
                  minHeight: '30px',
                  height: '30px',
                };
              },
              menu: (styles) => {
                return {
                  ...styles,
                  minWidth: '170px',
                };
              },
            }}
            getOptionLabel={(option) => {
              return option?.name;
            }}
            getOptionValue={(option) => {
              return option?.id;
            }}
            onInputChange={(inputString) => {
              setSearch(inputString);
            }}
            filterOption={null}
            inputValue={search}
            options={organizationData?.data?.rows}
            onChange={(selectedOption) => {
              handleChange({
                target: {
                  name: 'company_id',
                  value: selectedOption.id,
                },
              });
            }}
            value={
              organizationData?.data?.rows.find((option) => option.id === department.company_id) ||
              null
            } // Set the value based on project.company_id
          />
          {submitted && !department?.company_id && (
            <div className='help-block'>Organization selection is required</div>
          )}
        </div> */}

        {/* end of company  */}

        <div className='row '>
          <div className=' d-flex justify-content-end gap-2'>
            <Button.Container
              className={'cancel-btn'}
              type='reset'
              handleOk={() => {
                handleClose();
              }}
            >
              <Button.Title title={'Cancel'} />
            </Button.Container>

            <Button.Container type='submit' isDisabled={isLoading}>
              <Button.Title title={'Save'} />
              <Button.Loading isLoading={isLoading} />
            </Button.Container>
          </div>
        </div>
      </form>
    </div>
  );
}
