import React from 'react';

function ChatUpdateContainer({ children, className = '', isNeedSafetyOffset = false }) {
  return (
    <div
      className={`chat_list_condainer d-flex flex-column update-root-container ${className}`}
      style={{
        height: `${
          isNeedSafetyOffset ? 'calc(100vh - var(--top-bar-height) - 46px - 5px)' : 'auto'
        }`, //5px is a safety offset
        // paddingTop: 20,
        borderBottom: 'none',
      }}
    >
      {children}
    </div>
  );
}

export default ChatUpdateContainer;
