import { apiConstants } from '../_constants';
import { authHeader, handleResponse } from '../_helpers';
import { jsonToQueryString } from '../_helpers/utilFunctions';

export const teamService = {
  teamAdd,
  teamList,
  teamGet,
  teamDelete,
  teamDeactivate,
};

async function teamAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/team_add', requestOptions).then(handleResponse);
}

async function teamList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(apiConstants.apiUrl + '/team_list', requestOptions).then(handleResponse);
}

async function teamGet(data) {
  const params = jsonToQueryString(data);
  const apiUrl = `${apiConstants.apiUrl}/get_team?${params}`;
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}

async function teamDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/team_delete', requestOptions).then(handleResponse);
}

async function teamDeactivate(payload) {
  const apiUrl = `${apiConstants.apiUrl}/team_deactivate`;
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };

  return fetch(apiUrl, requestOptions).then(handleResponse);
}
