/* eslint-disable no-unused-vars */
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { ChevronDown, Ellipsis, Plus, Search } from 'lucide-react';
import React, { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Images } from '../Utils';
import { LoadingIcon } from '../Utils/SvgIcons';
import { Button, ContextMenu, PaginationComponent, TableRow } from '../_components';
import { ErrorComponent } from '../_components/ErrorComponent';
import { Modal } from '../_components/Modal';
import { DELETE_NOTE } from '../_constants/deletenote.constants';
import useDebounce from '../_helpers/useDebounce';
import { labelService } from '../_services/label.service';
import './Label.css';
import { LabelAdd } from './LabelAdd';
const TABLE_COLUMN = [
  'Sl.No',
  'Label',
  'Label Color',
  'Text Color',
  'Created At',
  'Status',
  'Action',
];

export function LabelList() {
  // hooks section
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();

  // reference section
  const reference = useRef(null);

  //  useState section

  const [editLabelID, setEditLabelID] = useState(null);
  const [contextMenuIndex, setContextMenuIndex] = useState(null);
  const [query, setQuery] = useState({
    select: ['id', 'name', 'text_color', 'label_color', 'createdAt', 'status'],
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
    searchKey: '',
  });
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const debouncedSearch = useDebounce(query?.searchKey, 500);

  // Label list API call section

  const {
    data: labelData,
    error,
    refetch,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ['label', query?.pageVo?.pageNo, debouncedSearch],
    queryFn: () => labelService.labelList(query),
    placeholderData: keepPreviousData,
  });

  const modalClose = () => {
    setDeleteModal(false);
    setEditLabelID(null);
  };
  // section trigger when delete option selected

  console.log({ labelData });

  const handleDeactivate = async (id) => {
    try {
      await labelService.labelDeactivate({ id });
      let isNeedToBack = labelData?.data?.rows.length === 1 && labelData?.data?.pages > 1;

      if (isNeedToBack) {
        // const newPageNo = query.pageVo.pageNo - 1;
        const page = searchParams.get('pageNo')
        searchParams.set('pageNo', page-1);
        setSearchParams(searchParams);
        setQuery((prev) => ({
          ...prev,
          pageVo: {
            ...prev.pageVo,
            pageNo: prev.pageVo.pageNo - 1,
          },
        }));
      } else {
        refetch();
      }

      await queryClient.invalidateQueries('label');
      modalClose();
    } catch (error) {
      console.error('Error deleting label:', error);
    }
  };

  // edit or delete function activation section

  const handleClickOption = (option) => {
    if (option == 'edit') {
      setAddModal(true);
    } else if (option == 'delete') {
      setDeleteModal(true);
    }
  };

  // get the number of data of label
  const noOfData = labelData?.data?.rows.length;

  // table data definition section

  const tableData = labelData?.data?.rows?.map((label, index) => {
    const slNo = (labelData?.data?.page - 1) * labelData?.data?.limit + index + 1;

    return (
      <tr key={index}>
        <td>{slNo}</td>
        <td>{label?.name}</td>
        <td style={{ verticalAlign: 'middle' }}>
          <div className='label_color_view' style={{ backgroundColor: label.label_color }} />
        </td>
        <td style={{ verticalAlign: 'middle' }}>
          <div className='label_color_view' style={{ backgroundColor: label.text_color }} />
        </td>

        <td>{label?.createdAt.split('T')[0]}</td>
        <td>{label?.status ? 'Active' : 'Deactivated'}</td>

        <td style={{ verticalAlign: 'middle', cursor: 'pointer' }}>
          <div
            className='ellipsis_icon'
            ref={reference}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <div onClick={() => setContextMenuIndex(index)}>
              <Ellipsis />
            </div>
            <ContextMenu
              handleClick={(value) => {
                setEditLabelID(label?.id);
                handleClickOption(value);
              }}
              isContextVisible={contextMenuIndex === index}
              onRequestClose={() => {
                // setEditLabelID(null);
                setContextMenuIndex(null);
              }}
            />
          </div>
        </td>
      </tr>
    );
  });

  return (
    <div className='department_list sub_padding'>
      <ErrorComponent error={error?.message} />
      <section>
        <div className='tab_contain mt-2'>
          <div className='search_contain'>
            <Search size={20} color='black' />
            <input
              onChange={(event) => {
                searchParams.set('pageNo', 1);
                setSearchParams(searchParams);
                setQuery({
                  ...query,
                  searchKey: event.target.value,
                  pageVo: { ...query.pageVo, pageNo: 1 },
                });
              }}
              type='text'
              className='bodr_none'
            />
          </div>
          <div className='d-flex gap-2'>
            <div className='add_item_button' onClick={() => setAddModal(true)}>
              <Plus size={16} /> Add Label
            </div>
            <div className='list_top_card'>
              Sort by <ChevronDown size={13} />
            </div>
          </div>
        </div>

        <section className='mt-2'>
          <div className='table_outer'>
            <table className='table table-hover tbl mb-0'>
              <thead>
                <tr>
                  {TABLE_COLUMN.map((heading, index) => {
                    return (
                      <th key={index} scope='col'>
                        {heading}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {isLoading && (
                  <TableRow noOfCols={7}>
                    <LoadingIcon color='blue' size={60} />
                  </TableRow>
                )}
                {!noOfData && isSuccess && <TableRow noOfCols={7}>No data found</TableRow>}
                {noOfData > 0 && isSuccess && tableData}
              </tbody>
            </table>
          </div>
        </section>
        {noOfData > 0 && (
          <section className='mt-2'>
            <PaginationComponent
              pageOnClick={(data) => {
                setQuery((prev) => ({
                  ...prev,
                  pageVo: {
                    ...prev.pageVo,
                    pageNo: data,
                  },
                }));
                searchParams.set('pageNo', data);
                setSearchParams(searchParams);
              }}
              demoData={labelData?.data}
            />
          </section>
        )}
      </section>

      {addModal && (
        <Modal.Container
          handleClose={() => {
            setEditLabelID(null);
            setAddModal(false);
          }}
        >
          <Modal.View className={'modal_view_label'}>
            <Modal.Head
              Heading={editLabelID ? 'Edit Labels' : 'Add Labels'}
              handleClose={() => {
                setEditLabelID(null);
                setAddModal(false);
              }}
            />
            <LabelAdd
              id={editLabelID}
              handleClose={() => {
                setAddModal(false);
                setEditLabelID(null);
                refetch();
              }}
            />
          </Modal.View>
        </Modal.Container>
      )}

      {deleteModal && (
        <Modal.Container>
          <Modal.View className={'status-delete'}>
            <Modal.Head handleClose={() => modalClose()} Heading={'Delete Confirmation'} />
            <Modal.Description>
              <img src={Images.DeleteBin} width={35} height={50} alt='' className='mb-3' />
              <div className='del-description'>{DELETE_NOTE.LABEL}</div>
            </Modal.Description>
            <div className='status-del-btn'>
              <Button.Container handleOk={() => modalClose()} className={'status-del-cancel'}>
                <Button.Title title={'Cancel'} />
              </Button.Container>
              <Button.Container handleOk={() => handleDeactivate(editLabelID)}>
                <Button.Title title={'Delete'} />
              </Button.Container>
            </div>
          </Modal.View>
        </Modal.Container>
      )}
    </div>
  );
}
