import React from 'react';

function ErrorBlock({ errorMsg = null, hasError = false }) {
  if (!hasError) {
    return null;
  }
  return <div className='error-block'>{errorMsg}</div>;
}

export default ErrorBlock;
