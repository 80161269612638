import { useQuery } from '@tanstack/react-query';
import { Hourglass, Info } from 'lucide-react';
import React, { useContext, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import ReactSelect from 'react-select';
import { getFormattedLabel } from '../../Task/TaskComponents/CustomInputs';
import { taskAssignUserSelect } from '../../Task/taskConstants';
import { getExpectedTime } from '../../Task/taskValidation';
import {
  GlobalContext,
  convertPriorityLabelToList,
  getSettingsByName,
  subtaskState,
} from '../../Utils';
import { Label } from '../../Utils/SvgIcons';
import { Button, Modal } from '../../_components';
import {
  ReactSelectPriorityComponents,
  SelectComponents,
} from '../../_components/ReactSelectCustomizations/SelectComponents';
import { reactSelectUtils } from '../../_components/ReactSelectCustomizations/reactSelectUtils';
import SearchableQuerySelect from '../../_components/SearchableQuerySelect';
import UserDropDown from '../../_components/UserDropDown';
import { USER_LIST_ACTIONS } from '../../_constants';
import { POSITION } from '../../_constants/contextMenu.constants';
import {
  EDITOR_TOOLBAR_OPTIONS,
  START_MODES,
  TEMPLATE_ERROR_MSGS,
} from '../../_constants/template.constants';
import { userService } from '../../_services';
import { labelService } from '../../_services/label.service';
import ClickableSelect from './ClickableSelect';
import ErrorBlock from './ErrorBlock';
import MultiUnitDurationInput from './MultiUnitDurationInput';
import TemplateSubtask from './TemplateSubtask';

function RelatedTaskTemplateAddModal({
  isSubtaskActive,
  onClose = () => {},
  onSubmit = () => new Promise(),
  checkForCircularDependency = () => {},
  initialTaskData = null,
  assignPayload = {},
  isEdit = false,
  labelList,
  startModeOptions,
}) {
  const [scrollFlag, setScrollFlag] = useState({ isTopReached: true, isBottomReached: false });

  const { makeAlert, globalSettings } = useContext(GlobalContext);

  const { data: usersForAssigning } = useQuery({
    queryKey: ['user-list-assign'],
    queryFn: () =>
      userService.getPermittedUser({
        select: taskAssignUserSelect,
        action: USER_LIST_ACTIONS.TASK_ADD,
        ...assignPayload,
      }),
    select: (data) => {
      return data?.data?.rows;
    },
  });

  const priorityLabel = getSettingsByName(globalSettings, 'priority-label');
  const priorityLabelList = convertPriorityLabelToList(priorityLabel?.labels);

  // THis modal can be used for later other places, so creating it's own handleChange and state.
  const [relatedTaskData, setRelatedTaskData] = useState(initialTaskData);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  /***
   * Will scroll to the first-found error element. 'error-block' class is considered as the error element. It will scroll to the first found parent of error-block class.
   */
  const scrollToErrorElement = () => {
    window.requestAnimationFrame(() => {
      const element = document.querySelector('.error-block');
      if (!element) {
        return;
      }
      const parentElement = element.parentElement;
      const inputElement = parentElement.querySelector('input');
      if (inputElement) {
        inputElement.focus({ preventScroll: false });
      }
      parentElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setRelatedTaskData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  /***
   * For updating or adding subtask. If there is no index or index is out of bound of subtask list length, it will be considered as add, otherwise it will be updated based on the index.
   */
  const handleSubTaskAddOrUpdate = (value, index = null) => {
    // update
    if (!isNaN(index) && index !== null && index <= relatedTaskData.subtasks.length - 1) {
      setRelatedTaskData((prev) => ({
        ...prev,
        subtasks: prev?.subtasks?.map((itm, idx) => {
          if (idx === index) {
            return { ...itm, content: value };
          }
          return itm;
        }),
      }));
      return;
    }

    const initSubtask = { ...subtaskState };
    initSubtask.content = value;
    // TODO: Add user_id and task_id(if exist)

    setRelatedTaskData((prev) => ({
      ...prev,
      subtasks: [...prev.subtasks, initSubtask],
    }));
  };

  const handleSubtaskDelete = (index) => {
    setRelatedTaskData((prev) => ({
      ...prev,
      subtasks: prev.subtasks.filter((item, idx) => idx !== index),
    }));
  };

  const validateData = (data) => {
    if (!data.name) {
      return { isValid: false, msg: 'Name is required' };
    }

    // if (!data.assignee_id || data.assignee_id <= 0) {
    //   return { isValid: false, msg: 'User is not assigned' };
    // }

    return { isValid: true, msg: null };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    const result = validateData(relatedTaskData);
    if (!result.isValid) {
      scrollToErrorElement();
      return;
    }
    try {
      setIsSubmitting(true);
      await onSubmit(relatedTaskData); //onSubmit can be a promise or normal function
    } catch (err) {
      // HandleError
    } finally {
      onClose();
      setIsSubmitting(false);
    }
  };

  const handleStartModeChange = (selectedOption) => {
    const result = {};

    result.task_start_mode = selectedOption.startMode;

    if (selectedOption.startMode === START_MODES.IMMEDIATE) {
      result.preceding_task_id = 0;
      result.custom_start_time = 0;
    }
    if (selectedOption.startMode === START_MODES.CUSTOM_START_TIME) {
      result.preceding_task_id = 0;
      result.custom_start_time = 0;
    }

    if (selectedOption.startMode === START_MODES.PRECEDING_OTHER_TASK) {
      result.preceding_task_id = selectedOption.tempId;
      result.custom_start_time = 0;
      result.preceding_task_name = selectedOption?.relatedTaskName;
    }

    const hasCircularDependency = checkForCircularDependency({ ...relatedTaskData, ...result });
    if (hasCircularDependency) {
      makeAlert(TEMPLATE_ERROR_MSGS.CIRCULAR_DEPENDENCY_FOUND, 'error');
      return;
    }

    setRelatedTaskData((prev) => ({
      ...prev,
      ...result,
    }));
  };

  // There will be multiple preceding other task options in it. So we need to append the preceding task id for that options
  const defaultStartMode = startModeOptions?.find((mode) => {
    return mode.startMode === START_MODES.PRECEDING_OTHER_TASK
      ? mode.value === `${relatedTaskData.task_start_mode}${relatedTaskData.preceding_task_id}`
      : mode.value === relatedTaskData.task_start_mode;
  });

  const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;

    //

    if (scrollTop === 0) {
      setScrollFlag({ isTopReached: true });
    } else if (scrollHeight - scrollTop <= clientHeight + 5) {
      setScrollFlag({ isBottomReached: true });
    } else {
      setScrollFlag({ isBottomReached: false, isTopReached: false });
    }
  };

  const expectedTime = getExpectedTime(
    parseInt(relatedTaskData?.expected_hours),
    parseInt(relatedTaskData?.expected_minutes),
  );

  const selectedPriority = priorityLabelList?.find(
    (label) => label.value === relatedTaskData.priority,
  );

  return (
    <Modal.Container>
      <Modal.View
        containerClass={'template-task-add-modal-container template-add-container'}
        className={'template-task-add-content'}
      >
        <Modal.Head
          containerClass={`related-head-text-container ${
            scrollFlag.isTopReached ? '' : 'related-top-box-shadow'
          }`}
          headerClass={'related-head-text'}
          Heading={`${isEdit ? 'Edit' : 'Add'} Related Task`}
          handleClose={onClose}
        />
        <form onSubmit={handleSubmit}>
          {/* Form Content */}
          <div
            className='template-input-section template-modal-content pt-20px pb-4'
            onScroll={handleScroll}
          >
            {/* Task Name */}
            <div className='row'>
              <div className='col bare-input-container'>
                <input
                  onChange={handleChange}
                  type='text'
                  name='name'
                  value={relatedTaskData.name}
                  placeholder='Type task name'
                  className='input-box w-100 p-2 bare-input fz-18px-rem'
                />
                <div className='input-wrapper' />
                <ErrorBlock
                  errorMsg={'Task name is required'}
                  hasError={isSubmitted && !relatedTaskData.name}
                />
              </div>
            </div>

            {/* WYSIWYG EDITOR */}
            <div className='row mt-18px'>
              <div className='col'>
                <div className='input-label'>Description</div>
                <Editor
                  toolbar={EDITOR_TOOLBAR_OPTIONS}
                  toolbarClassName='template-editor-toolbar'
                  stripPastedStyles={true}
                  onChange={(contentState) =>
                    handleChange({
                      target: { value: contentState, name: 'note' },
                    })
                  }
                  defaultContentState={relatedTaskData?.note}
                  wrapperClassName='template-wrapper-style mt-5px p-2'
                  editorClassName='editor-style'
                  placeholder='Type Your Description'
                />
              </div>
            </div>

            {/* Assignee user */}
            <div className=' row mt-18px'>
              <div className='col'>
                <div className='input-label'>Assign User</div>

                {/* <StackedAvatars
                  selectProps={{
                    isCustomFunction: true,
                    queryKey: 'user-list-assign',
                    queryFn: userService.getPermittedUser,
                    getFunctionParams: (searchKey) => ({
                      searchKey,
                      select: taskAssignUserSelect,
                      action: USER_LIST_ACTIONS.TASK_ADD,
                      ...assignPayload,
                    }),
                  }}
                  imageList={
                    relatedTaskData?.assignee_id !== 0 ? [relatedTaskData?.assignee_id] : []
                  }
                  getNameByKey={(user) => fullNameUser(user.first_name, user.last_name)}
                  getSourceByKey={(user) => user.image_url}
                  getGenderByKey={(user) => user.gender}
                  size={30}
                  dropdownSize={40}
                  isClearable={() => true}
                  onAvatarRemove={() => {
                    setRelatedTaskData((prev) => ({
                      ...prev,
                      assignee_id: 0,
                    }));
                  }}
                  options={usersForAssigning}
                  isAddable
                  addableLimit={1}
                  justifyPosition=''
                  showInitials={false}
                  getValueByKey={(user) => user.id}
                  onAdd={(selectedUsers) => {
                    handleChange({
                      target: {
                        value: selectedUsers[0],
                        name: 'assignee_id',
                      },
                    });
                  }}
                /> */}

                <SearchableQuerySelect
                  initialValue={usersForAssigning}
                  queryKey='user-list-assign'
                  queryFn={userService.getPermittedUser}
                  getFunctionParams={(searchKey) => ({
                    searchKey,
                    select: taskAssignUserSelect,
                    action: USER_LIST_ACTIONS.TASK_ADD,
                    ...assignPayload,
                  })}
                  isCustomFunction
                  className='mt-5px w-100'
                  menuPortalTarget={document.getElementById('MODAL')}
                  formatOptionLabel={(data, metaData) => (
                    <UserDropDown data={data} metaData={metaData} />
                  )}
                  onChange={(selectedOption) =>
                    handleChange({ target: { value: selectedOption, name: 'assignee_id' } })
                  }
                  value={relatedTaskData.assignee_id}
                  getOptionLabel={(option) => option.first_name}
                  getOptionValue={(option) => option.id}
                />
              </div>
            </div>

            {/* Labels */}
            <div className='row mt-18px'>
              <div className='col-auto d-flex flex-wrap align-items-center' style={{ gap: 8 }}>
                {/* Label */}
                <ClickableSelect
                  label={
                    relatedTaskData?.label?.length > 0
                      ? relatedTaskData.label.length + ' Labels'
                      : 'Labels'
                  }
                  hasValue={relatedTaskData?.label?.length > 0}
                  shouldReplace
                  icon={<Label size={13} />}
                  customClass={'.react-select-portal'}
                >
                  <SearchableQuerySelect
                    initialValue={labelList}
                    menuIsOpen
                    queryKey='label'
                    queryFn={labelService.labelList}
                    menuPortalTarget={document.getElementById('MODAL')}
                    getFunctionParams={(searchKey) => ({
                      searchKey,
                      select: ['id', 'name', 'text_color', 'label_color', 'createdAt', 'status'],
                    })}
                    isCustomFunction
                    isMulti
                    value={relatedTaskData.label}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={(selectedOptions) =>
                      handleChange({ target: { value: selectedOptions, name: 'label' } })
                    }
                    formatOptionLabel={(data) => {
                      return getFormattedLabel({
                        value: data?.id,
                        color: data?.label_color,
                        label: data?.name,
                      });
                    }}
                    // menuPlacement='top'
                    className='mt-5px w-100'
                  />
                </ClickableSelect>
                {/* Priority */}

                <ReactSelect
                  options={priorityLabelList}
                  menuPortalTarget={document.getElementById('MODAL')}
                  placeholder='Priority Label'
                  classNames={reactSelectUtils.getPriorityLabelClassNames(
                    Boolean(selectedPriority),
                  )}
                  styles={reactSelectUtils.getPriorityLabelStyles()}
                  components={ReactSelectPriorityComponents}
                  formatOptionLabel={(data, metaData) => (
                    <SelectComponents.PriorityLabelMenuItem
                      data={data}
                      metaData={metaData}
                      getLabel={(data) => data.text}
                    />
                  )}
                  getOptionLabel={(option) => option.text}
                  getOptionValue={(option) => option.value}
                  value={selectedPriority}
                  onChange={(selectedOption) => {
                    handleChange({ target: { value: selectedOption?.value, name: 'priority' } });
                  }}
                />

                {/* Expected hours */}
                <ClickableSelect
                  label={expectedTime ? expectedTime : 'Expected Hours'}
                  icon={<Hourglass size={15} />}
                  containerWidth='max-content'
                  hasValue={Boolean(expectedTime)}
                >
                  <MultiUnitDurationInput
                    defaultValues={{
                      hours: relatedTaskData.expected_hours,
                      minutes: relatedTaskData.expected_minutes,
                    }}
                    maxValues={[undefined, 59]}
                    containerClassName='mt-5px'
                    onChange={(selectedOption) => {
                      setRelatedTaskData({
                        ...relatedTaskData,
                        expected_hours: selectedOption.hours,
                        expected_minutes: selectedOption.minutes,
                      });
                    }}
                  />
                </ClickableSelect>
              </div>
            </div>

            <div className='row mt-18px'>
              {/* <div className='col-md-6'> */}
              <div className='input-label'>Start Mode</div>
              <ReactSelect
                options={startModeOptions}
                value={defaultStartMode}
                onChange={handleStartModeChange}
                menuPortalTarget={document.getElementById('MODAL')}
                classNames={{ menuPortal: () => 'z-index-100 fz-14px' }}
                className='mt-5px w-100'
                formatOptionLabel={(data, metaData) => (
                  <StartModeOptionMenu data={data} metaData={metaData} />
                )}
              />
              {/* </div> */}
            </div>
            {relatedTaskData.task_start_mode === START_MODES.CUSTOM_START_TIME && (
              <div className='row mt-10px'>
                <div className='col-md-12'>
                  <MultiUnitDurationInput
                    containerClassName='gap-3'
                    defaultValues={{
                      days: relatedTaskData.custom_start_days,
                      hours: relatedTaskData.custom_start_hours,
                    }}
                    inputKeys={['days', 'hours']}
                    placeholders={['Days', 'Hours']}
                    maxValues={[undefined, 24]}
                    onChange={(data) => {
                      const { days, hours } = data;
                      setRelatedTaskData({
                        ...relatedTaskData,
                        custom_start_days: days,
                        custom_start_hours: hours,
                      });
                    }}
                  />
                </div>
              </div>
            )}

            {/* subtask list and Add subtask button  */}
            {isSubtaskActive && (
              <TemplateSubtask
                key={2}
                list={relatedTaskData.subtasks}
                onAddOrUpdate={handleSubTaskAddOrUpdate}
                onDelete={handleSubtaskDelete}
                position={POSITION.RIGHT}
              />
            )}
          </div>
          {/* Buttons */}
          <div
            className={` w-100 ms-auto modal-btn-container ${
              scrollFlag.isBottomReached ? '' : 'top-light-shadow'
            }`}
          >
            <div className='d-flex gap-2 justify-content-end'>
              <Button.Container
                className='cancel-btn'
                type='button'
                isDisabled={isSubmitting}
                handleOk={onClose}
              >
                <Button.Title title='Cancel' />
              </Button.Container>
              <Button.Container type='submit' isDisabled={isSubmitting}>
                <Button.Title title={`${isEdit ? 'Save' : 'Add'}`} />
              </Button.Container>
            </div>
          </div>
        </form>
      </Modal.View>
    </Modal.Container>
  );
}

const StartModeOptionMenu = ({ data, metaData }) => {
  if (data.isCircular) {
    return (
      <div style={{ color: 'grey' }}>
        <div>
          {data.label}
          {/* <div className='position-relative select-info'>
            <Info size={12} />
            <div className='d-none'>{data.relatedTaskName}</div>
          </div> */}
          {/* - {data.relatedTaskName} */}
          {Boolean(data.relatedTaskName) && (
            <span className='related-task-name-block '>{data.relatedTaskName}</span>
          )}
        </div>
        <div style={{ fontSize: 10 }}>Option unavailable due to circular dependency.</div>
      </div>
    );
  }

  if (metaData.context === 'value') {
    return (
      <>
        {data.label}
        {Boolean(data.relatedTaskName) && (
          <span className='related-task-name-block '>{data.relatedTaskName}</span>
        )}
      </>
    );
  }

  return (
    <div className='d-flex gap-1 align-items-center'>
      {data.label}
      {/* {Boolean(data.relatedTaskName) && (
        <div className='position-relative select-info'>
          <Info size={12} />
          <div className='d-none'>{data.relatedTaskName}</div>
        </div>
      )} */}
      {Boolean(data.relatedTaskName) && (
        <span className='related-task-name-block '>{data.relatedTaskName}</span>
      )}
      {data.info && (
        <div className='position-relative select-info'>
          <Info size={12} />
          <div className='d-none'>{data.info}</div>
        </div>
      )}
    </div>
  );
};

// const SelectCustomMenu = (props, second) => {
//
//   return (
//     <div>
//       {props.options.map((item) => {
//         return item.text;
//       })}
//     </div>
//   );
// };

// const SelectCustomControl = (props, second) => {
//
//   return <div>Hello</div>;
// };

export default RelatedTaskTemplateAddModal;
