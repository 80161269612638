import React, { useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { getTaskTime } from '../Task/taskValidation';

// Custom Tooltip to display details only when hovering over the bar
const CustomTooltip = ({ active = false, payload }) => {
  if (!(active && payload && payload.length)) {
    return null;
  }

  const estimatedTime = payload[0]?.value;
  // const expected_hours = Math.floor(payload[0]?.value);
  // const expected_minutes = Math.round((payload[0]?.value % 1) * 60);

  const actualTime = payload[0]?.payload?.sum_of_effort ? payload[1]?.payload?.sum_of_effort : 0;
  const taskName = payload[0].payload.name;

  if (estimatedTime || actualTime) {
    return (
      <div
        className='custom-tooltip'
        style={{
          backgroundColor: '#fff',
          padding: '10px',
          borderRadius: 4,
          boxShadow: ' 0px 0px 5px 0px rgba(0, 0, 0, 0.12)',
        }}
      >
        <span style={{ fontSize: 14, fontWeight: 500 }}>{taskName}</span>
        <div className='fz-13px-rem d-flex justify-content-between gap-3 w-100 mt-2'>
          <span>Est. Time</span>
          <span style={{ fontWeight: 500 }}>{getTaskTime(estimatedTime)}</span>
        </div>
        <div className='fz-13px-rem d-flex justify-content-between w-100'>
          <span className='me-4'>Sum of efforts</span>
          <span style={{ fontWeight: 500 }}>{actualTime}</span>
        </div>
      </div>
    );
  }
};

// Custom Legend to display circles instead of rectangles
const CustomLegend = ({ payload }) => {
  return (
    <div className='bar-graph-info-container'>
      {payload.map((entry, index) => (
        <div key={`item-${index}`} className='d-flex me-3  align-items-center '>
          {/* Circle instead of rectangle */}
          <div
            style={{
              backgroundColor: entry.color,
            }}
            className='graph-info-label '
          />
          <span>{entry.value}</span>
        </div>
      ))}
    </div>
  );
};

export const BarChartReport = ({ reportData = [] }) => {
  const [toolTipVisible, setToolTipVisible] = useState(false);
  return (
    <ResponsiveContainer width='100%' height={400}>
      <BarChart data={reportData} margin={{ top: 20, right: 30, bottom: 5 }}>
        {/* Adding the CartesianGrid for horizontal and vertical grid lines */}
        <CartesianGrid color='#F0F0F0' strokeWidth={0.5} horizontal={true} vertical={false} />
        <XAxis
          axisLine={{ stroke: '#F0F0F0', strokeWidth: 0.5 }}
          tick={{ stroke: '#F0F0F0', strokeWidth: 0.5 }} // Style the tick marks
          hide={true}
        />
        <YAxis axisLine={false} />
        <Tooltip
          active={toolTipVisible}
          content={<CustomTooltip />}
          cursor={{ fill: 'transparent' }}
        />

        {/* Custom Legend to display circles */}
        <Legend content={<CustomLegend />} align='left' verticalAlign='bottom' />

        {/* Bar components with pointer cursor */}
        <Bar
          dataKey='expected_hours'
          fill='#FBB4AE'
          name='Estimated Time'
          onMouseEnter={() => setToolTipVisible(true)}
          onMouseLeave={() => setToolTipVisible(false)}
          isAnimationActive={false}
          cursor='pointer'
        />
        <Bar
          dataKey='total_effort_in_hour'
          fill='#B3CDE3'
          name='Actual Time'
          onMouseEnter={() => setToolTipVisible(true)}
          onMouseLeave={() => setToolTipVisible(false)}
          isAnimationActive={false}
          cursor='pointer'
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
