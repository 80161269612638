import React, { createContext } from 'react';

const ChatContext = createContext();

function ChatProvider({ children, value }) {
  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
}

export { ChatContext };
export default ChatProvider;
