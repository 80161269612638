/* eslint-disable no-unused-vars */
import { Calendar, Hourglass, Minus, Plus, Users } from 'lucide-react';
import React, { useContext, useEffect, useState } from 'react';
// import ReactSelect from 'react-select';
import {
  convertHtmlToRawContent,
  fullNameUser,
  GlobalContext,
  validateTaskWithTemplateTest,
} from '../../Utils';
import { Button } from '../../_components';
// import SearchableQuerySelect from '../../_components/SearchableQuerySelect';
import { Close, Description, Label, Priority } from '../../Utils/SvgIcons';
// import SearchableQuerySelect from '../../_components/SearchableQuerySelect';
import ToolTip from '../../_components/Tooltip/ToolTip';
// import UserDropDown from '../../_components/UserDropDown';
import { apiConstants, USER_LIST_ACTIONS } from '../../_constants';
import { VALIDATION_VALUES } from '../../_constants/template.constants';
import { userService } from '../../_services';
// import { labelService } from '../../_services/label.service';
import CircularAvatar from '../../_components/Avatar/CircularAvatar';
// import { templateService } from '../../_services/template.service';
import draftToHtml from 'draftjs-to-html';
import moment from 'moment';
import { DateRange } from 'react-date-range';
import { Popover } from '../../_components/Popover';
import UserDropDown from '../../_components/UserDropDown';
import {
  CustomEditor,
  CustomSelectModal,
  CustomTimeInput,
  CustomVariableInput,
  SearchInput,
} from '../TaskComponents/CustomInputs';
import EditableField from '../TaskComponents/EditableField';
import {
  // customSelectBoxStyles,
  formMode,
  taskAssignUserSelect,
  taskValidationConstant,
} from '../taskConstants';
import {
  getExpectedTime,
  getHours,
  getPriorityByContextAPI,
  getStartDateMilliSecond,
  removeTagsAndGetContent,
  validateHours,
} from '../taskValidation';

export function RelatedInlineAdd({
  taskData, // task state
  setTaskData, // state handler
  templateData, // selectedTemplate
  onAdd = () => {}, // button handler for add
  onCancel = () => {}, // button handler for cancel
  onActiveForm, // to check if the form is active then change state management
  isRelatedTask = false, //
  setIsAddRowActive = () => {},
  componentMode = formMode.FORM, // form or inline
  isMain = false,
  isEdit = false,
  editExisting = false,
  isLoading = false,
  userList = [], // assign user list
  labelList = [], // label list
  setUserSearch, // search handler for user
  setLabelSearch, // search handler for label
  userSearch = '', // search key
  verifyUserData = [], // accepts {label,value,...} pairs
  isMenuLabelOn = false, // for settings vise label option visibility
  isList = false,
  tableRef,
}) {
  const validation_fields = templateData?.validation_fields;
  const [isAddRowVisible, setIsAddRowVisible] = useState(false); //state for add menu visibility
  const [contextDescriptionItem, setContextDescriptionItem] = useState(false); //state for add description visibility
  const [priorityLabelList, setPriorityLabelList] = useState(false); //state for  priority label list visibility
  const [contextLabelList, setContextLabelList] = useState(false); //state for  label list visibility
  const [contextAssignUser, setContextAssignUser] = useState(false); //state for  label list visibility

  const [contextDateItem, setContextDateItem] = useState(false); //state for add date visibility
  // const [contextAssignUserItem, setContextAssignUserItem] = useState(false); //state for add user visibility
  const [contextTimeItem, setContextTimeItem] = useState(false); //state for add time visibility
  // const [contextTagItem, setContextTagItem] = useState(false); //state for add tags visibility
  const [validationData, setValidationData] = useState([]); //state for add tags visibility
  // const [editorState, setEditorState] = useState(EditorState.createEmpty()); // to manage state of the editor
  const priorityLabel = getPriorityByContextAPI(); // to fetch the priority  label from context api
  const { makeAlert } = useContext(GlobalContext);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (onActiveForm) {
      setIsAddRowVisible(true);
      setIsAddRowActive(true);
    }
  }, [onActiveForm]);

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name == 'task_start_date' || name == 'task_end_date') {
      value = getStartDateMilliSecond(value);
    } else {
      if (name == 'expected_hours') {
        // to validate the hours according to the start - end dates
        const isValidHour = validateHours(
          taskData?.task_start_date,
          taskData?.task_end_date,
          value,
        );
        if (isValidHour) {
          let time = parseInt(value);
          value = time;
        } else {
          setError(true);
          makeAlert(taskValidationConstant.INVALID_TASK_DATES);
          errorTimeout();
          return;
        }
      } else if (name == 'expected_minutes') {
        let time = parseInt(value);
        value = time;
      }
    }

    if (name === 'label') {
      setTaskData((prev) => {
        const currentLabelDetails = prev?.labelDetails || [];
        const currentLabels = prev?.label || [];
        const updatedLabelsDetails = currentLabelDetails.some(
          (item) => item.id === value || item.id === value?.id,
        )
          ? currentLabelDetails.filter((item) => parseInt(item?.id) !== parseInt(value?.id))
          : [...currentLabelDetails, value];
        const updatedLabels = currentLabels.includes(value.id)
          ? currentLabels
              .filter((item) => parseInt(item) !== parseInt(value?.id))
              ?.map((item) => item)
          : [...currentLabels, value.id];
        return {
          ...prev,
          [name]: updatedLabels,
          labelDetails: updatedLabelsDetails,
        };
      });
      return;
    }
    if (onActiveForm && isRelatedTask && !editExisting) {
      setTaskData({ [name]: value });
      return;
    }
    setTaskData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const errorTimeout = () => {
    setTimeout(() => {
      setValidationData([]);
      setError(false);
    }, 3000);
  };

  // to add new task row - for related tasks
  const addNewRow = () => {
    let validationFields = { ...validation_fields };
    if (isRelatedTask) {
      delete validationFields?.verifying_users;
    }
    if (taskData?.expected_hours || taskData?.expected_minutes) {
      taskData.expected_hours = getHours(taskData); // get hours by hours and minutes
    }
    const validationError = validateTaskWithTemplateTest(validationFields, [taskData]);
    if (!validationError?.isValid) {
      setValidationData(validationError?.requiredFields);
      errorTimeout();
      return;
    }
    const isValidHour = validateHours(
      taskData?.task_start_date,
      taskData?.task_end_date,
      taskData.expected_hours,
    ); // to validate the hours according to the start - end dates
    if (isValidHour) {
      let time = parseFloat(taskData.expected_hours);
      taskData.expected_hours = time;
    } else {
      makeAlert(taskValidationConstant.INVALID_EXPECTED_TIME);
      return;
    }
    // setIsAddRowVisible(false);
    // setIsAddRowActive(false);
    onAdd(setIsAddRowVisible, setIsAddRowActive);
  };

  // to cancel add modal remove the validated value
  const onClickCancel = () => {
    setIsAddRowVisible(false);
    setIsAddRowActive(false);
    setValidationData([]);
    // setTaskData('');
    // const currentEditorState = EditorState.createEmpty();
    // setEditorState(currentEditorState);
    onCancel();
  };

  let selectedUser; // to display selected users
  if (taskData?.assignee_id) {
    // if change user or add new
    selectedUser = userList?.find((item) => item?.id == taskData?.assignee_id);
  }
  const imageUrl = selectedUser?.image_url ?? taskData?.assigned_user?.image_url;
  const finalUrl = imageUrl ? apiConstants.imgUrlS3 + imageUrl : null;

  const blocks = taskData?.note?.blocks;
  const descriptionValue = blocks
    ? blocks?.map((block) => (!block?.text?.trim() && '\n') || block?.text).join('\n')
    : ' ';

  const hasRequestTime =
    Boolean(parseInt(taskData?.expected_hours)) || Boolean(parseInt(taskData?.expected_minutes));

  const filteredLabelList = labelList?.filter(
    (item) =>
      // item.name?.toLowerCase().includes(labelSearch?.toLowerCase()) &&
      !taskData?.labelDetails?.some(
        (selectedItem) => parseInt(selectedItem.id) === parseInt(item.id),
      ),
  );

  const handleSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    handleChange({ target: { name: 'task_start_date', value: startDate } });
    handleChange({ target: { name: 'task_end_date', value: endDate } });
  };

  const currentDate = moment().valueOf();
  let fullName = 'Select User';

  if (taskData?.assigned_user?.first_name) {
    fullName = fullNameUser(
      taskData?.assigned_user?.first_name,
      taskData?.assigned_user?.last_name,
    );
  }
  const selectedPriority = priorityLabel.filter(
    (item) => parseInt(item.id) === parseInt(taskData?.priority),
  );
  const ExpectedTime = getExpectedTime(
    parseInt(taskData?.expected_hours),
    parseInt(taskData?.expected_minutes),
  );

  const StartDate = moment(taskData?.task_start_date).format('MMM DD');
  const EndDate = moment(taskData?.task_end_date).format('MMM DD');

  // checking if the description is present after submission of task
  if (typeof taskData?.note != 'object') {
    // convert note html to content state for editor edit purpose
    taskData.note = convertHtmlToRawContent(taskData?.note);
  }
  const hasFilledNote = removeTagsAndGetContent(draftToHtml(taskData?.note)).trim();

  if (isAddRowVisible) {
    return (
      <div className='inline-task-container w-100'>
        <div className='d-flex flex-wrap gap-1 align-items-center justify-content-between w-100'>
          <div className='mb-1 d-flex flex-1'>
            <CustomVariableInput
              name='name'
              value={taskData?.name}
              placeholder='Enter Task Name'
              onChange={(event) => handleChange(event)}
              className={`inline-related-task-name-box fz-13px-rem flex-1
                ${validationData?.includes('name') ? ' has-error' : ''}`}
            />
          </div>
          <div className='d-flex  gap-1 align-items-center '>
            {/* description section  */}
            <ToolTip
              tooltipText={'Description'}
              popOver={isList}
              isModern
              hideTooltip={contextDescriptionItem}
            >
              <div
                role='button'
                className={`col-md-1 inline-related-task-item-container custom-icon ${
                  validationData?.includes('note')
                    ? ' has-error'
                    : hasFilledNote
                    ? 'has-filled'
                    : ''
                }`}
                hidden={validation_fields?.note == VALIDATION_VALUES.HIDE}
                style={{
                  border: contextDescriptionItem ? '1px solid #3454D1' : '  ',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setContextDescriptionItem(true);
                }}
              >
                <Description
                  width={9}
                  height={11}
                  color={contextDescriptionItem ? '#3454D1' : '#87909E'}
                />
                <Popover.PopoverItem
                  onClose={() => setContextDescriptionItem(false)}
                  positionLeft={(width) => width}
                  positionTop={() => 5}
                  scrollRef={tableRef}
                >
                  <div
                    className={`inline-context-container inline-description ${
                      contextDescriptionItem ? '' : 'tab-click-pop-up'
                    }`}
                  >
                    <CustomEditor
                      wrapperClassName='custom-editor'
                      className='inline-task-description tab-input-focus'
                      // key={editorState}
                      contentState={taskData?.note}
                      handleChange={handleChange}
                    />
                  </div>
                </Popover.PopoverItem>
              </div>
            </ToolTip>
            {/* section of priority  */}
            <ToolTip
              tooltipText={selectedPriority[0]?.label ?? 'Priority'}
              isModern
              hideTooltip={priorityLabelList}
              popOver={isList}
            >
              <div
                role='button'
                className={`col-md-1 inline-related-task-item-container custom-icon ${
                  validationData?.includes('priority')
                    ? ' has-error'
                    : taskData?.priority > 0
                    ? 'has-filled'
                    : ''
                }`}
                // hidden={validation_fields?.note == VALIDATION_VALUES.HIDE}
                style={{ border: priorityLabelList ? '1px solid #3454D1' : '  ' }}
                onClick={(e) => {
                  e.preventDefault();
                  setPriorityLabelList(true);
                }}
              >
                <Priority height={12} width={7} color={priorityLabelList ? '#3454D1' : '#87909E'} />
                <Popover.PopoverItem
                  onClose={() => setPriorityLabelList(false)}
                  positionLeft={(width) => width}
                  positionTop={() => 5}
                  scrollRef={tableRef}
                >
                  <div
                    className={`inline-context-container  menu_popup inline-related-priority-list ${
                      priorityLabelList ? '' : 'tab-click-pop-up'
                    }`}
                  >
                    {priorityLabel.map((item, index) => {
                      const isActive = taskData?.priority == item.id;
                      return (
                        <div
                          key={index}
                          className='sort-item'
                          style={{
                            height: 28,
                            gap: 7,
                            background: taskData?.priority == item.id || isActive ? '#e2fbf0' : '',
                          }}
                          onClick={() => {
                            handleChange({
                              target: {
                                name: 'priority',
                                value: item?.value,
                              },
                            });
                          }}
                        >
                          <Priority height={13} width={8} color='#2A2E34' />
                          {item?.label}
                        </div>
                      );
                    })}
                    <span
                      className='clear-button-list'
                      style={{ color: '#87909E' }}
                      onClick={() => {
                        handleChange({
                          target: {
                            name: 'priority',
                            value: 0,
                          },
                        });
                      }}
                    >
                      <Minus size={17} />
                      Clear
                    </span>
                  </div>
                </Popover.PopoverItem>
              </div>
            </ToolTip>
            {/* end of section  priority  */}
            <ToolTip
              tooltipText={`${StartDate} - ${EndDate}` ?? 'Date'}
              isModern
              hideTooltip={contextDateItem}
              popOver={isList}
            >
              <div
                role='button'
                className={` inline-related-task-item-container custom-icon ${
                  validation_fields?.task_start_date == VALIDATION_VALUES.HIDE &&
                  validation_fields?.task_end_date == VALIDATION_VALUES.HIDE &&
                  'd-none'
                } ${
                  validationData?.includes('task_start_date') ||
                  validationData?.includes('task_end_date')
                    ? ' has-error'
                    : 'has-filled'
                }`}
                style={{ border: contextDateItem ? '1px solid #3454D1' : '  ' }}
                onClick={(e) => {
                  e.preventDefault();
                  setContextDateItem(true);
                }}
              >
                <Calendar height={11} width={11} color={contextDateItem ? '#3454D1' : '#87909E'} />
                <Popover.PopoverItem
                  onClose={() => setContextDateItem(false)}
                  positionLeft={(width) => width}
                  positionTop={() => 5}
                  scrollRef={tableRef}
                >
                  <div
                    className={`inline-context-container custom-field position-left  p-2 pb-0 calender-pop-up inline-calendar ${
                      contextDateItem ? '' : 'tab-click-pop-up'
                    }`}
                  >
                    <span className='fz-14px-rem inline-task-sub-head '>Add Date</span>
                    <div className='date-range-container mt-2'>
                      <DateRange
                        className='date-range-picker'
                        editableDateInputs={true}
                        onChange={handleSelect}
                        moveRangeOnFirstSelection={false}
                        minDate={new Date(currentDate)}
                        ranges={[
                          {
                            startDate: taskData?.task_start_date
                              ? new Date(taskData.task_start_date)
                              : new Date(),
                            endDate: taskData?.task_end_date
                              ? new Date(taskData.task_end_date)
                              : new Date(),
                            key: 'selection',
                          },
                        ]}
                        ariaLabels={{
                          dateInput: {
                            startDate: 'Start date input',
                            endDate: 'End date input',
                          },
                        }}
                      />
                    </div>
                  </div>
                </Popover.PopoverItem>
              </div>
            </ToolTip>
            <ToolTip
              popOver={isList}
              tooltipText={ExpectedTime ?? 'Time'}
              isModern
              hideTooltip={contextTimeItem}
            >
              <div
                role='button'
                className={` inline-related-task-item-container custom-icon
              ${validation_fields?.expected_hours == VALIDATION_VALUES.HIDE && 'd-none'}
              ${
                (validationData?.includes('expected_hours') && !hasRequestTime) ||
                (validation_fields?.expected_hours == VALIDATION_VALUES.REQUIRED &&
                  isEdit &&
                  !hasRequestTime) ||
                error
                  ? ' has-error'
                  : taskData?.expected_hours || taskData?.expected_minutes
                  ? 'has-filled'
                  : ''
              }`}
                style={{ border: contextTimeItem ? '1px solid #3454D1' : '  ' }}
                onClick={(e) => {
                  e.preventDefault();
                  setContextTimeItem(true);
                }}
              >
                <Hourglass color={contextTimeItem ? '#3454D1' : '#87909E'} width={11} height={15} />
                <Popover.PopoverItem
                  onClose={() => setContextTimeItem(false)}
                  positionLeft={(width) => width}
                  positionTop={() => 5}
                  scrollRef={tableRef}
                >
                  <div
                    className={` time-related-container p-1 inline-context-container custom-field position-left  p-2 pb-0 calender-pop-up inline-calendar ${
                      contextTimeItem ? '' : 'tab-click-pop-up'
                    }`}
                  >
                    <CustomTimeInput
                      key={taskData?.expected_hours}
                      handleChange={(e) => handleChange(e)}
                      value={{
                        expected_hours: taskData?.expected_hours,
                        expected_minutes: taskData?.expected_minutes,
                      }}
                      focusField
                    />
                  </div>
                </Popover.PopoverItem>
              </div>
            </ToolTip>
            {/* assign user section  */}
            <ToolTip
              popOver={isList}
              tooltipText={fullName ?? 'Assignee'}
              isModern
              hideTooltip={contextAssignUser}
            >
              <div
                role='button'
                className={`col-md-1 inline-related-task-item-container custom-icon ${
                  validationData?.includes('assignee_id')
                    ? 'has-error'
                    : taskData?.assignee_id
                    ? 'has-filled'
                    : ''
                }`}
                style={{ border: contextAssignUser ? '1px solid #3454D1' : '  ' }}
                onClick={(e) => {
                  e.preventDefault();
                  setContextAssignUser(true);
                }}
              >
                <div className='d-flex justify-content-center align-items-center'>
                  <CircularAvatar
                    key={selectedUser?.id}
                    isMale={selectedUser?.gender === 1}
                    size={15}
                    source={finalUrl}
                  />
                </div>
                <Popover.PopoverItem
                  onClose={() => setContextAssignUser(false)}
                  positionLeft={(width) => width}
                  positionTop={() => 5}
                  scrollRef={tableRef}
                >
                  <div
                    className={`  ${contextAssignUser ? '' : 'tab-click-pop-up'}`}
                    style={{ marginTop: -33 }}
                  >
                    <CustomSelectModal
                      padding={0}
                      top={35}
                      left={0}
                      right={10}
                      listData={userList}
                      setSearch={(value) => setUserSearch(value)}
                      selectedValueVisibility={false} // show the selected value
                      search={userSearch}
                      valueId={taskData?.assignee_id}
                      handleChange={(value) => {
                        setUserSearch('');
                        handleChange({
                          target: {
                            value: parseInt(value?.id),
                            name: 'assignee_id',
                          },
                        });
                        handleChange({
                          target: { value: value, name: 'assigned_user' },
                        });
                      }}
                      formatOptionLabel={(data, metaData) => (
                        <UserDropDown size={19} gap={14} data={data} metaData={metaData} />
                      )} // Show formatted label for users
                    />
                  </div>
                </Popover.PopoverItem>
              </div>
            </ToolTip>
            {/* label selection section  */}
            {isMenuLabelOn && (
              <ToolTip
                popOver={isList}
                tooltipText={'Label'}
                isModern
                hideTooltip={contextLabelList}
              >
                <div
                  role='button'
                  className={`col-md-1 inline-related-task-item-container custom-icon ${
                    validationData?.includes('label')
                      ? ' has-error'
                      : taskData?.label?.length > 0
                      ? 'has-filled'
                      : ''
                  }`}
                  // hidden={validation_fields?.note == VALIDATION_VALUES.HIDE}
                  style={{ border: contextLabelList ? '1px solid #3454D1' : '  ' }}
                  onClick={(e) => {
                    e.preventDefault();
                    setContextLabelList(true);
                  }}
                >
                  <Label width={10} height={10} color={contextLabelList ? '#3454D1' : '#87909E'} />
                  <Popover.PopoverItem
                    onClose={() => setContextLabelList(false)}
                    positionLeft={(width) => width}
                    positionTop={() => 5}
                    scrollRef={tableRef}
                  >
                    <div
                      className={`inline-context-container menu_popup inline-priority-list ${
                        contextLabelList ? '' : 'tab-click-pop-up'
                      }`}
                    >
                      <div className='search-list-container'>
                        <div className='inline-task-label-container'>
                          {taskData?.labelDetails?.map((item, idx) => (
                            <div
                              key={idx}
                              className='label-item-task-list'
                              style={{
                                background: item?.label_color,
                                color: item?.text_color,
                                margin: '0 10px 10px 0',
                                border: `1px solid ${item?.text_color}`,
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleChange({ target: { value: item, name: 'label' } });
                              }}
                            >
                              {item.name}
                            </div>
                          ))}
                        </div>
                        <SearchInput
                          className='tab-input-focus'
                          handleChange={(value) => setLabelSearch(value)}
                        />
                        <div className='inline-task-label-container'>
                          {filteredLabelList?.length === 0 && (
                            <span style={{ color: 'lightgray' }}>no data</span>
                          )}
                          {filteredLabelList.map((item, idx) => (
                            <div
                              key={idx}
                              className='label-item-task-list'
                              style={{
                                background: item?.label_color,
                                color: item?.text_color,
                                margin: '0 10px 10px 0',
                                border: taskData?.labelDetails?.includes(item)
                                  ? `1px solid ${item?.text_color}`
                                  : '',
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleChange({ target: { value: item, name: 'label' } });
                              }}
                            >
                              {item.name}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </Popover.PopoverItem>
                </div>
              </ToolTip>
            )}
            <div
              className={` px-1 ${
                (validation_fields?.verifying_users == VALIDATION_VALUES.HIDE ||
                  (templateData?.verifying_users?.length == VALIDATION_VALUES.HIDE &&
                    templateData?.is_verify_user_changeable == VALIDATION_VALUES.HIDE) ||
                  isRelatedTask) &&
                'd-none'
              }`}
            >
              <EditableField
                onSave={(val) => {
                  handleChange({
                    target: { value: val?.verifying_users, name: 'verifying_users' },
                  });
                }}
                key={1}
                type='select'
                queryKey='user-list-verify'
                queryFn={userService.getPermittedUser}
                functionParams={{
                  select: taskAssignUserSelect,
                  action: USER_LIST_ACTIONS.TASK_VERIFY,
                }}
                placeholder='Select Verify Users'
                list={verifyUserData}
                isEditable={validation_fields?.verifying_users !== VALIDATION_VALUES.HIDE}
                data={verifyUserData.filter((item) =>
                  taskData?.verifying_users?.includes(item?.id),
                )}
                name='verifying_users'
                isMulti
              >
                <div
                  role='button'
                  className={`inline-task-icon-container custom-icon  custom-icon ${
                    validationData?.includes('verifying_users') ? ' has-error' : ''
                  }`}
                  style={{ minWidth: 'fit-content' }}
                >
                  <Users size={16} color='#879096' />
                  {taskData?.verifying_users?.length > 0 && taskData?.verifying_users?.length}
                </div>
              </EditableField>
            </div>
            {/* { console.log(componentMode , componentMode == formMode.INLINE ,'test')} */}
            <div className='mx-1' hidden={componentMode == formMode.INLINE}>
              {isEdit ? (
                <ToolTip tooltipText={'Close'} isModern>
                  <div
                    role='button'
                    className={`col-md-1 inline-related-task-item-container custom-icon `}
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickCancel();
                    }}
                  >
                    <Close width={10} height={12} />{' '}
                  </div>
                </ToolTip>
              ) : (
                <div className='d-flex gap-2'>
                  <Button.Container
                    className='inline-related-task-cancel-button'
                    handleOk={() => onClickCancel()}
                  >
                    <Button.Title className={'button-inline-related-task-font'} title='Cancel' />
                  </Button.Container>
                  <Button.Container
                    type='Save'
                    handleOk={() => addNewRow()}
                    // hidden={isUpdateButtonVisible}
                    isDisabled={isLoading}
                    className='blue-button-inline-related-task'
                  >
                    <Button.Title
                      className={'button-inline-related-task-font'}
                      title={isMain ? 'Save' : isEdit ? 'Update' : 'Add'}
                    />
                    <Button.Loading isLoading={isLoading} color='#fff' size={20} />
                  </Button.Container>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='w-100 d-flex align-items-center '>
      <ToolTip tooltipText={'Add Related task'} isModern popOver={isList}>
        <div
          onClick={() => {
            setIsAddRowVisible(true);
            setIsAddRowActive(true);
          }}
          className='mx-4 px-3 py-1 w-fit-content d-flex align-items-center gap-1 btn-wrapper my-1 '
        >
          <Plus strokeWidth={1.5} color='#879096' size={18} />
        </div>
      </ToolTip>
    </div>
  );
}
