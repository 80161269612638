/* eslint-disable no-unused-vars */
import { Check } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { SortIcons } from '../Utils';

export const TaskListSort = ({
  // position = POSITION.RIGHT,
  searchParams,
  setSearchParams,
  setQuery,
  handleClose = () => {},
}) => {
  // const positionClassName = position === POSITION.RIGHT ? 'right-30px' : 'left-30px';
  const sortList = ['Priority', 'Task Date', 'Recently Viewed', 'Overdue'];
  const [checkedItems, setCheckedItems] = useState({});

  useEffect(() => {
    const sortParams = searchParams.getAll('sort');
    const initialCheckedItems = {};
    sortList.forEach((item) => {
      initialCheckedItems[item] = sortParams.includes(item);
    });
    setCheckedItems(initialCheckedItems);
  }, [searchParams]);

  const getArrayParam = (param) => {
    const value = searchParams.getAll(param);
    return value.length > 0 ? value : null;
  };

  const handleCheckBoxChange = (item) => {
    searchParams.delete('sort');
    searchParams.append('sort', item);
    setSearchParams(searchParams);
  };

  const clearSort = (event) => {
    setCheckedItems({});
    searchParams.delete('sort');
    setSearchParams(searchParams);
    handleClose(event);
  };

  return (
    <div
      className={`menu_popup filter-container `}
      style={{ width: 260, padding: '20px 0px', right: 0 }}
    >
      <div className='filter-head mb-1 ' style={{ padding: '0px 20px 0px 20px' }}>
        <h6 className='menu-item-head '>Sort By</h6>
        <div className='menu-item-clear' style={{ fontSize: 12 }} onClick={clearSort}>
          Clear
        </div>
      </div>

      {sortList.map((item) => (
        <SortItem
          key={item}
          name={item}
          isChecked={checkedItems[item] || false}
          onChange={(event) => {
            handleCheckBoxChange(item);
            handleClose(event);
          }}
        />
      ))}
    </div>
  );
};

const SortItem = ({ name, isChecked, onChange }) => {
  return (
    <div className='sort-item' onClick={onChange}>
      <div style={{ width: '22px' }}>
        <img src={getSortIcon(name)} alt='' />
      </div>

      <span className='flex-grow-1 menu-item-clear'>{name}</span>
      {isChecked ? <Check size={18} /> : ''}
    </div>
  );
};

const getSortIcon = (sort) => {
  const path = SortIcons[sort];
  return path;
};
