/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from '../_components';
import { AI_VIEW } from '../_constants/ai.constants';
import { useAudioRecorder } from '../Hook/useAudioRecorder';
import useSpeechSynthesis from '../Hook/useSpeechSynthesis';
import { useUser } from '../Hook/useUser';
import { Images } from '../Utils';
import './ai.css'; // Add CSS for styling
import { AnimatedLoader } from './AiComponents/AnimateLoader';
import { ModalHead } from './AiComponents/ModalHead';

export function AiAudioModal({
  newResponse,
  openAudioModal,
  setOpenAudioModal,
  setInput,
  generateResponse,
  isPending,
}) {
  const [latestResponse, setLatestResponse] = useState();
  useEffect(() => {
    setLatestResponse(newResponse?.response);
  }, [newResponse?.response[0]]);

  return (
    <div>
      {openAudioModal && (
        <Modal.Container>
          <Modal.View className={'audio-modal'}>
            <ModalHead
              setLatestResponse={setLatestResponse}
              setOpenAudioModal={setOpenAudioModal}
            />

            <AudioRecordView
              generateResponse={generateResponse}
              newResponse={newResponse}
              isPending={isPending}
              latestResponse={latestResponse}
              setLatestResponse={setLatestResponse}
            />

            {/* <AiVoiceRecord
              setLatestResponse={setLatestResponse}
              generateResponse={generateResponse}
              setInput={setInput}
              setOpenAudioModal={setOpenAudioModal}
              isPending={isPending}
              latestResponse={latestResponse}
            /> */}
          </Modal.View>
        </Modal.Container>
      )}
    </div>
  );
}

const AudioRecordView = ({
  generateResponse,
  isPending,
  newResponse,
  latestResponse,
  setLatestResponse,
}) => {
  const timeoutRef = useRef(null);
  const [currentComponent, setCurrentComponent] = useState(AI_VIEW.INTRO_VIEW);

  const aiComponents = {
    [AI_VIEW.INTRO_VIEW]: {
      component: <IntroView setCurrentComponent={setCurrentComponent} />,
    },
    [AI_VIEW.RECORDING_VIEW]: {
      component: (
        <RecordingView
          setCurrentComponent={setCurrentComponent}
          generateResponse={generateResponse}
          isPending={isPending}
          newResponse={newResponse}
          latestResponse={latestResponse}
          setLatestResponse={setLatestResponse}
        />
      ),
    },
  };

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setCurrentComponent(AI_VIEW.RECORDING_VIEW);
    }, 3500);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <section style={{ flexGrow: 1, display: 'flex' }}>
      {aiComponents[currentComponent]?.component}
    </section>
  );
};

const IntroView = ({ setCurrentComponent }) => {
  const user = useUser();
  let welComeMessage = `Hi ${user?.first_name}, how can I help you?`;
  const { speak, stop } = useSpeechSynthesis(welComeMessage);
  useEffect(() => {
    speak();
    return () => {
      stop();
    };
  }, []);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '5vh',
          width: '100%',
        }}
      >
        <div className='gradient-border'>
          <img loading='lazy' src={Images.ai_mic} style={{ minHeight: '40px', minWidth: '40px' }} />
        </div>

        <div style={{ color: '#4A70AA', fontSize: 25, marginTop: 40 }}>{welComeMessage}</div>
        {/* <div onClick={() => setCurrentComponent(AI_VIEW.RECORDING_VIEW)}>Speak</div> */}
      </div>
    </>
  );
};

const RecordingView = ({ generateResponse, isPending, latestResponse, setLatestResponse }) => {
  const [animateLoader, setAnimateLoader] = useState();

  const {
    transcription,
    recording,
    isLoading,
    error,
    startRecording,
    stopRecording,
    clearMicrophone,
    isTranscriptionAvailable,
    retryPermission,
    checkMicrophonePermission,
  } = useAudioRecorder({
    isNeedTranscript: true,
    isAutoStopBased: true,
    onStop: () => {
      setAnimateLoader(false);
    },
  });

  const { isSpeaking, speak, stop } = useSpeechSynthesis(latestResponse?.join(' '));

  useEffect(() => {
    setAnimateLoader(recording || isSpeaking);
  }, [recording, isSpeaking]);

  console.log('ERROR', error);

  // if (error) {
  //   return <div style={{ textAlign: 'center', marginTop: 15 }}>{error}</div>;
  // }

  useEffect(() => {
    if (!isSpeaking && latestResponse) {
      speak();
      setAnimateLoader(true);
    }
    return () => {
      stop();
      setAnimateLoader(false);
    };
  }, [latestResponse]);

  useEffect(() => {
    startRecording();
    return () => {
      clearMicrophone();
      stopRecording();
    };
  }, []);

  useEffect(() => {
    if (transcription && isTranscriptionAvailable) {
      generateResponse(transcription);
    }
  }, [transcription]);

  return (
    <div className='recording-container'>
      {error && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
          <div style={{ width: '80%', textAlign: 'center', fontSize: 17 }}>{error}</div>
        </div>
      )}
      {!error && (
        <>
          <div className='loader-container'>
            <div className='gradient-border'>
              <AnimatedLoader start={animateLoader} />
            </div>
          </div>

          {/* CONTENT */}
          <div className='ai-content'>
            {recording && <div className='listening-txt'>Listening...</div>}
            {isLoading && <div className='listening-txt'>Transcribing...</div>}
            {!latestResponse?.length && transcription && (
              <div className='listening-txt'>{transcription}</div>
            )}
            {isPending && <div style={{ textAlign: 'center', fontSize: 14 }}>Generating...</div>}

            {latestResponse && (
              <div style={{ padding: '5px 15px' }}>
                {latestResponse?.map((line, index) => (
                  <div
                    key={index}
                    className='animated-line'
                    style={{
                      animationDelay: `${index * 0.1}s`,
                      fontSize: 20,
                      lineHeight: '1.9rem',
                      color: '#3454d1',
                    }}
                  >
                    {line}
                  </div>
                ))}
              </div>
            )}
          </div>
          {/* CONTENT END */}

          {/* ACTIONS */}
          <div className='action-container'>
            <div style={{ display: 'flex', gap: 10 }}>
              {latestResponse && latestResponse?.length && (
                <div
                  className='ai-button'
                  onClick={() => {
                    if (isSpeaking) {
                      stop();
                      setAnimateLoader(false);
                    } else {
                      setAnimateLoader(true);
                      speak();
                    }
                  }}
                >
                  {isSpeaking ? 'PAUSE' : 'PLAY'}
                </div>
              )}

              {recording && (
                <div
                  className='ai-button'
                  onClick={() => {
                    stopRecording();
                  }}
                >
                  STOP
                </div>
              )}
              {!recording && !isPending && (
                <div
                  className='ai-button'
                  onClick={() => {
                    startRecording();
                    setLatestResponse();
                  }}
                >
                  {latestResponse?.length ? 'SPEAK NEW' : 'SPEAK'}
                </div>
              )}
            </div>
          </div>
          {/* ACTIONS END*/}
        </>
      )}
    </div>
  );
};
