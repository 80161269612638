import { Calendar, Clock3, Frame, MessageCircle, SquareCheck } from 'lucide-react';
import React from 'react';

function TimeSheetListHead() {
  return (
    <thead style={{ background: '#F3F5F7' }}>
      <tr>
        <th className='th'>
          <div className='th-content'>
            <Frame size={18} strokeWidth={1.5} />
            Sl.No
          </div>
        </th>
        <th className='th'>
          <div className='th-content'>
            <SquareCheck size={18} strokeWidth={1.5} />
            Projects
          </div>
        </th>
        <th className='th'>
          <div className='th-content'>
            <SquareCheck size={18} strokeWidth={1.5} />
            Tasks
          </div>
        </th>
        <th className='th'>
          <div className='th-content'>
            <Calendar size={18} strokeWidth={1.5} />
            Start Date
          </div>
        </th>
        <th className='th'>
          <div className='th-content'>
            <Calendar size={18} strokeWidth={1.5} />
            End Date
          </div>
        </th>
        <th className='th'>
          <div className='th-content'>
            <Clock3 size={18} strokeWidth={1.5} />
            Actual Hours
          </div>
        </th>
        <th className='th'>
          <div className='th-content'>
            <Clock3 size={18} strokeWidth={1.5} />
            Billing Hours
          </div>
        </th>
        <th className='th'>
          <div className='th-content'>
            <MessageCircle size={18} strokeWidth={1.5} />
            Note
          </div>
        </th>
        <th className='th'>
          <div className='th-content'>Action</div>
        </th>
      </tr>
    </thead>
  );
}

export default TimeSheetListHead;
