import { PermissionData } from '../_constants';

export const usePermissions = (permissions) => {
  const permissionMap = {};

  Object.keys(PermissionData).forEach((key) => {
    permissionMap[key] = permissions?.some(
      (item) => item?.action === PermissionData?.[key] && item?.is_access,
    );
  });

  return permissionMap;
};
