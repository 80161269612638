/* eslint-disable no-unused-vars */
import { X } from 'lucide-react';
import React from 'react';
import { createPortal } from 'react-dom';
import { Images } from '../Utils/Images';
import { Button } from './CustomButton';

const Container = ({ className, children, handleClose }) => {
  const modalContent = document.getElementById('MODAL');
  return createPortal(
    <div
      className={`fade-opacity ${className ? className : 'model_contain'}`}
      onClick={() => handleClose()}
    >
      {children}
    </div>,
    modalContent,
  );
};

const View = ({ children, className }) => {
  return (
    <div className='view-center'>
      <div
        onClick={(e) => e.stopPropagation()}
        className={`scale-up-center ${className ? className : 'delete_modal_view'}`}
      >
        {children}
      </div>
    </div>
  );
};

const Head = ({ handleClose }) => {
  return (
    <>
      <X
        onClick={() => handleClose()}
        size={20}
        color='black'
        className='close_button_position'
      />
      <div className='delete_bin_head'>
        <div className='delete_bin_image_container'>
          <img src={Images.DeleteBin} width={35} height={50} alt='' />
        </div>
      </div>
    </>
  );
};

const Buttons = ({ buttonLabel, className, handleClick }) => {
  const buttonText = buttonLabel || 'Delete';
  const buttonStyle = className;

  return (
    <div>
      <Button.Container className={buttonStyle} handleOk={handleClick}>
        <Button.Title title={buttonText} />
      </Button.Container>
    </div>
  );
};

const ButtonView = ({ children, className }) => {
  const buttonStyle = className || 'delete_modal_button';
  return <div className={buttonStyle}>{children}</div>;
};

const Description = ({ description, className }) => {
  const deleteMessage = description || 'Are you sure ? ';
  const descStyle = className || 'delete_modal_note';

  return <h6 className={descStyle}>{deleteMessage}</h6>;
};

const ModalTitle = ({ heading, className }) => {
  const deleteTitle = heading || 'Delete Title ';
  const titleStyle = className || 'delete_modal_title';

  return <h4 className={titleStyle}>{deleteTitle}</h4>;
};

export const DeleteModal = {
  Container,
  View,
  Head,
  ModalTitle,
  Description,
  Buttons,
  ButtonView,
};
