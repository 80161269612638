import { useQuery } from '@tanstack/react-query';
import { Search } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { focusInputByClassName, fullNameUser } from '../Utils';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import { apiConstants, USER_LIST_ACTIONS } from '../_constants';
import useDebounce from '../_helpers/useDebounce';
import { userService } from '../_services';

export const ExceedUserListPopUp = ({
  userList,
  searchParams,
  setSearchParams,
  toggleSelect,
  nonExceededUserList,
  setIsUserSelected,
}) => {
  const [checkedItems, setCheckedItems] = useState({});
  const [searchKey, setSearchKey] = useState(undefined);
  const debouncedSearch = useDebounce(searchKey, 500);
  const [selectedUser, setSelectedUser] = useState([]);

  useEffect(() => {
    focusInputByClassName('exceed-user-search');
  }, []);

  const exceedUserList = [
    ...selectedUser,
    ...userList.filter((user) => !selectedUser.some((selected) => selected.id === user.id)),
  ];

  const { data: searchedUserList } = useQuery({
    queryKey: ['task-list-user-list', debouncedSearch],
    queryFn: () =>
      userService.getPermittedUser({
        action: USER_LIST_ACTIONS.TASK_FILTER,
        select: ['id', 'first_name', 'last_name', 'image_url', 'gender'],
        searchKey: debouncedSearch,
        exclude_ids: nonExceededUserList?.map((item) => item?.id),
      }),
    select: (response) => response.data.rows,
    enabled: Boolean(debouncedSearch),
  });

  useEffect(() => {
    const userParams = searchParams?.getAll('user_id');
    const initialCheckedItems = {};
    const initiallySelectedUsers = [];

    // const missingUserIds = userParams.filter(
    //   (userId) => !userList.some((user) => user.id.toString() === userId),
    // );
    const missingUserIds = userParams.filter(
      (userId) =>
        !userList.some((user) => user.id.toString() === userId) &&
        !nonExceededUserList.some((user) => user.id.toString() === userId),
    );

    // Check users that are already in the userList
    userList.forEach((item) => {
      const isChecked = userParams.includes(item.id.toString());
      initialCheckedItems[item.id] = isChecked;

      if (isChecked) {
        initiallySelectedUsers.push(item); // Add checked users to the selectedUser state
      }
    });

    // If there are missing users, fetch them
    if (missingUserIds?.length > 0) {
      missingUserIds?.map((item) => {
        userService.userGet({ id: item }).then((response) => {
          const fetchedUsers = response.data;
          // Update checkedItems and selectedUser with the fetched users
          initialCheckedItems[fetchedUsers.id] = true; // Mark them as checked
          initiallySelectedUsers.push(fetchedUsers); // Add to selected users

          // Update state after fetching missing users
          setCheckedItems(initialCheckedItems);
          setSelectedUser(initiallySelectedUsers);
        });
      });
    } else {
      // If no missing users, just update the state with the initial values
      setCheckedItems(initialCheckedItems);
      setSelectedUser(initiallySelectedUsers);
    }
  }, [searchParams]);

  useEffect(() => {
    if (selectedUser?.length > 0) {
      setIsUserSelected(true);
    } else {
      setIsUserSelected(false);
    }
  }, [selectedUser]);

  const handleCheckBoxChange = (userId, user) => {
    setCheckedItems((prevState) => {
      const newCheckedItems = {
        ...prevState,
        [userId]: !prevState[userId],
      };

      const currentParams = new URLSearchParams(searchParams);
      if (newCheckedItems[userId]) {
        currentParams.append('user_id', userId);
      } else {
        const updatedUserIds = currentParams
          .getAll('user_id')
          .filter((id) => id !== userId.toString());
        currentParams.delete('user_id');
        updatedUserIds.forEach((id) => currentParams.append('user_id', id));
      }
      currentParams.set('pageNo', 1);
      setSearchParams(currentParams);
      return newCheckedItems;
    });

    // Update selectedUser
    setSelectedUser((prevFilterUser) => {
      if (prevFilterUser.some((item) => item.id === user.id)) {
        return prevFilterUser.filter((item) => item.id !== user.id); // Remove user if already selected
      } else {
        return [...prevFilterUser, user]; // Add user to selectedUser if not present
      }
    });
  };
  return (
    <div className='menu_popup filter-container p-0 pb-2' style={{ width: 200 }}>
      <div className='d-flex align-items-center px-3 gap-1'>
        <Search size={15} color='grey' />
        <input
          placeholder='Search'
          className='w-100 unset-btn exceed-user-search' // NOTE: Don't use this className on any other element
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
          style={{ height: 40 }}
        />
      </div>
      {searchedUserList?.length === 0 && Boolean(debouncedSearch) && (
        <div className='text-center' style={{ color: 'rgb(135, 135, 135)' }}>
          No data
        </div>
      )}
      {(debouncedSearch ? searchedUserList : exceedUserList)?.map((user) => {
        const isMale = user?.gender === 1;
        const isNeutral = user?.gender === 3;
        const url = user?.image_url;
        const finalUrl = url ? apiConstants.imgUrlS3 + url : null;
        const fullName = fullNameUser(user?.first_name, user?.last_name);

        return (
          <div className='d-flex align-items-center mb-2 px-3' key={user.id}>
            <span>
              <input
                className='project_menu_check_box  cursor-pointer'
                style={{ marginRight: 5, marginTop: 5 }}
                type='checkbox'
                checked={checkedItems[user?.id] || false}
                onChange={() => {
                  toggleSelect(user);
                  handleCheckBoxChange(user?.id, user);
                }}
              />
            </span>

            <span
              className='flex-grow-1  cursor-pointer'
              onClick={() => {
                toggleSelect(user);
                handleCheckBoxChange(user?.id, user);
              }}
            >
              <div className='d-flex align-items-center'>
                <CircularAvatar
                  size={25}
                  className='fz-none zoom-in employee-img-with-low-border'
                  source={finalUrl}
                  isMale={isMale}
                  isNeutral={isNeutral}
                />
                <span className='name-overflow'>{fullName}</span>
              </div>
            </span>
          </div>
        );
      })}
    </div>
  );
};
