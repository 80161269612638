export const COLOR_CONSTANT = {
  blue: {
    bg: '#def0ff',
    bar: '#007bff',
    text: '#3a444e',
  },
  orange: {
    bg: '#fff2de',
    bar: '#ff7f00',
    text: '#3a444e',
  },
  regular: {
    bg: '#e0e0e0',
    bar: '#4f4f4f',
    text: '#3a444e',
  },
  red: {
    bg: '#ffeceb',
    bar: '#ff4d4d',
    text: '#3a444e',
  },
  green: {
    bg: '#dcffe5',
    bar: '#4caf50',
    text: '#3a444e',
  },
  violet: {
    bg: '#dadaf5',
    bar: '#07082F',
    text: '#3a444e',
  },
};
