/* eslint-disable no-unused-vars */
import Picker from '@emoji-mart/react';
import { Mic, Plus, SendHorizontal, Smile, Square, Trash2, X } from 'lucide-react';
import React, { useRef, useState } from 'react';
import useOutsideClickHandler from '../Hook/useOutsideClickHandler';
import { convertMillisecondsToDuration, getFileType, sanitizeFileName } from '../Utils';
import { Button } from '../_components';
import TextAreaComponent from '../_components/TextAreaComponent';
import { CHAT_TEXTAREA_CLASS_NAME, MESSAGE_TYPES } from '../_constants/chat.constants';
import ChatAttachmentsMenu from './ChatAttachments/ChatAttachmentsMenu';
import { ChatMessageCards } from './ChatAttachments/ChatMessageCards';
import useRecorder from './useRecorder';

const ChatInputBox = ({
  hideSend = false,
  hideAudioRec = false,
  hideAttachments = false,
  isError,
  onSubmit = () => {},
  onInputChange = () => {},
  onFileChange = () => {},
  initialObject = '',
  onAttachmentRemove = () => {},
  onPaste = () => {},
  isInTask,
}) => {
  const { audioURL, isRecording, startRecording, stopRecordingAsync, setAudioURL, timer } =
    useRecorder();
  const [attachmentsVisible, setAttachmentsVisible] = useState();

  const [isEmojiPickerShown, setIsEmojiPickerShown] = useState(false);
  const { containerRef } = useOutsideClickHandler({ onClose: () => setIsEmojiPickerShown(false) });

  const formRef = useRef(null);
  const Icon = attachmentsVisible ? X : Plus;

  const handleFileChange = (audioUrl) => {
    let fileName = URL.createObjectURL(audioUrl).split('/').pop();
    const extension = audioUrl.type.split('/').pop().split(';')[0];
    const file = new File([audioUrl], fileName + '.' + extension, {
      type: audioUrl.type,
    });
    const recordedFile = {
      name: file.name.replace(/[^\w.-]|[\s&]/g, ''),
      sanitizeName: sanitizeFileName(file.name),
      fileType: getFileType(file.name.split('.').pop()),
      size: file.size,
      extension: file.name.split('.').pop(),
      file,
      attachedType: MESSAGE_TYPES.RECORDED_AUDIO,
      blobUrl: URL.createObjectURL(file),
      isUploading: true,
      isFailed: false,
      isSuccess: false,
    };
    onFileChange([recordedFile]);
  };

  // UI
  if (audioURL) {
    return (
      <AudioPreview
        isError={isError}
        onAttachmentRemove={onAttachmentRemove}
        audioURL={audioURL}
        initialObject={initialObject}
        onSubmit={(e) => {
          e.preventDefault();
          setAudioURL(null); //remove the file and preview
          onSubmit();
        }}
        setAudioURL={setAudioURL}
      />
    );
  }

  if (isRecording) {
    return (
      <RecordingPreview
        onStop={async () => {
          const audioUrl = await stopRecordingAsync();
          handleFileChange(audioUrl);
        }}
        timer={timer}
      />
    );
  }
  return (
    <form
      ref={formRef}
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
      style={{ position: 'relative', maxHeight: 148, marginBottom: 10 }}
      className='d-flex taskDetails__chatBox--typeBox m-auto'
    >
      <div className='pointer' ref={containerRef} onClick={() => setIsEmojiPickerShown(true)}>
        <Smile color='#879096' size={16} />
        {isEmojiPickerShown && (
          <Picker
            previewPosition={'none'}
            onEmojiSelect={(emoji) => {
              onInputChange({ target: { value: initialObject.message + emoji.native } });
            }}
            theme={'light'}
          />
        )}
      </div>

      <TextAreaComponent
        onChange={onInputChange}
        onPaste={onPaste}
        onSend={onSubmit}
        isInTask={isInTask}
        placeholder='Write a message...'
        className={`${CHAT_TEXTAREA_CLASS_NAME} m-1`}
        text={initialObject.message}
        containerRef={formRef}
        setText={(text) => onInputChange({ target: { value: text } })}
      />
      <div className='d-flex gap-2'>
        {!hideAttachments && (
          <div
            className='icon_bg pointer'
            onClick={(event) => {
              event.stopPropagation();
              setAttachmentsVisible((prev) => !prev);
            }}
          >
            <Icon color='#879096' size={18} />
          </div>
        )}
        {attachmentsVisible && (
          <ChatAttachmentsMenu
            onChange={onFileChange}
            onRequestClose={() => {
              setAttachmentsVisible(false);
            }}
            multiSelect={false}
          />
        )}
        {!hideAudioRec && !initialObject.message && (
          <div className='icon_bg pointer' onClick={() => startRecording()}>
            <Mic color='#879096' size={18} />
          </div>
        )}
        {!hideSend && (
          <div>
            <Button.Container className={'sent-btn'} isDisabled={isError}>
              {/* <Button.Image src={''} /> */}
              <SendHorizontal size={18} />
              {/* <Button.Title title={'Sent'} /> */}
            </Button.Container>
          </div>
        )}
      </div>
    </form>
  );
};

const RecordingPreview = ({ timer, onStop }) => {
  return (
    <div style={{ position: 'relative' }} className='d-flex taskDetails__chatBox--typeBox'>
      <div className='d-flex w-100'>
        <div className='col chat_msg'>Recording.... </div>
        <div className='chat_msg'>{convertMillisecondsToDuration(timer)}</div>
        <div className='d-flex col justify-content-end pointer' onClick={onStop}>
          <Square size={18} color='red' fill='red' />
        </div>
      </div>
    </div>
  );
};
const AudioPreview = ({
  onAttachmentRemove,
  isError,
  initialObject,
  setAudioURL = () => {},
  onSubmit,
}) => {
  return (
    <form
      style={{ position: 'relative', paddingLeft: '5px' }}
      className='d-flex taskDetails__chatBox--typeBox'
      onSubmit={onSubmit}
    >
      <div className='d-flex w-100 gap-2 input-recorder-preview'>
        <ChatMessageCards.RecordedAudio isLocal file={initialObject.files[0]} iconSize={25} />
        <div className='d-flex  align-items-center pointer'>
          <Trash2
            color='#879096'
            size={18}
            onClick={() => {
              onAttachmentRemove(() => []);
              setAudioURL(null);
            }}
          />
        </div>
        <Button.Container className={'audio_sent-btn mt-1'} isDisabled={isError}>
          <SendHorizontal />
        </Button.Container>
      </div>
    </form>
  );
};

export default ChatInputBox;
