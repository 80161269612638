import React from 'react';
import { TemplateAdd } from './TemplateAdd';

function TemplateAddModal() {
  return (
    // For page design
    <div className='template-root'>
      <TemplateAdd />
    </div>
    // For modal design
    // <Modal.Container className={'template-modal-root-container'}>
    //   <Modal.View
    //     className={'template-modal-form m-auto'}
    //     containerClass={'h-100 d-flex align-items-center justify-content-center'}
    //   >
    //     <TemplateAdd />
    //   </Modal.View>
    // </Modal.Container>
  );
}

export default TemplateAddModal;
