import moment from 'moment';
import React, { useEffect, useState } from 'react';
import StackedAvatars from '../_components/Avatar/StackedAvatars';
import { DetailsRow } from '../_components/DetailsRow';
import { ErrorComponent } from '../_components/ErrorComponent';
import { Popover } from '../_components/Popover';
import { alertConstants } from '../_constants';
import { POSITION } from '../_constants/contextMenu.constants';
import { folderService } from '../_services';
import { fullNameUser, getSize, renderUserDetails } from '../Utils';
import { Calendar, FileIcon, FileSize, LoadingIcon, ShareIcon, User } from '../Utils/SvgIcons';

export const FolderDetails = ({ id, handleClose = () => {} }) => {
  const [folder, setFolder] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ message: '' });
  const [query, setQuery] = useState({
    select: ['id', 'name', 'created_time', 'shared_with_team', 'shared_with_user'],
  });

  useEffect(() => {
    if (id) {
      setQuery((prevQuery) => ({ ...prevQuery, id }));
      getFolder({ ...query, id });
    }
  }, [id]);

  /**
   * Get Folder
   */
  const getFolder = async (queryTemp) => {
    let queryNew = queryTemp || query;
    setIsLoading(true);

    try {
      const folder = await folderService.folderGet(queryNew);
      if (folder?.data?.length) {
        const result = folder.data[0];
        let createdTime = moment(parseInt(result?.created_time)).format('MMM DD, YYYY');
        setFolder({ ...result, created_time: createdTime });
      }
    } catch (error) {
      setError({
        message: error?.message || alertConstants.SERVER_ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Popover.PopoverItem positionLeft={(width) => -330 + width} onClose={handleClose}>
      <div
        className={`menu_popup filter-container  `}
        style={{
          padding: 20,
          width: 330,
          boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.12)',
        }}
      >
        <div className='filter-head mb-2'>
          <h6 className='menu-item-head'>Folder Details</h6>
        </div>
        <div style={{ fontSize: 15 }}>
          <ErrorComponent error={error?.message} />
          {isLoading && (
            <div className='text-center'>
              <LoadingIcon color='blue' size={50} />
            </div>
          )}

          {!isLoading && (
            <>
              <DetailsRow
                heading={'Folder Name'}
                value={folder.name}
                icon={<FileIcon color='#87909E' />}
              />
              <DetailsRow
                heading={'Size'}
                value={getSize(folder?.total_file_size)}
                icon={<FileSize />}
              />
              <DetailsRow
                heading={'Owner'}
                value={renderUserDetails([folder?.creator])}
                icon={<User />}
              />
              <DetailsRow
                heading={'Created On'}
                value={folder?.created_time}
                icon={<Calendar height={14} width={14} />}
              />
              <DetailsRow
                heading={'Shared With User'}
                value={
                  folder?.shared_with_user?.includes(0) ? (
                    'All Users'
                  ) : (
                    <StackedAvatars
                      justifyPosition='justify-content-left'
                      getSourceByKey={(user) => user?.image_url}
                      getNameByKey={(user) => fullNameUser(user?.first_name, user?.last_name)}
                      getGenderByKey={(user) => user?.gender === 1}
                      imageList={folder?.members}
                      showInitials={false}
                      tooltipPosition={POSITION.TOP}
                      size={25}
                    />
                  )
                }
                icon={<ShareIcon />}
              />
              {/* {folder?.shared_with_team?.length > 0 && ( */}
              <DetailsRow
                heading={'Shared With Team'}
                value={
                  folder?.shared_with_team?.includes(0) ? (
                    'All Teams'
                  ) : (
                    <StackedAvatars
                      justifyPosition='justify-content-left'
                      getSourceByKey={(user) => user?.image_url}
                      getNameByKey={(user) => user?.name}
                      getGenderByKey={(user) => user?.gender === 1}
                      imageList={folder?.teams}
                      showInitials={true}
                      tooltipPosition={POSITION.TOP}
                      size={25}
                    />
                  )
                }
                icon={<ShareIcon />}
              />
            </>
          )}
        </div>
      </div>
    </Popover.PopoverItem>
  );
};
