import { useMutation } from '@tanstack/react-query';
import React, { useContext } from 'react';
import { Button } from '../_components/CustomButton';
import ToolTip from '../_components/Tooltip/ToolTip';
import { taskService } from '../_services';
import { GlobalContext } from '../Utils';
import { ArchiveIcon } from '../Utils/SvgIcons';

// eslint-disable-next-line no-unused-vars
export function Archive({
  taskId = null,
  status = null,
  refetch,
  isTaskStarted,
  tableContainerRef,
}) {
  const { makeAlert } = useContext(GlobalContext);
  // const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  // const [archiveData, setArchiveData] = useState({ status: 1 });

  const handleArchive = async () => {
    try {
      if (isTaskStarted) {
        return makeAlert('Unable to archive. This task is currently ongoing.');
      }
      const payload = { id: taskId, status: status == 2 ? 1 : 2 };
      const result = await taskService.taskAdd(payload);
      console.log(result);
      makeAlert('Task Archived');
      refetch();
      // navigate(-1);
    } catch (e) {
      makeAlert(e?.message);
      makeAlert('Archive Failed');
    } finally {
      // favoriteRefetch(); // fetch after update
    }
  };

  //-- react query fot mutation function
  const { mutate: handleMutate, isPending } = useMutation({
    mutationFn: handleArchive,
  });
  return (
    <ToolTip
      tooltipText={status == 2 ? 'Unarchive' : 'Add to Archive'}
      isModern
      popOver
      scrollRef={tableContainerRef}
    >
      <Button.Container
        isDisabled={isPending}
        className={'tab-click inline-task-item-container custom-icon pin-container '}
        handleOk={handleMutate}
      >
        <ArchiveIcon
          height={16}
          width={16}
          color={status == 2 ? '#000' : '#D2D2D2'}
          strokeWidth='1'
        />
      </Button.Container>
    </ToolTip>
  );
}
