import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function ProtectedRoute({ children }) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!localStorage.getItem('user')) {
      navigate('/login', { replace: true });
    }
  }, [location.pathname]);

  return children;
}
