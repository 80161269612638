/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import SearchableQuerySelect from '../_components/SearchableQuerySelect';
import { USER_LIST_ACTIONS } from '../_constants';
import { projectService, userService } from '../_services';
import { templateService } from '../_services/template.service';
import { taskAssignUserSelect } from '../Task/taskConstants';
import { TaskUserList } from '../Task/TaskListView';

function TimeBoxFilters({
  setSelectedUsers = () => {}, //to set the state for selected members
  selectedUsers = [], // selected members state
  filteredPayload = [], // the filtered options for event list call
  setFilteredPayload = () => {}, // to set the state for filtered options
  searchParams,
  setSearchParams = () => {},
  isNeedProject = true,
  isNeedTemplate = true,
  isFrom = 'calendar',
  isNeedUser = false,
}) {
  useEffect(() => {
    const fetchTemplatesByIds = async (ids) => {
      const promises = ids.map((id) =>
        templateService
          .getSingleTemplate({ id: id, select: ['id', 'name'] })
          .then((res) => res.data),
      );
      return Promise.all(promises);
    };

    const fetchProjectsByIds = async (ids) => {
      const promises = ids.map((id) =>
        projectService.projectGet({ id: id, select: ['id', 'name'] }).then((res) => res.data),
      );
      return Promise.all(promises);
    };

    const params = new URLSearchParams(window.location.search);
    const newFilterData = structuredClone(filteredPayload);

    const templateIds = [];
    const projectIds = [];

    params.forEach((value, key) => {
      if (key === 'template_id' || key === 'project_id') {
        if (!newFilterData[key]) newFilterData[key] = [];
        newFilterData[key].push(isNaN(value) ? value : parseInt(value));
        if (key === 'template_id') {
          templateIds.push(parseInt(value));
        }
        if (key === 'project_id') {
          projectIds.push(parseInt(value));
        }
      }
    });

    const updateFilterDataWithTemplatesAndProjects = async () => {
      if (templateIds.length > 0) {
        const templates = await fetchTemplatesByIds(templateIds);
        newFilterData.template_id = templates.map((template) => ({
          id: template[0].id,
          name: template[0].name,
        }));
      }

      if (projectIds.length > 0) {
        const projects = await fetchProjectsByIds(projectIds);
        newFilterData.project_id = projects.map((project) => ({
          id: project.id,
          name: project.name,
        }));
      }

      setFilteredPayload(newFilterData);
    };

    updateFilterDataWithTemplatesAndProjects();
  }, []);

  // project list fetch by user id - here user id will be assignee id of task
  const {
    data: projectData,
    error,
    isLoading: projectLoading,
  } = useQuery({
    queryKey: ['projectList'],
    queryFn: () =>
      projectService.projectListAdvanced({
        select: ['id', 'name', 'start_date', 'end_date', 'status'],
        pageVo: {
          pageNo: 1,
          noOfItems: 10,
        },
      }),
    select: (data) => {
      const response = data.data.rows;
      return response;
    },
    enabled: isNeedProject,
  });

  // user params according to the project
  console.log({ filteredPayload });

  // to fetch task user list
  const { data: teamList, isLoading: isTeamLoading } = useQuery({
    queryKey: ['team-list', filteredPayload?.project_id?.id],
    queryFn: () =>
      userService.getPermittedUser({
        action: USER_LIST_ACTIONS.TASK_FILTER,
        select: taskAssignUserSelect,
        project_id: parseInt(
          filteredPayload?.project_id?.id ?? filteredPayload?.taskFilters?.project_id,
        ),
        is_unlisted: false,
        template_id: filteredPayload?.template_id?.id ?? filteredPayload?.taskFilters?.template_id,
      }),
    select: (response) => {
      return response.data.rows;
    },
    enabled: !isNeedUser,
  });

  // for template list fetch by project_id
  const {
    data: templateData,
    error: templateFetchError,
    isLoading: templateLoading,
  } = useQuery({
    queryKey: ['templateList'],
    queryFn: () =>
      templateService.getTemplateList({
        select: ['id', 'name', 'is_default', 'status_group'],
        project_id: filteredPayload?.project_id?.id || filteredPayload?.taskFilters?.project_id,
        pageVo: { sort: 'is_default', sort_order: 'DESC' },
      }),
    select: (data) => {
      const templateList = data?.data?.rows;
      const updatedTemplateData = templateList;
      return updatedTemplateData;
    },
    enabled: isNeedTemplate,
  });

  // handler for project and template filter
  const handleChange = (e) => {
    const { name, value } = e.target;
    // Get the current URL parameters
    const params = new URLSearchParams(window.location.search);

    if (name == 'project_id') {
      params.delete('project_id');
      let project = value?.map((item) => parseInt(item?.id));
      project?.map((item) => params.append('project_id', item));
    }
    if (name == 'template_id') {
      params.delete('template_id');
      let template = value?.map((item) => parseInt(item?.id));
      template?.map((item) => params.append('template_id', item));
    }
    setSearchParams(params, { replace: true });
    setFilteredPayload((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className='mb-2 align-items-center time-box-filter'>
      <div className={`col-md-2 ${isNeedProject ? 'd-block' : 'd-none'}`}>
        <SearchableQuerySelect
          initialValue={projectData}
          queryKey='projectList'
          queryFn={projectService.projectListAdvanced}
          getFunctionParams={(searchKey) => {
            return {
              searchKey: searchKey,
              select: ['id', 'name', 'start_date', 'end_date', 'status'],
              pageVo: {
                pageNo: 1,
                noOfItems: 10,
              },
            };
          }}
          isCustomFunction
          placeholder={'Select Work Space'}
          className='mt-5px w-100'
          onChange={(selectedOption) => {
            handleChange({ target: { value: selectedOption, name: 'project_id' } });
          }}
          value={filteredPayload?.project_id}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          styles={{
            control: (styles, { isFocused }) => ({
              ...styles,
              minHeight: '34px',
              fontSize: '13px',
              // borderColor: ' #879096',
              borderColor: isFocused ? '#3454D1' : styles.borderColor,
              boxShadow: isFocused ? '0 0 0 1px #3454D1' : styles.boxShadow,
              '&:hover': {
                borderColor: isFocused
                  ? '#3454D1'
                  : styles['&:hover']?.borderColor || styles.borderColor,
              },
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            menu: (styles) => ({
              ...styles,
              width: '345px', // Adjusts to the width of the control
              zIndex: 10,
            }),
          }}
          isClearable
          isMulti
        />
      </div>
      <div
        className={`col-md-2 ${isNeedTemplate && templateData?.length > 1 ? 'd-block' : 'd-none'}`}
      >
        <SearchableQuerySelect
          initialValue={templateData}
          queryKey='templateList'
          queryFn={templateService.getTemplateList}
          getFunctionParams={(searchKey) => {
            return {
              searchKey: searchKey,
              select: ['id', 'name', 'is_default', 'status_group'],
              pageVo: { sort: 'is_default', sort_order: 'DESC' },
            };
          }}
          isCustomFunction
          placeholder={'Select Template'}
          className='mt-5px w-100 z-2'
          onChange={(selectedOption) => {
            handleChange({ target: { value: selectedOption, name: 'template_id' } });
          }}
          value={filteredPayload?.template_id}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          styles={{
            control: (styles, { isFocused }) => ({
              ...styles,
              minHeight: '34px',
              fontSize: '13px',
              // borderColor: ' #879096',
              borderColor: isFocused ? '#3454D1' : styles.borderColor,
              boxShadow: isFocused ? '0 0 0 1px #3454D1' : styles.boxShadow,
              '&:hover': {
                borderColor: isFocused
                  ? '#3454D1'
                  : styles['&:hover']?.borderColor || styles.borderColor,
              },
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            menu: (styles) => ({
              ...styles,
              width: '345px', // Adjusts to the width of the control
              zIndex: 10,
            }),
          }}
          isClearable
          isMulti
        />
      </div>
      <div className='col-md-3 d-flex justify-content-start'>
        {teamList && !isNeedUser && (
          <TaskUserList
            visibleUsers={teamList}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            assignee_id={filteredPayload?.taskFilters?.userIds}
          />
        )}
      </div>
    </div>
  );
}

export default TimeBoxFilters;
