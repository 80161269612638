import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { BranchState } from '../Utils';
import { Button } from '../_components';
import { ErrorComponent } from '../_components/ErrorComponent';
import { alertConstants } from '../_constants';
import { branchService } from '../_services/branch.service';

const BranchAdd = ({ id, handleClose = () => {} }) => {
  // const [organization, setOrganization] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState({ message: '' });
  const [branch, setBranch] = useState(BranchState);
  const [query, setQuery] = useState({
    select: ['id', 'name', 'company_id', 'branch_time_zone', 'open_time', 'close_time'],
  });

  useEffect(() => {
    if (id && id != '0') {
      query.id = id;
      getAllBranch(query);
    }
    // getAllOrganization();
    getTimeZone();
  }, [id]);

  const getAllBranch = async (queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    setQuery(queryNew);
    setIsLoading(true);
    try {
      const brch = await branchService.branchGet(queryNew);

      if (brch?.data?.length) {
        setBranch(brch?.data[0]);
      }
    } catch (error) {
      setError({
        message: error?.message || alertConstants.SERVER_ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getTimeZone = () => {
    fetch('/timezones.json').then(async (res) => {
      const data = await res.json();
      setTimeZoneList(data);
    });
  };

  // const getAllOrganization = async (search) => {
  //   setIsLoading(true);
  //   try {
  //     const org = await organizationService.organizationList({ searchKey: search });
  //
  //     if (org?.data?.rows.length) {
  //       setOrganization(org.data.rows);
  //     }
  //   } catch (error) {
  //     setError({
  //       message: error?.message || alertConstants.SERVER_ERROR,
  //     });
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleChange = (event) => {
    const { name, value, type } = event.target;

    if (type == 'time') {
      setBranch({
        ...branch,
        [name]: value.toString(),
      });
    } else {
      setBranch({
        ...branch,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    setIsLoading(true);

    if (
      branch?.name.toString().trim() === '' ||
      // branch?.company_id === '' ||
      branch?.branch_time_zone === '' ||
      branch?.open_time === '' ||
      branch?.close_time === ''
    ) {
      setIsLoading(false);
      return null;
    }

    if (branch?.open_time > branch?.close_time) {
      setError({
        common: 'Invalid closing time',
      });
      setTimeout(() => {
        setError({ common: '' });
      }, 3000);
      setIsLoading(false);
      return null;
    }

    try {
      await branchService.branchAdd(branch).then(() => {
        setSubmitted(false);
      });
      handleClose();
    } catch (error) {
      setError({
        message: error?.message || alertConstants.SERVER_ERROR,
      });
    } finally {
      setIsLoading(false);
      setSubmitted(false);
    }
  };

  const DropDownStyle = {
    container: (styles) => {
      return {
        ...styles,
        fontSize: '13px',
        minHeight: '35px',
        minWidth: '170px',
      };
    },
    control: (styles) => {
      return {
        ...styles,
        minHeight: '30px',
      };
    },
    indicatorsContainer: (styles) => {
      return {
        ...styles,
        minHeight: '30px',
        height: '30px',
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        minWidth: '170px',
      };
    },
  };

  return (
    <div className='px-4  py-3' style={{ fontSize: '13px' }}>
      {/* component will active when error occurs   */}
      <ErrorComponent error={error?.message} />

      <form action='' className='d-flex flex-column gap-2' onSubmit={handleSubmit}>
        <div className='row mb-2'>
          <div className='form-group'>
            <label htmlFor='name'>
              Name<span style={{ color: 'red' }}> *</span>
            </label>
            <input
              style={{ fontSize: '13px' }}
              type='text'
              className='form-control mt-2'
              id='name'
              name='name'
              placeholder='Enter branch name '
              value={branch?.name}
              onChange={handleChange}
            />
            {submitted && !branch?.name.trim() && (
              <div className='help-block'>Branch name is required</div>
            )}
          </div>
        </div>

        {/* company selection  */}
        {/* <div>
          <div className='mb-2'>
            Organization
            <span style={{ color: 'red' }}> *</span>
          </div>
          <ReactSelect
            name='company_id'
            styles={DropDownStyle}
            getOptionLabel={(option) => {
              return option?.name;
            }}
            getOptionValue={(option) => {
              return option?.id;
            }}
            options={organization}
            onChange={(selectedOption) => {
              handleChange({
                target: {
                  name: 'company_id',
                  value: selectedOption.id,
                },
              });
            }}
            value={organization?.find((option) => option.id === branch?.company_id)}
          />
          {submitted && !branch?.company_id && (
            <div className='help-block'>Organization selection is required</div>
          )}
        </div> */}

        {/* end of company  */}

        {/* Time zone select */}
        <div>
          <div className='mb-2'>
            Time Zone
            <span style={{ color: 'red' }}> *</span>
          </div>
          <ReactSelect
            name='company_id'
            styles={DropDownStyle}
            getOptionLabel={(option) => {
              return option?.text;
            }}
            getOptionValue={(option) => {
              return option?.offset;
            }}
            options={timeZoneList}
            onChange={(selectedOption) => {
              handleChange({
                target: {
                  name: 'branch_time_zone',
                  value: selectedOption,
                },
              });
            }}
            value={branch?.branch_time_zone}
          />
          {submitted && !branch?.branch_time_zone && (
            <div className='help-block'> Time Zone selection is required</div>
          )}
        </div>
        {/* Time zone select end */}

        {/* Time Select */}
        <div className='row mb-2'>
          <div className='col-md-6'>
            <div className='form-group'>
              <label htmlFor='name '>
                Opening Time<span style={{ color: 'red' }}> *</span>
              </label>
              <input
                style={{ fontSize: '13px' }}
                type='time'
                className='form-control mt-2'
                id='open_time'
                name='open_time'
                onChange={handleChange}
                value={branch?.open_time}
              />
              {submitted && !branch?.open_time && (
                <div className='help-block'>Opening Time is required</div>
              )}
            </div>{' '}
          </div>
          <div className='col-md-6 mb-2'>
            <div className='form-group'>
              <label htmlFor='name'>
                Closing Time<span style={{ color: 'red' }}> *</span>
              </label>
              <input
                style={{ fontSize: '13px' }}
                type='time'
                className='form-control mt-2'
                id='close_time'
                name='close_time'
                value={branch?.close_time}
                onChange={handleChange}
              />
              {submitted && !branch?.close_time && (
                <div className='help-block'>Closing Time is required</div>
              )}
            </div>{' '}
          </div>

          <div className=' help-block ml-4'>{error.common}</div>
        </div>
        {/* Time Select end */}

        <div className='row '>
          <div className=' d-flex justify-content-end gap-2'>
            <Button.Container
              className={'cancel_btn'}
              type='reset'
              handleOk={() => {
                handleClose();
              }}
            >
              <Button.Title title={'Cancel'} />
            </Button.Container>

            <Button.Container type='submit' isDisabled={isLoading}>
              <Button.Title title={'Save'} />
              <Button.Loading isLoading={isLoading} />
            </Button.Container>
          </div>
        </div>
      </form>
    </div>
  );
};

export default BranchAdd;
