import { useEffect, useRef } from 'react';

function useMutationObserver({
  onMutation = () => {},
  customRef = null,
  options = {
    attributes: true,
    subtree: true,
    childList: true,
    attributeFilter: ['class', 'style'],
    attributeOldValue: true,
  },
}) {
  const elementRef = useRef(null);

  useEffect(
    () => {
      const handleMutation = () => {
        onMutation();
      };

      const mutationObserver = new MutationObserver(handleMutation);

      if (elementRef.current) {
        mutationObserver.observe(elementRef.current, options);
      } else if (customRef?.current) {
        mutationObserver.observe(customRef.current, options);
      }

      return () => {
        mutationObserver.disconnect();
      };
    },
    customRef ? [customRef.current] : [elementRef.current],
  );

  return { elementRef };
}

export default useMutationObserver;
