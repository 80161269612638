import { apiConstants } from '../_constants';
import { authHeader, handleResponse } from '../_helpers';

export const dashboardService = { getDashboardCounts };

async function getDashboardCounts() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    // body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/dashboard_counts', requestOptions).then(handleResponse);
}
