import { Pen, Trash } from 'lucide-react';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableRow } from '../_components';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import { Popover } from '../_components/Popover';
import ToolTip from '../_components/Tooltip/ToolTip';
import { apiConstants, RT } from '../_constants';
import { fullNameUser, getSize, Images } from '../Utils';
import { Calendar, FileSize, FolderImage, InfoIcon, LoadingIcon, User } from '../Utils/SvgIcons';
import { FolderAdd } from './FolderAdd';
import { FolderDetails } from './FolderDetails';

export const FolderListView = ({
  count,
  list,
  isLoading,
  isSuccess,
  setDeleteModal,
  isFetching,
}) => {
  const [detailsVisibleIndex, setDetailsVisibleIndex] = useState(false);
  const [editVisibleIndex, setEditVisibleIndex] = useState(false);
  const navigate = useNavigate();
  // reference section
  const reference = useRef(null);
  const tableContainerRef = useRef(null);

  const TABLE_HEADING = [
    { name: <img src={Images.SerialNo} height={14} width={14} />, minWidth: 50 },
    {
      name: <div className='d-flex align-items-center gap-1'>Folder Name</div>,
      minWidth: '10vw',
      maxWidth: '20vw',
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <User /> Owner
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <Calendar height={14} width={14} /> Created On
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <FileSize /> File Size
        </div>
      ),
      minWidth: 50,
    },

    {},
  ];

  const tableData = list?.rows?.map((folder, index) => {
    const slNo = (list?.page - 1) * list?.limit + index + 1;
    const ownerName = fullNameUser(folder?.creator?.first_name, folder?.creator?.last_name);
    const isMale = folder?.creator?.gender === 1;
    const isNeutral = folder?.creator?.gender === 3;
    const url = folder?.creator?.image_url;
    const finalUrl = url ? apiConstants.imgUrlS3 + url : null;

    return (
      <tr
        key={folder?.id}
        onClick={() => {
          navigate(`${RT.FILELIST}/${folder?.id}?folder_name=${folder?.name}`);
        }}
      >
        <td style={{ fontWeight: 500, paddingLeft: 24 }}>{slNo}</td>
        <td className='cursor-pointer' style={{ fontWeight: 500,maxWidth:300 }}>
          <div className='d-flex gap-2'>
            <span>
              <FolderImage />
            </span>
            <span className='folder-view-type-name'>{folder?.name}</span>
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            <CircularAvatar
              size={25}
              className='fz-none zoom-in employee-img-with-low-border'
              source={finalUrl}
              isMale={isMale}
              isNeutral={isNeutral}
            />
            <span style={{ fontWeight: 500 }}>{ownerName}</span>
          </div>
        </td>

        <td>
          <span>
            {folder?.created_time
              ? moment(parseInt(folder?.created_time)).format('MMM DD, YYYY')
              : '--'}
          </span>
        </td>
        <td>{getSize(folder?.total_file_size)}</td>

        <td
          style={{ verticalAlign: 'middle', cursor: 'pointer' }}
          ref={reference}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div
            className={`d-flex gap-2 justify-content-end icon-div ${
              detailsVisibleIndex === folder?.id || editVisibleIndex === folder?.id
                ? ''
                : 'invisible'
            }`}
          >
            {/* details icon  */}
            <div className='position-relative'>
              <ToolTip
                tooltipText={'Details'}
                isModern
                popOver
                scrollRef={tableContainerRef}
                hideTooltip={detailsVisibleIndex}
              >
                <div
                  className='icon-bg'
                  onClick={() => {
                    setDetailsVisibleIndex(folder?.id);
                  }}
                >
                  <InfoIcon />
                </div>
              </ToolTip>
              {detailsVisibleIndex === folder?.id && (
                <FolderDetails
                  id={folder?.id}
                  handleClose={(event) => {
                    if (event) {
                      event.stopPropagation();
                    }
                    setDetailsVisibleIndex(null);
                  }}
                />
              )}
            </div>

            {/* end of details icon  */}

            <div className='position-relative'>
              <ToolTip
                tooltipText={'Edit Folder'}
                isModern
                popOver
                scrollRef={tableContainerRef}
                hideTooltip={editVisibleIndex}
              >
                <div
                  className='icon-bg'
                  onClick={() => {
                    setEditVisibleIndex(folder?.id);
                  }}
                >
                  <Pen size={15} />
                </div>
              </ToolTip>
              {editVisibleIndex === folder?.id && (
                <Popover.PopoverItem
                  onClose={() => setEditVisibleIndex(null)}
                  positionLeft={(width) => -330 + width}
                >
                  <FolderAdd
                    id={folder?.id}
                    handleClose={(event) => {
                      if (event) {
                        event.stopPropagation();
                      }
                      setEditVisibleIndex(null);
                    }}
                  />
                </Popover.PopoverItem>
              )}
            </div>
            <ToolTip tooltipText={'Delete Folder'} isModern popOver scrollRef={tableContainerRef}>
              <div
                className='icon-bg'
                onClick={(event) => {
                  event.stopPropagation();
                  // setEditProjectId(project?.id);
                  setDeleteModal(folder?.id);
                }}
              >
                <Trash size={15} />
              </div>
            </ToolTip>
          </div>
        </td>
        {/* )} */}
      </tr>
    );
  });

  return (
    <div>
      <div
        className='table-container no-padding'
        ref={tableContainerRef}
        // style={{ maxHeight: '65vh' }}
      >
        <table className='table table-hover tbl mb-0'>
          <thead>
            <tr style={{ backgroundColor: '#F3F5F7' }} className='table-head'>
              {TABLE_HEADING.map((heading, index) => {
                return (
                  <th
                    key={index}
                    scope='col'
                    style={{ minWidth: heading.minWidth, maxWidth: heading.maxWidth }}
                  >
                    {heading.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody style={{ verticalAlign: 'middle' }}>
            {(isLoading || isFetching) && (
              <TableRow noOfCols={8}>
                <LoadingIcon color='blue' size={50} />
              </TableRow>
            )}
            {!count && isSuccess && !isFetching && <TableRow noOfCols={8}>No data found</TableRow>}
            {count > 0 && isSuccess && !isFetching && tableData}
          </tbody>
        </table>
      </div>
    </div>
  );
};
