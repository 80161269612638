/* eslint-disable no-unused-vars */
import { useQueryClient } from '@tanstack/react-query';
import { ImageUp } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactSelect from 'react-select';
import { phoneNumberValidation, validatePinNumber } from '../Utils';
import { OrganizationState } from '../Utils/States';
import { CustomFileUpload } from '../_components';
import { Button } from '../_components/CustomButton';
import { ErrorComponent } from '../_components/ErrorComponent';
import { alertConstants, apiConstants } from '../_constants';
import { organizationService } from '../_services/organization.service';
export function OrganizationAdd() {
  const queryClient = useQueryClient();

  const { id } = useParams(); // Access the id parameter from the URL
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ message: '' });
  const navigate = useNavigate();
  const [timeZoneList, setTimeZoneList] = useState([]);

  const [query, setQuery] = useState({
    select: [
      'id',
      'name',
      'address',
      'city',
      'pin_code',
      'phone',
      'email',
      'website',
      'logo',
      'company_time_zone',
      'open_time',
      'close_time',
    ],
  });
  const [organization, setOrganization] = useState(OrganizationState);
  const [compressed, setCompressed] = useState();

  useEffect(() => {
    if (id && id != '0') {
      query.id = id;
      getAllOrganization(query);
    }
    getTimeZone();
  }, [id]);

  const getTimeZone = () => {
    fetch('/timezones.json').then(async (res) => {
      const data = await res.json();
      setTimeZoneList(data);
    });
  };

  /**
   * Get organization
   * */
  const getAllOrganization = async (queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;

    setQuery(queryNew);
    setIsLoading(true);

    try {
      const org = await organizationService.organizationGet(queryNew);
      if (org?.data?.length) {
        setOrganization(org.data[0]);
      }
    } catch (error) {
      setError({
        message: error?.message || alertConstants.SERVER_ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value, type } = event.target;
    if (type == 'time') {
      setOrganization((prev) => ({
        ...prev,
        [name]: value.toString(),
      }));
      return;
    }
    setOrganization((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImageUpload = (file, name, compressed, compressedBlob) => {
    console.log(file, name);
    setOrganization((prev) => ({
      ...prev,
      [name]: file.pop(),
    }));

    console.log({ compressed });
    setCompressed(compressedBlob);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    setIsLoading(true);

    if (
      organization?.name.toString().trim() === '' ||
      organization?.company_time_zone === null ||
      organization?.open_time > organization?.close_time
    ) {
      setIsLoading(false);
      return null;
    }
    const isPhoneValid = organization?.phone ? phoneNumberValidation(organization?.phone) : true;

    const isPinValid = organization?.pin_code ? validatePinNumber(organization?.pin_code) : true;

    if (!isPhoneValid || !isPinValid) {
      setIsLoading(false);
      return null;
    }

    try {
      await organizationService.organizationAdd(organization).then(() => {
        setSubmitted(false);
        queryClient.invalidateQueries(['organizationList']);
      });
      navigate(-1);
      // navigate(`/${RT.SETTINGS}/${RT.WORK_SPACE}/${RT.ORGANIZATION}`);
    } catch (error) {
      setError({
        common: error?.message || alertConstants.SERVER_ERROR,
      });
    } finally {
      setIsLoading(false);
      setSubmitted(false);
    }
  };

  let imageUrl = compressed
    ? compressed
    : organization?.logo
    ? apiConstants.imgUrlS3 + organization?.logo
    : null;

  console.log({ imageUrl });

  return (
    <div className='px-4  py-3 col-7' style={{ fontSize: '13px' }}>
      {/* component will active when error occurs   */}
      <ErrorComponent error={error?.message} />

      <h4 className='mb-3'>{id == 0 ? 'Add' : 'Edit'} Organization</h4>

      <form action='' className='d-flex flex-column gap-2' onSubmit={handleSubmit}>
        <div>
          <div style={{ height: 100, width: 100, borderRadius: 200, marginBottom: 5 }}>
            {imageUrl ? (
              <img
                src={imageUrl}
                style={{
                  objectFit: 'contain',
                  height: '100%',
                  width: '100%',
                  borderRadius: 200,
                  padding: 2,
                }}
              />
            ) : (
              <ImageUp color='#d2d2d2' size={100} />
            )}
          </div>

          {/* {compressed && (
            <img
              src={compressed}
              width={'100px'}
              height={'100px'}
              style={{ objectFit: 'contain' }}
            />
          )} */}

          <CustomFileUpload
            isCompressed={true}
            name='logo'
            accept='image'
            handleOK={handleImageUpload}
            CustomView={
              <div className=' text-decoration-underline' style={{ cursor: 'pointer' }}>
                Upload logo
              </div>
            }
          />
        </div>
        <div className='row mt-3'>
          <div className='col-md-6'>
            <div className='form-group '>
              <label htmlFor='name' className='mb-1'>
                Organization Name<span style={{ color: 'red' }}> *</span>
              </label>
              <input
                style={{ fontSize: '13px' }}
                type='text'
                className='form-control'
                id='name'
                name='name'
                value={organization?.name}
                placeholder='Enter Organization name'
                onChange={(event) => {
                  handleChange(event);
                }}
              />
              {submitted && !organization?.name.trim() && (
                <div className='help-block'>Organization name is required</div>
              )}
            </div>
          </div>
          {/* </div> */}

          {/* <div className='row'> */}
          <div className='col-md-6'>
            <div className='form-group'>
              <label htmlFor='address' className='mb-1'>
                Address
              </label>
              <input
                style={{ fontSize: '13px' }}
                type='text'
                className='form-control'
                id='address'
                name='address'
                value={organization?.address}
                placeholder='Enter address '
                onChange={(event) => {
                  handleChange(event);
                }}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-3'>
            <div className='form-group'>
              <label htmlFor='city' className='mb-1'>
                City
              </label>
              <input
                style={{ fontSize: '13px' }}
                type='text'
                className='form-control'
                id='city'
                name='city'
                value={organization?.city}
                placeholder='Enter city '
                onChange={(event) => {
                  handleChange(event);
                }}
              />
            </div>
          </div>
          <div className='col-md-3'>
            <div className='form-group'>
              <label htmlFor='pin_code' className='mb-1'>
                {' '}
                PIN
              </label>
              <input
                style={{ fontSize: '13px' }}
                type='number'
                className='form-control'
                id='pin_code'
                name='pin_code'
                // min={0}
                value={organization?.pin_code}
                placeholder='Enter PIN '
                onChange={(event) => {
                  handleChange(event);
                }}
              />
              {submitted &&
                !validatePinNumber(organization?.pin_code) &&
                organization?.pin_code && <div className='help-block'> Invalid PIN code</div>}
            </div>
          </div>
          {/* </div>
        <div className='row'> */}
          <div className='col-md-6'>
            <div className='form-group'>
              <label htmlFor='email' className='mb-1'>
                Email
              </label>
              <input
                style={{ fontSize: '13px' }}
                type='email'
                className='form-control'
                id='email'
                name='email'
                value={organization?.email}
                placeholder='Enter email address '
                onChange={(event) => {
                  handleChange(event);
                }}
              />
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-6'>
            <div className='form-group'>
              <label htmlFor='phone' className='mb-1'>
                Phone Number
              </label>
              <input
                style={{ fontSize: '13px' }}
                type='number'
                className='form-control'
                id='phone'
                name='phone'
                value={organization?.phone}
                placeholder='Enter phone number '
                onChange={(event) => {
                  handleChange(event);
                }}
              />
              {submitted && !phoneNumberValidation(organization?.phone) && organization?.phone && (
                <div className='help-block'> Invalid Phone number</div>
              )}
            </div>
          </div>

          <div className='col-md-6'>
            <div className='form-group'>
              <label htmlFor='company-web' className='mb-1'>
                Company Website
              </label>
              <input
                style={{ fontSize: '13px' }}
                type='text'
                className='form-control'
                id='company-web'
                name='website'
                value={organization?.website}
                placeholder='Enter company website '
                onChange={(event) => {
                  handleChange(event);
                }}
              />
            </div>
          </div>
        </div>

        <div className='row mb-4'>
          {/* Time zone select */}

          <div className='col-md-6'>
            <div className='mb-1'>
              Time Zone
              <span style={{ color: 'red' }}> *</span>
            </div>

            <ReactSelect
              name='company_time_zone'
              placeholder='Choose time zone'
              getOptionLabel={(option) => {
                return option?.text;
              }}
              getOptionValue={(option) => {
                return option;
              }}
              options={timeZoneList}
              onChange={(selectedOption) => {
                handleChange({
                  target: {
                    name: 'company_time_zone',
                    value: selectedOption,
                  },
                });
              }}
              value={organization?.company_time_zone}
              styles={{
                control: (styles) => {
                  return {
                    ...styles,
                    minHeight: '33px',
                  };
                },
              }}
            />

            {submitted && organization?.company_time_zone == null && (
              <div className='help-block'> Time Zone selection is required</div>
            )}
          </div>

          {/* end of Time zone select */}

          <div className='col-md-3'>
            <div className='form-group'>
              <label htmlFor='open_time' className='mb-1'>
                Opening Time
              </label>
              <input
                style={{ fontSize: '13px' }}
                type='time'
                className='form-control'
                id='open_time'
                name='open_time'
                onChange={(event) => {
                  handleChange(event);
                }}
                value={organization?.open_time}
              />
            </div>
          </div>
          <div className='col-md-3'>
            <div className='form-group'>
              <label htmlFor='close_time' className='mb-1'>
                Closing Time
              </label>
              <input
                style={{ fontSize: '13px' }}
                type='time'
                className='form-control '
                id='close_time'
                name='close_time'
                value={organization?.close_time}
                onChange={(event) => {
                  handleChange(event);
                }}
              />

              {submitted && organization?.open_time > organization?.close_time && (
                <div className='help-block'>Opening time must be later than closing time.</div>
              )}
            </div>
          </div>
        </div>

        <div className='row ' style={{ fontSize: '14px' }}>
          <div className='col-md-6'>
            {id == 0 && (
              <div
                className='text-decoration-underline'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSubmitted(false);
                  setIsLoading(false);
                  setOrganization(OrganizationState);
                }}
              >
                Reset all
              </div>
            )}
          </div>

          <div className='col-md-6 d-flex justify-content-end gap-2'>
            <Button.Container type='submit' isDisabled={isLoading}>
              <Button.Title title={'Save'} />
              <Button.Loading isLoading={isLoading} />
            </Button.Container>
          </div>
        </div>
      </form>
    </div>
  );
}
