import moment from 'moment';

export const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const monthWithDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

// Find leap year
function isLeapYear(year) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

export const formatDate = ({ monthIndex, year }) => {
  const groups = {};
  const days =
    monthIndex === 1 && isLeapYear(year) ? 29 : monthWithDays[monthIndex];
  const dateData = Array(days)
    .fill(0)
    .map((_, index) => index + 1)
    .reduce((_, currentValue) => {
      const weekLength = 7;
      const firstDay = new Date(year, monthIndex, 1).getDay();
      const currentWeek = Math.ceil((currentValue + firstDay) / weekLength);
      if (!groups[currentWeek]) {
        groups[currentWeek] = [];
      }

      groups[currentWeek].push(currentValue);
      return groups;
    }, {});

  // Adding null to the first week based on offset
  const offsetDays = 7 - dateData[1].length;
  dateData[1] = [...Array(offsetDays).fill(null), ...dateData[1]];

  return dateData;
};

export const generateWeekDays = ({ year, month }) => {
  const startDate = moment(`${year}-${month}-01`);
  const endDate = startDate.clone().endOf('month');

  const weekDays = {};
  let currentWeek = 0;

  let date = startDate.clone().startOf('week');
  while (date.isSameOrBefore(endDate)) {
    if (!weekDays[currentWeek]) {
      weekDays[currentWeek] = [];
    }

    if (date.month() === Number(month) - 1) {
      weekDays[currentWeek].push(date.date());
    } else {
      weekDays[currentWeek].push(null); // Add null for dates from previous/next month
    }

    date.add(1, 'days');

    if (date.weekday() === 0 && date.isSameOrBefore(endDate)) {
      currentWeek++;
    }
  }

  // Add the previous month's dates to the beginning of the first week
  let firstWeek = Object.values(weekDays)[0];
  let prevMonth = startDate.clone().subtract(1, 'month').endOf('month').date();
  while (firstWeek.length < 7) {
    firstWeek.unshift(prevMonth--);
  }

  // Add the next month's dates to the end of the last week
  let lastWeek = Object.values(weekDays)[Object.keys(weekDays).length - 1];
  let nextMonth = 1;
  while (lastWeek.length < 7) {
    lastWeek.push(nextMonth++);
  }

  return weekDays;
};
