/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { Mic } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { QUERY_TYPE } from '../_constants/ai.constants';
import { aiService } from '../_services/ai.service';
import '../AI/ai.css'; // Add CSS for styling
import useObserver from '../Hook/useObserver';
import { useUser } from '../Hook/useUser';
import { getSettingsByName, getSettingValue, GlobalContext } from '../Utils';
import { AIHeader, CircleRightArrow } from '../Utils/SvgIcons';
import { AiAudioModal } from './AiAudioModal';
import { AnimatedHeader, MessageContainer, TaskCard } from './AiComponents/AiComponents';
import AiSuggestions from './AiSuggestions';
import AIInputBox from './AlInputBox';
import { SpeakerView } from './SpeakerView';

export const AITaskManager = () => {
  const user = useUser();
  const currentDate = moment().format('YYYY-MM-DD');

  const [items, setItems] = useState([]);
  const [input, setInput] = useState('');
  const [audio, setAudio] = useState('');
  const [newResponse, setNewResponse] = useState();

  const { globalSettings } = useContext(GlobalContext);

  const GeneralSettings = getSettingsByName(globalSettings, 'general-settings');

  let aiAudio = getSettingValue(GeneralSettings, 'ai_audio');

  const generateResponse = async (question) => {
    let response = await aiService.generateResponse({ message: question });
    return response.data;
  };

  const {
    data: aiChatData,
    fetchNextPage,
    hasNextPage,
    refetch,
    isLoading,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['chat-list'],
    queryFn: ({ pageParam = 1 }) =>
      aiService.aiChatList({
        start_date: currentDate,
        pageVo: {
          noOfItems: 10,
          pageNo: pageParam,
        },
      }),
    getNextPageParam: (lastPage) => {
      return parseInt(lastPage.data.page) < parseInt(lastPage.data.pages)
        ? parseInt(lastPage.data.page) + 1
        : undefined;
    },
  });

  const { observerRef } = useObserver({
    onIntersection: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    dependency: [hasNextPage],
  });

  React.useEffect(() => {
    if (aiChatData?.pages) {
      const allChatItems = aiChatData.pages.flatMap((page) => page.data.rows);
      setItems(allChatItems); // Save the computed items in the state
    }
  }, [aiChatData]);

  const {
    mutateAsync: handleMutate,
    isPending,
    isError,
    error,
    isPaused, // -- true when network offline mode
  } = useMutation({
    mutationFn: generateResponse,
    mutationKey: ['response'],
    onSuccess: (data) => {
      
      if (items.length > 0) {
        const firstItem = items[0];

        // Combine firstItem with the response data
        setNewResponse(data);

        const updatedFirstItem = {
          ...firstItem,
          response: data.response,
          db_data: data.db_data,
          newGenerated: true,
        };

        setItems((prevItems) => [updatedFirstItem, ...prevItems.slice(1)]);
      } else {
        const newItem = { response: data, db_data: data.db_data, message: '', newGenerated: true };

        setItems((prevItems) => [newItem, ...prevItems]);
      }
      setNewResponse(data);
    },
  });

  const handleSend = (newInput) => {
    if (newInput.trim()) {
      // Check if there are any items to modify
      if (items.length > 0) {
        const lastItem = items[items.length - 1];

        const updatedLastItem = { ...lastItem, response: null, message: newInput };

        setItems((prevItems) => [updatedLastItem, ...prevItems]);
      } else {
        const newItem = { response: null, message: newInput };
        // Add new item to the start
        setItems((prevItems) => [newItem, ...prevItems]);
      }

      handleMutate(newInput);

      setInput('');
    }
  };

  const [aiView, setAiView] = useState(true);
  const [openAudioModal, setOpenAudioModal] = useState(false);

  const scrollRef = useRef(null);

  // Function to scroll to the specific position (the red div)
  const scrollToBottom = () => {
    window.requestAnimationFrame(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    });
  };

  useEffect(() => {
    // Scroll to the red div when the component mounts or when items change
    scrollToBottom();
  }, [items]); // Ensure the scroll happens when items change

  if (aiView) {
    return (
      <section className='ai-container'>
        <section className='section-container'>
          <section className='col-sm-12 col-md-8'>
            {!items?.length && !isLoading ? <AnimatedHeader user={user} /> : null}

            <AiInitialData />

            <div
              style={{
                flexDirection: 'column-reverse',
                display: 'flex',
              }}
            >
              <div ref={scrollRef} style={{ height: 30 }}></div>
              <Generating isPending={isPending} />

              {items?.map((message, index) => (
                <>
                  <HiDeskAi index={index} generatedData={message} isPending={isPending} />
                  <UserMessage message={message?.message} />
                </>
              ))}
              <div ref={observerRef} style={{ width: '100%', minHeight: 30 }}></div>
            </div>
          </section>
        </section>
        <section>
          <section style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
            <section className='col-sm-12 col-md-8 sch-sug-container position-relative'>
              <AiSuggestions handleSend={(input) => handleSend(input)} />
              <AIInputBox
                setInput={setInput}
                onFileChange={(e) => setInput(e)}
                onInputChange={(e) => setInput(e.target.value)}
                hideAudioRec
                onSubmit={() => handleSend(input)}
                initialObject={{ message: input }}
              />
              {aiAudio && (
                <div className='audio-button' onClick={() => setOpenAudioModal(true)}>
                  <Mic size={20} />
                </div>
              )}
            </section>
          </section>
        </section>
        <AiAudioModal
          setInput={setInput}
          setOpenAudioModal={setOpenAudioModal}
          openAudioModal={openAudioModal}
          generateResponse={(data) => handleSend(data)}
          newResponse={newResponse}
          isPending={isPending}
        />
      </section>
    );
  }

  return (
    <div className='welcome-page'>
      {/* Header Section */}
      <div className='col-md-8'>
        <div className='row'>
          <header className='header-ai animated-text'>
            <h1 className='line1'>
              Hi, {user.first_name} {user.last_name}
            </h1>
            <p className='line2'>What would like to know?</p>
          </header>
          <section>
            <section className='ai-body-container'>
              {isInitialDataAvailable && (
                <>
                  <div className='ai-icon'>
                    <AIHeader />
                  </div>
                  {/* Task summary section */}
                  <div className='tasks-summary-container'>
                    <div className='d-flex'>
                      <div className='tasks-summary-header'>
                        You’ve {initialMessage?.total_tasks} tasks scheduled this week
                      </div>
                    </div>
                    <div className='task-summary-wrapper'>
                      <section className='tasks-summary'>
                        {initialMessage?.tasks?.map((task, index) => (
                          <>
                            <TaskCard />
                          </>
                          // <div key={index} className='task-card'>
                          //   <div className='d-flex w-100 justify-content-between'>
                          //     <div className='task-single-menu-value priority-box'>
                          //       <text className='priority-text'>Energy Booster</text>
                          //     </div>
                          //     <img
                          //       src='https://via.placeholder.com/40'
                          //       alt='Profile'
                          //       className='profile-pic-container'
                          //     />
                          //   </div>

                          //   <div className='task-info'>{task.title}</div>
                          //   <span className='flag-value'>
                          //     <Flag color='#BC4841' size={13} />
                          //     {task.priority}
                          //   </span>
                          // </div>
                        ))}
                      </section>
                    </div>
                    <div className='position-relative'>
                      <div className='task-summary-footer cursor-pointer'>
                        <CircleRightArrow color='#879096' size={28} strokeWidth={1.5} />
                      </div>
                    </div>
                  </div>

                  {/* Notifications and Task status */}
                  <section className='notifications'>
                    <div className='notification-item-wrapper'>
                      <div className='notification-item'>You`ve 10 tasks scheduled this week</div>
                      <div className='notification-item-arrow'>
                        <CircleRightArrow color='#879096' size={28} strokeWidth={1.5} />
                      </div>
                    </div>
                    <div className='notification-item-wrapper'>
                      <div className='notification-item'>3 high priority tasks are pending</div>
                      <div className='notification-item-arrow'>
                        <CircleRightArrow color='#879096' size={28} strokeWidth={1.5} />
                      </div>
                    </div>
                    <div className='notification-item-wrapper'>
                      <div className='notification-item'>
                        1 mention in messages today. 20 unread messages
                      </div>
                      <div className='notification-item-arrow'>
                        <CircleRightArrow color='#879096' size={28} strokeWidth={1.5} />
                      </div>
                    </div>
                    <div className='notification-item-wrapper'>
                      <div className='notification-item'>Please verify 345 tasks</div>
                      <div className='notification-item-arrow'>
                        <CircleRightArrow color='#879096' size={28} strokeWidth={1.5} />
                      </div>
                    </div>
                  </section>
                </>
              )}
            </section>
            <section>
              <div>asas</div>
            </section>
            {/* Suggestions */}
            {/* <AiSuggestions /> */}
          </section>
        </div>
      </div>
      <div className='ai-input-box-container'>
        <AIInputBox hideAudioRec />
      </div>
    </div>
  );
};

const UserMessage = (props) => {
  const { message } = props;
  return (
    <section style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
      <div
        style={{
          width: '70%',
          fontSize: 14,
          borderRadius: 5,
          padding: '5px 5px 5px 8px',
          textAlign: 'end',
        }}
      >
        {message}
      </div>
    </section>
  );
};

const HiDeskAi = (props) => {
  const { generatedData, isPending, index } = props;

  return (
    <section
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        marginBottom: 10,
        flexDirection: 'column',
      }}
    >
      {generatedData?.response && (
        <>
          <DataCard generatedData={generatedData} />

          <div style={{ marginTop: 10 }}>
            <MessageContainer>
              <DescriptionView data={generatedData} index={index} />
            </MessageContainer>
          </div>
        </>
      )}
    </section>
  );
};

const Generating = (props) => {
  const { isPending } = props;
  return <section>{isPending ? <div style={{ fontSize: 13 }}>Generating..</div> : null}</section>;
};

const DataCard = (props) => {
  const { generatedData, isPending } = props;
  let cardView = null;

  console.log({ generatedData2: generatedData });

  if (generatedData?.db_data?.length) {
    if (generatedData?.type === QUERY_TYPE.TASK_LIST) {
      let card = generatedData?.db_data.map((data, index) => {
        console.log('data11', data);
        return (
          <>
            <TaskCard key={index} data={data} />
          </>
        );
      });

      cardView = (
        <div
          style={{
            display: 'flex',
            overflowX: 'auto',
            overflowY: 'hidden',
            minHeight: 80,
            gap: 10,
            paddingBottom: 7,
          }}
        >
          {card}
        </div>
      );
    }
  }

  return <section>{cardView}</section>;
};

const DescriptionView = (props) => {
  const { data, index } = props;
  const [lines, setLines] = useState([]);

  useEffect(() => {
    // Split the response data into lines and store it in the state
    if (index === 0) {
      const splitLines = data?.response; // Assuming newline separates lines
      setLines(splitLines);
    }
  }, [data]);

  return (
    <div style={{ marginTop: 10 }}>
      <div>
        {index === 0 &&
          lines.map((line, index) => (
            <div
              key={index}
              className='animated-line'
              style={{ animationDelay: `${index * 0.1}s`, fontSize: 14, lineHeight: '1.5rem' }}
            >
              {line}
            </div>
          ))}

        {index !== 0 &&
          data?.response.map((line, index) => (
            <div key={index} style={{ fontSize: 14, lineHeight: '1.5rem' }}>
              {line}
            </div>
          ))}

        <SpeakerView text={data?.response.join(' ')} />
      </div>
    </div>
  );
};

const getAiInitialData = async (user) => {
  let response = await aiService.aiGet({ user_id: user?.id });
  console.log('response', response?.data);

  return response.data;
};

const AiInitialData = () => {
  const user = useUser();

  const { data: initialMessage } = useQuery({
    queryKey: ['initialMessage'],
    queryFn: () => getAiInitialData(user),

    enabled: user?.id ? true : false,
  });

  const isInitialDataAvailable =
    Boolean(parseInt(initialMessage?.todo_tasks)) ||
    Boolean(parseInt(initialMessage?.total_mentions)) ||
    Boolean(parseInt(initialMessage?.total_tasks)) ||
    Boolean(parseInt(initialMessage?.verify_tasks_count)) ||
    Boolean(parseInt(initialMessage?.unread_messages));

  // console.log('isInitialDataAvailable', initialMessage);

  return (
    <section>
      {isInitialDataAvailable && (
        <>
          <section style={{ marginTop: 5 }}>
            <Wrapper>
              <MessageContainer aiIconNeed isHoverBased isNavigable>
                <div style={{ fontSize: 13 }}>
                  {`You've ${initialMessage?.todo_tasks} tasks scheduled this week`}
                </div>
              </MessageContainer>
            </Wrapper>

            <Wrapper>
              <MessageContainer isHoverBased isNavigable customClass='message-container-list'>
                <div
                  style={{
                    display: 'flex',
                    gap: 10,
                    maxWidth: '55vw',
                    minWidth: '10vw',
                    overflow: 'auto',
                  }}
                >
                  {initialMessage?.tasks?.map((task, index) => (
                    <TaskCard key={index} data={task} />
                  ))}
                </div>
              </MessageContainer>
            </Wrapper>

            <Wrapper>
              <MessageContainer isNavigable>
                <div
                  style={{ fontSize: 13 }}
                >{`${initialMessage?.todo_tasks} high priority tasks are pending`}</div>
              </MessageContainer>
            </Wrapper>
            <Wrapper>
              <MessageContainer isNavigable>
                <div style={{ fontSize: 13 }}>
                  {' '}
                  {`${initialMessage?.total_mentions} mention in messages today. ${initialMessage?.unread_messages} unread messages`}
                </div>
              </MessageContainer>
            </Wrapper>
            <Wrapper>
              <MessageContainer isNavigable>
                <div
                  style={{ fontSize: 13 }}
                >{` Please verify ${initialMessage?.verify_tasks_count} tasks`}</div>
              </MessageContainer>
            </Wrapper>
          </section>
        </>
      )}
    </section>
  );
};

const Wrapper = ({ children, customClass }) => {
  return <section className={`${customClass ? customClass : 'd-flex'}`}>{children}</section>;
};
