export const NOTIFICATION_EMPTY_TEXTS = {
  NOTIFICATION: 'Your notifications are shows here',
  UPDATES_UNREAD: 'Your unread updates are shows here',
  UPDATES_READ: 'Your read updates are shows here',
  NOTIFICATION_READ: `You're all caught up! No notifications at the moment.`,
  NOTIFICATION_UNREAD: `You're all caught up! No notifications at the moment.`,
  CHATS: 'Your chats are shows here',
};

export const NOTIFICATION_FROM = {
  ALL_NOTIFICATION: 'ALL_NOTIFICATION',
  CHAT: 'Chat',
  TASK: 'Task',
  UPDATE: 'Update',
  OTHER: 'Other',
  REMINDER: 'Reminder',
};
