import React from 'react';
import { AIIcon } from '../Utils/SvgIcons';

function AiSuggestions({ handleSend }) {
  return (
    <section className='suggestions'>
      <button className='suggestion-btn'>
        <AIIcon />
        <span className='suggestion-text' onClick={() => handleSend('What are my todo task?')}>
          What are my todo task?
        </span>
      </button>
      <button className='suggestion-btn'>
        <AIIcon />
        <span className='suggestion-text'>What should I work on next?</span>
      </button>
      <button className='suggestion-btn'>
        <AIIcon />
        <span className='suggestion-text'>Add new task</span>
      </button>
      <button className='suggestion-btn'>
        <AIIcon />
        <span className='suggestion-text'>Chat with AI</span>
      </button>
    </section>
  );
}

export default AiSuggestions;
