import React from 'react';
import StackedAvatars from '../_components/Avatar/StackedAvatars';
import { USER_LIST_ACTIONS } from '../_constants';
import { TEAM_ALL_OPTION, USER_ALL_OPTION } from '../_constants/filesystem.constant';
import { userService } from '../_services';
import { teamService } from '../_services/team.service';
import { FileSystemImage, fullNameUser } from '../Utils';

export const SharedWith = ({
  sharedUserSelect,
  userImageList = [],
  usersForShare = [],
  onAvatarRemove,
  onAdd,
  teamForShare = [],
  teamImageList,
  menuPlacement = 'bottom',
}) => {
  const usersForShareWithAll = [
    { id: 0, first_name: 'All', last_name: '', image_url: FileSystemImage.GroupIcon },
    ...usersForShare,
  ];
  const teamForShareWithAll = [
    { id: 0, name: 'All', image_url: FileSystemImage.GroupIcon },
    ...teamForShare,
  ];

  console.log(teamForShareWithAll, usersForShareWithAll, 'team');

  return (
    <div>
      <div className='my-2'>
        <label className='mb-1 fz-13px-rem '>Shared with member</label>
        <div className='shared-with-container'>
          <StackedAvatars
            selectProps={{
              menuPlacement: menuPlacement,
              // menuPortalTarget: document.getElementById('MODAL'),

              isCustomFunction: true,
              queryKey: 'user-list-file',
              queryFn: userService.getPermittedUser,
              getFunctionParams: (searchKey) => ({
                searchKey,
                select: sharedUserSelect,
                action: USER_LIST_ACTIONS.FILE_SYSTEM,
              }),
              itemToAdd: USER_ALL_OPTION,
            }}
            imageList={userImageList}
            getNameByKey={(user) => fullNameUser(user.first_name, user.last_name)}
            getSourceByKey={(user) => user.image_url}
            getGenderByKey={(user) => user.gender}
            size={30}
            dropdownSize={40}
            isClearable={() => true}
            onAvatarRemove={(user) => {
              onAvatarRemove(user, 'shared_with_user');
            }}
            // menuPlacement='top'
            options={usersForShareWithAll}
            isAddable
            justifyPosition=''
            limit={5}
            showInitials={false}
            getValueByKey={(user) => user.id}
            onAdd={(selectedUsers) => {
              onAdd(selectedUsers, 'user');
            }}
            zIndex={menuPlacement === 'bottom' ? 2 : 1}
          />
        </div>
      </div>
      <div className='my-2'>
        <label className='mb-1 fz-13px-rem '>Shared with team</label>
        <div className='shared-with-container'>
          <StackedAvatars
            selectProps={{
              menuPlacement: menuPlacement,
              // menuPortalTarget: document.getElementById('MODAL'),

              isCustomFunction: true,
              queryKey: 'shared-team-list',
              queryFn: teamService.teamList,
              getFunctionParams: (searchKey) => ({
                searchKey,
                select: ['id', 'name'],
              }),
              itemToAdd: TEAM_ALL_OPTION,
            }}
            imageList={teamImageList}
            getNameByKey={(team) => team?.name}
            size={30}
            dropdownSize={40}
            isClearable={() => true}
            onAvatarRemove={(team) => {
              onAvatarRemove(team, 'shared_with_team');
            }}
            options={teamForShareWithAll}
            isAddable
            justifyPosition=''
            limit={5}
            showInitials={true}
            getValueByKey={(team) => team.id}
            onAdd={(selectedTeams) => {
              onAdd(selectedTeams, 'team');
            }}
            zIndex={menuPlacement === 'bottom' ? 1 : 2}
          />
        </div>
      </div>
    </div>
  );
};
