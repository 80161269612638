/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import Picker from '@emoji-mart/react';
import {
  AudioWaveform,
  CircleArrowDown,
  Clock10,
  Ellipsis,
  File,
  MessageSquareMore,
  PlusIcon,
  RefreshCcw,
  Timer,
  VideoIcon,
} from 'lucide-react';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import CircularAvatar from '../../_components/Avatar/CircularAvatar';
import OkrAudioPlayer from '../../_components/OkrAudioPlayer';
import { Popover } from '../../_components/Popover';
import { apiConstants } from '../../_constants';
import { MESSAGE_TYPES } from '../../_constants/chat.constants';
import useOutsideClickHandler from '../../Hook/useOutsideClickHandler';
import useProgressDownloader from '../../Hook/useProgressDownloader';
import {
  convertBytes,
  ensureHttps,
  fullNameUser as getFullName,
  isValidUrlFeatured,
} from '../../Utils';
import { CircularProgressBar, LoadingIcon } from '../../Utils/SvgIcons';
import { ChatContext } from '../ChatProvider';

// ------- MAIN CONTAINER --------------------//
/***
 * Can be used to display message cards. There is individual type for each cards and overall cards based on type in the MessageCard component
 */
function Container({ children, replyRef, hideContainer }) {
  if (hideContainer) {
    return null;
  }
  return (
    <div
      ref={replyRef}
      className='position-relative message-card-container'
      style={{ marginTop: 5 }}
    >
      {children}
    </div>
  );
}

//--------------- WRAPPER COMPONENTS ---------------------//

// This can be used for both reply msgs and normal msgs, Adjust the container style
/***
 * For displaying all cards based on the provided messageType
 */
const MessageCard = ({ containerClass = '', animDelay = 300, children }) => {
  return (
    <div
      className={`msg-card-container pe-3 py-2 ${containerClass ?? ''}`}
      style={{ animationDelay: `${animDelay}ms` }}
    >
      {children}
    </div>
  );
};

const ReplyCard = ({ containerClass = '', animDelay = 300, children, onReplyClick }) => {
  return (
    <div
      onClick={onReplyClick}
      className={`msg-card-container reply-card ${containerClass ?? ''}`}
      style={{ animationDelay: `${animDelay}ms` }}
    >
      {children}
    </div>
  );
};

const InfoCard = ({ data }) => {
  if (data?.type !== MESSAGE_TYPES.CHAT_CREATION) {
    return null;
  }

  return <div className='text-center info-card'>{data.message}</div>;
};

// For wrapping the user details around the content. It will contains username, avatar and time of the message.
const SenderDetailsSection = ({
  children,
  data,
  isSending,
  onMoreOptionClick,
  disableOption = false,
  includeAvatar = true,
}) => {
  const { first_name, last_name, image_url, gender } = data.creator_details;
  const name = getFullName(first_name, last_name);
  const profilePicUrl = apiConstants.imgUrlS3 + image_url;
  return (
    <div className='d-flex justify-content-between align-items'>
      <div className='d-flex' style={{ gap: 10, flex: 1 }}>
        {includeAvatar && (
          <div className='position-relative h-fit-content'>
            <CircularAvatar
              isCentered={false}
              source={profilePicUrl}
              className='mt-5px'
              size={33}
              isMale={gender === 1}
            />
            {isSending && (
              <div className={'msg-sent-status'}>
                <Clock10 width={'100%'} height={'100%'} strokeWidth={1} />
              </div>
            )}
          </div>
        )}
        <div style={{ flex: 1 }}>
          <div className='d-flex gap-1 align-items-end'>
            <div className='fz-13px fw-600 sender-name'>{name}</div>
            <div className='grey-txt fz-10px'>{moment(data.createdAt).format('hh:mm a')}</div>
          </div>
          {children}
        </div>
      </div>
      {!disableOption && (
        <div
          className='msg-more-options btn-wrapper'
          style={{ visibility: 'hidden' }}
          onClick={(event) => {
            onMoreOptionClick(event.clientX, event.clientY + 20);
          }}
        >
          <div>
            <Ellipsis size={20} />
          </div>
        </div>
      )}
    </div>
  );
};

//------------------------- CHAT LIST CARDS --------------------------

/***
 * For displaying date card
 */
const DateCard = ({ date, showDate = false, zIndex }) => {
  if (!showDate) {
    return null;
  }

  return (
    <div style={{ zIndex }} className='date-card mt-1'>
      {date}
    </div>
  );
};

/***
 * For displaying status change
 */
const StatusChangeInfo = ({ info, showMessage }) => {
  // Check if the condition for showing the status change message is met
  const shouldDisplay =
    (info.type === MESSAGE_TYPES.STATUS_CHANGE &&
      showMessage('The status change needs to be displayed in the chat.')) ||
    (info.type === MESSAGE_TYPES.START_STOP_CHANGE &&
      showMessage('Start and stop message needs to be displayed in the chat'));

  // Conditionally add 'd-none' class if shouldDisplay is false
  return (
    <div className={`status-change-card mt-1 ${!shouldDisplay ? 'd-none' : ''}`}>
      {info.message} at {moment(info.createdAt).format('hh:mm a')} 
    </div>
  );
};

/***
 * For reaction
 */

// For reactions
const SelectedReactions = ({ countObj, onClick = () => {} }) => {
  if (!countObj) {
    return null;
  }

  const emojiList = Object.entries(countObj)
    .filter(([, value]) => value > 0)
    .map(([key]) => key);

  if (emojiList.length <= 0) {
    return null;
  }

  return (
    <div className='reaction-container mt-1 d-flex gap-1 fz-14px'>
      {emojiList.map((emoji, index) => {
        return (
          <React.Fragment key={index}>
            <div
              onClick={() => onClick(emoji)}
              className={`btn-wrapper px-1 ${countObj[emoji] ? 'selected' : ''}`}
            >
              {emoji}
              {Boolean(countObj[emoji]) && countObj[emoji]}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

/***
 * For text messages
 */
const _Text = ({ data, containerClass = '' }) => {
  if (!data.message || data.type !== MESSAGE_TYPES.TEXT) {
    return null;
  }

  return (
    <>
      {data?.link_details && <Link data={data.link_details} />}
      <div className={`fz-13px chat-text-message ${containerClass}`}>
        {data.message.split(' ').map((word, index) => {
          if (isValidUrlFeatured(word)) {
            return (
              <a
                href={ensureHttps(word)}
                target='_blank'
                rel='noopener noreferrer'
                className='link-in-text'
                key={index}
                style={{ color: 'blue', textDecoration: 'none' }}
              >
                {word}{' '}
              </a>
            );
          }
          return `${word} `;
        })}
      </div>
    </>
  );
};

const Text1 = ({ data, containerClass = '' }) => {
  if (!data.message || data.type !== MESSAGE_TYPES.TEXT) {
    return null;
  }

  const renderContent = () => {
    const lines = data.message.split('\n');
    const result = [];
    let currentLine = '';

    for (let i = 0; i < lines.length; i++) {
      currentLine += (i > 0 ? ' ' : '') + lines[i];

      if (
        i === lines.length - 1 ||
        isValidUrlFeatured(currentLine) ||
        !currentLine.match(/(https?:\/\/[^\s]+)/g)
      ) {
        const words = currentLine.split(/(\s+)/);
        const lineContent = words.map((word, wordIndex) =>
          isValidUrlFeatured(word) ? (
            <a
              href={ensureHttps(word)}
              target='_blank'
              rel='noopener noreferrer'
              className='link-in-text'
              key={`${i}-${wordIndex}`}
              style={{ color: 'blue', textDecoration: 'none' }}
            >
              {word}
            </a>
          ) : (
            word
          ),
        );

        result.push(<React.Fragment key={i}>{lineContent}</React.Fragment>);
        if (i < lines.length - 1) {
          result.push(<br key={`br-${i}`} />);
        }
        currentLine = '';
      }
    }

    return result;
  };

  return (
    <>
      {data?.link_details && <Link data={data.link_details} />}
      <div className={`fz-13px chat-text-message ${containerClass}`}>{renderContent()}</div>
    </>
  );
};

const Text = ({ data, containerClass = '' }) => {
  if (!data.message || data.type !== MESSAGE_TYPES.TEXT) {
    return null;
  }

  const text = data.message;
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const matches = text.match(urlRegex);

  if (!matches) {
    return <div className={`fz-13px chat-text-message ${containerClass}`}>{text}</div>;
  }

  const parts = text.split(urlRegex);
  return (
    <>
      {data?.link_details && <Link data={data.link_details} />}
      <div className={`fz-13px chat-text-message ${containerClass}`}>
        {parts.map((part, index) => {
          if (matches.includes(part)) {
            return (
              <a
                href={part}
                target='_blank'
                rel='noopener noreferrer'
                className='link-in-text'
                key={index}
                style={{ color: 'blue', textDecoration: 'none' }}
              >
                {part}
              </a>
            );
          }
          return <span key={index}>{part}</span>;
        })}
      </div>
    </>
  );
};

export default Text;

// FOr document attachment preview
const Document = ({ file, isLocal = false }) => {
  const fileUrl = isLocal
    ? URL.createObjectURL(file.file)
    : apiConstants.imgUrlS3 + file?.uploaded_path;

  if (file.attachedType !== MESSAGE_TYPES.DOCUMENT) {
    return null;
  }

  const { downloadFile, progress, isDownloading, downloadedBytes } = useProgressDownloader({
    fileName: file.name,
    sourceUrl: fileUrl,
  });

  const { unit, value } = convertBytes(file.size);
  const { value: loadedMB, unit: loadedUnit } = convertBytes(downloadedBytes);
  return (
    <>
      <div className='colored-container position-relative '>
        {file?.isUploading && (
          <div className='linear-progress-bar'>
            <div className='lp-progress'></div>
          </div>
        )}
        <div className='d-flex w-100 gap-2 align-items-center'>
          <div className='position-relative'>
            <div className='chat-file-ext'>{file.extension}</div>
            <File size={40} color='grey' fill='grey' className='file-icon' />
          </div>

          <div style={{ flex: 1 }}>
            <div className='chat_msg_name'>{file.name}</div>
            <div className='chat_small_font'>
              {value}
              {unit}
            </div>
          </div>

          {!isDownloading ? (
            <div
              className='d-flex p-0 align-items-center justify-content-end pointer'
              onClick={() => downloadFile()}
            >
              <CircleArrowDown color='#879096' />
            </div>
          ) : (
            <CircularProgressBar
              downloadedMB={loadedMB}
              color='black'
              size='35'
              unit={loadedUnit}
              percentage={Math.round(progress)}
            />
          )}
        </div>
        {/* <div className='row w-100 chat_small_font d-flex justify-content-end'>10:30 am</div> */}
      </div>
    </>
  );
};

// For images preview
const Image = ({
  file,
  isLocal = false,
  containerClassName = '',
  onClick = () => {},
  // size = 20,
  imageClassName = '',
}) => {
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const imageUrl = isLocal
    ? URL.createObjectURL(file.file)
    : apiConstants.imgUrlS3 + file?.uploaded_path;
  // Return null if file is not image
  if (file.attachedType !== MESSAGE_TYPES.IMAGE) {
    return null;
  }

  return (
    <div
      className={`position-relative img-prev-container ${containerClassName} pointer`}
      onClick={onClick}
    >
      {/* Loader */}
      {(file?.isUploading || (!isError && isImageLoading)) && (
        <div className='img-overlay-load'>
          <LoadingIcon size={40} color='#FFF' />
        </div>
      )}

      {/* Show image, if fails show error message */}
      {!isError ? (
        <img
          onLoad={() => {
            setIsImageLoading(false);
            setIsError(false);
          }}
          className={`${imageClassName}`}
          onError={() => setIsError(true)}
          src={imageUrl}
          style={{ width: '100%', height: '100%' }}
        />
      ) : (
        <div
          className='img-overlay-load'
          style={{ cursor: 'pointer' }}
          onClick={() => setIsError(false)} //For retrying
        >
          <div className='img-error'>
            <div>Failed to load</div>
            <RefreshCcw size={10} color='#FFF' />
          </div>
        </div>
      )}
    </div>
  );
};
// For videos preview
const Video = ({ file, isLocal = false }) => {
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(1);
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    const adjustHeight = () => {
      const width = videoElement.videoWidth;
      const height = videoElement.videoHeight;

      // Calculate the aspect ratio
      if (height === 0) {
        setAspectRatio(1); // Default aspect ratio if height is zero to avoid division by zero
        return;
      }
      if (width > height) {
        // Landscape video
        setAspectRatio(width / height);
      } else {
        // Portrait video
        setAspectRatio(height / width);
      }

      // Remove the event listener after the aspect ratio is set
      // videoElement.removeEventListener('loadedmetadata', adjustHeight);
    };

    videoElement?.addEventListener('loadedmetadata', adjustHeight);

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('loadedmetadata', adjustHeight);
      }
    };

    // if (videoElement) {
    //   videoElement.addEventListener('loadedmetadata', adjustHeight);
    // }
  }, []);

  const imageUrl = isLocal
    ? useMemo(() => URL.createObjectURL(file.file), [file.file])
    : apiConstants.imgUrlS3 + file?.uploaded_path;
  // Return null if file is not video
  if (file.attachedType !== MESSAGE_TYPES.VIDEO) {
    return null;
  }

  return (
    <div className='position-relative video-prev-container h-100'>
      {/* Loader */}
      {(file?.isUploading || (!isError && isVideoLoading)) && (
        <div className='img-overlay-load'>
          <LoadingIcon size={40} color='#FFF' />
        </div>
      )}

      {/* Show video, if fails show error message */}
      {!isError ? (
        <video
          onLoadedData={() => {
            setIsVideoLoading(false);
            setIsError(false);
          }}
          ref={videoRef}
          controls
          onError={() => setIsError(true)}
          src={imageUrl}
          style={{ width: '100%', height: '100%', aspectRatio }}
        />
      ) : (
        <div
          className='img-overlay-load'
          style={{ cursor: 'pointer' }}
          onClick={() => setIsError(false)} //For retrying
        >
          <div className='img-error'>
            <div>Failed to load</div>
            <RefreshCcw size={10} color='#FFF' />
          </div>
        </div>
      )}
    </div>
  );
};

// Can be used to show the additional number of files as a card
const ExtraCardCount = ({ files = [], onClick = () => {} }) => {
  if (!Array.isArray(files) || files.length <= 0) {
    return null;
  }
  const additionalCount = files.length;

  const firstFilePath = files[0].attachedType === MESSAGE_TYPES.IMAGE && files[0].uploaded_path;

  return (
    <div
      role='button'
      onClick={onClick}
      className='w-100 h-100 d-flex align-items-center justify-content-center extra-count-card position-relative'
    >
      <div className='count'>+{additionalCount}</div>
      {firstFilePath && (
        <img className='overlay-reply-img' src={apiConstants.imgUrlS3 + firstFilePath} />
      )}
    </div>
  );
};

// FOr audio attachments preview
const Audio = ({ file, isLocal }) => {
  // const [play, setPlay] = useState(false);
  // const [isAudioLoading, setIsAudioLoading] = useState(true);

  const audioUrl = isLocal
    ? URL.createObjectURL(file.file)
    : apiConstants.imgUrlS3 + file?.uploaded_path;

  if (file.attachedType !== MESSAGE_TYPES.AUDIO) {
    return null;
  }

  return (
    <div className='d-flex audio-player-container'>
      <OkrAudioPlayer src={audioUrl} disableAvatar />
    </div>
  );
};
// FOr recorded attachment preview
const RecordedAudio = ({ file, isLocal, iconSize = 40 }) => {
  // const [play, setPlay] = useState(false);
  // const [isAudioLoading, setIsAudioLoading] = useState(true);

  const audioUrl = isLocal ? file.blobUrl : apiConstants.imgUrlS3 + file?.uploaded_path;

  if (file.attachedType !== MESSAGE_TYPES.RECORDED_AUDIO) {
    return null;
  }

  return (
    <div className='d-flex recorder-audio-preview'>
      <OkrAudioPlayer src={audioUrl} iconSize={iconSize} />
    </div>
  );
};

// for reminder
const Reminder = ({ data }) => {
  if (data.type !== MESSAGE_TYPES.REMINDER) {
    return null;
  }
  return (
    <>
      <div className='d-flex align-items-center gap-3 colored-container'>
        <div>
          <div className='icon_bg_inside_chat'>
            <Timer color='#797979' size={25} />
          </div>
        </div>
        <div>
          <div className='chat_msg_name'>{data.message}</div>
          <div className='chat_reminder_font'>Today from 1:00 to 1:30</div>
        </div>
      </div>
      {/* <div className='row w-100 chat_small_font d-flex justify-content-end'>10:30 am</div> */}
    </>
  );
};

// For Request
const Request = ({ data }) => {
  return (
    <>
      {data.creator_id != 1 && <div className='chat_msg_name'>{data.creator_name}</div>}
      <div className='row w-100'>
        <div className='col-md-2'>
          <div className='icon_bg_inside_chat'>
            <MessageSquareMore color='#797979' size={18} />
          </div>
        </div>
        <div className='col-md-10 ms-0'>
          <div className='chat_msg_name'>Task anme_dummy_task name</div>
          <div className='chat_reminder_font mt-1'>
            Organize Your Life designed by Movadex Connect with them on Dribbble..
          </div>
        </div>
      </div>
      <div className='row w-100 mt-1'>
        <div className='col-md-2'></div>
        <div className='col-md-8 d-flex align-items-end'>
          <CircularAvatar
            size={20}
            source={'/icons/man.png'}
            className='fz-none zoom-in employee-img-with-low-border'
            isMale
          />
          <div className='chat_msg_name'>John</div>
        </div>
      </div>
      <div className='row w-100 chat_small_font d-flex justify-content-end'>10:30 am</div>
    </>
  );
};

// For Links
const Link = ({ data }) => {
  const url = ensureHttps(data.url);
  const imageUrl = data.image ? (data.image.startsWith('/') ? url + data.image : data.image) : null;

  return (
    <>
      <div className='colored-container link-prev-container mb-2'>
        <a className='link-prev' href={url} target='_blank' rel='noopener noreferrer'>
          <div className='link-img-container'>
            <img src={imageUrl} />
          </div>
          <div className='link-title mt-2'>{data.title}</div>
          <div className='link-description'>{data.description}</div>
        </a>
      </div>
    </>
  );
};

const ReplyItemPreview = ({ item, onFileClick = () => {}, isUserSame = false }) => {
  return (
    <div className='d-flex gap-2 mt-1 justify-content-between'>
      <div className='d-flex flex-column justify-contern'>
        {!isUserSame && <div className='fw-500'>{item.creator_details.first_name} </div>}
        <Text containerClass='clamp-text' data={item} />
      </div>
      {item?.files?.length > 0 && (
        <div className='extra-card-reply-container'>
          <ExtraCardCount
            files={item.files}
            onClick={(event) => {
              event.stopPropagation();
              onFileClick(item);
            }}
            additionalCount={item.files.length ?? 0}
          />
        </div>
      )}
    </div>
  );
};

// ------------------------- FOR REPLY - CARDS --------------------------//
// For preview
// file
const DocumentPreview = ({ file }) => {
  if (file.attachedType !== MESSAGE_TYPES.DOCUMENT) {
    return null;
  }

  const { unit, value } = convertBytes(file.size);
  return (
    <div className='w-100 h-100 d-flex flex-column align-items-center justify-content-center'>
      <div className='position-relative w-fit-content'>
        <div className='chat-file-ext' style={{ fontSize: 20 }}>
          {file.extension}
        </div>
        <File size={100} color='grey' fill='grey' className='file-icon' />
      </div>

      <div className='text-center'>
        <div className='chat_msg_name'>{file.name}</div>
        <div className='chat_small_font'>
          {value}
          {unit}
        </div>
      </div>
    </div>
  );
};

const FileIcons = ({ attachedType, imgUrl }) => {
  if (attachedType === MESSAGE_TYPES.IMAGE) {
    return <img src={imgUrl} />;
  }

  if (attachedType === MESSAGE_TYPES.DOCUMENT) {
    return (
      <div className='d-flex flex-column thin-grey-border justify-content-center align-items-center h-100'>
        <File size={30} />
      </div>
    );
  }
  if (attachedType === MESSAGE_TYPES.VIDEO) {
    return (
      <div className='d-flex flex-column thin-grey-border justify-content-center align-items-center h-100'>
        <VideoIcon size={30} />
      </div>
    );
  }
  if (attachedType === MESSAGE_TYPES.AUDIO) {
    return (
      <div className='d-flex flex-column thin-grey-border justify-content-center align-items-center h-100'>
        <AudioWaveform size={30} />
      </div>
    );
  }
};

const DefaultReactions = ({ onSelect = () => {}, isVisible, onClose = () => {} }) => {
  const { messageListRef } = useContext(ChatContext);
  const [isPickerVisible, setIsPickerVisible] = useState(false);
  const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 });
  const { containerRef } = useOutsideClickHandler({
    onClose: () => {
      setIsPickerVisible(false);
      onClose();
    },
  });

  if (!isVisible) {
    return null;
  }

  return (
    <Popover.PopoverWrapper>
      <div className='default-reactions' ref={containerRef}>
        <div className='pointer emoji' onClick={() => onSelect('👍')}>
          👍
        </div>
        <div className='pointer emoji' onClick={() => onSelect('👌')}>
          👌
        </div>
        <div className='pointer emoji' onClick={() => onSelect('😊')}>
          😊
        </div>
        <div
          className='btn-wrapper'
          onClick={(event) => {
            setPointerPosition({ x: event.clientX, y: event.clientY });
            setIsPickerVisible(true);
          }}
        >
          <PlusIcon />
        </div>

        {isPickerVisible && (
          <Popover.PopoverItem
            scrollRef={messageListRef}
            positionLeft={() => -5}
            positionTop={() => -10}
          >
            <div
              className='emoji-add'
              style={{
                backgroundColor: 'red',
                position: 'absolute',
                top: `calc(${pointerPosition.x} + 10px)`,
                left: `calc(${pointerPosition.y})`,
                zIndex: '999',
              }}
            >
              <Picker
                previewPosition={'none'}
                onEmojiSelect={(emoji) => {
                  setIsPickerVisible(false);
                  onSelect(emoji.native);
                }}
                theme={'light'}
              />
            </div>
          </Popover.PopoverItem>
        )}
      </div>
    </Popover.PopoverWrapper>
  );
};

// Slack Type Cards
export const ChatMessageCards = {
  Container,
  Reminder,
  FileIcons,
  Video,
  Audio,
  Document,
  MessageCard,
  ReplyCard,
  SenderDetailsSection,
  DefaultReactions,
  DateCard,
  Text,
  Image,
  ExtraCardCount,
  RecordedAudio,
  Request,
  Link,
  ReplyItemPreview,
  DocumentPreview,
  SelectedReactions,
  InfoCard,
  StatusChangeInfo,
};
