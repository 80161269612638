/* eslint-disable no-unused-vars */
import { ChevronLeft, ChevronRight, Download, X } from 'lucide-react';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import Pdf from 'react-pdf-js';
import { getFileType } from '../Utils';
import { apiConstants } from '../_constants';
import './file.css';
// import PDFViewer from 'pdf-viewer-reactjs';

const Container = ({ className, children, handleClose = () => {} }) => {
  const modalContent = document.getElementById('MODAL');
  return createPortal(
    <div
      className={`fade-opacity ${className ? className : 'preview-modal-contain'}`}
      // onClick={() => handleClose()}
    >
      {children}
    </div>,
    modalContent,
  );
};

const Head = ({ handleClose, containerClass, headerClass, file ,fileUrl}) => {
  const containerStyle = containerClass || 'preview-modal';
  const headerStyle = headerClass || 'preview-head';
  return (
    <div className={containerStyle}>
      <div className={headerStyle}>{file}</div>
      <div style={{ display: 'flex', gap: '20px' }}>
        <Download
          size={24}
          color='white'
          onClick={() => {
            window.open(apiConstants.imgUrlS3 + fileUrl, '_blank');
          }}
          style={{ cursor: 'pointer' }}
        />

        <X size={24} color='white' onClick={() => handleClose()} style={{ cursor: 'pointer' }} />
      </div>
    </div>
  );
};
const FileDisplay = {
  image: ({ file }) => <img src={apiConstants.imgUrlS3 + file} alt='file preview' width={'60%'} height={'60%'}/>,
  audio: ({ file }) => <audio controls src={apiConstants.imgUrlS3 + file} />,
  video: ({ file }) => (
    <video controls src={apiConstants.imgUrlS3 + file} width={'60%'} height={'60%'} />
  ),
  pdf : ({ file }) => {
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(null);
  
    const onDocumentComplete = (numPages) => {
      setPages(numPages);
    };

  
    return (
      <div className='mb-5'>
          <div className="preview-pdf" >
        <button 
          onClick={() => setPage(page - 1)} 
          disabled={page === 1} 
          style={{ position: 'absolute', left: 0 }}
        >
          <ChevronLeft size={32} />
        </button>
        <Pdf
          file={apiConstants.imgUrlS3 + file}
          onDocumentComplete={onDocumentComplete}
          page={page}
          // style={pdfStyle}
          
        />
       
        <button 
          onClick={() => setPage(page + 1)} 
          disabled={page === pages} 
          style={{ position: 'absolute', right: 0 }}
        >
          <ChevronRight size={32}  />
        </button>
       
      </div>

      <p style={{color:'white', textAlign:'center'}}>
          Page {page} of {pages}
        </p>

      </div>

    
    );
  },
  
  default: ({ file }) => <div>Unsupported file type</div>,
};

const FileView = ({ file, extension }) => {
  const fileType = getFileType(extension);
  const FileComponent = FileDisplay[fileType] || FileDisplay.default;

  return (
    <div className='preview-files'>
      <FileComponent file={file} />
    </div>
  );
};
export const FilePreviewModal = {
  Container,
  Head,
  FileView,
};
