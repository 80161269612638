import React, { useState } from 'react';
import { SelectableContextMenu } from '../../_components';

const RequestHourInput = ({
  setIsTimeInputVisible,
  onSubmit,
  initialValue,
  isHeadInvisible = false,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [reqTimeInput, setReqTimeInput] = useState(initialValue);

  const hasRequestTime =
    Boolean(parseInt(reqTimeInput?.expected_hours)) ||
    Boolean(parseInt(reqTimeInput?.expected_minutes));

  return (
    <SelectableContextMenu
      isContextVisible={true}
      onRequestClose={() => setIsTimeInputVisible(false)}
      className='time-context-view '
    >
      <form
        className='d-flex flex-column gap-2 p-2'
        onSubmit={(e) => {
          e.preventDefault();
          setIsSubmitted(true);
          if (hasRequestTime) {
            onSubmit(reqTimeInput);
            setIsTimeInputVisible(false);
          }
        }}
        style={{ fontSize: 13 }}
      >
        <div hidden={isHeadInvisible}>Expected Time</div>
        <div className='d-flex gap-2 align-items-center'>
          <input
            type='number'
            className={`form-control  ${isSubmitted && !hasRequestTime ? 'has-error' : ''}`}
            id='expected_hours'
            name='expected_hours'
            placeholder='Hours '
            min='0'
            value={reqTimeInput.expected_hours}
            onChange={({ target }) =>
              setReqTimeInput({
                ...reqTimeInput,
                expected_hours: target.value,
              })
            }
            style={{ width: 100, fontSize: 13 }}
          />
          :
          <input
            type='number'
            className={`form-control  ${isSubmitted && !hasRequestTime ? 'has-error' : ''}`}
            id='expected_minutes'
            min='0'
            max='59'
            name='expected_minutes'
            placeholder='Minutes '
            value={reqTimeInput.expected_minutes}
            onChange={({ target }) =>
              setReqTimeInput({
                ...reqTimeInput,
                expected_minutes: target.value,
              })
            }
            style={{ width: 100, fontSize: 13 }}
          />
        </div>
        {isSubmitted && !hasRequestTime && (
          <div className='error-block'>Please enter a valid time</div>
        )}
        <div className='d-flex justify-content-end w-100 gap-2'>
          <div
            className='btn-wrapper pe-1 py-1'
            role='button'
            onClick={() => setIsTimeInputVisible(false)}
          >
            Cancel
          </div>
          <button type='submit' className='unset-btn btn-wrapper pe-1 py-1'>
            Save
          </button>
        </div>
      </form>
    </SelectableContextMenu>
  );
};

export default RequestHourInput;
