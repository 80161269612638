/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import { Plus } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ReactSelect from 'react-select';
import { Heading } from '../../Misc';
import { GlobalContext } from '../../Utils';
import { Modal, PaginationComponent, TableRow } from '../../_components';
import DatePicker from '../../_components/DatePicker';
import ToolTip from '../../_components/Tooltip/ToolTip';
import useDebounce from '../../_helpers/useDebounce';
import { projectService, taskService } from '../../_services';
import { reportServices } from '../../_services/reports.service';
import { TimeSheetAddModal } from './TimeSheetAddModal';
import { filterSelectBoxStyles, timeSheetSelect } from './TimeSheetHelper';
import TimeSheetListBody from './TimeSheetListBody';
import TimeSheetListHead from './TimeSheetListHead';
import './timesheet.css';

export default function TimeSheetList() {
  const { makeAlert } = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [project, setProject] = useState(null);
  const [task, setTask] = useState(null);

  const timeSheetProject = searchParams.get('project');
  const timeSheetTask = searchParams.get('task');
  const [timeSheetAddModal, setTimeSheetAddModal] = useState(false);

  const [query, setQuery] = useState({
    searchKey: '',
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
  });

  // useEffect(() => {
  //   if (timeSheetProject) {
  //     const selectedProject = projectData?.find((p) => p.id === timeSheetProject);
  //     setProject(selectedProject);
  //   } else {
  //     setProject(null);
  //   }

  //   if (timeSheetTask) {
  //     const selectedTask = taskData?.find((t) => t.id === timeSheetTask);
  //     setTask(selectedTask);
  //   } else {
  //     setTask(null);
  //   }
  // }, [timeSheetProject, timeSheetTask, projectData, taskData]);

  // Fetch the projects
  const { data: projectData, error: projectError } = useQuery({
    queryKey: ['project-list'],
    queryFn: () =>
      projectService.projectList({
        select: ['id', 'name'],
      }),
    select: (data) => data.data.rows,
  });
  if (projectError) makeAlert(projectError.message);

  // Fetch the tasks based on the selected project
  const { data: taskData, error: taskError } = useQuery({
    queryKey: ['task-list', project],
    queryFn: () =>
      taskService.taskList({
        select: ['id', 'name'],
        project_id: project?.id,
      }),
    enabled: !!project,
    select: (data) => data.data.rows,
  });
  if (taskError) makeAlert(taskError.message);

  const debouncedSearchKey = useDebounce(query.searchKey, 500);
  const debouncedProjectID = useDebounce(timeSheetProject, 500);
  const debouncedTaskId = useDebounce(timeSheetTask, 500);

  const handleSetSearchParams = (name, value) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set(name, value);
    setSearchParams(updatedSearchParams);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuery({ ...query, [name]: value });
    handleSetSearchParams(name, value);
  };

  const handleProjectChange = (selectedOption) => {
    setTask(null);
    setProject(selectedOption);
  
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set('project', selectedOption?.id || '');
    updatedSearchParams.delete('task'); // Remove the task parameter
    setSearchParams(updatedSearchParams);
  };
  

  const handleTaskChange = (selectedOption) => {
    setTask(selectedOption);
    handleSetSearchParams('task', selectedOption?.id || '');
  };

  const handleDateChange = (dates) => {
    const startDate = moment(dates.startDate).startOf('day').valueOf();
    const endDate = moment(dates.endDate).endOf('day').valueOf();
    handleSetSearchParams('fromDate', startDate);
    handleSetSearchParams('toDate', endDate);
  };

  const {
    data,
    error: timeSheetError,
    refetch,
  } = useQuery({
    queryKey: [
      'time-sheet-list',
      query.pageVo.pageNo,
      debouncedSearchKey,
      debouncedProjectID,
      debouncedTaskId,
    ],
    queryFn: () =>
      reportServices.timeSheetList({
        select: timeSheetSelect,
        search_key: debouncedSearchKey,
        project_id: debouncedProjectID,
        task_id: debouncedTaskId,
        pageVo: query.pageVo,
      }),
  });

  const timeSheetData = data?.data?.rows;

  const handleClear = () => {
    setProject(null);
    setTask(null);
    const filters = ['project', 'task', 'fromDate', 'toDate'];
    filters.forEach((key) => searchParams.delete(key));
    setSearchParams(searchParams);
  };

  return (
    <div className='p-4'>
      <Heading title={'Timesheet'} />
      <div className='d-flex mt-3 justify-content-between'>
        <div className='d-flex gap-3'>
          <div style={{ width: 'fit-content' }}>
            <ReactSelect
              styles={filterSelectBoxStyles}
              placeholder='Project'
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              onChange={handleProjectChange}
              options={projectData}
              value={project}
            />
          </div>
          <div style={{ width: 'fit-content' }}>
            <ReactSelect
              styles={filterSelectBoxStyles}
              placeholder='Task'
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              onChange={handleTaskChange}
              options={taskData}
              value={task}
            />
          </div>
          <DatePicker
            onDateChange={handleDateChange}
            initialStartDate={parseInt(searchParams.get('fromDate'))}
            initialEndDate={parseInt(searchParams.get('toDate'))}
            isEditable
          />
          <div
            className='menu-item-clear'
            style={{ fontSize: 12, cursor: 'pointer', marginTop: 12 }}
            onClick={handleClear}
          >
            Clear
          </div>
        </div>
        <ToolTip tooltipText={'Add Timesheet'} isModern>
          <div
            className='d-flex justify-content-center align-items-center add_item_icon_bg'
            style={{ height: 31 }}
            onClick={() => setTimeSheetAddModal(true)}
          >
            <Plus size={18} />
          </div>
        </ToolTip>
      </div>
      <section className='mt-3'>
        <div className='table_outer'>
          <table className='table table-hover tbl mb-0'>
            <TimeSheetListHead />
            <tbody style={{ verticalAlign: 'middle' }}>
              {timeSheetData?.length > 0 ? (
                timeSheetData.map((item, index) => (
                  <TimeSheetListBody
                    timeSheetData={item}
                    index={(query.pageVo.pageNo - 1) * query.pageVo.noOfItems + (index + 1)}
                    key={index}
                  />
                ))
              ) : (
                <tr>
                  <TableRow noOfCols={9}>No data found</TableRow>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <section className='mt-2'>
          <PaginationComponent
            pageOnClick={(data) => {
              setQuery((prev) => ({
                ...prev,
                pageVo: {
                  ...prev.pageVo,
                  pageNo: data,
                },
              }));
              handleSetSearchParams('pageNo', data);
            }}
            demoData={data?.data}
          />
        </section>
      </section>
      <section>
        {timeSheetAddModal && (
          <Modal.Container>
            <Modal.View>
              <TimeSheetAddModal
                handleClose={() => {
                  setTimeSheetAddModal(false);
                  refetch();
                }}
              />
            </Modal.View>
          </Modal.Container>
        )}
      </section>
    </div>
  );
}
