import React from 'react';
import ReactSelect, { components } from 'react-select';
import { fullNameUser, isTemplateFieldDisabled, subtaskState } from '../../Utils';
import { ToggleButton } from '../../_components';

import { Hourglass } from 'lucide-react';
import { getFormattedLabel } from '../../Task/TaskComponents/CustomInputs';
import { taskAssignUserSelect } from '../../Task/taskConstants';
import { getExpectedTime } from '../../Task/taskValidation';
import { Label } from '../../Utils/SvgIcons';
import StackedAvatars from '../../_components/Avatar/StackedAvatars';
import InputWithIcons from '../../_components/Inputs/InputWithIcons';
import SearchableQuerySelect from '../../_components/SearchableQuerySelect';
import TeamDropDown from '../../_components/TeamDropDown';
import UserDropDown from '../../_components/UserDropDown';
import { USER_LIST_ACTIONS } from '../../_constants';
import {
  MENU_ICONS,
  SHARE_UPDATES_OPTIONS,
  TASK_TYPES,
  TEMPLATE_ASSIGN_USER_SCOPE,
  TEMPLATE_ERROR_MSGS,
  TEMPLATE_FIELD_VALIDATIONS,
  TEMPLATE_TYPE,
  TEMPLATE_VERIFY_CHANGE,
  TEMPLATE_VISIBLE_ALL_OPTION,
} from '../../_constants/template.constants';
import { userService } from '../../_services';
import { labelService } from '../../_services/label.service';
import { statusService } from '../../_services/status.service';
import ClickableSelect from './ClickableSelect';
import ErrorBlock from './ErrorBlock';
import MultiUnitDurationInput from './MultiUnitDurationInput';
import TemplateSubtask from './TemplateSubtask';
const { MultiValueRemove } = components;

function TemplateFormSection({
  templateDetails,
  handleChange,
  isSubtaskActive,
  setTemplateDetails,
  isSubmitted,
  labelList,
  userList = [],
  usersForVerification = [],
  statusGroup,
  groupList,
  isEdit = false,
  containerRef,
}) {
  // const [isSettingsExpanded, setIsSettingExpanded] = useState(false);

  const AssignUserOptions = [
    {
      label: 'User and Subordinates',
      value: TEMPLATE_ASSIGN_USER_SCOPE.USER_AND_CHILD_USERS,
    },
    { label: 'Everyone', value: TEMPLATE_ASSIGN_USER_SCOPE.EVERYONE },
    { label: 'Only User', value: TEMPLATE_ASSIGN_USER_SCOPE.ONLY_USER },
    { label: 'Reported Users', value: TEMPLATE_ASSIGN_USER_SCOPE.REPORTED_USERS },
  ];

  const templateTypeOptions = [
    { label: 'Task', value: TEMPLATE_TYPE.NORMAL },
    { label: 'Request', value: TEMPLATE_TYPE.REQUEST },
    { label: 'Todo', value: TEMPLATE_TYPE.TODO },
  ];

  /***
   * For updating or adding subtask. If there is no index or index is out of bound of subtask list length, it will be considered as add, otherwise it will be updated based on the index.
   */
  const handleSubTaskAddOrUpdate = (value, index = null) => {
    // update
    if (!isNaN(index) && index !== null && index <= templateDetails.subtasks.length - 1) {
      setTemplateDetails((prev) => ({
        ...prev,
        subtasks: prev.subtasks.map((itm, idx) => {
          if (idx === index) {
            return { ...itm, content: value };
          }
          return itm;
        }),
      }));
      return;
    }

    const initSubtask = { ...subtaskState };
    initSubtask.content = value;
    // TODO: Add user_id and task_id(if exist)

    setTemplateDetails((prev) => ({
      ...prev,
      subtasks: [...prev.subtasks, initSubtask],
    }));
  };

  const handleSubtaskDelete = (index) => {
    setTemplateDetails((prev) => ({
      ...prev,
      subtasks: prev.subtasks.filter((_, idx) => idx !== index),
    }));
  };

  const handleTemplateTypeSelection = (type) => {
    type === TEMPLATE_TYPE.NORMAL &&
      handleChange({
        target: {
          name: 'assign_user_scope',
          value: TEMPLATE_ASSIGN_USER_SCOPE.USER_AND_CHILD_USERS,
        },
      });
    type === TEMPLATE_TYPE.TODO &&
      handleChange({
        target: {
          name: 'assign_user_scope',
          value: TEMPLATE_ASSIGN_USER_SCOPE.ONLY_USER,
        },
      });

    const result = {};

    for (let [key, typeValue] of Object.entries(TEMPLATE_FIELD_VALIDATIONS)) {
      if (typeValue[type]) {
        result[key] = typeValue[type].value;
      }
    }

    setTemplateDetails((prev) => ({ ...prev, ...result }));
  };

  // We don't want to show 'All' options if template selected group is selected
  const templateVisibleOptions =
    templateDetails?.template_visible_groups?.length > 0
      ? userList
      : [TEMPLATE_VISIBLE_ALL_OPTION, ...userList];

  const isNonBoundedTask = templateDetails.task_type === TASK_TYPES.NON_TIME_BOUND_TASK;

  const expectedTime = getExpectedTime(
    parseInt(templateDetails?.expected_hours),
    parseInt(templateDetails?.expected_minutes),
  );

  const labelCount = templateDetails?.labels?.length ?? 0;
  //start time and end time not need to pre-set on template
  // const startTime = templateDetails.start_time;
  // const endTime = templateDetails.end_time;

  const isTaskTypeDisabled = isTemplateFieldDisabled(templateDetails.type, 'task_type');
  const isUnlistedDisabled = isTemplateFieldDisabled(templateDetails.type, 'is_unlisted');

  return (
    <section className='template-create-section half  ' style={{ marginBottom: 30 }}>
      <div className='template-input-section template-container-horizontal-padding'>
        {/* --- TEMPLATE DETAILS --- */}

        {/* Name and description inputs */}
        <div className='row'>
          <div className='col bare-input-container'>
            <input
              type='text'
              name='name'
              onChange={handleChange}
              value={templateDetails.name}
              placeholder='Type template name'
              className='input-box fz-18px-rem w-100 fw-500 default-input bare-input'
            />
            {/* <div className='input-wrapper' /> */}
            <ErrorBlock
              errorMsg={TEMPLATE_ERROR_MSGS.TEMPLATE_NAME_REQUIRED}
              hasError={isSubmitted && !templateDetails?.name}
            />
          </div>
        </div>

        {/* <div className='row mt-18px'>
          <div className='col'>
            <div className='input-label'>Description</div>
            <input
              placeholder='Description'
              name='description'
              value={templateDetails.description}
              onChange={handleChange}
              type='text'
              className='input-box w-100 default-input'
            />
          </div>
        </div> */}

        <div className='row mt-18px'>
          <div className='col-md-6'>
            <div className='input-label'>
              Template For<sup>*</sup>
            </div>
            <div className='row mt-5px'>
              <div className='col-auto d-flex w-100 align-items-center'>
                <ReactSelect
                  value={templateTypeOptions.find((item) => item.value === templateDetails.type)}
                  onChange={(selectedOption) => {
                    handleChange({ target: { name: 'type', value: selectedOption.value } });
                    handleTemplateTypeSelection(selectedOption.value);
                  }}
                  classNames={{
                    control: () => {
                      return 'default-select';
                    },
                  }}
                  isDisabled={templateDetails?.related_tasks?.length > 0 || isEdit}
                  className='w-100'
                  options={templateTypeOptions}
                />
              </div>
            </div>
          </div>

          <div className='col-md-6'>
            <div className='input-label'>
              Assignee User Scope<sup>*</sup>
            </div>
            <div className='row mt-5px'>
              <div className='col-auto d-flex w-100 align-items-center'>
                <ReactSelect
                  value={AssignUserOptions.find(
                    (item) => item.value === templateDetails.assign_user_scope,
                  )}
                  classNames={{
                    control: () => {
                      return 'default-select';
                    },
                  }}
                  isDisabled={
                    isEdit ||
                    templateDetails.type !== TEMPLATE_TYPE.REQUEST ||
                    templateDetails?.related_tasks?.length > 0
                  }
                  onChange={(selectedOption) =>
                    handleChange({
                      target: { name: 'assign_user_scope', value: selectedOption.value },
                    })
                  }
                  className='w-100'
                  options={AssignUserOptions}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Template visibility - Users and groups */}
        <div className='mt-18px'>
          <div className='row'>
            <div className='col'>
              <div className='input-label'>
                Template Visibility-Users<sup>*</sup>
              </div>

              {/* <div className='my-3'>
                <StackedAvatars
                  imageList={templateDetails?.template_visible_users ?? []}
                  getNameByKey={(user) => fullNameUser(user.first_name, user.last_name)}
                  getSourceByKey={(user) => user.image_url}
                  getGenderByKey={(user) => user.gender}
                  size={30}
                  dropdownSize={40}
                  isClearable={(user) => user.id > 0}
                  onAvatarRemove={(user) => {
                    setTemplateDetails((prev) => ({
                      ...prev,
                      template_visible_users: prev.template_visible_users.filter(
                        (item) => parseInt(item.id) !== parseInt(user.id),
                      ),
                    }));
                  }}
                  options={templateVisibleOptions}
                  isAddable
                  justifyPosition=''
                  showInitials
                  getValueByKey={(user) => user.id}
                  renderCustomOptionLabel={(data, metaData) =>
                    data &&
                    data.id <= 0 &&
                    metaData.context === 'value' && <SelectComponents.AllUsersFormatLabel />
                  }
                  onAdd={(selectedUsers) => {
                    handleChange({
                      target: {
                        value: [
                          ...(templateDetails.template_visible_users ?? []),
                          ...selectedUsers,
                        ],
                        name: 'template_visible_users',
                      },
                    });
                  }}
                />
              </div> */}

              <SearchableQuerySelect
                initialValue={templateVisibleOptions}
                queryKey='user-list'
                classNames={{
                  control: () => {
                    return 'default-select';
                  },
                }}
                isClearable={
                  templateDetails?.template_visible_users?.length > 0 &&
                  templateDetails?.template_visible_users?.[0]?.id !== 0
                } //When selected 'All' option, we don't need to show clearable option
                placeholder='Select users'
                components={{ MultiValueRemove: TemplateVIsibleUserCloseComponent }}
                formatOptionLabel={(data, metaData) => (
                  <UserDropDown data={data} metaData={metaData} />
                )}
                isMulti
                value={templateDetails.template_visible_users}
                onChange={(selectedOptions) =>
                  handleChange({
                    target: { value: selectedOptions, name: 'template_visible_users' },
                  })
                }
                getOptionLabel={(option) => option.first_name}
                getOptionValue={(option) => option.id}
                className='mt-5px w-100'
              />
            </div>
          </div>
        </div>
        {/* Template group visibility */}
        <div className='row mt-18px'>
          <div className='col'>
            <div className='input-label'>
              Template Visibility-Groups<sup>*</sup>
            </div>
            <ReactSelect
              options={groupList}
              classNames={{
                control: () => {
                  return 'default-select';
                },
              }}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              formatOptionLabel={(data, metaData) => (
                <TeamDropDown data={data} metaData={metaData} limit={4} />
              )}
              value={templateDetails.template_visible_groups}
              onChange={(selectedOptions) =>
                handleChange({
                  target: { value: selectedOptions, name: 'template_visible_groups' },
                })
              }
              isMulti
              placeholder='Select groups'
              className='mt-5px w-100'
            />
          </div>
        </div>

        {/* Status Group */}
        <div className='mt-18px'>
          <div className='row '>
            <div className='col-md-6'>
              <div className='input-label'>Status Group</div>
              <SearchableQuerySelect
                isCustomFunction
                isDisabled={isEdit}
                initialValue={statusGroup}
                getFunctionParams={(searchKey) => ({
                  searchKey,
                  select: ['id', 'name', 'company_id', 'members', 'members_details'],
                })}
                queryKey='group-status-list'
                querySelect={(data) =>
                  data.data ? [...data.data.defaultStatus, ...data.data.nonDefaultStatus] : []
                }
                queryFn={statusService.statusGroupList}
                classNames={{
                  control: () => {
                    return 'default-select';
                  },
                }}
                options={statusGroup}
                // placeholder='Select'
                className='mt-5px w-100'
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onChange={(selectedOption) =>
                  handleChange({ target: { value: selectedOption, name: 'status_group' } })
                }
                value={templateDetails.status_group}
              />
              <ErrorBlock
                hasError={isSubmitted && !templateDetails.status_group}
                errorMsg={'Please select a status group'}
              />
            </div>
          </div>

          <div className='w-100 d-flex flex-wrap align-items-center mt-20px gap-2 status-group-prev-container'>
            {templateDetails?.status_group?.items?.map((status) => {
              return (
                <div key={status.id} className='status-prev-item d-flex align-items-center'>
                  {status.color && (
                    <div className='status-dot' style={{ backgroundColor: status.color }} />
                  )}
                  {status.name}
                </div>
              );
            })}
          </div>
        </div>

        {/* Other Toggle Options */}
        <div className='mt-18px '>
          {!isTaskTypeDisabled && (
            <div className='row  align-items-center mt-10px'>
              <div className='col'>
                <div className='d-flex align-items-center justify-content-between gap-3 '>
                  <div className='fz-13px-rem fw-500'>Set as non-time-bound tasks</div>
                  <input
                    type='checkbox'
                    checked={isNonBoundedTask}
                    className='template-checkbox'
                    // disabled={isTemplateFieldDisabled(templateDetails.type, 'task_type')}
                    id={'task_type'}
                    onChange={(event) => {
                      const { checked } = event.target;
                      handleChange({
                        target: {
                          value: checked
                            ? TASK_TYPES.NON_TIME_BOUND_TASK
                            : TASK_TYPES.TIME_BOUND_TASK,
                          name: 'task_type',
                        },
                      });
                      handleChange({
                        target: {
                          value: 0,
                          name: 'expected_hours',
                        },
                      });
                      handleChange({
                        target: {
                          value: 0,
                          name: 'expected_minutes',
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {!isUnlistedDisabled && (
            <div className='row  align-items-center mt-10px'>
              <div className='col'>
                <div className='d-flex align-items-center justify-content-between gap-3'>
                  <div className='fz-13px-rem fw-500'>Set as unlisted task</div>
                  <input
                    type='checkbox'
                    onChange={handleChange}
                    className='template-checkbox'
                    name='is_unlisted'
                    checked={templateDetails.is_unlisted}
                    // disabled={isTemplateFieldDisabled(templateDetails.type, 'is_unlisted')}
                    id={'is_unlisted'}
                  />
                </div>
              </div>
            </div>
          )}
          <div className='row align-items-center  mt-10px'>
            <div className='col'>
              <div className='d-flex align-items-center justify-content-between gap-3'>
                <div className='fz-13px-rem fw-500'>Share Updates</div>
                <input
                  type='checkbox'
                  checked={templateDetails.share_updates === SHARE_UPDATES_OPTIONS.SHARE}
                  id={'share_updates'}
                  className='template-checkbox'
                  name='share_updates'
                  onChange={(event) => {
                    const { name, checked } = event.target;
                    const value = checked
                      ? SHARE_UPDATES_OPTIONS.SHARE
                      : SHARE_UPDATES_OPTIONS.DO_NOT_SHARE;
                    handleChange({
                      target: {
                        value: value,
                        name: name,
                        type: 'text',
                      },
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className='row align-items-center  mt-10px'>
            <div className='col'>
              <div className='d-flex align-items-center justify-content-between gap-3'>
                <div className='fz-13px-rem fw-500'>View in sidebar</div>

                <ToggleButton
                  isChecked={templateDetails.include_in_menu}
                  disabled={templateDetails.is_unlisted}
                  size={30}
                  handleToggle={handleChange}
                  id={'include_in_menu'}
                />
              </div>
            </div>
          </div>
          {templateDetails.include_in_menu && (
            <div className='row mt-10px'>
              <div className='col '>
                <InputWithIcons
                  placeholder='Enter name for menu'
                  onIconChange={(icon) =>
                    handleChange({ target: { value: icon.name, name: 'menu_icon' } })
                  }
                  iconList={MENU_ICONS}
                  getIconUrl={(icon) => icon.path}
                  extractId={(icon) => icon.id}
                  inputValue={templateDetails.menu_title}
                  name='menu_title'
                  onInputChange={handleChange}
                  findIcon={(icon) => icon.name === templateDetails.menu_icon}
                />

                <ErrorBlock
                  errorMsg={'Enter the menu name'}
                  hasError={isSubmitted && !templateDetails.menu_title}
                />
              </div>
            </div>
          )}
        </div>

        {/* --- MAIN TASK DATA--- */}

        <div className='sub-section'>
          <div className='fw-600'>Main Task</div>
          <div className='mt-18px'>
            <div className='row'>
              <div className='col-auto d-flex align-item-center' style={{ gap: 8 }}>
                {/* Label */}
                <ClickableSelect
                  label={labelCount > 0 ? labelCount + ' Labels' : 'Labels'}
                  shouldReplace
                  icon={<Label size={13} />}
                  hasValue={labelCount > 0}
                >
                  <SearchableQuerySelect
                    isCustomFunction
                    initialValue={labelList}
                    menuIsOpen
                    getFunctionParams={(searchKey) => ({
                      searchKey,
                      select: ['id', 'name', 'text_color', 'label_color', 'createdAt', 'status'],
                    })}
                    queryFn={labelService.labelList}
                    queryKey='label'
                    classNames={{
                      control: () => {
                        return 'default-select';
                      },
                    }}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    value={templateDetails.labels}
                    onChange={(selectedOptions) =>
                      handleChange({ target: { value: selectedOptions, name: 'labels' } })
                    }
                    autoFocus
                    isMulti
                    placeholder='Select labels'
                    formatOptionLabel={(data) => {
                      return getFormattedLabel({
                        value: data?.id,
                        color: data?.label_color,
                        label: data?.name,
                      });
                    }}
                    className=' w-100 ms-1'
                  />
                </ClickableSelect>
                {/* Expected hours */}
                <ClickableSelect
                  label={expectedTime ? expectedTime : 'Expected Hours'}
                  icon={<Hourglass size={15} />}
                  containerWidth='max-content'
                  hasValue={Boolean(expectedTime)}
                  isHidden={isNonBoundedTask}
                >
                  <MultiUnitDurationInput
                    defaultValues={{
                      hours: templateDetails.expected_hours,
                      minutes: templateDetails.expected_minutes,
                    }}
                    maxValues={[undefined, 59]}
                    disabled={isNonBoundedTask}
                    containerClassName=''
                    onChange={(selectedOption) => {
                      setTemplateDetails({
                        ...templateDetails,
                        expected_hours: selectedOption.hours,
                        expected_minutes: selectedOption.minutes,
                      });
                    }}
                  />
                </ClickableSelect>
              </div>
            </div>
          </div>

          {/* Time required or not */}
          <div className='mt-18px'>
            <div className='d-flex justify-content-between mt-5px'>
              <div className='fz-13px-rem fw-500'>
                Include Start and End Time Fields in the task
              </div>
              <ToggleButton
                isChecked={templateDetails.has_time}
                handleToggle={handleChange}
                id={'has_time'}
                size={30}
              />
            </div>
          </div>

          {/*start time and end time not need to pre-set on template */}

          {/* {templateDetails.has_time && (
            <div className='mt-13px'>
              <div className='row'>
                <div className='col d-flex align-items-center' style={{ gap: 8 }}>
                  <ClickableSelect
                    icon={<Clock3 size={16} />}
                    label={startTime ? startTime : 'Start Time'}
                    isRequired={isSubmitted && !templateDetails.start_time}
                    errorMsg={TEMPLATE_ERROR_MSGS.START_TIME_REQUIRED}
                    hasValue={Boolean(startTime)}
                  >
                    <input
                      type='time'
                      placeholder='Start time'
                      onChange={handleChange}
                      name='start_time'
                      className='mt-5px w-100 expected-time px-2'
                      value={templateDetails.start_time}
                    />
                  </ClickableSelect>

                  <ClickableSelect
                    label={endTime ? endTime : 'End Time'}
                    icon={<Clock3 size={16} />}
                    errorMsg={TEMPLATE_ERROR_MSGS.END_TIME_REQUIRED}
                    isRequired={isSubmitted && !templateDetails.end_time}
                    hasValue={Boolean(endTime)}
                  >
                    <input
                      type='time'
                      name='end_time'
                      onChange={handleChange}
                      className='mt-5px w-100 expected-time px-2'
                      value={templateDetails.end_time}
                    />
                  </ClickableSelect>
                </div>
              </div>
            </div>
          )} */}

          {/* Verification - Radio and private toggle */}
          <div className='row mt-18px'>
            <div className='col d-flex justify-content-between'>
              <div className='input-label'>Verification required</div>
              <div>
                <input
                  type='checkbox'
                  onChange={(event) => {
                    handleChange(event);

                    !event.checked &&
                      handleChange({
                        target: {
                          name: 'is_verify_user_changeable',
                          checked: TEMPLATE_VERIFY_CHANGE.NOT_ALLOWED,
                          type: 'checkbox',
                        },
                      });
                  }}
                  name='is_verification_required'
                  id='is_verification_required'
                  checked={templateDetails.is_verification_required}
                />
              </div>
            </div>
          </div>

          {/* Verifying users */}
          {templateDetails.is_verification_required && (
            <>
              <div className='row mt-10px'>
                <div className='col d-flex justify-content-between'>
                  <div className='input-label'>Verification user changeable</div>
                  <div>
                    <input
                      type='checkbox'
                      name='is_verify_user_changeable'
                      checked={
                        templateDetails.is_verify_user_changeable === TEMPLATE_VERIFY_CHANGE.ALLOWED
                      }
                      onChange={(e) => {
                        const { type, name, checked } = e.target;
                        handleChange({
                          target: {
                            type,
                            name,
                            checked: checked
                              ? TEMPLATE_VERIFY_CHANGE.ALLOWED
                              : TEMPLATE_VERIFY_CHANGE.NOT_ALLOWED,
                          },
                        });
                      }}
                      id='verify-changeable'
                    />
                  </div>
                </div>
              </div>

              {/* Additional verifying users */}
              <div className='mt-10px'>
                <div className='row'>
                  <div className='col'>
                    <div className='input-label'>Additional Verifying Users</div>

                    <div className='my-3' style={{ marginLeft: 3 }}>
                      <StackedAvatars
                        selectProps={{
                          isCustomFunction: true,
                          menuPlacement: 'top',
                          scrollRef: containerRef,
                          queryKey: 'user-list-verify',
                          queryFn: userService.getPermittedUser,
                          getFunctionParams: (searchKey) => ({
                            searchKey,
                            select: taskAssignUserSelect,
                            action: USER_LIST_ACTIONS.TASK_VERIFY,
                          }),
                        }}
                        imageList={templateDetails.verifying_users ?? []}
                        getNameByKey={(user) => fullNameUser(user.first_name, user.last_name)}
                        getSourceByKey={(user) => user.image_url}
                        getGenderByKey={(user) => user.gender}
                        size={30}
                        dropdownSize={40}
                        isClearable={(user) => user.id > 0}
                        onAvatarRemove={(user) => {
                          setTemplateDetails((prev) => ({
                            ...prev,
                            verifying_users: prev.verifying_users.filter(
                              (item) => parseInt(item.id) !== parseInt(user.id),
                            ),
                          }));
                        }}
                        options={usersForVerification}
                        isAddable
                        justifyPosition=''
                        showInitials={false}
                        getValueByKey={(user) => user.id}
                        onAdd={(selectedUsers) => {
                          handleChange({
                            target: {
                              value: [...(templateDetails.verifying_users ?? []), ...selectedUsers],
                              name: 'verifying_users',
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* Subtasks  */}
          {isSubtaskActive && (
            <TemplateSubtask
              title='Main Subtask'
              key={1}
              onAddOrUpdate={handleSubTaskAddOrUpdate}
              onDelete={handleSubtaskDelete}
              list={templateDetails.subtasks}
            />
          )}
        </div>
      </div>
    </section>
  );
}

// eslint-disable-next-line no-unused-vars
const TemplateVIsibleUserCloseComponent = (props) => {
  if (props.data && props.data.id === 0) {
    return <div className='dummy-block' style={{ width: 5, borderRadius: 2 }} />;
  }
  return <MultiValueRemove {...props} />;
};

export default TemplateFormSection;
