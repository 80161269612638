import { File, Headphones, Image, VideoIcon } from 'lucide-react';
import React from 'react';
import useOutsideClickHandler from '../../Hook/useOutsideClickHandler';
import { getFileType, sanitizeFileName } from '../../Utils';
import { MESSAGE_TYPES } from '../../_constants/chat.constants';

const ChatAttachmentsMenu = ({
  onRequestClose = () => {},
  onChange = () => {},
  multiSelect = false,
}) => {
  const { containerRef: contextRef } = useOutsideClickHandler({ onClose: onRequestClose });

  const handleChange = (event, attachmentType) => {
    const { files } = event.target;
    const updatedFiles = [];
    for (let file of files) {
      updatedFiles.push({
        name: file.name.replace(/[^\w.-]|[\s&]/g, ''),
        sanitizeName: sanitizeFileName(file.name),
        fileType: getFileType(file.name.split('.').pop()),
        size: file.size,
        extension: file.name.split('.').pop(),
        file,
        attachedType: attachmentType,
        isUploading: true,
        isFailed: false,
        isSuccess: false,
      });
    }

    console.log(updatedFiles, 'fileanshid', attachmentType);

    onChange(updatedFiles);
    onRequestClose();
  };

  return (
    <div ref={contextRef} className='chat_menu_popup'>
      <div>
        <label htmlFor='image' className='chat_menu_items'>
          <div className='icon_bg'>
            <Image color='#879096' size={18} />
            <input
              type='file'
              multiple={multiSelect}
              onChange={(event) => handleChange(event, MESSAGE_TYPES.IMAGE)}
              id='image'
              name='image'
              accept='image/*'
              hidden
            />
          </div>
          Image
        </label>

        <label htmlFor='video' className='chat_menu_items'>
          <div className='icon_bg'>
            <VideoIcon color='#879096' size={18} />
            <input
              type='file'
              value=''
              multiple={multiSelect}
              onChange={(event) => handleChange(event, MESSAGE_TYPES.VIDEO)}
              id='video'
              name='video'
              accept='video/mp4,video/x-m4v,video/*'
              hidden
            />
          </div>
          Video
        </label>

        <label htmlFor='document' className='chat_menu_items'>
          <div className='icon_bg'>
            <File color='#879096' size={18} />
            <input
              type='file'
              value=''
              multiple={multiSelect}
              id='document'
              onChange={(event) => handleChange(event, MESSAGE_TYPES.DOCUMENT)}
              name='document'
              hidden
            />
          </div>
          Document
        </label>

        <label htmlFor='audio' className='chat_menu_items'>
          <div className='icon_bg'>
            <Headphones color='#879096' size={18} />{' '}
            <input
              type='file'
              value=''
              multiple={multiSelect}
              onChange={(event) => handleChange(event, MESSAGE_TYPES.AUDIO)}
              id='audio'
              name='audio'
              accept='audio/*'
              hidden
            />
          </div>
          Audio
        </label>
      </div>
    </div>
  );
};

export default ChatAttachmentsMenu;
