/* eslint-disable no-unused-vars */
import { useIsMutating, useMutation, useQuery } from '@tanstack/react-query';
import { CircleDashed, Pen, Plus } from 'lucide-react';
import React, {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { Modal } from '../_components';
import SearchableQuerySelect from '../_components/SearchableQuerySelect';
import { CustomDropdownIndicator } from '../_components/SelectionDropDownIcon';
import ToolTip from '../_components/Tooltip/ToolTip';
import { CommonDropDownStyle } from '../_constants';
import { dashboardService, miscService } from '../_services';
import { chatService } from '../_services/chat.service';
import useNotification from '../Hook/useNotification';
import { useUser } from '../Hook/useUser';
import { useUserValidate } from '../Hook/useUserValidate';
import { DashboardContext, GlobalContext, jsonToUrlEncoded } from '../Utils';
import { DashBoardDrawer, LoadingIcon } from '../Utils/SvgIcons';
import { widgetConstant, widgetTitles } from './_utils';
import './dashboard.css';
import './dashboardcustom.css';
import AddWidgetModal from './Widget/AddWidget';

const ResponsiveGridLayout = WidthProvider(Responsive);

// Lazy load components
const DashboardBarGraph = React.lazy(() => import('./DashboardBarGraph'));
const DashboardChat = React.lazy(() => import('./DashboardChat'));
const DashboardStatusCounts = React.lazy(() => import('./DashboardStatusCounts'));
const DashboardTaskStatistics = React.lazy(() => import('./DashboardTaskStatistics'));
const DashboardUpdates = React.lazy(() => import('./DashboardUpdates'));
const DashboardDeadlines = React.lazy(() => import('./Widget/DashboardDeadlines'));
const DashboardWorkLoadStatics = React.lazy(() => import('./Widget/DashboardWorkLoadStatics'));
const TimeBoxCalendar = React.lazy(() => import('../Calander/TimeBoxCalendar'));

// Error boundary hook for handling errors in functional components
function useErrorBoundary() {
  const [error, setError] = useState(null);

  const ErrorBoundaryWrapper = ({ children }) => {
    if (error) {
      return <div>Error loading component: {error.message}</div>;
    }
    return children;
  };

  const captureError = (err) => {
    setError(err);
  };

  return [ErrorBoundaryWrapper, captureError];
}

const DashBoardCustom = () => {
  const user = useUser();
  const { makeAlert, globalSettingsRefetch } = useContext(GlobalContext);
  const userValidate = useUserValidate();
  const updateToken = useNotification({ disabled: false });

  const [queryPayload, setQueryPayload] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [isWidgetModal, setIsWidgetModal] = useState(false);
  const [isWidgetDropDown, setIsWidgetDropDown] = useState({ modal: false, key: '' });
  const tableRef = useRef(null);

  const [currentBreakpoint, setCurrentBreakpoint] = useState('lg');
  const isMutating = useIsMutating();

  const [savedLayouts, setSavedLayouts] = useState(() => {
    const saved = localStorage.getItem('dashboard-layouts');
    return saved ? JSON.parse(saved) : { lg: [], md: [], sm: [], xs: [], xxs: [] };
  });
  const [layout, setLayout] = useState(savedLayouts[currentBreakpoint] || []);

  const {
    data: widgetList,
    isLoading: widgetLoading,
    refetch,
  } = useQuery({
    queryKey: ['widgets'],
    queryFn: () => miscService.getWidget('widget'),
    select: (resp) => resp?.data,
    enabled: !!user?.id,
  });
  const [selectedWidget, setSelectedWidget] = useState({});
  // Use an effect to set the selected widget when the widget list is loaded
  useEffect(() => {
    if (widgetList && widgetList?.length > 0) {
      if (widgetList?.length > 1 && widgetList?.[0]?.is_default)
        setSelectedWidget(widgetList[1]); // Set the first widget as the selected widget
      else setSelectedWidget(widgetList[0]); // Set the first widget as the selected widget
    }
  }, [widgetList]);
  const customLayout = selectedWidget?.custom_data?.map((item) => item?.position);

  useEffect(() => {
    if (!widgetLoading && customLayout) {
      setLayout(customLayout);
      setSavedLayouts((prev) => ({ ...prev, [currentBreakpoint]: customLayout }));
    }
  }, [widgetLoading, currentBreakpoint, selectedWidget]);

  const updateQuery = {
    select: [
      'id',
      'task_id',
      'last_message',
      'last_message_time',
      'message',
      'message_count_info',
      'update_type',
      'updated_time',
      'last_message_creator_id',
    ],
    update_type: 'task',
    unread: true,
    task_select: ['id', 'name', 'note', 'priority', 'order_seq'],
  };

  const getPayLoad = (query) => {
    const encodedQuery = Object.keys(query).reduce((acc, key) => {
      acc[key] = typeof query[key] === 'object' ? JSON.stringify(query[key]) : query[key];
      return acc;
    }, {});
    setQueryPayload(jsonToUrlEncoded(encodedQuery));
    return query;
  };

  const { data: updatesList, isLoading } = useQuery({
    queryKey: ['dashboard-update'],
    queryFn: () => chatService.getChatUpdateList({ payload: getPayLoad(updateQuery) }),
    select: (resp) => resp.data,
    enabled: !!user?.id,
  });

  const { data: dashboardCounts, isLoading: isDashboardLoading } = useQuery({
    queryKey: ['dashboard-counts'],
    queryFn: () => dashboardService.getDashboardCounts(),
    select: (resp) => resp.data,
    enabled: !!user?.id,
  });

  const handleLayoutChange = useCallback(
    (newLayout, allLayouts) => {
      if (isEditMode) {
        setSavedLayouts(allLayouts);
        localStorage.setItem('dashboard-layouts', JSON.stringify(allLayouts));
        setLayout(newLayout);
        console.log({ handleLayoutChange: newLayout, allLayouts });
      }
    },
    [isEditMode],
  );
  const handleBreakpointChange = (breakpoint) => {
    setCurrentBreakpoint(breakpoint);
    setLayout(savedLayouts[breakpoint] || []);
  };

  // Adjust the delay as needed (300ms in this example)

  const { total_counts, status_with_count } = dashboardCounts || {};

  const Loader = () => {
    return (
      <div className='loading-wrapper'>
        <LoadingIcon color={'#c1c1c1'} size={70} />
      </div>
    );
  };
  const dashboardWidgets = useMemo(
    () => ({
      [widgetConstant.task_statics]: (props) => (
        <Suspense fallback={<Loader />}>
          <DashboardTaskStatistics
            counts={{ total_counts: total_counts, status_with_count: status_with_count }}
            {...props}
          />
        </Suspense>
      ),
      [widgetConstant.calendar]: (props) => (
        <Suspense fallback={<Loader />}>
          <TimeBoxCalendar isNeedProject={false} isNeedTemplate={false} {...props} />
        </Suspense>
      ),
      [widgetConstant.chats]: (props) => (
        <Suspense fallback={<Loader />}>
          <DashboardChat {...props} />
        </Suspense>
      ),
      [widgetConstant.updates]: (props) => (
        <Suspense fallback={<Loader />}>
          <DashboardUpdates {...props} queryPayload={queryPayload} />
        </Suspense>
      ),
      [widgetConstant.graphical_report]: (props) => (
        <Suspense fallback={<Loader />}>
          <DashboardBarGraph {...props} />
        </Suspense>
      ),
      [widgetConstant.status_counts]: (props) => (
        <Suspense fallback={<Loader />}>
          <DashboardStatusCounts user={user} {...props} />
        </Suspense>
      ),
      [widgetConstant.upcoming_deadlines]: (props) => (
        <Suspense fallback={<Loader />}>
          <DashboardDeadlines user={user} {...props} />
        </Suspense>
      ),
      [widgetConstant.workload_statics]: (props) => (
        <Suspense fallback={<Loader />}>
          <DashboardWorkLoadStatics {...props} />
        </Suspense>
      ),
    }),
    [queryPayload],
  );

  const getDashBoardWidget = (widgetName) => {
    let selectedProps = {};
    const WidgetComponent = dashboardWidgets[widgetName] || null;

    // Use find instead of filter to get the first matching item
    const customDataItem = selectedWidget?.custom_data?.find((item) => item?.name === widgetName);

    if (customDataItem) {
      selectedProps = { userType: customDataItem.userType, project_id: customDataItem.project_id };
    }

    // If the widget component is not null, call it with the additional props
    return WidgetComponent ? WidgetComponent(selectedProps) : null;
  };

  const dashboardContextValue = useMemo(
    () => ({
      updatesList,
      dashboardCounts,
      isDashboardLoading,
      isLoading,
    }),
    [updatesList, dashboardCounts, isDashboardLoading, isLoading],
  );

  const handleOpenModal = () => setIsWidgetModal(true);
  const handleCloseModal = () => setIsWidgetModal(false);

  const { mutateAsync, isPending } = useMutation({
    mutationKey: ['event-add'],
    mutationFn: (payload) => miscService.addWidget(payload),
    onSuccess: () => {
      refetch();
      handleCloseModal();
      return;
    },
    onError: (err) => {
      console.error(err);
      makeAlert(err?.message);
    },
  });
  const handleDragAndResizeStop = async (layout, oldItem, newItem, placeholder, e, node) => {
    const currentWidget = structuredClone(selectedWidget);
    const updatedLayout = currentWidget.custom_data?.map((item) => {
      if (item.name == newItem.i) {
        return { ...item, position: newItem };
      }
      return item;
    });

    currentWidget.custom_data = updatedLayout;
    if (currentWidget?.is_default) {
      currentWidget.is_default = false;

      if (widgetList.length > 0) {
        const existingWidget = widgetList.filter((widget) => widget.title == currentWidget.title);
      }
      delete currentWidget.id, currentWidget.user_id, currentWidget.title;
      currentWidget.user_id = user.id;
      currentWidget.title = 'Custom Layout' + widgetList.length;
    }
    await mutateAsync(currentWidget); // Pass the form data to the mutation
  };

  const handleSubmit = async (formData) => {
    try {
      const customData = selectedWidget?.custom_data;

      delete formData.label, formData.value;
      customData?.push(formData);

      selectedWidget.custom_data = customData;

      if (selectedWidget?.is_default) {
        selectedWidget.is_default = false;
        delete selectedWidget.id, selectedWidget.user_id, selectedWidget.title;
        selectedWidget.user_id = user.id;
        selectedWidget.title = 'Custom Layout';
      }

      await mutateAsync(selectedWidget); // Pass the form data to the mutation
    } catch (error) {
      console.error('Error during submission:', error);
    }
  };

  if (isLoading || isDashboardLoading || widgetLoading) {
    return null;
  }

  return (
    <DashboardContext.Provider value={dashboardContextValue}>
      <section className='dashboard-layout'>
        <div className='notification-nav-contain'>
          <div className={'dashboard_menu_active cursor-pointer'}>
            <CircleDashed size={18} className='me-1' /> Overview
          </div>
          <section className='d-flex gap-1 mb-1'>
            <SearchableQuerySelect
              queryFn={miscService.getWidget}
              initialValue={widgetList}
              isCustomFunction
              value={selectedWidget || {}}
              isLoading={widgetLoading}
              queryKey='widgets'
              getOptionLabel={(option) => option?.title}
              getOptionValue={(option) => option?.id}
              classNames={{ menuPortal: () => 'z-index-100 fz-14px react-select-portal mr-1' }}
              styles={CommonDropDownStyle}
              components={{ DropdownIndicator: CustomDropdownIndicator }}
              placeholder={`Choose Layout`}
              onChange={(val) => {
                setSelectedWidget(val);
              }}
            />
            <ToolTip tooltipText={'Dashboard'} isModern>
              <div
                className={`menu-item-outline${!isEditMode ? ' active' : ''}`}
                onClick={() => setIsEditMode(false)}
              >
                <DashBoardDrawer color={isEditMode ? '#87909E' : '#3454d1'} />
              </div>
            </ToolTip>
            <ToolTip tooltipText={'Edit'} isModern>
              <div
                className={`menu-item-outline${isEditMode ? ' active' : ''}`}
                onClick={() => setIsEditMode(true)}
              >
                <Pen color={isEditMode ? '#3454d1' : '#87909E'} size={18} />
              </div>
            </ToolTip>
          </section>
        </div>
      </section>
      <ResponsiveGridLayout
        className='layout'
        layouts={savedLayouts}
        onLayoutChange={handleLayoutChange}
        onBreakpointChange={handleBreakpointChange}
        onDragStop={handleDragAndResizeStop}
        onResizeStop={handleDragAndResizeStop} // Add the resize handler
        cols={{ lg: 3, md: 3, sm: 2, xs: 1, xxs: 1 }}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        draggableHandle='.dashboard-header-container'
        resizeHandles={['se']}
        allowOverlap={false} // Allow overlap only in edit mode
        compactType={isEditMode ? null : 'vertical'} // Allow free dragging in edit mode
        width={'100%'}
        margin={{ lg: [20, 20], md: [20, 20], sm: [20, 20], xs: [20, 20], xxs: [20, 20] }}
        preventCollision={!isEditMode} // Prevent collision only when not in edit mode
        isDraggable={isEditMode}
        isResizable={isEditMode}
        autoSize
        rowHeight={100}
      >
        {layout?.map((item) => {
          return (
            <div
              key={item?.i}
              data-grid={{ ...item, isDraggable: isEditMode, isResizable: isEditMode }}
              style={{
                padding: item.i === widgetConstant.status_counts ? '0px' : '10px',
                background: item.i == widgetConstant.status_counts ? 'transparent' : 'white',
                height: 'auto',
                border: item.i === widgetConstant.status_counts ? 'none' : '',
              }}
            >
              <div className='dashboard-header-container' style={{ paddingBottom: '5px' }}>
                <div className='dashboard-widget-header'>{widgetTitles[item?.i]}</div>
                <div className='d-flex gap-2'>
                  {/* <div
                    className='menu-item-outline'
                    onClick={() => setIsWidgetDropDown({ modal: true, key: item.i })}
                  >
                    <Ellipsis size={18} color='var(--Second-text-color)' />
                  </div>
                  {console.log({ isWidgetDropDown: isWidgetDropDown })}
                  {isWidgetDropDown.modal && isWidgetDropDown.key == item.i && (
                    <Popover.PopoverItem
                      onClose={() => setIsWidgetDropDown({ modal: false, key: '' })}
                      positionTop={(top) => -140}
                      positionLeft={() => -360}
                      scrollRef={tableRef}
                    >
                      <div className='dashboard-widget-dropdown'>
                        <section>
                          <Pen size={16} /> Edit
                        </section>
                        <section>
                          <Maximize size={16} /> Full Screen
                        </section>
                        <section>
                          <Copy size={16} /> Duplicate
                        </section>
                        <section>
                          <Trash size={16} /> Delete
                        </section>
                      </div>
                    </Popover.PopoverItem>
                  )} */}
                </div>
              </div>
              <section className='widget'>{getDashBoardWidget(item?.i)}</section>
            </div>
          );
        })}
      </ResponsiveGridLayout>
      {isEditMode && (
        <>
          <button
            style={{ background: 'var(--Base-Color)' }}
            className='taskAdd-FAB'
            onClick={handleOpenModal}
          >
            <Plus color='#fff' />
          </button>
          {isWidgetModal && (
            <Modal.Container handleClose={handleCloseModal}>
              <Modal.View
                className={'widget-form-container'}
                containerClass={'d-flex justify-content-end'}
              >
                <AddWidgetModal
                  user={user}
                  isOpen={isWidgetModal}
                  onClose={handleCloseModal}
                  onSubmit={handleSubmit}
                  isSaving={isPending}
                />
              </Modal.View>
            </Modal.Container>
          )}
        </>
      )}
    </DashboardContext.Provider>
  );
};

export default DashBoardCustom;
