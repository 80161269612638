import { keepPreviousData, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { BoardView } from '../BoardView/BoardView';
import SearchableQuerySelect from '../_components/SearchableQuerySelect';
import { CustomDropdownIndicator } from '../_components/SelectionDropDownIcon';
import { CommonDropDownStyle } from '../_constants';
import { milestoneService } from '../_services/milestone.service';

const ProjectBoard = () => {
  const id = useParams()?.id;

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedMilestone, setSelectedMilestone] = useState(null);

  const milestone_id = searchParams.get('milestone_id');

  const handleChange = (selectedOption) => {
    setSelectedMilestone(selectedOption);

    if (selectedOption) {
      searchParams.set('milestone_id', selectedOption?.id);
    } else {
      searchParams.delete('milestone_id');
    }
    setSearchParams(searchParams);
  };

  const fetchMilestone = async () => {
    const filteredMilestone = milestoneData?.find(
      (milestone) => parseInt(milestone?.id) === parseInt(milestone_id),
    );

    if (filteredMilestone) {
      setSelectedMilestone(filteredMilestone);
    } else {
      const milestone = await milestoneService.milestoneGet({
        id: milestone_id,
        select: ['id', 'name', 'progress_status'],
      });

      if (milestone?.data) {
        setSelectedMilestone(milestone?.data);
      }
    }
  };

  // eslint-disable-next-line no-unused-vars
  const { data: singleMilestone } = useQuery({
    queryKey: ['milestone-data', milestone_id],
    queryFn: () => fetchMilestone(),
    enabled: Boolean(milestone_id) && !selectedMilestone,
    placeholderData: keepPreviousData,
  });

  const fetchMilestoneList = async () => {
    const data = await milestoneService.milestoneList({
      select: ['id', 'name', 'progress_status'],
      project_id: id,
    });

    if (!milestone_id) {
      const startedMilestone =
        data?.data?.rows?.filter((prev) => parseInt(prev.progress_status) === 1) ?? null;

      if (startedMilestone.length > 0) {
        handleChange(startedMilestone[0]);
      }
    }

    return data?.data?.rows;
  };

  const {
    data: milestoneData,
    // error,
    isLoading: milestoneLoading,
  } = useQuery({
    queryKey: ['milestoneList'],
    queryFn: () => fetchMilestoneList(),
    placeholderData: keepPreviousData,
  });

  // const { data: milestoneData, isLoading: milestoneLoading } = useQuery({
  //   queryKey: ['milestoneList'],
  //   queryFn: () =>
  //     milestoneService.milestoneList({
  //       select: ['id', 'name', 'progress_status'],
  //       project_id: id,
  //     }),
  //   select: (data) => {
  //     if (!milestone_id) {

  //       const startedMilestone =
  //         data?.data?.rows?.filter((prev) => parseInt(prev.progress_status) === 1) ?? null;

  //       if (startedMilestone.length > 0) {
  //         handleChange(startedMilestone[0]);
  //       }
  //     }
  //     return data?.data?.rows;
  //   },
  //   placeholderData: keepPreviousData,
  // });
  return (
    <div style={{ marginTop: 16, background: '#fff' }} className='fade-entry'>
      {/* milestone selection section   */}
      <div className=' mb-3' style={{ width: 184 }}>
        <SearchableQuerySelect
          queryKey={'milestone-list-project'}
          queryFn={milestoneService.milestoneList}
          getFunctionParams={(searchKey) => ({
            searchKey,
            select: ['id', 'name', 'progress_status'],
            project_id: id,
          })}
          isCustomFunction
          isLoading={milestoneLoading}
          initialValue={milestoneData}
          getOptionLabel={(option) => option?.name}
          isClearable
          getOptionValue={(option) => option.id}
          onChange={handleChange}
          styles={CommonDropDownStyle}
          components={{ DropdownIndicator: CustomDropdownIndicator }}
          placeholder='Choose Milestone '
          value={selectedMilestone ?? null}
          // value={filterData?.template_id}
        />
      </div>

      {/* end  */}
      <BoardView milestone_id={milestone_id} />
    </div>
  );
};

export default ProjectBoard;
