/* eslint-disable no-unused-vars */
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Calendar, Clock, Hash } from 'lucide-react';
import moment from 'moment';
import React, { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { PaginationComponent } from '../../_components';
import useDebounce from '../../_helpers/useDebounce';
import { taskService } from '../../_services';
import { formatMillisecondFeatured } from '../../Utils';
import { getTaskTime } from '../taskValidation';

function TaskWorkHistory({ taskData }) {
  const id = useParams()?.id ?? taskData?.id;
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState({
    select: ['id', 'start_time', 'end_time', 'time_difference', 'status'],
    task_id: parseInt(id),
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
  });

  const debouncedSearch = useDebounce(query?.searchKey, 500);

  //function use to get branch list
  const { data: workHistoryData } = useQuery({
    queryKey: ['workHistoryData', query?.pageVo?.pageNo, taskData, debouncedSearch],
    queryFn: () => taskService.workHistoryList(query),
    placeholderData: keepPreviousData,
  });
  const workHistoryCount = workHistoryData?.data?.rows.length;
  return (
    <div className='container-fluid'>
      <table className=' table table-bordered table-hover tbl ' style={{ marginBottom: '0' }}>
        <thead>
          <tr>
            <th style={{ width: 100 }}>
              <div className='d-flex align-items-center  gap-2'>
                <Hash size={15} /> Sl No.
              </div>
            </th>
            <th>
              <div className='d-flex align-items-center  gap-2'>
                <Calendar size={15} />
                Start Time
              </div>
            </th>
            <th>
              <div className='d-flex align-items-center  gap-2'>
                <Calendar size={15} />
                End Time
              </div>
            </th>
            <th>
              {' '}
              <div className='d-flex align-items-center  gap-2'>
                <Clock size={15} />
                Total Time
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {workHistoryData?.data?.rows.map((item, index) => {
            const slNo = (query?.pageVo?.pageNo - 1) * query?.pageVo?.noOfItems + index + 1;
            return (
              <tr key={index}>
                <td className='grey-label'>{slNo}</td>
                <td className='grey-label'>
                  {item?.start_time
                    ? moment(parseInt(item?.start_time)).format('DD MMM YYYY, hh:mm A')
                    : '--'}
                </td>
                <td className='grey-label'>
                  {' '}
                  {item?.end_time
                    ? moment(parseInt(item?.end_time)).format('DD MMM YYYY, hh:mm A')
                    : '--'}
                </td>
                <td className='grey-label'>
                  {item?.time_difference > 0
                    ? formatMillisecondFeatured(parseInt(item?.time_difference), true)
                    : '--'}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className='d-flex justify-content-end mt-2' style={{ fontSize: '12px' }}>
        <div className='px-2'>Expected Time: {getTaskTime(taskData?.expected_hours)}</div>
        <div className='px-2'>
          Total Worked :
          {formatMillisecondFeatured(parseInt(workHistoryData?.data?.total_worked), true)}
        </div>
      </div>

      {workHistoryCount > 0 && (
        <section className='mt-2'>
          <PaginationComponent
            pageOnClick={(data) => {
              setQuery((prev) => ({
                ...prev,
                pageVo: {
                  ...prev.pageVo,
                  pageNo: data,
                },
              }));
              searchParams.set('pageNo', data);
              setSearchParams(searchParams);
            }}
            demoData={workHistoryData?.data}
          />
        </section>
      )}
    </div>
  );
}

export default TaskWorkHistory;
