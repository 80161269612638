import { Check, X } from 'lucide-react';
import React, { useRef, useState } from 'react';
import SearchableQuerySelect from '../../_components/SearchableQuerySelect';
import '../task.css';
import { customSelectBoxStyles, fieldTypes } from '../taskConstants';
import { getInputValues } from '../taskValidation';
import { CustomVariableInput } from './CustomInputs';
const EditableField = ({
  data,
  onSave,
  children,
  containerClassName = 'task-tags-container ',
  inputClassName = 'task-name-input w-100 form-control',
  type = 'input', // for which type is editing select, input or else
  inputType = 'text',
  list = [],
  className,
  isEditable,
  placeholder = 'Select...',
  inputCustomFields,
  name = 'name',
  isMulti = false,
  formattedOptionLabel = null,
  functionParams = {},
  queryFn = () => {},
  queryKey = 'user-list',
  querySelect = () => {},
}) => {
  const [editError, setEditError] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [tempValue, setTempValue] = useState(data);
  // for adjusting height for task name and handler for tempValue
  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === 'name') {
      // to control the height of the task name input
      if (value.length > 100 || value === '\n') {
        e.target.style.height = '1px';
        e.target.style.height = 30 + e.target.scrollHeight + 'px';
      } else {
        e.target.style.height = '1px';
        e.target.style.height = e.target.scrollHeight - 30 + 'px';
      }
    }
    if (name == 'task_start_date') {
      const start = new Date(value);
      const end = new Date(tempValue.task_start_date);
      if (start > end) {
        setTempValue((prev) => ({
          ...prev,
          task_end_date: value,
        }));
      }
      setTempValue((prev) => ({
        ...prev,
        [name]: value,
      }));
      return;
    }
    if (name == 'task_end_date') {
      const end = new Date(value);
      const start = new Date(tempValue.task_start_date);
      if (end < start) {
        setTempValue((prev) => ({
          ...prev,
          task_start_date: value,
        }));
      }
      setTempValue((prev) => ({
        ...prev,
        [name]: value,
      }));
      return;
    }

    if (name == 'label' || name == 'verifying_users') {
      value = value.map((item) => {
        return item.id;
      });
      onSave({
        [name]: value,
      });
    }
    setTempValue({ ...tempValue, [name]: value }); // to change child state
    if (name == 'priority' || name == 'assignee_id') {
      onSave({ [name]: value });
      setTempValue(data);
      setIsEditing(false);
    }
  };

  const handleSubmit = () => {
    if (type == fieldTypes.INPUT && inputType == fieldTypes.TEXT) {
      const text = tempValue?.[name].replace(/\n/g, ''); // Remove leading and trailing whitespace
      const message = text.trim() === '';
      if (message) {
        setEditError(`${name} is Required`);
        setTimeout(() => {
          setEditError('');
        }, 3000);
        return;
      }
    }
    onSave(tempValue);
    setTempValue(data);
    setIsEditing(false);
  };
  const timeInputRef = useRef(); //reference kept for outside click fvevent capture

  if (isEditing) {
    // two types of returns input and select field -- can be add types according to 'type'
    if (type == fieldTypes.INPUT) {
      if (inputType == fieldTypes.TIME || inputType == fieldTypes.DATE) {
        const fieldData = getInputValues(inputType, tempValue);
        return (
          <div className='editable-field time-input-container '>
            <div ref={timeInputRef} className='d-flex gap-2 align-items-center '>
              <input
                type={fieldData.typeOfInput}
                className={`form-control ${className ? className : 'input-field'}`}
                id={fieldData.inputNameFieldOne}
                name={fieldData.inputNameFieldOne}
                placeholder='Hours '
                min={fieldData.inputMinValueOne}
                onChange={handleChange}
                autoFocus
                onKeyUp={(e) => e.key === 'Enter' && handleSubmit()}
                value={fieldData.inputValueOne}
              />
              :
              <input
                type={fieldData.typeOfInput}
                className={`form-control ${className ? className : 'input-field'}`}
                id={fieldData.inputNameFieldTwo}
                min={fieldData.inputMinValueTwo}
                max={fieldData.inputMaxValueTwo}
                name={fieldData.inputNameFieldTwo}
                placeholder='Minutes '
                onChange={handleChange}
                onKeyUp={(e) => e.key === 'Enter' && handleSubmit()}
                value={fieldData.inputValueTwo}
              />
            </div>
            <div className='d-flex gap-1 justify-content-end'>
              <div
                className='close-box'
                onClick={(e) => {
                  e.preventDefault();
                  setIsEditing(false);
                }}
              >
                <X onClick={() => setIsEditing(false)} />
              </div>
              <div
                className='close-box'
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <Check onClick={() => handleSubmit()} />
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className='editable-field position-relative'>
            <CustomVariableInput
              name={name}
              onChange={(e) => handleChange(e)}
              placeholder='Enter Task Name'
              value={tempValue[name]}
              // onKeyUp={(e) => e.key === 'Enter' && handleSubmit()}
              className={`${inputClassName} ${editError && 'has-error'}`}
              // onBlur={() => setIsEditing(false)}
              {...inputCustomFields}
            />
            <div className='d-flex gap-1 justify-content-end position-absolute r-0 mt-1'>
              <div
                className='close-box'
                onClick={(e) => {
                  e.preventDefault();
                  setIsEditing(false);
                }}
              >
                <X size={16} onClick={() => setIsEditing(false)} />
              </div>
              <div
                className='close-box'
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <Check size={16} />
              </div>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className='editable-field w-80'>
          <SearchableQuerySelect
            queryKey={queryKey}
            queryFn={queryFn}
            getFunctionParams={(searchKey) => ({
              searchKey,
              ...functionParams,
            })}
            querySelect={(val) => querySelect(val)}
            isCustomFunction
            initialValue={list}
            placeholder={placeholder}
            getOptionLabel={(option) => option?.label}
            getOptionValue={(option) => parseInt(option?.id)}
            value={isMulti ? data : list?.find((item) => parseInt(item?.id) == parseInt(data))}
            onChange={(selectedOptions) => {
              handleChange({
                // target: { name: name, value: isMulti ? selectedOptions : selectedOptions?.id },
                target: { name: name, value: selectedOptions },
              });
            }}
            onBlur={() => setIsEditing(false)}
            autoFocus={true}
            menuIsOpen={true}
            styles={{
              ...customSelectBoxStyles,
              control: (style) => ({
                ...style,
                minHeight: 30,
                maxWidth: '31.8vw',
                width: isMulti ? 215 : 170,
                zIndex: 99,
                textTransform: 'capitalize',
                fontSize: 13,
              }),
              container: (style) => ({ ...style, height: 30 }),
              placeholder: (style) => ({ ...style, fontSize: 12 }),
              menu: (style) => ({ ...style, width: 170, top: null, textTransform: 'capitalize' }),
            }}
            formatOptionLabel={formattedOptionLabel}
            isMulti={isMulti}
            menuPortalTarget={document.getElementById('MODAL')}
          />
        </div>
      );
    }
  }
  // main return
  return (
    <div className='editable-field '>
      <div onClick={() => isEditable && setIsEditing(true)} className={containerClassName}>
        {children ? children : data}
      </div>
    </div>
  );
};

export default EditableField;
