import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useContext, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { STATUS } from '../Task/taskConstants';
import {
  base64ToFile,
  extractUrls,
  filterNullValues,
  focusInputByClassName,
  getFileType,
  GlobalContext,
  sanitizeFileName,
} from '../Utils';
import useAlert from '../_components/ActionPopup/useAlert';
import {
  CHAT_TEXTAREA_CLASS_NAME,
  CHAT_UPDATE_TYPE,
  MESSAGE_TYPES,
  MIME_TYPE_MAPPING,
} from '../_constants/chat.constants';
import useDebounce from '../_helpers/useDebounce';
import { errorToString } from '../_helpers/utilFunctions';
import { miscService } from '../_services';
import { chatService } from '../_services/chat.service';
import ChatHeader from './ChatHeader';
import ChatInputBox from './ChatInputBox';
import ChatProvider from './ChatProvider';
import FileDragDrop from './FileDragDrop';
import InputLinkPreview from './InputLinkPreview';
import MediaPreview from './MediaPreview';
import MessagesList from './MessagesList';
import ReplyContainer from './ReplyContainer';
import './chat.css';

const ChatContainer = ({
  containerClassName = '',
  listClassName = 'chat-content',
  headerVisibility,
  initialHeight = 0,
  type = CHAT_UPDATE_TYPE.TASK,
  activeUpdate,
  listPayload,
  updateRefetch = () => {},
  inputPayload,
  onMessageSendSuccess = () => {},
  isInTask = false,
}) => {
  const chatSelect = [
    'id',
    'message',
    'type',
    'files',
    'createdAt',
    'reactions_count',
    'reply_chat_id',
    'link_details',
  ];
  // const { makeAlert } = useContext(GlobalContext);
  const { makeActionAlert } = useAlert();
  const { makeAlert } = useContext(GlobalContext);
  const listBottomRef = useRef();
  const queryClient = useQueryClient();
  const messageListRef = useRef();
  // Put all the const payloads available on load here, It will bes passed along when fetching chat list
  const filteredPayloads = filterNullValues(listPayload); //Will avoid all blank values in the listPayload
  const filteredInputPayload = filterNullValues(inputPayload);
  const initChatPayload = { ...filteredInputPayload, message: '', is_you: true, files: [] };

  const [searchParam, setSearchParam] = useSearchParams();
  const [tempMessages, setTempMessages] = useState([]); //For saving the temp messages
  const [replyItem, setReplyItem] = useState(null);
  const [currentPayload, setCurrentPayload] = useState(structuredClone(initChatPayload)); //Chat payload send on message add

  const debouncedText = useDebounce(currentPayload.message, 500);
  const urlsInInput = debouncedText ? extractUrls(debouncedText) : []; // Will fetch the url on input text

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (payload) =>
      chatService.addChatMessage({
        payload: { ...payload, update_type: type, type: MESSAGE_TYPES.TEXT },
      }),
    onSuccess: (response, payload) => {
      if (searchParam.has('chat_user_id')) {
        searchParam.delete('chat_user_id');
        searchParam.set('id', response.data.update_id);
        setSearchParam(searchParam);
      }
      onMessageSendSuccess(response, payload);
    },
  });

  const deleteMessageFromDb = async (item, deletedIndexObj, index) => {
    try {
      await chatService.deleteChatMessage({ payload: { id: item.id, isLastMsg: index === 0 } });
      updateRefetch();
    } catch (err) {
      makeActionAlert({ message: errorToString(err), isSuccess: false, showButton: false });
      recoverMessageFromUseQuery(deletedIndexObj, item);
    }
  };

  const chatListQueryKey = ['chat-list', ...Object.values(filteredPayloads)];

  // Main chat list
  const {
    data: chatList,
    isLoading,
    isSuccess: isChatMsgsSuccess,
    hasNextPage,
    isError,
    error,
    isFetchingNextPage,
    fetchNextPage,
    // refetch,
  } = useInfiniteQuery({
    queryKey: chatListQueryKey,
    queryFn: ({ pageParam = 1 }) => {
      return chatService.getChatList({
        payload: {
          ...filteredPayloads,
          select: chatSelect,
          status: STATUS.ACTIVE,
          pageVo: {
            pageNo: pageParam,
            noOfItems: 10,
          },
          additional_offset: tempMessages.length,
          shouldUpdateNotifications: true,
        },
      });
    },
    select: (response) => response.pages,
    getNextPageParam: (lastPage) =>
      lastPage.data.page < lastPage.data.pages ? lastPage.data.page + 1 : undefined,
    enabled: Object.keys(filteredPayloads).length > 0,
    // staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false, //Later TODO: Only fetch if there is an error. Pass a function to check it.
  });

  // // Pass refetch function to parent via setRefetchInParent prop
  // useEffect(() => {
  //   setRefetchInParent(refetch);
  //   console.log('idsuuu');
  // }, [setRefetchInParent]);

  const isNotEnabled = Object.keys(filteredPayloads).length <= 0;
  const isChatListError = isError || isNotEnabled;

  const getLatestMessages = async () => {
    const lastTempMsgId = tempMessages.find((item) => Boolean(item.id))?.id;
    const lastMsgListId = chatList[0].data.rows?.find((item) => Boolean(item.id))?.id;

    const lastMessageId = lastTempMsgId || lastMsgListId ? lastTempMsgId ?? lastMsgListId : 0;

    const response = await chatService.getLatestMessages({
      payload: { last_id: lastMessageId, select: chatSelect, ...filteredPayloads },
    });
    if (response.data.rows.length > 0) {
      scrollToBottom();
    }
    setTempMessages((prev) => [...response.data.rows, ...prev]);

    return response;
  };

  // For getting latest messages from other users
  useQuery({
    queryKey: ['latest-messages', ...Object.values(filteredPayloads)],
    queryFn: () => getLatestMessages(),
    select: (res) => res.data.rows,
    enabled: isChatMsgsSuccess,
    refetchInterval: 5000,
    refetchIntervalInBackground: true,
    staleTime: Infinity,
  });

  const updateMessage = (messageId, newMessageData) => {
    queryClient.setQueryData(chatListQueryKey, (oldData) => {
      //Change the key according to props
      if (!oldData) return;

      const newPages = oldData.pages.map((page) => ({
        ...page,
        data: {
          ...page.data,
          rows: page.data.rows.map((message) =>
            parseInt(message.id) === parseInt(messageId)
              ? { ...message, ...newMessageData }
              : message,
          ),
        },
      }));

      return {
        ...oldData,
        pages: newPages,
      };
    });
  };

  // On input change
  const handleChatInputChange = (event) => {
    const { value } = event.target;
    setCurrentPayload((prev) => ({
      ...prev,
      message: value,
    }));
  };

  const scrollToBottom = () => {
    window.requestAnimationFrame(() => {
      if (listBottomRef.current) {
        listBottomRef.current.scrollIntoView({ block: 'end' });
      }
    });
  };

  // On file selection
  const handleFileChange = (files) => {
    console.log('select', files);

    setCurrentPayload((prev) => ({
      ...prev,
      files: [...prev.files, ...files],
    }));
  };
  /***
   * Update temp Message status - Will be used to change the success, failed and upload status
   */
  const updateTempMessages = ({ index, updateObj, id }) => {
    setTempMessages((prev) =>
      prev.map((item, idx) =>
        (id ? id === item.tempId : index === idx) ? { ...item, ...updateObj } : item,
      ),
    );
  };
  /***
   * For updating the file status
   * @param {number} index - The index of the file to be updated
   * @param {Object} updateObj - The value to be added to the file
   * @param {number} tempId - The id of the chat in the tempMessages
   */
  const updateFileStatus = ({ index, updateObj, tempId }) => {
    // Check for the required msg in tempMessages and updated it with updateObj
    setTempMessages((prev) =>
      prev.map((item) => {
        if (item.tempId === tempId) {
          const updatedFiles = item.files.map((file, fileIndex) => {
            if (fileIndex === parseInt(index)) {
              return { ...file, ...updateObj };
            }
            return file;
          });

          return { ...item, files: updatedFiles };
        }
        return item;
      }),
    );
  };

  // Handle File Upload
  const handleFileUpload = async (payload) => {
    const files = payload.files;
    const successObj = { isFailed: false, isUploading: false, isSuccess: true };
    for (let index in files) {
      const file = files[index];
      // On retries, we don't want to upload the success files again
      if (!file?.isSuccess) {
        try {
          // upload file
          let signedData = await miscService.createSignedUploadUrl({
            type: file.fileType, //--video,image,audio
            ext: file.extension, //--jpg or mp4
            name: file.sanitizeName,
          });

          if (signedData?.data?.signedUrl) {
            let signedUrl = signedData.data.signedUrl;
            let fileName = signedData.data.filename;
            // TODO: Need to move this waterfall update to parallel upload later
            await fetch(signedUrl, {
              method: 'PUT',
              headers: { 'Content-Type': 'multipart/form-data' },
              body: file.file,
            });
            // Update the status
            file.uploaded_path = fileName;
            updateFileStatus({
              index,
              updateObj: { ...successObj, uploaded_name: fileName },
              tempId: payload.tempId,
            });
          }
        } catch (err) {
          updateFileStatus({
            index,
            updateObj: { isFailed: true, isUploading: false, isSuccess: false },
            tempId: payload.tempId,
          });
          throw err;
        }
      }
    }

    return { ...payload, files: payload.files.map((file) => ({ ...file, ...successObj })) };
  };

  /*** Will upload the files first, then save the chat with the text and files. */
  const uploadFileAndSaveChat = async (messageObj) => {
    try {
      // handle file upload and return updated payload
      const updatedPayload =
        messageObj.files.length > 0 ? await handleFileUpload(messageObj) : messageObj;
      // Sending msg
      const response = await mutateAsync(updatedPayload);

      updateTempMessages({
        updateObj: {
          isSuccess: true,
          isFailed: false,
          isUploading: false,
          id: response?.data?.id,
          is_you: true,
        },
        id: messageObj.tempId,
      });
    } catch (err) {
      updateTempMessages({
        updateObj: { isSuccess: false, isFailed: true, isUploading: false, is_you: true },
        id: messageObj.tempId,
      });
    }
  };

  // On chat submit
  const handleChatSubmit = async () => {
    if (isError || (currentPayload.files.length <= 0 && !currentPayload.message.trim())) {
      return;
    }

    const {
      first_name,
      last_name,
      gender = 1,
      image_url = '',
      id,
    } = JSON.parse(localStorage.getItem('user'));

    const currentTempMsg = {
      ...currentPayload,
      tempId: new Date().getTime(),
      createdAt: new Date(),
      type: MESSAGE_TYPES.TEXT,
      creator_details: { first_name, last_name, gender, image_url, id },
      isUploading: true,
      isFailed: false,
      isSuccess: false,
    };
    // If contains reply, add it's id
    if (replyItem?.id) {
      currentTempMsg.reply_chat_id = replyItem.id;
      currentTempMsg.replyDetails = replyItem;
      setReplyItem(null);
    }

    setTempMessages((prev) => [currentTempMsg, ...prev]);
    setCurrentPayload({ ...structuredClone(initChatPayload), files: [] });

    scrollToBottom();
    await uploadFileAndSaveChat(currentTempMsg);
  };
  /***CHances for hitting an error in mutation is less, since they will pause the mutation until the connection is restored
   * So the chances for appearing retry is less
   */
  const handleRetry = async (item) => {
    const updatedItem = { ...item, isUploading: true, isSuccess: false, isFailed: false };
    await uploadFileAndSaveChat(updatedItem);
  };

  const handleReply = (item) => {
    // focus the textarea
    focusInputByClassName(CHAT_TEXTAREA_CLASS_NAME);

    // eslint-disable-next-line no-unused-vars
    const { replyDetails, ...rest } = item;
    setReplyItem(rest);
  };

  const handleAttachmentRemove = (updateFunction) => {
    setCurrentPayload((prev) => ({ ...prev, files: updateFunction(prev.files) }));
  };

  // Delete from query client
  const deleteFromUseQuery = (messageId) => {
    let queryIndex = 0;

    const updatedTempMessages = tempMessages.filter((message, index) => {
      if (parseInt(messageId) === parseInt(message.id)) queryIndex = index;
      return parseInt(messageId) !== parseInt(message.id);
    });

    if (updatedTempMessages.length !== tempMessages.length) {
      setTempMessages(updatedTempMessages);
      return { queryIndex, isFromTemp: true };
    }

    queryClient.setQueryData(chatListQueryKey, (oldData) => {
      //Change teh key according to props
      if (!oldData) return;

      const newPages = oldData.pages.map((page) => ({
        ...page,
        data: {
          ...page.data,
          rows: page.data.rows.filter((message, index) => {
            if (parseInt(message.id) === parseInt(messageId)) queryIndex = index;
            return parseInt(message.id) !== parseInt(messageId);
          }),
        },
      }));

      return {
        ...oldData,
        pages: newPages,
      };
    });
    return { queryIndex, isFromTemp: false };
  };

  // Recover to query client
  const recoverMessageFromUseQuery = (queryIndexObj, item) => {
    const { queryIndex, isFromTemp } = queryIndexObj;

    if (isFromTemp) {
      setTempMessages((message) => {
        return [...message.slice(0, queryIndex), item, ...message.slice(queryIndex)];
      });
      return;
    }

    queryClient.setQueryData(chatListQueryKey, (oldData) => {
      //Change teh key according to props
      if (!oldData) return;

      const newPages = oldData.pages.map((page) => {
        return {
          ...page,
          data: {
            ...page.data,
            rows: [
              ...page.data.rows.slice(0, queryIndex),
              item,
              ...page.data.rows.slice(queryIndex),
            ],
          },
        };
      });

      return {
        ...oldData,
        pages: newPages,
      };
    });
  };

  const handleInputPaste = (e) => {
    if (!e.clipboardData && !window.clipboardData) {
      return;
    }
    const items = (e.clipboardData || window.clipboardData)?.items;
    if (!items || items.length <= 0) {
      return;
    }

    // Currently, only one image paste is possible, so only taking last copied image, After multi upload support add all images
    const lastImage = items[items.length - 1];

    if (lastImage.kind === 'file') {
      const blob = lastImage.getAsFile();
      const reader = new FileReader();
      reader.onload = (event) => {
        const dataUriRegex = /^data:(image\/\w+);base64,/;
        if (event.target.result.match(dataUriRegex)) {
          const { file, fileType } = base64ToFile(event.target.result);

          if (!fileType) {
            makeAlert('Unsupported file');
            return;
          }

          const fileData = {
            name: file.name.replace(/[^\w.-]|[\s&]/g, ''),
            sanitizeName: sanitizeFileName(file.name),
            fileType: getFileType(file.name.split('.').pop()),
            size: file.size,
            extension: file.name.split('.').pop(),
            file,
            attachedType: fileType,
            isUploading: true,
            isFailed: false,
            isSuccess: false,
          };
          handleFileChange([fileData]);
        } else {
          makeAlert('Unsupported file');
        }
      };
      reader.readAsDataURL(blob);
    }

    // for (let index in items) {
    //   const item = items[index];
    //   if (item.kind === 'file') {
    //     setchatData({ ...chatData, chatMessage: '' });
    //     const blob = item.getAsFile();
    //     const reader = new FileReader();
    //     reader.onload = (event) => {
    //       const dataUriRegex = /^data:(image\/\w+);base64,/;
    //       if (event.target.result.match(dataUriRegex)) {
    //         setPastedImage(event.target.result);
    //       } else {
    //         setUploadError('Only image file format supported');
    //         setIsLoadingDelete(false);
    //         setTimeout(() => {
    //           setUploadError('');
    //         }, 3000);
    //       }
    //     };
    //     reader.readAsDataURL(blob);
    //   }
    // }
  };
  // const [drag, setDrag] = useState(false);

  // functions to manage drag and drop 

  const [isDragOver, setIsDragOver] = useState(false);

  const onDragSubmit = (files) => {
    const lastFile = files[files.length - 1];
    const attachmentType = MIME_TYPE_MAPPING[lastFile.type] || 2;
    const fileDetails = {
      name: lastFile.name.replace(/[^\w.-]|[\s&]/g, ''),
      sanitizeName: sanitizeFileName(lastFile.name),
      fileType: getFileType(lastFile.name.split('.').pop()),
      size: lastFile.size,
      extension: lastFile.name.split('.').pop(),
      file: lastFile,
      attachedType: attachmentType,
      isUploading: true,
      isFailed: false,
      isSuccess: false,
    };
    handleFileChange([fileDetails]);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragOver(false);
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      onDragSubmit(files);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
    event.dataTransfer.effectAllowed = 'copyMove';
    !isDragOver && setIsDragOver(true);
  };

  const handleDragLeave = () => {
    isDragOver && setIsDragOver(false);
  };


  return (
    <ChatProvider value={{ messageListRef, type }}>
      <section className='responsive-comments-container'>Comments</section>
      <div
        className={`col chat-bg chat-list-content p-0 ${containerClassName}`}
        style={{
          height: `max(calc(100vh - var(--top-bar-height) - 82px) , ${initialHeight}px - var(--top-bar-height))`,
        }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        {/* drag and drop  */}
        <FileDragDrop isDragOver={isDragOver} />

        <div style={{ flex: 1, overflow: 'hidden' }} className='message-list-wrapper'>
          {/* Chat header */}
          {headerVisibility && (
            <ChatHeader activeUpdate={activeUpdate} updateRefetch={updateRefetch} />
          )}
          {/* Message list */}
          <MessagesList
            isChatLoading={isLoading}
            isError={isError}
            error={error}
            hasNextPage={hasNextPage}
            messageListRef={messageListRef}
            onReactionUpdate={updateMessage}
            isFetchingNextPage={isFetchingNextPage}
            listClassName={listClassName}
            chatList={chatList}
            recoverMessageFromUseQuery={recoverMessageFromUseQuery}
            deleteFromUseQuery={deleteFromUseQuery}
            tempMessages={tempMessages}
            listBottomRef={listBottomRef}
            onDelete={deleteMessageFromDb}
            onReply={handleReply}
            onTopReached={fetchNextPage}
            onReplySelect={handleReply}
            onRetry={handleRetry}
            handleFileChange={handleFileChange}
          />
        </div>
        <div className='chat-input-container'>
          {/* Reply container on input */}
          <ReplyContainer selectedItem={replyItem} onRemove={() => setReplyItem(null)} />
          {/* Link Preview */}

          <InputLinkPreview
            linkDetails={currentPayload?.link_details}
            url={urlsInInput[0]}
            onLinkLoad={(metaData) => {
              setCurrentPayload((prev) => ({ ...prev, link_details: metaData }));
            }}
            onLinkRemove={() => {
              setCurrentPayload((prev) => ({ ...prev, link_details: null }));
            }}
          />

          {/* Input section */}
          <ChatInputBox
            onSubmit={handleChatSubmit}
            onFileChange={handleFileChange}
            onAttachmentRemove={handleAttachmentRemove}
            onInputChange={handleChatInputChange}
            initialObject={currentPayload}
            isPending={isPending}
            onPaste={handleInputPaste}
            isChatListError={isChatListError}
            isInTask={isInTask}
          />
        </div>
        {/* Media preview if files are selected, will avoid recorded audio, since they have another UI */}
        {currentPayload.files?.filter((item) => item.attachedType !== MESSAGE_TYPES.RECORDED_AUDIO)
          .length > 0 && (
          <MediaPreview
            files={currentPayload.files}
            isInput
            createdTime={currentPayload?.createdAt}
            onInputChange={handleChatInputChange}
            onSubmit={handleChatSubmit}
            initialText={currentPayload.message}
            isLocal
            isPrevBeforeSend={currentPayload.files.length > 0}
            onDelete={(idx) =>
              setCurrentPayload({
                ...currentPayload,
                files: currentPayload.files.filter((_, index) => idx !== index),
              })
            }
            onFileChange={handleFileChange}
            onClose={() => {
              setCurrentPayload({
                ...currentPayload,
                files: [],
              });
            }}
          />
        )}
      </div>
    </ChatProvider>
  );
};

export default ChatContainer;
