import React, { useRef } from 'react';

import { Popover } from '../Popover';
import './tooltip.css';

function ToolTip({
  children,
  className = '',
  tooltipText = null,
  popOver = false,
  position = 'bottom',
  hideTooltip = false, //Only for new tooltip
  customBgColor = 'rgba(42, 46, 52, 1)', //Only for new tooltip
  isModern = false, //For newer tooltip
  style = {},
  scrollRef,
  onClose = () => {},
  containerClassName = '',
}) {
  // top: calc(100% + 20px);
  // right: 50%;
  // transform: translateX(50%);
  const positionStyle =
    position === 'bottom'
      ? {
          top: 'calc(100% + 20px)',
          right: '50%',
          transform: 'translateX(50%)',
        }
      : position === 'right'
      ? {
          top: '50%',
          left: 'calc(100% + 10px)',
          transform: 'translateY(-50%)',
        }
      : position === 'left'
      ? {
          top: '-50%',
          right: 'calc(100% + 10px)',
          transform: 'translateY(50%)',
        }
      : {
          bottom: 'calc(100% + 20px)',
          right: '50%',
          transform: 'translateX(50%)',
        };

  if (isModern) {
    return (
      <ModernToolTip
        containerClassName={containerClassName}
        className={className}
        customBgColor={customBgColor}
        hideTooltip={hideTooltip}
        tooltipText={tooltipText}
        popOver={popOver}
        scrollRef={scrollRef}
      >
        {children}
      </ModernToolTip>
    );
  }

  return (
    <>
      <div className='hr-tooltip-container' onClick={onClose} style={style}>
        {children}
        {tooltipText && tooltipText.toString().trim() !== '' && (
          <div className='hrv-tooltip' style={positionStyle}>
            {tooltipText}
          </div>
        )}
      </div>
    </>
  );
}

const ModernToolTip = ({
  children,
  tooltipText,
  hideTooltip,
  customBgColor,
  className,
  containerClassName = '',
  popOver,
  scrollRef,
}) => {
  const contentRef = useRef(null);
  if (popOver) {
    return (
      <Popover.PopoverWrapper>
        <div className={`okr-tooltip-container ${containerClassName} `} ref={contentRef}>
          {children}
          <Popover.PopoverItem isToolTip scrollRef={scrollRef}>
            {tooltipText && !hideTooltip && (
              <>
                <div
                  className={`okr-tooltip-text ${className}`}
                  style={{ backgroundColor: customBgColor }}
                >
                  {tooltipText}
                </div>
                <div className='triangle' style={{ backgroundColor: customBgColor }} />
              </>
            )}
          </Popover.PopoverItem>
        </div>
      </Popover.PopoverWrapper>
    );
  }

  return (
    <div className={`okr-tooltip-container ${containerClassName} `}>
      {children}
      {tooltipText && !hideTooltip && (
        <>
          <div
            className={`okr-tooltip-text ${className}`}
            style={{ backgroundColor: customBgColor }}
          >
            {tooltipText}
          </div>
          <div className='triangle' style={{ backgroundColor: customBgColor }} />
        </>
      )}
    </div>
  );
};

export default ToolTip;
