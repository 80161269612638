import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../Hook/useUser';
import { ROLE } from '../_constants';

export function SettingsProtectedRoute({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useUser();

  useEffect(() => {
    if (user.role !== ROLE.ADMIN) {
      navigate('/404', { replace: true });
    }
  }, [location.pathname]);

  return children;
}
