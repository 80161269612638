import React, { useState } from 'react';
import { Button } from '../../_components';

export const TimeSheetAddModal = ({ handleClose = () => {} }) => {
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className=' ' style={{ fontSize: '13px' }}>
      <form action='' className='d-flex flex-column gap-2 p-4 '>
        <h5>Add Time Sheet</h5>
        <div className='d-flex justify-content-between align-items-center '>
          <a
            className='reset-button'

            // onClick={() => {
            //   setSubmitted(false);
            //   setIsLoading(false);

            //   setEmployee((prev) => ({
            //     ...defaultEmployee,
            //     reported_users: [adminDetails],
            //   }));
            // }}
          >
            Reset All
          </a>
          <div className='d-flex gap-2'>
            <Button.Container
              type='button'
              className='cancel-btn'
              handleOk={() => {
                handleClose();
              }}
            >
              <Button.Title title='Cancel' />
            </Button.Container>
            <Button.Container type='submit' isDisabled={isLoading}>
              <Button.Title title={'Apply'} />
              <Button.Loading isLoading={isLoading} />
            </Button.Container>
          </div>
        </div>
      </form>
    </div>
  );
};
