import React from 'react';
import { LoadingIcon } from '../Utils/SvgIcons';
export const LoadingImg = () => {
  return (
    <div className='loadingimg'>
      <LoadingIcon color='blue' />
    </div>
  );
};

{
  /* <div className='d-flex justify-content-center p-5'>
          <div className='spinner-border' role='status'>
            <span className='sr-only'></span>
          </div>
        </div> */
}
