import { useQuery } from '@tanstack/react-query';
import parse from 'html-react-parser';
import { ArrowLeftIcon, Ellipsis, EyeIcon, FileText, Home, Plus } from 'lucide-react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, ContextMenu, Modal } from '../_components';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import StackedAvatars from '../_components/Avatar/StackedAvatars';
import IconLabelSet from '../_components/IconLabelSet';
import ToolTip from '../_components/Tooltip/ToolTip';
import { apiConstants, normalTaskTypes, RT, typeConstants } from '../_constants';
import { POSITION } from '../_constants/contextMenu.constants';
import { DELETE_NOTE } from '../_constants/deletenote.constants';
import { TASK_TYPES } from '../_constants/template.constants';
import { okrService } from '../_services';
import { useUser } from '../Hook/useUser';
import { TaskAdd } from '../Task';
import { LinkOptions } from '../Task/taskValidation';
import { fullNameUser, Images } from '../Utils';
import {
  AtIcon,
  Calendar,
  ChildTreeDownIcon,
  FileIcon,
  GroupUser,
  LoadingIcon,
  OkrIcon,
  OkrRoundProgress,
} from '../Utils/SvgIcons';
import { ExistingTaskAdd } from './ExistingTaskAdd';
import OkrChildAdd from './OkrChildAdd';
import {
  EXPIRY,
  manualProgressType,
  objectiveType,
  objectiveVisibility,
  okrFormAction,
} from './okrConstants';
import OkrObjectiveAdd from './OkrObjectiveAdd';
import { OkrIconWithName } from './OkrTableRow';

export const OkrSingleView = () => {
  const [okrData, setOkrData] = useState({});
  const [childObjectiveData, setChildObjectiveData] = useState([]);
  const [childKeyResultData, setChildKeyResultData] = useState([]);
  const [childTaskData, setChildTaskData] = useState([]);
  const [childAddModal, setChildAddModal] = useState(false);
  const [IsObjectiveAdd, setIsObjectiveAdd] = useState(false);
  const [addTaskModal, setAddTaskModal] = useState(false);
  const [isTaskAddButtonClicked, setTaskAddButtonClicked] = useState(false);
  const [isTaskSelectionPopup, setIsTaskSelectionPopup] = useState(false);

  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const navigate = useNavigate();
  const currentOkrId = useParams().id;

  // let childProgress = parseFloat(project?.completion_percentage);
  let childProgress = parseFloat(30);

  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = useState({
    // select: ['id', 'name', 'start_date', 'end_date', 'note', 'task_end_date', 'task_start_date'],
    select: [
      'id',
      'name',
      'status',
      'type',
      'task_start_date',
      'task_end_date',
      'visibility_team',
      'visibility_type',
      'children',
      'okr_parent_id',
      'objective_type',
      'created_time',
      'note',
      'task_type',
      'assignee_id',
      'okr_progress_type',
      'manual_progress_type',
      'progress_measure_start',
      'progress_measure_end',
      'okr_progress',
      'manual_current_progress',
      'task_visible_users',
    ],
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
    id: currentOkrId,
  });

  const fetchOkr = async (query) => {
    const res = await okrService.getOKR({ ...query, id: currentOkrId });

    if (res?.data) {
      const result = res.data;

      if (result?.children?.length > 0) {
        const childData = result.ChildrenDetails;

        // Calculate progress for each child objective, key result, and task
        const objectives = childData
          .filter((data) => parseInt(data?.type) === parseInt(typeConstants.OBJECTIVE))
          .map((objective) => {
            return { ...objective };
          });

        const keyResults = childData
          .filter((data) => parseInt(data?.type) === parseInt(typeConstants.KEY_RESULT))
          .map((keyResult) => {
            return { ...keyResult };
          });

        const tasks = childData
          .filter((data) => normalTaskTypes.includes(parseInt(data?.type)))
          .map((task) => {
            // const { progress, progressText } = progressCalculation(task);
            return { ...task };
          });

        setChildObjectiveData(objectives);
        setChildKeyResultData(keyResults);
        setChildTaskData(tasks);
      }

      setOkrData({
        ...result,
        // progress: progress,
        // progressText: progressText,
      });
    }

    return res;
  };

  const valueRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const element = valueRef?.current;
    if (element?.scrollWidth > element?.clientWidth) {
      setIsOverflowing(true);
    } else {
      setIsOverflowing(false);
    }
  }, [okrData?.name]);
  // fetch okr

  const {
    // eslint-disable-next-line no-unused-vars
    data,
    // error,
    refetch,
    isLoading,
    isPending,
    isFetching,
    // isSuccess,
  } = useQuery({
    queryKey: ['okr-get', currentOkrId],
    queryFn: () => fetchOkr(query),
    select: (data) => data?.data,
  });

  const modalClose = () => {
    setDeleteModal(false);
    setContextMenuVisible(false);
    setSelectedId(null);
  };

  // Ensure childProgress is a number before formatting
  if (typeof childProgress === 'number' && childProgress > 0) {
    // Check if the childProgress is a whole number
    if (childProgress % 1 === 0) {
      childProgress = childProgress.toFixed(0); // No decimal points
    } else {
      childProgress = childProgress.toFixed(1); // One decimal point
    }
  }

  const assigneeName = fullNameUser(okrData.User?.first_name, okrData.User?.last_name);
  const assigneeIsMale = okrData?.User?.gender === 1;
  const assigneeIsNeutral = okrData?.User?.gender === 3;
  const assigneeUrl = okrData?.User?.image_url;
  const finalUrl = assigneeUrl ? apiConstants.imgUrlS3 + assigneeUrl : null;

  const handleMenuClick = (value) => {
    if (value === 'delete') {
      setDeleteModal(true);
    }
    if (value === 'edit') {
      setEditModal(true);
    }
  };

  // section trigger when delete option selected

  const handleDeactivate = async (id) => {
    try {
      await okrService.OKRDelete({ id });
      // if (parseInt(id) === parseInt(currentOkrId)) {
      navigate(`/${RT.OKR_LIST}`);
      // } else {
      //   modalClose();
      // }
    } catch (error) {
      console.error('Error deleting OKR:', error);
    }
  };

  const handleChileSingleView = () => {
    setChildKeyResultData([]);
    setChildObjectiveData([]);
    setChildTaskData([]);
  };

  const loggedUser = useUser();

  const handleTaskClick = (task) => {
    const isTaskOwner = loggedUser?.id == parseInt(task?.assignee_id);
    const tab =
      isTaskOwner && task?.task_type != TASK_TYPES.NON_TIME_BOUND_TASK ? 'subtask' : 'description';
    // navigate(`/${RT.TASK}/${task?.id}?tab=${tab}&&${params}`);
    navigate(`/${RT.TASK}/${task?.id}?tab=${tab}`);
  };

  return (
    <div ref={valueRef}>
      <div className='d-flex ptr breadcrumbs url-inner-navigation'>
        <div className='back-button-container' onClick={() => navigate(-1)}>
          <ArrowLeftIcon strokeWidth={1.5} size={26} color='#87909E' />
        </div>
        <div className='d-flex align-items-center '>
          <IconLabelSet
            label={'Home'}
            fontWeight={400}
            icon={<Home strokeWidth={1.5} size={16} />}
            onClick={() => navigate('/')}
          />
          <img className='slash-img-icon-size ms-1 mt-1' src='/icons/Lineicon.png' alt='' />
          <IconLabelSet
            label={'OKR'}
            fontWeight={400}
            gap={3}
            icon={<OkrIcon color='#87909E' iconForSideBar='#87909E' />}
            onClick={() => navigate(`/${RT.OKR_LIST}`)}
          />
          <img className='slash-img-icon-size ms-1 mt-1' src='/icons/Lineicon.png' alt='' />
          <IconLabelSet
            label={okrData?.name ? okrData?.name : '--'}
            fontWeight={500}
            color={`#2A2E34`}
            // icon={<FileText strokeWidth={1.5} size={16} />}
            onClick={() => navigate(`/${RT.OKR_VIEW}/${currentOkrId}`)}
            className='test-bread'
          />{' '}
        </div>
      </div>

      {(isLoading || isPending || isFetching) && (
        <div className=' objective-card '>
          <LoadingIcon size={60} color='blue' className=' mx-auto mt-5' />
        </div>
      )}

      {!(isLoading || isPending || isFetching) && (
        <div className='objective-card'>
          {okrData?.parentDetails && (
            <div className='d-flex parent-info-head' style={{ margin: 0 }}>
              <div>
                <ChildTreeDownIcon />
              </div>
              <div
                onClick={() => navigate(`/${RT.OKR_VIEW}/${okrData?.okr_parent_id}`)}
                className='cursor-pointer'
              >
                <div className='fz-10px-rem'>Parent Objective</div>
                <div className=' fz-14 d-flex' style={{ fontWeight: '500' }}>
                  <span>{okrData?.parentDetails?.name}</span>
                </div>
              </div>
            </div>
          )}
          <div className='header position-relative'>
            <div
              className='okr-edit-icon'
              style={{
                background: contextMenuVisible ? '#e9f2ff' : '',
              }}
              // style={{ position: 'absolute', top: 0, right: 15 }}
              onClick={() => {
                setContextMenuVisible(true);
                setSelectedId(currentOkrId);
              }}
            >
              <ToolTip tooltipText={'Edit & Delete'} isModern hideTooltip={contextMenuVisible}>
                <Ellipsis
                  color={contextMenuVisible ? '#3454d1' : '#2a2e34'}
                  style={{ marginBottom: 2 }}
                />
              </ToolTip>

              <ContextMenu
                handleClick={(value) => {
                  handleMenuClick(value);
                }}
                positionClass='right-0px  '
                isContextVisible={contextMenuVisible}
                onRequestClose={() => {
                  setContextMenuVisible(false);
                }}
              />
            </div>
            <OkrRoundProgress
              progressColor={okrData?.progressDetails?.color}
              parentProgressColor={okrData?.progressDetails?.backgroundColor}
              progress={okrData?.progressDetails?.progress}
              progressText={okrData?.progressDetails?.progressText}
            />

            <div className='objective-head'>
              <span className='okr-view-label'>
                {parseInt(okrData?.type) === typeConstants.OBJECTIVE
                  ? okrData?.parentDetails
                    ? 'Objective'
                    : 'Main Objective'
                  : 'Key Result'}
              </span>
              <span className='okr-name' style={isOverflowing ? { lineBreak: 'anywhere' } : {}}>
                {okrData?.name}
              </span>
            </div>
          </div>

          <div className='okr-details'>
            {/* info section   */}
            <div className='okr-info'>
              <span className='okr-view-label'>
                <span>
                  <AtIcon />
                </span>
                <span>Assignee</span>
              </span>

              <span className='okr-view-value d-flex align-items-center gap-1'>
                <CircularAvatar
                  size={25}
                  source={finalUrl}
                  className='fz-none zoom-in employee-img-with-low-border ms-0 me-0'
                  isMale={assigneeIsMale}
                  isNeutral={assigneeIsNeutral}
                />

                {assigneeName}
              </span>
            </div>
            <div className='okr-info'>
              <span className='okr-view-label'>
                <span>
                  <Calendar height={14} width={14} />
                </span>
                <span>Start Date</span>
              </span>
              <span className='okr-view-value '>
                {moment(parseInt(okrData.task_start_date)).format('DD MMM YYYY')}
              </span>
            </div>

            <div className='okr-info'>
              <span className='okr-view-label'>
                <span>
                  <Calendar height={14} width={14} />
                </span>
                <span>Due Date</span>
                {/* Due Date */}
              </span>
              <span className='okr-view-value '>
                <span style={{ color: okrData.expiry_status === EXPIRY.EXPIRED ? '#ea4d4d' : '' }}>
                  {moment(parseInt(okrData.task_end_date)).format('DD MMM YYYY')}
                </span>
                {/* {moment(parseInt(okrData.task_end_date)).format('DD MMM YYYY')} */}
              </span>
            </div>
            {parseInt(okrData?.objective_type) === objectiveType?.company && (
              <>
                <div className='okr-info'>
                  <span className='okr-view-label'>
                    <span>
                      <EyeIcon size={17} />
                    </span>
                    <span>Visibility</span>
                  </span>
                  <span className='okr-view-value '>
                    {parseInt(okrData?.visibility_type) === objectiveVisibility.Team
                      ? 'Team'
                      : 'All'}
                  </span>
                </div>

                {parseInt(okrData?.visibility_type) === objectiveVisibility.Team && (
                  <div className='okr-info'>
                    <span className='okr-view-label'>
                      <span>
                        <GroupUser height={14} width={16} />
                      </span>
                      <span>Visibility Team</span>
                    </span>
                    <span className='okr-view-value '>
                      <StackedAvatars
                        justifyPosition='justify-content-left'
                        getSourceByKey={(team) => team?.image_url}
                        getNameByKey={(team) => team?.name}
                        getGenderByKey={(team) => team?.gender === 1}
                        imageList={okrData?.teams}
                        showInitials={true}
                        tooltipPosition={POSITION.TOP}
                        size={25}
                      />
                    </span>
                  </div>
                )}
              </>
            )}

            {parseInt(okrData?.objective_type) === objectiveType?.team && (
              <div className='okr-info'>
                <span className='okr-view-label'>
                  <span>
                    <GroupUser height={14} width={16} />
                  </span>
                  <span>Responsible Team</span>
                </span>
                <span className='okr-view-value '>
                  <StackedAvatars
                    justifyPosition='justify-content-left'
                    getSourceByKey={(team) => team?.image_url}
                    getNameByKey={(team) => team?.name}
                    getGenderByKey={(team) => team?.gender === 1}
                    imageList={okrData?.teams}
                    showInitials={true}
                    tooltipPosition={POSITION.TOP}
                    size={25}
                  />
                </span>
              </div>
            )}
          </div>

          {/* end of info section  */}

          {okrData?.note && (
            <div className='okr-description'>{parse(okrData?.note, LinkOptions)}</div>
          )}
          <div className='project-separation ' />

          {/* child section  */}

          <div className='sub-sections'>
            {/*  objective section  */}
            {(okrData?.objective_type == objectiveType.company ||
              okrData?.objective_type == objectiveType.team) && (
              <section>
                <div className='d-flex justify-content-between align-items-center'>
                  <span className='okr-name' style={{ fontSize: 15 }}>
                    Child Objective
                  </span>
                  <ToolTip tooltipText={'Add Child Objective'} isModern>
                    <div
                      className='plus-icon-outline'
                      onClick={() => {
                        setChildAddModal(true);
                        setIsObjectiveAdd(true);
                      }}
                      //  onClick={() => nav(`/${RT.PROJECT_ADD}/0`)}
                    >
                      <Plus size={16} />
                    </div>
                  </ToolTip>
                </div>

                <div className='key-result-info-parent'>
                  {childObjectiveData.map((data, index) => (
                    <div key={index} className='key-result-info cursor-pointer'>
                      <div
                        className='d-flex gap-1 align-items-center '
                        style={{ width: '85%' }}
                        onClick={() => {
                          handleChileSingleView();
                          navigate(`/${RT.OKR_VIEW}/${data?.id}`);
                        }}
                      >
                        <OkrIconWithName
                          type={data?.objective_type}
                          isKeyResult={false}
                          size={24}
                        />
                        {/* <span className='icon'>I</span> */}
                        <span>{data?.name}</span>
                      </div>
                      <div className='d-flex gap-5  align-items-center '>
                        {/* <div className='d-flex align-items-center gap-2'>
                        {parseInt(data?.manual_progress_type) !== manualProgressType.binary && (
                          <div
                            className='progress-bar-parent'
                            style={{
                              backgroundColor: data?.progressDetails?.backgroundColor,
                            }}
                          >
                            <div
                              className='progress-bar-child'
                              style={{
                                width: data?.progressDetails?.progress + '%',
                                backgroundColor: data?.progressDetails?.color,
                                // ?.full,
                              }}
                            />
                          </div>
                        )}
                        <span style={{ color: okrData?.progressDetails?.color }}>
                          {parseInt(data?.manual_progress_type) !== manualProgressType.binary
                            ? data?.progressDetails?.progressText
                            : parseInt(data?.progressDetails?.progress) === 100
                            ? 'Completed'
                            : 'In-completed'}
                        </span>
                      </div> */}
                        <div className='d-flex align-items-center gap-2'>
                          {parseInt(data?.manual_progress_type) !== manualProgressType.binary ? (
                            <>
                              <div
                                className='progress-bar-parent'
                                style={{
                                  backgroundColor: data?.progressDetails?.backgroundColor,
                                }}
                              >
                                <div
                                  className='progress-bar-child'
                                  style={{
                                    width: data?.progressDetails?.progress + '%',
                                    backgroundColor: data?.progressDetails?.color,
                                    // ?.full,
                                  }}
                                />
                              </div>
                              {data?.progressDetails?.progressText}
                            </>
                          ) : parseInt(data?.progressDetails?.progress) === 100 ? (
                            <span style={{ color: data?.progressDetails?.color }}>Completed</span>
                          ) : (
                            <span style={{ color: data?.progressDetails?.color }}>
                              In-completed
                            </span>
                          )}
                          {/* {data?.progressDetails?.progress + '%'} */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            )}
            {/* end of objective section  */}

            {/* key result section  */}
            {typeConstants.KEY_RESULT !== parseInt(okrData?.type) && (
              <section>
                <div className='d-flex justify-content-between align-items-center'>
                  <span className='okr-name' style={{ fontSize: 15 }}>
                    Key Results
                  </span>
                  <ToolTip tooltipText={'Add Key Result'} isModern>
                    <div
                      className='plus-icon-outline'
                      onClick={() => {
                        setChildAddModal(true);
                        setIsObjectiveAdd(false);
                      }}
                      //  onClick={() => nav(`/${RT.PROJECT_ADD}/0`)}
                    >
                      <Plus size={16} />
                    </div>
                  </ToolTip>
                </div>

                <div className='key-result-info-parent'>
                  {childKeyResultData.map((data, index) => (
                    <div key={index} className='key-result-info cursor-pointer'>
                      <div
                        className='d-flex gap-1 align-items-center'
                        style={{ width: '85%' }}
                        onClick={() => {
                          handleChileSingleView();
                          navigate(`/${RT.OKR_VIEW}/${data?.id}`);
                        }}
                      >
                        {/* <span className='icon'>I</span> */}
                        <OkrIconWithName type={data?.objective_type} isKeyResult={true} size={24} />
                        <span>{data?.name}</span>
                      </div>
                      <div className='d-flex gap-5  align-items-center'>
                        <div className='d-flex align-items-center gap-2'>
                          {parseInt(data?.manual_progress_type) !== manualProgressType.binary ? (
                            <>
                              <div
                                className='progress-bar-parent'
                                style={{
                                  backgroundColor: data?.progressDetails?.backgroundColor,
                                }}
                              >
                                <div
                                  className='progress-bar-child'
                                  style={{
                                    width: data?.progressDetails?.progress + '%',
                                    backgroundColor: data?.progressDetails?.color,
                                    // ?.full,
                                  }}
                                />
                              </div>
                              {data?.progressDetails?.progressText}
                            </>
                          ) : parseInt(data?.progressDetails?.progress) === 100 ? (
                            <span style={{ color: data?.progressDetails?.color }}>Completed</span>
                          ) : (
                            <span style={{ color: data?.progressDetails?.color }}>
                              In-completed
                            </span>
                          )}
                          {/* {data?.progressDetails?.progress + '%'} */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            )}
            {/* end of key result section  */}

            {/* task section  */}
            <section>
              <div className='d-flex justify-content-between align-items-center'>
                <span className='okr-name' style={{ fontSize: 15 }}>
                  Tasks
                </span>
                <ToolTip tooltipText={'Add Task'} isModern hideTooltip={isTaskAddButtonClicked}>
                  <div
                    className='plus-icon-outline '
                    onClick={() => {
                      setTaskAddButtonClicked(true);
                    }}
                    //  onClick={() => nav(`/${RT.PROJECT_ADD}/0`)}
                  >
                    <Plus size={16} />
                    <ContextMenu
                      handleClick={(value) => {
                        handleMenuClick(value);
                      }}
                      positionClass='right-0px  '
                      isContextVisible={isTaskAddButtonClicked}
                      onRequestClose={() => {
                        setTaskAddButtonClicked(false);
                      }}
                    >
                      <div
                        className='menu_items'
                        onClick={(event) => {
                          event.stopPropagation();
                          setTaskAddButtonClicked(null);

                          setIsTaskSelectionPopup(okrData?.id);
                        }}
                        style={{ width: 130 }}
                      >
                        <div className='d-flex align-items-center gap-1  w-100'>
                          <div>
                            <FileIcon size={18} />
                          </div>
                          <div className='flex-1'>Existing Task</div>
                        </div>
                      </div>
                      <div
                        className='menu_items'
                        onClick={(event) => {
                          event.stopPropagation();
                          setTaskAddButtonClicked(null);
                          setAddTaskModal(true);
                        }}
                      >
                        <div className='d-flex align-items-center gap-1 w-100'>
                          <div>
                            <FileIcon size={18} />
                          </div>
                          <div className='flex-1'>Add Task</div>
                        </div>
                      </div>
                    </ContextMenu>
                  </div>
                </ToolTip>
                {isTaskSelectionPopup === okrData?.id && (
                  <ExistingTaskAdd
                    handleClose={(event) => {
                      if (event) {
                        event.stopPropagation();
                      }
                      setTaskAddButtonClicked(null);
                      setIsTaskSelectionPopup(null);
                      refetch();
                    }}
                    parentId={okrData?.id}
                    top={10}
                    right={60}
                  />
                )}
              </div>

              <div className='key-result-info-parent'>
                {childTaskData.map((data, index) => (
                  <div
                    key={index}
                    className='key-result-info cursor-pointer'
                    style={{
                      cursor: data?.isTaskClickable ? '' : 'default',
                      color: data?.isTaskClickable ? '' : 'rgba(135, 144, 158, 1)',
                    }}
                  >
                    <div
                      className='d-flex gap-1 align-items-center'
                      onClick={() => (data?.isTaskClickable ? handleTaskClick(data) : '')}
                    >
                      <FileText size={20} />
                      <span>{data?.name}</span>
                    </div>
                    <div className='d-flex gap-5  align-items-center'></div>
                  </div>
                ))}
              </div>
            </section>
            {/* )} */}

            {/* end of task section  */}
          </div>
        </div>
      )}

      {childAddModal && (
        <Modal.Container>
          <Modal.View className={'okr-modal-view'} containerClass={'objective-add-container'}>
            <OkrChildAdd
              // id={editOkrId}
              parentData={okrData}
              isChildObjective={IsObjectiveAdd}
              handleClose={(value) => {
                setChildAddModal(false);
                setIsObjectiveAdd(null);
                if (value === okrFormAction.Save) {
                  refetch();
                }
                // refetch();
                // setIsExpand(true);
              }}
            />
          </Modal.View>
        </Modal.Container>
      )}
      {addTaskModal && (
        <Modal.Container>
          <Modal.Head containerClass={'task-add-head'}></Modal.Head>
          <Modal.View className={'task-add-container'}>
            <TaskAdd
              id={0}
              okr_Parent_id={okrData?.id}
              handleClose={() => {
                setAddTaskModal(false);
                refetch();
              }}
              selectDefaultTemplateIfPresent
              assignTo={loggedUser?.id}
              defaultAssignee={loggedUser}
            />
          </Modal.View>
        </Modal.Container>
      )}
      {editModal && (
        <Modal.Container>
          <Modal.View className={'okr-modal-view'} containerClass={'objective-add-container'}>
            {parseInt(okrData?.objective_type) === objectiveType.company ||
            (!parseInt(okrData?.okr_parent_id) &&
              parseInt(okrData?.type) === typeConstants.OBJECTIVE) ? (
              <OkrObjectiveAdd
                id={selectedId}
                handleClose={(value) => {
                  setEditModal(false);
                  setSelectedId(null);
                  if (value === okrFormAction.Save) {
                    refetch();
                  }
                }}
              />
            ) : (
              <OkrChildAdd
                id={selectedId}
                parentData={okrData?.parentDetails}
                isChildObjective={parseInt(okrData?.type) === typeConstants.OBJECTIVE}
                handleClose={(value) => {
                  setEditModal(false);
                  if (value === okrFormAction.Save) {
                    refetch();
                  } // setEditOkrId(null);
                  // setIsExpand(true);
                }}
              />
            )}
          </Modal.View>
        </Modal.Container>
      )}
      {/* delete pop up  */}
      <section>
        {deleteModal && (
          <Modal.Container>
            <Modal.View className={'status-delete'}>
              <Modal.Head handleClose={() => modalClose()} Heading={'Delete Confirmation'} />
              <Modal.Description>
                <img src={Images.DeleteBin} width={35} height={50} alt='' className='mb-3' />
                <div className='del-description'>{DELETE_NOTE.OKR}</div>
              </Modal.Description>
              <div className='status-del-btn'>
                <Button.Container handleOk={() => modalClose()} className={'status-del-cancel'}>
                  <Button.Title title={'Cancel'} />
                </Button.Container>
                <Button.Container handleOk={() => handleDeactivate(parseInt(selectedId))}>
                  <Button.Title title={'Delete'} />
                </Button.Container>
              </div>
            </Modal.View>
          </Modal.Container>
        )}
      </section>
    </div>
  );
};
