/* eslint-disable no-unused-vars */
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';
import { Calendar, File, Hourglass, Plus, User, Zap } from 'lucide-react';
import moment from 'moment';
import React, { useState } from 'react';
import CircularAvatar from '../../_components/Avatar/CircularAvatar';
import DatePicker from '../../_components/DatePicker';
import IconLabelSet from '../../_components/IconLabelSet';
import { Popover } from '../../_components/Popover';
import SearchableQuerySelect from '../../_components/SearchableQuerySelect';
import ToolTip from '../../_components/Tooltip/ToolTip';
import UserDropDown from '../../_components/UserDropDown';
import { apiConstants } from '../../_constants';
import { POSITION } from '../../_constants/contextMenu.constants';
import { START_MODE, VALIDATION_VALUES } from '../../_constants/template.constants';
import { userService } from '../../_services';
import SubtaskListWithAdd from '../../Template/SubComponents/SubtaskListWithAdd';
import { fullNameUser, subtaskState } from '../../Utils';
import { Label, Priority } from '../../Utils/SvgIcons';
import { EditableDescription } from '../TaskComponents';
import { CustomLabelSelectModal, CustomVariableInput } from '../TaskComponents/CustomInputs';
import EditableField from '../TaskComponents/EditableField';
import RequestHourInput from '../TaskComponents/RequestHourInput';
import {
  customUserSelectBoxStyles,
  taskAssignUserSelect,
  USER_LIST_ACTIONS,
} from '../taskConstants';
import {
  getExpectedTime,
  getLabelIsActive,
  getPriorityByContextAPI,
  getSubTaskIsActive,
  removeTagsAndGetContent,
} from '../taskValidation';

const InlineRelatedTaskItemExpand = ({
  relatedTaskData,
  onSubmitSubtask,
  isExpanded,
  precedingTask,
  validation_fields,
  setLabelSearch = () => {},
  setUserSearch = () => {},
  labelSearch,
  userSearch,
  userList = [],
  labelList = [],
  setRelatedTaskData = () => {},
  scrollRef,
}) => {
  // for get settings label value and subtask value - get values by context API
  const isMenuLabelOn = getLabelIsActive();

  const isMenuSubTaskOn = getSubTaskIsActive();
  const [contextLabel, setContextLabel] = useState(false); //state for  label list visibility
  const [contextTimeLine, setContextTimeLine] = useState(false); //state for  label list visibility
  const [contextTime, setContextTime] = useState(false); //state for  label list visibility

  const priorityLabelList = getPriorityByContextAPI(); // to fetch the priority  label from context api
  const [subTask, setSubTask] = useState(relatedTaskData?.subtasks);
  const priorityLabel = priorityLabelList.filter(
    (item) => parseInt(item.id) === parseInt(relatedTaskData?.priority),
  );
  const StartDate = moment(relatedTaskData?.task_start_date).format('MMM DD');
  const EndDate = moment(relatedTaskData?.task_end_date).format('MMM DD');
  const ExpectedTime = getExpectedTime(
    parseInt(relatedTaskData?.expected_hours),
    parseInt(relatedTaskData?.expected_minutes),
  );
  const isNeutral = relatedTaskData?.assigned_user?.gender === 3;
  const isMale = relatedTaskData?.assigned_user?.gender === 1;
  const url = relatedTaskData?.assigned_user?.image_url;
  const finalUrl = url ? apiConstants.imgUrlS3 + url : null;
  const fullName = fullNameUser(
    relatedTaskData?.assigned_user.first_name,
    relatedTaskData?.assigned_user.last_name,
  );
  const labelCount = relatedTaskData?.labelDetails?.length;
  const moreLabel = relatedTaskData?.labelDetails?.slice(1);
  const handleSubTaskAddOrUpdate = (value, index = null, ...rest) => {
    // update
    if (!isNaN(index) && index !== null && index <= subTask.length - 1) {
      setSubTask((prev) =>
        prev?.map((itm, idx) => {
          if (idx === index) {
            return {
              ...itm,
              content: value,
              start_date_milli: moment(rest[1]).valueOf(),
              startDate: rest[1],
            };
          }
          return itm;
        }),
      );
      onSubmitSubtask(
        subTask?.map((itm, idx) => {
          if (idx === index) {
            return {
              ...itm,
              content: value,
              start_date_milli: moment(rest[1]).valueOf(),
              startDate: rest[1],
            };
          }
          return itm;
        }),
      );
      return;
    }
    const initSubtask = { ...subtaskState };
    initSubtask.content = value;
    initSubtask.startDate = rest[1];
    initSubtask.start_date_milli = moment(rest[1]).valueOf();

    setSubTask((prev) => [...prev, initSubtask]);
    onSubmitSubtask([...subTask, initSubtask]);
  };

  /** new functions */
  // to handle the changes
  const handleTaskUpdate = (e) => {
    let { name, value } = e.target;

    setRelatedTaskData({
      ...relatedTaskData,
      [name]: value,
    });
  };
  // to handle assignee change
  const handleAssigneeChange = (value) => {
    setRelatedTaskData((prev) => ({
      ...prev,
      assigned_user: value,
      assignee_id: value.id,
    }));
  };
  // to handle expected hour and minute
  const handleTimeInputs = (time) => {
    setRelatedTaskData((prev) => ({
      ...prev,
      expected_hours: time.expected_hours,
      expected_minutes: time.expected_minutes,
    }));
  };

  const onSaveDate = (dates) => {
    setRelatedTaskData((prev) => ({
      ...prev,
      dates,
    }));
  };

  /** end */
  // remove subtask data
  const handleSubtaskDelete = (index) => {
    setSubTask((prev) => prev.filter((item, idx) => idx !== index));
  };

  const filteredIds =
    relatedTaskData?.labelList && relatedTaskData?.labelList?.map((item) => item.id);

  // to change label to required format and save
  const handleLabelUpdate = (item) => {
    let editedLabelData = filteredIds?.length > 0 ? [...filteredIds, item] : [item];
    const labelData = labelList?.filter((label) => label.id == item);
    const labelDetails = [...relatedTaskData.labelDetails, ...labelData];
    setRelatedTaskData((prev) => ({
      ...prev,
      labelDetails: labelDetails,
      label: editedLabelData,
    }));
    setLabelSearch('');
    // handleTaskUpdate({ target: { name: 'label', value: editedLabelData } });
  };

  // to remove label
  const handleLabelRemove = (item) => {
    let editedLabelData = relatedTaskData?.label.filter((id) => id != item);
    const labelData = relatedTaskData?.labelDetails?.filter((label) => label?.id != item);
    setRelatedTaskData((prev) => ({
      ...prev,
      labelDetails: labelData,
      label: editedLabelData,
    }));
  };

  const filteredLabelList = labelList?.filter(
    (item) =>
      // item.name?.toLowerCase().includes(labelSearch?.toLowerCase()) &&
      !relatedTaskData?.labelDetails?.some(
        (selectedItem) => parseInt(selectedItem.id) === parseInt(item.id),
      ),
  );

  return (
    <div
      className={`anim-hide my-5px ${isExpanded ? '' : 'template-rows-0fr'} position-relative`}
      style={{ paddingLeft: 30, top: -22 }}
    >
      <div className={`${isExpanded ? 'delay-overflow-visible' : 'overflow-hidden'}`}>
        <div className='row'>
          <CustomVariableInput
            name='name'
            value={relatedTaskData.name}
            placeholder='Enter Task Name'
            onChange={(event) => handleTaskUpdate(event)}
            className={`taskname-input  w-100  border-none task-bg-grey`}
          />
        </div>
        <div className='row mt-3 '>
          <div className='col-md-6 p-0'>
            <div className='d-flex gap-4 align-items-center related-line-items'>
              {' '}
              <IconLabelSet label={'Owner'} icon={<User size={14} />} />
              <SearchableQuerySelect
                name='assignee_id'
                getOptionLabel={(option) => {
                  return option?.first_name + '' + option?.last_name;
                }}
                getOptionValue={(option) => {
                  return option?.value;
                }}
                formatOptionLabel={(data, metaData) => {
                  if (metaData.context == 'value') {
                    const imageUrl = apiConstants.imgUrlS3 + data.image_url;
                    return (
                      <div className='d-flex align-items-center' style={{ gap: 2 }}>
                        <CircularAvatar
                          className={`member-avatar `}
                          isImgStacked
                          size={19}
                          source={imageUrl}
                          isMale={data.isMale}
                        />
                        <div className='task-single-menu-value'>
                          {data?.first_name + '' + data?.last_name}
                        </div>
                      </div>
                    );
                  }
                  return <UserDropDown size={19} gap={4} data={data} metaData={metaData} />;
                }}
                queryFn={userService.getPermittedUser}
                queryKey={['userData', relatedTaskData]}
                isCustomFunction
                initialValue={userList}
                getFunctionParams={(searchKey) => {
                  return {
                    searchKey: searchKey,
                    action: USER_LIST_ACTIONS.TASK_ADD,
                    select: taskAssignUserSelect,
                    ...(relatedTaskData?.project_id
                      ? { project_id: relatedTaskData?.project_id }
                      : {}),
                    ...(relatedTaskData?.template_id && relatedTaskData?.template_id > 0
                      ? { template_id: relatedTaskData?.template_id }
                      : {}),
                  };
                }}
                onChange={(value) => handleAssigneeChange(value)}
                styles={customUserSelectBoxStyles}
                className='w-100 '
                value={userList?.find((item) => item.value == relatedTaskData?.assigned_user?.id)}
              />
              {/* <div className='d-flex gap-2 align-items-center'>
                <CircularAvatar
                  size={23}
                  className='fz-none  employee-img-with-low-border'
                  source={finalUrl}
                  isMale={isMale}
                  isNeutral={isNeutral}
                />
                <span className='font-12'>{fullName}</span>
              </div> */}
            </div>
          </div>
          <div
            className={`col-md-6 p-0 d-flex ${
              validation_fields?.expected_hours == VALIDATION_VALUES.HIDE && 'd-none'
            }`}
          >
            <div className='d-flex gap-4 align-items-center related-line-items'>
              {' '}
              <IconLabelSet
                label={'Expected Time'}
                icon={<Hourglass width={11} height={15} />}
              />{' '}
              {contextTime && (
                <RequestHourInput
                  onSubmit={(val) => {
                    handleTimeInputs(val);
                  }}
                  setIsTimeInputVisible={setContextTime}
                  initialValue={{
                    expected_hours: Math.floor(relatedTaskData?.expected_hours),
                    expected_minutes: Math.round((relatedTaskData?.expected_hours % 1) * 60),
                  }}
                  isHeadInvisible
                />
              )}
              <div
                className='timeline-text task-single-menu-value pointer no-wrap'
                onClick={() => setContextTime(true)}
              >
                {ExpectedTime}{' '}
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-3 '>
          <div className='col-md-6 p-0'>
            <div className='d-flex gap-4 align-items-center related-line-items'>
              {' '}
              <IconLabelSet
                label={'Priority'}
                icon={<Priority width={13} height={13} color='#2A2E34' />}
              />
              <EditableField
                onSave={(val) =>
                  handleTaskUpdate({ target: { name: 'priority', value: val?.priority?.id } })
                }
                key={1}
                type='select'
                placeholder='Select Priority'
                list={priorityLabelList}
                data={relatedTaskData?.priority}
                name='priority'
                isEditable={true}
                className={''}
              >
                <div className='task-single-menu-value priority-box' role={'button'}>
                  <text className='priority-text'>
                    {relatedTaskData?.priority && priorityLabelList?.length > 0
                      ? priorityLabelList?.find((item) => item.value == relatedTaskData?.priority)
                          ?.label
                      : relatedTaskData?.edit_fields?.includes('Priority')
                      ? 'Select Priority'
                      : ' Select... '}
                  </text>
                </div>
              </EditableField>
              {/* {priorityLabel.length > 0 && (
                <div className=' priority-box mt-1' style={{ height: 23 }}>
                  <text className='priority-text'>{priorityLabel[0].label}</text>
                </div>
              )} */}
            </div>
          </div>
          <div className={`col-md-6 p-0 ${isMenuLabelOn ? 'd-flex' : 'd-none'} `}>
            <div className='d-flex gap-4 align-items-center related-line-items'>
              {' '}
              <IconLabelSet
                label={'Label'}
                icon={<Label width={13} height={14} color='#2A2E34' />}
                className='min-width-95px'
              />
              <div className='d-flex task-single-menu-value  gap-2'>
                {contextLabel && (
                  <Popover.PopoverItem
                    positionLeft={(width) => width - 200}
                    positionTop={() => -40}
                    onClose={() => setContextLabel(false)}
                    scrollRef={scrollRef}
                  >
                    <CustomLabelSelectModal
                      defaultLabelDetails={relatedTaskData?.labelDetails}
                      labelData={filteredLabelList}
                      key={1}
                      labelDataLoading={false}
                      setLabelSearch={(value) => setLabelSearch(value)}
                      handleAdd={(item) => handleLabelUpdate(item)}
                      handleRemove={(item) => handleLabelRemove(item)}
                    />
                  </Popover.PopoverItem>
                )}
                <div
                  role='button'
                  className='label-item-task-list task-single-menu-value'
                  onClick={() => setContextLabel(true)}
                  style={{
                    background: relatedTaskData?.labelDetails?.[0]?.label_color,
                    color: relatedTaskData?.labelDetails?.[0]?.text_color,
                    border: `1px solid ${relatedTaskData?.labelDetails?.[0]?.text_color}`,
                  }}
                >
                  {relatedTaskData?.labelDetails?.[0]?.name ?? 'Select...'}
                </div>{' '}
                {labelCount > 1 && (
                  <ToolTip tooltipText={moreLabel?.map((itm) => itm.name + ', \n')} isModern>
                    <div className='label-count-container'>
                      <Plus color='#87909E' size={9} />
                      {labelCount - 1}
                    </div>
                  </ToolTip>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-3 '>
          <div className='col-md-6 p-0'>
            <div className='d-flex gap-4 align-items-center related-line-items'>
              {' '}
              <IconLabelSet label={'Timeline'} icon={<Calendar size={13} />} />{' '}
              <div className='timeline-text '>
                <DatePicker
                  calenderStyle={{
                    position: 'absolute',
                    zIndex: 1000,
                    background: 'white',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    borderRadius: '8px',
                    left: 0,
                  }}
                  onDateChange={(value) => {
                    onSaveDate({
                      task_start_date: moment(value?.startDate).valueOf(),
                      task_end_date: moment(value?.endDate).valueOf(),
                    });
                  }}
                  initialStartDate={parseInt(relatedTaskData?.task_start_date)}
                  initialEndDate={parseInt(relatedTaskData?.task_end_date)}
                  showLabel
                  isEditable={true}
                  inputContainerClass={`editable-time-line cursor-pointer `}
                  monthFormat='short'
                />
              </div>
            </div>
          </div>
          <div className='col-md-6 p-0'>
            <div className='d-flex gap-4 align-items-center related-line-items'>
              {' '}
              <IconLabelSet
                label={'Start Mode'}
                className='min-width-95px'
                icon={<Zap width={13} height={16} />}
              />
              <div className='start-mode-text task-single-menu-value'>
                {`${START_MODE[relatedTaskData?.task_start_mode]} ${
                  relatedTaskData?.preceding_task_id ? relatedTaskData?.preceding_task_id : ''
                }`}
                {relatedTaskData?.preceding_task_name && (
                  <span className='related-task-name-block '>
                    {relatedTaskData?.preceding_task_name}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='description-container-related p-0 pt-4'>
          <EditableDescription
            key={2}
            isEditable={true}
            isUpdate
            handleChange={(val) => handleTaskUpdate(val)}
            data={{ note: relatedTaskData?.note }}
          >
            <div className={`task-description-dummy mb-3`}>
              <div className='d-flex align-items-center ptr ms-1 '>
                {!removeTagsAndGetContent(draftToHtml(relatedTaskData?.note)) ? (
                  <IconLabelSet
                    textClassName='mb-1'
                    label={'Add Description'}
                    iconClassName='d-flex justify-content-center align-items-center'
                    icon={<File width={14} height={16} />}
                  />
                ) : (
                  parse(draftToHtml(relatedTaskData?.note))
                )}
              </div>{' '}
            </div>
          </EditableDescription>
          {/* {removeTagsAndGetContent(draftToHtml(relatedTaskData?.note))} */}
        </div>
        <div className='py-3' hidden={!isMenuSubTaskOn || !isExpanded}>
          <div className='timeline-text mb-1'> Sub Task</div>
          <div className={relatedTaskData?.subtasks.length == 0 ? 'subtask-add-box-border' : ''}>
            <SubtaskListWithAdd
              disableNonEmptyView={true}
              headVisible={false}
              key={1}
              // textMinWidth={'19vw'}
              list={subTask}
              onAddOrUpdate={handleSubTaskAddOrUpdate}
              //   handleChange={handleChange}
              onDelete={handleSubtaskDelete}
              position={POSITION.RIGHT}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InlineRelatedTaskItemExpand;
