import React, { useState } from 'react';

function MultiUnitDurationInput({
  defaultValues = { hours: '', minutes: '' },
  containerClassName = '',
  disabled = false,
  inputKeys = ['hours', 'minutes'],
  placeholders = ['Hours', 'Mins'],
  maxValues = [undefined, 60],
  // eslint-disable-next-line no-unused-vars
  onChange = ({ minutes, hours }) => {},
}) {
  const [selectedInput, setSelectedInput] = useState({
    [inputKeys[0]]: defaultValues[inputKeys[0]],
    [inputKeys[1]]: defaultValues[inputKeys[1]],
  });

  if (
    defaultValues[inputKeys[0]] != null &&
    !isNaN(defaultValues[inputKeys[0]]) &&
    selectedInput[inputKeys[0]] !== defaultValues[inputKeys[0]]
  ) {
    setSelectedInput({
      ...selectedInput,
      [inputKeys[0]]: defaultValues[inputKeys[0]],
    });
  }
  if (
    defaultValues[inputKeys[1]] != null &&
    !isNaN(defaultValues[inputKeys[1]]) &&
    selectedInput[inputKeys[1]] !== defaultValues[inputKeys[1]]
  ) {
    setSelectedInput({
      ...selectedInput,
      [inputKeys[1]]: defaultValues[inputKeys[1]],
    });
  }

  const handleChange = (event) => {
    const { value, name } = event.target;
    setSelectedInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    onChange({ ...selectedInput, [name]: value });
  };
  return (
    <div className={`d-flex gap-2 ${containerClassName}`} style={{ background: '#fff' }}>
      <input
        type='number'
        onChange={handleChange}
        disabled={disabled}
        placeholder={placeholders[0]}
        className='w-50 number-input-template'
        name={inputKeys[0]}
        min={0}
        max={maxValues[0]}
        value={selectedInput[inputKeys[0]]}
        autoFocus
      />
      <input
        type='number'
        placeholder={placeholders[1]}
        name={inputKeys[1]}
        disabled={disabled}
        max={maxValues[1]}
        min={0}
        className='w-50 number-input-template'
        onChange={handleChange}
        value={selectedInput[inputKeys[1]]}
      />
    </div>
  );
}

export default MultiUnitDurationInput;
