import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Home, Plus, Search } from 'lucide-react';
import React, { useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUser } from '../Hook/useUser';
import { TaskAdd } from '../Task';
import { Images } from '../Utils';
import {
  AtIcon,
  Calendar,
  LoadingIcon,
  OkrIcon,
  ProgressIcon,
  ProjectIcon,
} from '../Utils/SvgIcons';
import { Modal, PaginationComponent, TableNoData, TableRow } from '../_components';
import IconLabelSet from '../_components/IconLabelSet';
import ToolTip from '../_components/Tooltip/ToolTip';
import { RT, typeConstants } from '../_constants';
import useDebounce from '../_helpers/useDebounce';
import { okrService } from '../_services';
import { EmptyOkr } from './EmptyOkr';
import OkrChildAdd from './OkrChildAdd';
import OkrObjectiveAdd from './OkrObjectiveAdd';
import OkrTableRow from './OkrTableRow';
import { okrFormAction } from './okrConstants';
import './okrstyles.css';

export function OKRList() {
  // hooks section
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const loggedUser = useUser();

  // reference section
  const reference = useRef(null);

  // useState section
  const [query, setQuery] = useState({
    select: [
      'id',
      'name',
      'status',
      'type',
      'task_start_date',
      'task_end_date',
      'visibility_team',
      'visibility_type',
      'children',
      'okr_parent_id',
      'objective_type',
      'created_time',
      'okr_progress_type',
      'manual_progress_type',
      'manual_current_progress',
      'okr_progress',
      'assignee_id',
      'progress_measure_end',
      'task_visible_users',
    ],
    searchKey: '',
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
    type: typeConstants.OBJECTIVE,
  });

  const [contextMenuIndex, setContextMenuIndex] = useState(null);
  const [isTaskSelectionPopup, setIsTaskSelectionPopup] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [addChildModal, setAddChildModal] = useState(false);
  const [addTaskModal, setAddTaskModal] = useState(false);
  const [editOkrId, setEditOkrId] = useState(null);
  const [isChildObjective, setIsChildObjective] = useState(false);
  const [parentData, setParentData] = useState({
    id: 0,
    name: '',
  });
  const [isExpand, setIsExpand] = useState(false);
  const debouncedSearch = useDebounce(query?.searchKey, 500);
  // const [isFilterVisible, setIsFilterVisible] = useState(false);

  const {
    data: okrData,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ['okrList', query?.pageVo?.pageNo, debouncedSearch],
    queryFn: () => okrService.OKRList(query),
    placeholderData: keepPreviousData,
  });

  // filter popup reference
  // const { containerRef: filterRef } = useOutsideClickHandler({
  //   onClose: () => setIsFilterVisible(false),
  // });

  // const modalClose = () => {
  //   setDeleteModal(false);
  //   setEditOkrId(null);
  // };

  // // section trigger when delete option selected

  // const handleDeactivate = async (id) => {
  //   try {
  //     await okrService.OKRDelete({ id });
  //     await queryClient.invalidateQueries('okrList');
  //     modalClose();
  //   } catch (error) {
  //     console.error('Error deleting OKR:', error);
  //   }
  // };

  // edit or delete function activation section

  // const handleClickOption = (option, okr_parent_id) => {
  //   if (option == 'edit') {
  //     if (okr_parent_id > 0) {
  //       setAddChildModal(true);
  //       return;
  //     }
  //     setAddModal(true);
  //   } else if (option == 'delete') {
  //     setDeleteModal(true);
  //   }
  // };

  // get the count of organization
  const listItemCount = okrData?.data?.rows.length;

  const okrRows = okrData?.data?.rows.map((okr, index) => {
    const slNo = (okrData?.data?.page - 1) * okrData?.data?.limit + index + 1;

    const layer = 0;
    return (
      <>
        <OkrTableRow
          key={okr?.id}
          slNo={slNo}
          layer={layer}
          data={okr}
          refetch={refetch}
          isExpand={isExpand}
          setAddChildModal={setAddChildModal}
          setIsChildObjective={setIsChildObjective}
          setParentData={setParentData}
          setAddTaskModal={setAddTaskModal}
          // addContextMenuIndex={addContextMenuIndex}
          // setAddContextMenuIndex={setAddContextMenuIndex}
          contextMenuIndex={contextMenuIndex}
          setContextMenuIndex={setContextMenuIndex}
          // isActive={contextMenuIndex === okr?.id}
          index={index}
          // contextMenuVisible={contextMenuVisible}
          // setContextMenuVisible={setContextMenuVisible}
          isTaskSelectionPopup={isTaskSelectionPopup}
          setIsTaskSelectionPopup={setIsTaskSelectionPopup}
          // editOkrId={editOkrId}
          // setEditOkrId={setEditOkrId}
          reference={reference}
          // handleClickOption={(value, okr_parent_id) => handleClickOption(value, okr_parent_id)}
        />
      </>
    );
  });

  // search box handleChange
  const handleSearch = (event) => {
    searchParams.set('pageNo', 1);
    setSearchParams(searchParams);
    let search = event.target.value;
    setQuery((prevQuery) => ({
      ...prevQuery,
      pageVo: { ...query.pageVo, pageNo: 1 },
      searchKey: search,
    }));
  };

  // const filterCount = 0;
  return (
    <div className=' fade-entry'>
      {/* breadcrumbs area  */}
      <section>
        <div className='d-flex align-items-center url-container breadcrumbs'>
          <IconLabelSet
            label={'Home'}
            fontWeight={400}
            icon={<Home size={13} />}
            onClick={() => navigate(`/`)}
          />
          <img className='slash-img-icon-size ms-1 mt-1' src='/icons/Lineicon.png' alt='' />
          <IconLabelSet
            label={'OKR'}
            fontWeight={500}
            icon={<OkrIcon height={14} width={18} />}
            color={'#2A2E34'}
            gap={3}
            onClick={() => navigate(`/${RT.OKR_LIST}`)}
          />
        </div>
      </section>

      {listItemCount === 0 && isSuccess && !debouncedSearch ? (
        <EmptyOkr refetch={refetch} />
      ) : (
        <div className='okr-table-container'>
          <div className='tab_contain '>
            <div className='d-flex gap-2'>
              <div className='search_contain'>
                <input
                  onChange={(event) => {
                    handleSearch(event);
                  }}
                  placeholder='Search...'
                  type='text'
                  className='bodr_none'
                />
                <Search size={18} color='#87909E' />
              </div>
            </div>
            <div className='d-flex gap-1 align-items-center '>
              <ToolTip tooltipText={'Add Objective'} isModern>
                <div
                  className='menu-item-outline'
                  onClick={() => {
                    setAddModal(true);
                  }}
                >
                  <Plus size={16} color='#87909E' />
                </div>
              </ToolTip>

              {/* <ToolTip tooltipText={'Filter'} isModern hideTooltip={isFilterVisible}>
                <div
                  className='menu-item-outline'
                  style={{
                    background: isFilterVisible || Boolean(filterCount) ? '#e9f2ff' : '',
                  }}
                  ref={filterRef}
                  onClick={() => {
                    setIsFilterVisible(true);
                  }}
                >
                  <ListFilter
                    size={16}
                    color={isFilterVisible || Boolean(filterCount) ? '#3454d1' : '#87909E'}
                  />
                  {isFilterVisible && (
                    <ProjectFilter
                      setSearchParams={setSearchParams}
                      handleClose={(event) => {
                        event.stopPropagation();
                        setIsFilterVisible(false);
                      }}
                      searchParams={searchParams}
                    />
                  )}

                  {Boolean(filterCount) && <div className='filter-count'>{filterCount}</div>}
                </div>
              </ToolTip> */}
            </div>
          </div>

          <section className='mt-2'>
            <div className='table-container  no-padding' style={{ maxHeight: '100vh' }}>
              <table className='table table-hover tbl mb-0'>
                <thead>
                  <tr style={{ backgroundColor: '#F3F5F7' }} className='table-head'>
                    {TABLE_HEADINGS.map((heading, index) => {
                      return (
                        <th
                          key={index}
                          scope='col'
                          style={{
                            minWidth: heading.minWidth,
                            maxWidth: '500px',
                            width: index === 0 ? '50px' : 'auto',
                          }}
                          // style={{ minWidth: heading.minWidth, maxWidth: heading.maxWidth }}
                        >
                          {heading.name}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {isLoading && (
                    <TableRow noOfCols={8}>
                      <LoadingIcon color='blue' size={60} />
                    </TableRow>
                  )}
                  {!listItemCount && isSuccess && <TableNoData noOfCols={8}>No Data</TableNoData>}
                  {listItemCount > 0 && isSuccess && okrRows}
                </tbody>
              </table>
            </div>
          </section>
          {listItemCount > 0 && (
            <section className='mt-2'>
              <PaginationComponent
                pageOnClick={(data) => {
                  setQuery((prev) => ({
                    ...prev,
                    pageVo: {
                      ...prev.pageVo,
                      pageNo: data,
                    },
                  }));
                  searchParams.set('pageNo', data);
                  setSearchParams(searchParams);
                }}
                demoData={okrData?.data}
              />
            </section>
          )}
        </div>
      )}
      {addModal && (
        <Modal.Container>
          <Modal.View className={'okr-modal-view'} containerClass={'objective-add-container'}>
            <OkrObjectiveAdd
              // id={editOkrId}
              handleClose={(value) => {
                setAddModal(false);
                setEditOkrId(null);
                if (value === okrFormAction.Save) {
                  searchParams.set('pageNo', 1);
                  setSearchParams(searchParams);
                  setQuery({
                    ...query,
                    pageVo: { ...query.pageVo, pageNo: 1 },
                  });
                  refetch();
                }
              }}
            />
          </Modal.View>
        </Modal.Container>
      )}
      {addChildModal && (
        <Modal.Container>
          <Modal.View className={'okr-modal-view'} containerClass={'objective-add-container'}>
            <OkrChildAdd
              // id={editOkrId}
              parentData={editOkrId > 0 ? {} : parentData}
              isChildObjective={isChildObjective}
              handleClose={(value) => {
                setAddChildModal(false);
                refetch();
                setEditOkrId(null);
                setIsExpand(true);
                if (value === okrFormAction.Save) {
                  refetch();
                }
              }}
            />
          </Modal.View>
        </Modal.Container>
      )}
      {addTaskModal && (
        <Modal.Container>
          <Modal.Head containerClass={'task-add-head'}></Modal.Head>
          <Modal.View className={'task-add-container'}>
            <TaskAdd
              id={0}
              okr_Parent_id={parentData?.id}
              handleClose={() => {
                refetch();

                setAddTaskModal(false);
              }}
              selectDefaultTemplateIfPresent
              assignTo={loggedUser?.id}
              defaultAssignee={loggedUser}
            />
          </Modal.View>
        </Modal.Container>
      )}
    </div>
  );
}

// OKR LIST HEADING
export const TABLE_HEADINGS = [
  { name: <img src={Images.SerialNo} height={14} width={14} />, minWidth: 50 },
  {
    name: (
      <div className='d-flex align-items-center gap-1 '>
        <ProjectIcon height={16} width={16} color='#87909E' /> Objectives & Key results
      </div>
    ),
    width: 160,
    minWidth: 300,
    maxWidth: 300,
  },
  {
    name: (
      <div className='d-flex align-items-center gap-1'>
        <ProgressIcon /> Grade
      </div>
    ),
    minWidth: 50,
  },
  {
    name: (
      <div className='d-flex align-items-center gap-1'>
        <AtIcon /> Assignee
      </div>
    ),
    minWidth: 50,
  },
  {
    name: (
      <div className='d-flex align-items-center gap-1'>
        <OkrIcon color='#87909E' iconForSideBar='#87909E' /> Type
      </div>
    ),
    minWidth: 50,
  },

  {
    name: (
      <div className='d-flex align-items-center gap-1'>
        <Calendar height={14} width={14} /> Created On
      </div>
    ),
    minWidth: 50,
  },
  {
    name: (
      <div className='d-flex align-items-center gap-1'>
        <Calendar height={14} width={14} /> Due Date
      </div>
    ),
    minWidth: 50,
  },
];
