export const QUERY_TYPE = {
  TASK_LIST: 'task_list',
  TASK_UPDATES: 'task_updates',
  COMPANY_UPDATES: 'company_updates',
  NOTIFICATION_READ: 'notification_read',
  NOTIFICATION_UNREAD: 'notification_unread',
  NOTIFICATION_ALL: 'notification_all',
  REMINDER_LIST: 'reminderList',
  ADD_TASK: 'addTask',
  ADD_REMINDER: 'addReminder',
  REMINDER_ONLY: 'reminderOnly',
  ACTIVITY: 'activity',
  EXPIRED_TASK: 'expiredTask',
  UPCOMING_MEETING: 'upComingMeeting',
  UPCOMING_TASK: 'upComingTask',
  UPCOMING_REMINDER: 'upComingReminder',
  FAILED_TASK: 'failedTask',
  NULL: null,
};

export const AI_VIEW = {
  INTRO_VIEW: 'INTRO_VIEW',
  RECORDING_VIEW:'RECORDING_VIEW'

};
