/* eslint-disable no-unused-vars */
import parse from 'html-react-parser';
import { AlarmClock, Pen, Play, Trash } from 'lucide-react';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RT } from '../_constants';
import { CONTEXT_MENU_OPTIONS, POSITION } from '../_constants/contextMenu.constants';
import { LinkOptions } from '../Task/taskValidation';
import { PlayDown } from '../Utils/SvgIcons';

function RemindersSingle({
  index,
  item,
  setIsContextVisible,
  isContentVisible,
  handleContextClick,
  SingleClassName = 'justify-content-between',
  position = POSITION.LEFT,
  isListView,
  toggleRow,
  expandedRows,
}) {
  const navigate = useNavigate();
  return (
    <div
      key={item.id}
      className={`d-flex align-items-baseline gap-1 flex-wrap subtask-view border-bottom ${SingleClassName}`}
    >
      <div className='font-13 d-flex align-items-baseline w-70 gap-1'>
        <div className='drop-arrow pointer' onClick={() => toggleRow(index)}>
          {!expandedRows[index] ? (
            <Play color='#87909E' fill='#87909E' size={10} />
          ) : (
            <PlayDown width={10} height={10} />
          )}
        </div>
        <section>
          <AlarmClock size={18} strokeWidth={1.5} />
        </section>
        <div className='col'>
          {!expandedRows[index] ? (
            <div className='d-flex'>
              <span className='reminder-name'>{item.title}</span>
              {isListView && item?.['Task.name'] && (
                <section
                  className='d-flex gap-1 reminder-task-name pointer overflow-multiline'
                  onClick={() => navigate(`/${RT.TASK}/${item?.['Task.id']}?tab=description`)}
                >
                  •<span className=' underline-hover'>{item?.['Task.name']}</span>
                </section>
              )}
            </div>
          ) : (
            <>
              <div className='overflow-multiline related-task-name'>{item?.title}</div>
              {isListView && item?.['Task.name'] && (
                <section
                  className='d-flex gap-1 pointer overflow-multiline'
                  onClick={() => navigate(`/${RT.TASK}/${item?.['Task.id']}?tab=description`)}
                >
                  •<span className=' underline-hover'>{item?.['Task.name']}</span>
                </section>
              )}
              {Boolean(item?.note) && (
                <div className='task-list-description '>
                  {parse(item?.note, LinkOptions)}
                  {/* {item?.note?.length > 140
                    ? convertToEditorState(item?.note).substring(0, 140) + '...'
                    : convertToEditorState(item?.note)} */}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <text className='d-flex'>
        {moment(parseInt(item.reminder_date)).format('DD MMM hh:mm a')}
      </text>
      <div className='position-relative d-flex gap-3'>
        <div className='d-flex gap-2 subtask-view-actions invisible'>
          <div
            className='subtask-icon-bg'
            onClick={() => handleContextClick(CONTEXT_MENU_OPTIONS.EDIT)}
          >
            <Pen size={13} />
          </div>
          <div
            className='subtask-icon-bg'
            onClick={() => {
              handleContextClick(CONTEXT_MENU_OPTIONS.DELETE);
            }}
          >
            <Trash size={13} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RemindersSingle;
