import { Plus } from 'lucide-react';
import React, { useState } from 'react';
import useOutsideClickHandler from '../Hook/useOutsideClickHandler';
import { FileSystemImage } from '../Utils';
import './FileSystem.css';
import { FileAdd } from './FileAdd';
import { FILE_TYPE } from '../_constants/filesystem.constant';
import { Modal } from '../_components';

export default function EmptyFile() {
  const [isFileAdd, setIsFileAdd] = useState(false);
  const [selectedTab, setSelectedTab] = useState(FILE_TYPE.DIGITAL);

  // filter popup reference
  const { containerRef: fileAddRef } = useOutsideClickHandler({
    onClose: () => setIsFileAdd(false),
    customClassToCheck: '#MODAL',
  });

  return (
    <div className='empty-outer'>
      <div className='icon-outer-file'>
        <img src={FileSystemImage.EmptyDocument} alt='no' height={60} width={60} />
      </div>
      <span className='empty-head'>No files found</span>
      <span className='empty-text'> There is no file in your directory</span>
      <div
        className='mt-3 empty-button '
        ref={fileAddRef}
        onClick={() => {
          setIsFileAdd(true);
        }}
      >
        <Plus size={15} />
        {'Add'}

        {/* {isFileAdd && (
          <FileAdd
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
            handleClose={(event) => {
              if(event){

                event.stopPropagation();
              }
              setIsFileAdd(false);
            }}
          /> */}
        {/* )} */}
      </div>
      {isFileAdd && (
        <Modal.Container>
          <Modal.Head containerClass={'task-add-head'}></Modal.Head>
          <Modal.View className={'task-add-container'}>
            <FileAdd
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              handleClose={(event) => {
                if (event) {
                  event.stopPropagation();
                }
                setIsFileAdd(false);
              }}
            />
          </Modal.View>
        </Modal.Container>
      )}
    </div>
  );
}
