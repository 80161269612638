import moment from 'moment';
import React from 'react';
import useDaySelectionCalc from '../../Task/RecurringTask/useDaySelectionCalc';
import { monthlyRecurType } from '../../_constants/recurrence.constants';

const CalendarDateCell = ({
  monthIndex,
  year,
  day,
  startDate,
  today,
  endDate,
  setSelectedDate,
  monthlyRecurrenceType,
  weekNumber,
  onSelect,
  isMonthly,
  daysOfMonth,
  isDaily,
  isWeekly,
  interval,
  daysOfWeek,
}) => {
  const date = moment({
    year: year,
    month: monthIndex,
    date: day,
  });

  const hookProps = {
    date,
    today,
    endDate,
    monthlyRecurType,
    isDaily,
    isMonthly,
    startDate,
    isWeekly,
    day,
    daysOfMonth,
    daysOfWeek,
    interval,
    monthlyRecurrenceType,
    weekNumber,
  };

  const { isDateBetweenStartAndEnd, isSelected, isToday, isEndDate, isStartDate } =
    useDaySelectionCalc(hookProps);

  return (
    <td
      onClick={() => {
        setSelectedDate({
          month: monthIndex,
          year: year,
          day: day,
        });

        onSelect({
          selectedValue: {
            month: monthIndex + 1,
            year: year,
            day: day,
          },
        });
        // setStatus('');
      }}
    >
      <div className={`position-relative ${day !== null ? 'day ' : ''}`}>
        <div
          className={`${isSelected && isDateBetweenStartAndEnd ? 'abs-selected ' : ''}  ${
            isToday ? 'abs-today' : ''
          } ${isStartDate ? 'abs-start-date' : ''} ${isEndDate ? 'abs-end-date ' : ''}`}
        >
          {day}
        </div>
      </div>
    </td>
  );
};

export default CalendarDateCell;
