import React from 'react';
import useOutsideClickHandler from '../../Hook/useOutsideClickHandler';
import './iconInputSelector.css';

function IconSelector({
  iconList = [],
  onIconChange,
  getIconUrl,
  extractId = () => {},
  isVisible = false,
  onClose = () => {},
}) {
  const { containerRef } = useOutsideClickHandler({ onClose: () => onClose() });

  if (!isVisible || !iconList || iconList.length <= 0) {
    return null;
  }

  return (
    <div className='icon-prev-container' ref={containerRef}>
      {iconList.map((iconObj, index) => {
        const iconUrl = getIconUrl(iconObj);
        const id = extractId(iconObj);
        return (
          <div
            className='pointer icon-prev-item'
            key={id ?? index}
            onClick={() => onIconChange(iconObj)}
          >
            <img src={iconUrl} />
          </div>
        );
      })}
    </div>
  );
}

export default IconSelector;
