import { useContext } from 'react';
import { GlobalContext } from '../../Utils';

function useAlert() {
  const { setActionAlert } = useContext(GlobalContext);

  const resetAlert = () => {
    setActionAlert({ message: null, isSuccess: true });
  };

  let alertTimeout;
  /***
   * For invoking the action alert
   * @param param
   * @param param.message - The message to be displayed on teh alert box
   * @param {"success" | "error" } param.type - Whether the alert is for success or failure
   * @param onTimesUp - The callback function invoked on timer end.
   */
  const makeActionAlert = ({
    message,
    isSuccess = true,
    time,
    onTimesUp = () => {},
    onButtonClick = () => {},
    showButton = true,
  }) => {
    setActionAlert((prev) => ({
      ...prev,
      message: message,
      isSuccess,
      onButtonClick: () => {
        resetAlert();
        onButtonClick({ timeout: alertTimeout });
      },
      showButton,
    }));
    clearTimeout(alertTimeout);
    alertTimeout = setTimeout(
      () => {
        setActionAlert({
          message: null,
          isSuccess,
        });
        onTimesUp();
      },
      time ? time * 1000 : 3000,
    );
  };

  return { makeActionAlert, resetAlert };
}

export default useAlert;
