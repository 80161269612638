import { keepPreviousData, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, { useState } from 'react';
import { Button } from '../_components';
import SearchableQuerySelect from '../_components/SearchableQuerySelect';
import { CustomDropdownIndicator } from '../_components/SelectionDropDownIcon';
import { taskService } from '../_services';
import { useUser } from '../Hook/useUser';
import { DropDownStyle } from '../OKR/okrConstants';
import ErrorBlock from '../Template/SubComponents/ErrorBlock';
import { SubTaskAddForm } from '../Template/SubComponents/SubtaskListWithAdd';

export const CalenderSubTaskAdd = ({ closeEditing = () => {}, startDate, endDate }) => {
  const current_user = useUser().id;
  const [subTaskData, setSubTaskData] = useState({ title: '' });
  const [subTaskDate, setSubTaskDate] = useState(startDate);
  const [subTaskEndDate, setSubTaskEndDate] = useState(endDate);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  console.log({ CalenderSubTaskAdd: endDate, subTaskEndDate, startDate });

  // task list of current user and under user

  const { data: taskList, isLoading: taskLoading } = useQuery({
    queryKey: ['task-list-for-calender-subtask'],
    queryFn: () =>
      taskService.taskListAdvanced({ assignee_id: current_user, select: ['id', 'name'] }),
    select: (data) => data?.data?.rows,
    placeholderData: keepPreviousData,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log({ handleChange: name, value });

    setSubTaskData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    setIsLoading(true);

    console.log({ handleSubmitEEEE: subTaskDate, subTaskEndDate });

    if (subTaskData.title.toString().trim() === '' || !subTaskData.task_id) {
      setIsLoading(false);
      return;
    }
    const subTaskPayLoad = {
      content: subTaskData.title,
      task_id: subTaskData.task_id,
      startDate: subTaskDate,
      start_date_milli: moment(subTaskDate).valueOf(),
      end_date_milli: moment(subTaskEndDate).valueOf(),
      user_id: current_user,
      created_time: moment().valueOf(),
      updated_time: moment().valueOf(),
      status: 1,
    };

    // handler for subtask
    try {
      const res = await taskService.subTaskAdd(subTaskPayLoad);
      if (res.statusCode === 200) {
        setIsSubmitted(false);
        closeEditing();
      }
    } catch (e) {
      console.log(e);
      setIsSubmitted(false);
    }
  };

  return (
    <div style={{ padding: 28 }}>
      <div className='mb-4'>
        <div className='mb-1 fz-13px-rem '>
          Select Parent Task
          <span className='field-required'> *</span>
        </div>
        <SearchableQuerySelect
          queryKey={'task-list-for-calender-subtask'}
          queryFn={taskService.taskListAdvanced}
          getFunctionParams={(searchKey) => ({
            searchKey,
            select: ['id', 'name'],
          })}
          isCustomFunction
          initialValue={taskList}
          isLoading={taskLoading}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option.id}
          components={{ DropdownIndicator: CustomDropdownIndicator }} // Use the custom dropdown indicator here
          onChange={(selectedOption) => {
            handleChange({ target: { name: 'task_id', value: selectedOption.id } });
          }}
          styles={DropDownStyle}
          placeholder='Choose Task'
          className={`select-box ${isSubmitted && !subTaskData.task_id && 'field-error'}`}
        />
        <section className='position-absolute'>
          <ErrorBlock
            errorMsg={'Selection Required'}
            hasError={isSubmitted && !subTaskData.task_id}
          />
        </section>
      </div>

      <SubTaskAddForm
        isSingleAdd={true}
        startDate={subTaskDate}
        endDate={subTaskEndDate}
        setEndDate={setSubTaskEndDate}
        setStartDate={setSubTaskDate}
        input={subTaskData.title}
        handleChange={handleChange}
        isSubmittedFromCalender={isSubmitted}
      />

      <div
        className={`mt-5 d-flex justify-content-end align-items-center gap-2 `}
        style={{ borderTop: '1px solid #87909e ', paddingTop: 10 }}
      >
        <Button.Container className='cancel-btn' isDisabled={isLoading} handleOk={closeEditing}>
          <Button.Title title='Cancel' />
        </Button.Container>
        <Button.Container type='submit' handleOk={handleSubmit} isDisabled={isLoading}>
          <Button.Title />
          <Button.Loading color='#FFF' isLoading={isLoading} size={20} />
        </Button.Container>
      </div>
    </div>
  );
};
