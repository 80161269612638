import { keepPreviousData, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import ErrorBlock from '../Template/SubComponents/ErrorBlock';
import { fileHistoryState, GlobalContext } from '../Utils';
import { FullFileIcon } from '../Utils/SvgIcons';
import { Button } from '../_components';
import { ErrorComponent } from '../_components/ErrorComponent';
import SearchableQuerySelect from '../_components/SearchableQuerySelect';
import UserDropDown from '../_components/UserDropDown';
import { USER_LIST_ACTIONS } from '../_constants';
import { DropDownStyle } from '../_constants/filesystem.constant';
import { userService } from '../_services';
import { fileHistoryService } from '../_services/fileHistory.service';

export const FileActivityAdd = ({
  fileName = '',
  id = null,
  handleClose = () => {},
  title = '',
  setIsActivityUpdated,
}) => {
  const currentTime = moment().format('HH:mm'); // Get current time in HH:mm format

  const [fileHistoryData, setFileHistoryData] = useState({
    ...fileHistoryState,
    time: currentTime,
  });

  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { makeAlert } = useContext(GlobalContext);
  const [commonError, setCommonError] = useState({ message: '' });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    setIsLoading(true);
    if (
      !fileHistoryData?.receiver_id ||
      !fileHistoryData?.sender_id ||
      !fileHistoryData?.date ||
      !fileHistoryData?.time
    ) {
      setIsLoading(false);
      return null;
    }
    const date = new Date(fileHistoryData?.date).getTime();
    let payload = {
      ...fileHistoryData,
      date: date,
      time: fileHistoryData.time.toString(),
      file_id: id,
      sender_id: parseInt(fileHistoryData?.sender_id?.id),
      receiver_id: parseInt(fileHistoryData?.receiver_id?.id),
    };

    try {
      const res = await fileHistoryService.fileHistoryAdd(payload);

      if (res.error_code === 0) {
        setIsActivityUpdated(true);
        setSubmitted(false);
        handleClose();
        makeAlert('Success');
      }
    } catch (error) {
      setCommonError({ message: error?.message });
    } finally {
      setIsLoading(false);
      setSubmitted(false);
    }
  };

  let today = moment().format('YYYY-MM-DD');
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFileHistoryData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // get all owner list
  const { data: userData, isError: userError } = useQuery({
    queryKey: ['file-activity-user-list'],
    queryFn: () =>
      userService.getPermittedUser({
        // searchKey: debouncedOwner,
        action: USER_LIST_ACTIONS.FILE_SYSTEM,
        select: ['id', 'first_name', 'last_name', 'image_url', 'employee_code'],
      }),
    select: (data) => data.data.rows,
    placeholderData: keepPreviousData,
  });

  console.log({
    time: fileHistoryData?.time,
  });

  return (
    <div
      className={`menu_popup filter-container text-start`}
      style={{
        padding: 20,
        left: 0,
        width: 330,
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.12)',
      }}
    >
      <ErrorComponent error={userError?.message || commonError?.message} />
      <form onSubmit={handleSubmit}>
        <div className='position-relative'>
          <div className='filter-head mb-2 '>
            <h6 className='menu-item-head'>{title}</h6>
          </div>
          <div className='mb-2 fz-13px-rem d-flex gap-1 '>
            <span>
              <FullFileIcon />
            </span>

            <span>{fileName}</span>
          </div>
        </div>

        <div className='project-separation ' />

        <div className='filter-body mt-2'>
          <div className='row'>
            <div className=' col-md-6'>
              <label className='mb-1 fz-13px-rem '> Date</label>
              <input
                type='date'
                className='field-input-field'
                placeholder={today}
                value={moment(fileHistoryData?.date).format('YYYY-MM-DD')}
                name='issued_date'
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>
            <div className='col-md-6'>
              <label className='mb-1 fz-13px-rem '>Time</label>
              <input
                type='time'
                className='field-input-field'
                id='time'
                name='time'
                // placeholder={currentTime} // Set the placeholder to the current time
                onChange={handleChange}
                value={fileHistoryData?.time}
              />
            </div>
          </div>

          <div className='mb-2'>
            <div className='my-1 fz-13px-rem '>
              Who ‘s Giving
              <span className='field-required'> *</span>
            </div>
            <SearchableQuerySelect
              queryKey={'file-activity-user-list'}
              queryFn={userService.getPermittedUser}
              getFunctionParams={(searchKey) => ({
                searchKey,
                action: USER_LIST_ACTIONS.FILE_SYSTEM,
                select: ['id', 'first_name', 'last_name', 'image_url', 'employee_code'],
              })}
              isCustomFunction
              initialValue={userData}
              formatOptionLabel={(data, metaData) => (
                <UserDropDown data={data} metaData={metaData} hideDesignation={true} />
              )}
              menuPlacement='top'
              getOptionValue={(option) => option.id}
              value={fileHistoryData?.sender_id}
              onChange={(selectedOptions) => {
                handleChange({
                  target: {
                    name: 'sender_id',
                    value: selectedOptions,
                  },
                });
              }}
              styles={DropDownStyle}
              placeholder='Choose'
              className={`select-box ${submitted && !fileHistoryData?.sender_id && 'has-error'} `}
            />
            <section className='position-absolute'>
              <ErrorBlock
                errorMsg={'Selection Required'}
                hasError={submitted && !fileHistoryData?.sender_id}
              />
            </section>
          </div>
          <div className='mb-2'>
            <div className='my-1 fz-13px-rem '>
              Who ‘s Taking
              <span className='field-required'> *</span>
            </div>

            <SearchableQuerySelect
              queryKey={'file-activity-user-list'}
              queryFn={userService.getPermittedUser}
              getFunctionParams={(searchKey) => ({
                searchKey,
                action: USER_LIST_ACTIONS.FILE_SYSTEM,
                select: ['id', 'first_name', 'last_name', 'image_url', 'employee_code'],
              })}
              isCustomFunction
              initialValue={userData}
              formatOptionLabel={(data, metaData) => (
                <UserDropDown data={data} metaData={metaData} hideDesignation={true} />
              )}
              menuPlacement='top'
              getOptionValue={(option) => option.id}
              value={fileHistoryData?.receiver_id}
              onChange={(selectedOptions) => {
                handleChange({
                  target: {
                    name: 'receiver_id',
                    value: selectedOptions,
                  },
                });
              }}
              styles={DropDownStyle}
              //   isDisabled={templateSelectionDisabled}
              placeholder='Choose'
              className={` select-box 
                ${submitted && !fileHistoryData?.receiver_id && 'has-error'}
               `}
            />
            <section className='position-absolute'>
              <ErrorBlock
                errorMsg={'Selection Required'}
                hasError={submitted && !fileHistoryData?.receiver_id}
              />
            </section>
          </div>
        </div>

        {/* button section  */}

        <div className='row mt-3'>
          <div className='d-flex justify-content-end gap-2'>
            <Button.Container
              className={'outline-cancel-button'}
              type='button'
              handleOk={(event) => {
                handleClose(event);
              }}
            >
              <Button.Title title={'Cancel'} />
            </Button.Container>

            <Button.Container type='submit' className='blue-btn' isDisabled={isLoading}>
              <Button.Title title={'Check-in'} />
              <Button.Loading isLoading={isLoading} />
            </Button.Container>
          </div>
        </div>
      </form>
    </div>
  );
};
