/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import { authService } from '../_services';
import { useUser } from './useUser';

// Define your custom hook
export function useWhoAmI() {
  const user = useUser();
  // user get
  const {
    data,
    isLoading: profileLoading,
    refetch,
  } = useQuery({
    queryKey: ['whoami'],
    queryFn: () => authService.whoAmI(),
    enabled: user?.id ? true : false,
    select: (userData) => {
      return userData?.data[0] || {};
    },
  });

  return { data, profileLoading, refetch };
}
