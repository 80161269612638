/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Header } from './Header';
import { ProtectedRoute } from './PrivateRoute';
import { SideBar } from './SideBar';

export function RootLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      <ProtectedRoute>
        <div className='global_container'>
          {sidebarOpen && (
            <div className='overlay-container' onClick={toggleSidebar}>
              {/* This could be an overlay or anything else */}
            </div>
          )}
          <div className='side_bar_outlet_contain'>
            <SideBar isOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
            <div className='outlet_contain'>
              <Header />
              {/* DON"T CHANGE 'page-content-section' class, which is used in PopOverItem. */}
              <div className='page-content-height page-content-section '>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </ProtectedRoute>
    </>
  );
}
