/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Button } from '../_components';
import { logout } from '../_helpers';
import { authService } from '../_services';

const ChangePasswordModal = ({ handleClose = () => {} }) => {
  const initialState = {
    current_password: '',
    password: '',
    confirm_password: '',
  };
  const [password, setPassword] = useState(initialState);
  const [error, setError] = useState();
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPassword({
      ...password,
      [name]: value,
    });
  };

  const updatePassword = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (
      password.current_password.toString().trim() == '' ||
      password.password.toString().trim() == '' ||
      password.confirm_password.toString().trim() == ''
    ) {
      return;
    }
    if (password.password.toString().trim() !== password.confirm_password.toString().trim()) {
      setError('Confirm Passwords does not match');
      return;
    }
    setTimeout(() => {
      setError('');
    }, 3000);
    try {
      const reponse = await authService.changePassword(password);
      setPassword(initialState);
      handleClose();
      logout();
    } catch (error) {
      console.log({ error });
      if (error.message) {
        setError(error.message);
      }
    } finally {
      setSubmitted(false);
    }
  };

  return (
    <div>
      <div className='px-4  py-3' style={{ fontSize: '13px' }}>
        <form action='' className='d-flex flex-column gap-2' onSubmit={updatePassword}>
          <div className='row mb-2'>
            <div className='form-group'>
              <label htmlFor='current_password'>
                Current Password<span style={{ color: 'red' }}> *</span>
              </label>
              <input
                style={{ fontSize: '13px' }}
                type='text'
                className={`form-control mt-2
                  ${
                    submitted && password?.current_password.toString().trim() == ''
                      ? 'has-error'
                      : ''
                  }
                `}
                id='current_password'
                name='current_password'
                placeholder='Current Password'
                onChange={handleChange}
              />
            </div>
          </div>

          <div className='row mb-2'>
            <div className='form-group'>
              <label htmlFor='password'>
                New Password<span style={{ color: 'red' }}> *</span>
              </label>
              <input
                style={{ fontSize: '13px' }}
                type='password'
                className={`form-control mt-2
                    ${submitted && password?.password.toString().trim() == '' ? 'has-error' : ''}
                  `}
                id='password'
                name='password'
                placeholder='New Password'
                onChange={handleChange}
              />
            </div>
          </div>

          <div className='row mb-2'>
            <div className='form-group'>
              <label htmlFor='confirm_password'>
                Confirm Password<span style={{ color: 'red' }}> *</span>
              </label>
              <input
                style={{ fontSize: '13px' }}
                type='password'
                className={`form-control mt-2
                    ${
                      submitted && password?.confirm_password.toString().trim() == ''
                        ? 'has-error'
                        : ''
                    }
                  `}
                id='confirm_password'
                name='confirm_password'
                placeholder='Confirm Password'
                onChange={handleChange}
              />
            </div>
            <div className='d-flex justify-content-center error-txt'>{error}</div>{' '}
          </div>

          <div className='row '>
            <div className=' d-flex justify-content-end gap-2'>
              <Button.Container
                className={'cancel_btn'}
                type='reset'
                handleOk={() => {
                  handleClose();
                }}
              >
                <Button.Title title={'Cancel'} />
              </Button.Container>

              <Button.Container type='submit'>
                <Button.Title title={'Update '} />
                <Button.Loading />
              </Button.Container>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePasswordModal;
