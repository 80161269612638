/* eslint-disable no-unused-vars */
import {
  Calendar,
  CaseSensitive,
  ChevronDown,
  ChevronUp,
  Clock3,
  CloudUpload,
  Plus,
} from 'lucide-react';
import React from 'react';
import { CustomFileUpload, ToggleButton } from '../_components';

export const ColorPicker = ({ heading, handleChange, id, value, onBlur }) => {
  return (
    <>
      <Heading heading={heading} />
      <div className='color_head'>
        <input
          onMouseLeave={onBlur}
          onChangeCapture={handleChange}
          type='color'
          className='color_picker'
          value={value}
        />
      </div>
    </>
  );
};

export const FileUpload = ({ heading, handleOk, accept, id, value }) => {
  return (
    <>
      <Heading heading={heading} />
      <CustomFileUpload
        name={id}
        accept={accept}
        handleOK={handleOk}
        CustomView={
          <div>
            <label htmlFor='upload'>
              <div className='upload_contain'>
                <CloudUpload size={17} color='black' />
                <div className='upload_txt'>{value ? 'Change' : 'Upload'}</div>
              </div>
            </label>
          </div>
        }
      />
    </>
  );
};

export const Container = ({ children }) => {
  return <div className='sub_container'>{children}</div>;
};

export const Heading = ({ heading, className }) => {
  const headStyle = className ? className : 'general_head';
  return <div className={headStyle}>{heading}</div>;
};

export const ToggleWithHeading = ({
  className,
  heading,
  isChecked,
  handleToggle,
  id,
  updateOnBlur,
  headStyle,
}) => {
  const toggleStyle = className ? className : 'toggle-container';

  return (
    <div className={toggleStyle}>
      <Heading className={headStyle} heading={heading} />
      <ToggleButton
        updateOnBlur={updateOnBlur}
        key={id}
        id={id}
        isChecked={isChecked}
        handleToggle={handleToggle}
        size={35}
      />
    </div>
  );
};

export const CheckBoxWithHeading = ({ updateOnBlur, heading, isChecked, handleCheckBox, id }) => {
  return (
    <div className='menu_selection_contain'>
      <Heading heading={heading} />
      <input
        onBlur={updateOnBlur}
        className='menu_check_box'
        type='checkbox'
        checked={isChecked}
        onChange={handleCheckBox}
      />
    </div>
  );
};

export const Number = () => {
  return <div className='number'>123</div>;
};

export const FieldTypes = [
  { icon: <CaseSensitive />, name: 'Text', type: 'text' },
  { icon: <Calendar />, name: 'Date', type: 'date' },
  { icon: <Clock3 />, name: 'Time', type: 'time' },
  { icon: <Number />, name: 'Number', type: 'number' },
];

export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 25,
    boxShadow: 'none',
    minHeight: 32,
    fontSize: 13,
  }),
  option: (style) => ({
    ...style,
    fontSize: 13,
  }),
};

export function AddNewView({ head, handleOK }) {
  const headName = head ? head : 'Add New';
  return (
    <div className='add-new' onClick={handleOK}>
      <Plus size={15} />
      <div style={{ fontSize: 13, fontWeight: '500' }}>{headName}</div>
    </div>
  );
}

export const HeadWithArrow = ({ head, isDown, handler }) => {
  let arrow = isDown ? <ChevronDown size={25} /> : <ChevronUp size={25} />;
  return (
    <div className='head-contain'>
      <div className='head-txt'>{head}</div>
      <div className='arow' onClick={handler}>
        {arrow}
      </div>
    </div>
  );
};

export const StatusCard = ({ LABEL, color, defaultName, is_default }) => {
  const roundColor = color ? color : '#d1d1d1';

  return (
    <div className='status-wrapper'>
      <div style={{ background: roundColor }} className='round'></div>
      <div className='status-txt'>
        {LABEL} {`${is_default ? `(${defaultName})` : ''}`}
      </div>
    </div>
  );
};
