import { apiConstants } from '../_constants';
import { authHeader, handleResponse } from '../_helpers';
import { jsonToQueryString } from '../_helpers/utilFunctions';

export const fileHistoryService = {
  fileHistoryAdd,
  fileHistoryList,
  fileHistoryGet,
  fileHistoryDeactivate,
};

async function fileHistoryList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(apiConstants.apiUrl + '/file_history_list', requestOptions).then(handleResponse);
}

function fileHistoryGet(data) {
  const params = jsonToQueryString(data);
  const apiUrl = `${apiConstants.apiUrl}/get_file_history?${params}`;
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}

function fileHistoryAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/file_history_add', requestOptions).then(handleResponse);
}

async function fileHistoryDeactivate(data) {
  const apiUrl = `${apiConstants.apiUrl}/file_history_deactivate`;
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(apiUrl, requestOptions).then(handleResponse);
}
