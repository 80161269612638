/* eslint-disable no-unused-vars */
import { UserRoundCheck, UserRoundX } from 'lucide-react';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { formatString, isActive } from '../Utils';
import IconLabelSet from '../_components/IconLabelSet';

export function MyTeamTabs() {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <section className='task_tabs'>
      <div className='task_tab_contain '>
        <div
          className={` ${isActive('Active Users') ? 'tab_active' : 'tab_inactive'}`}
          onClick={() => setSearchParams({ status: formatString('Active Users'), pageNo: 1 })}
        >
          <IconLabelSet
            label={'Active Users'}
            color={`${isActive('Active Users') ? 'black' : 'grey'}`}
            icon={<UserRoundCheck size={16} />}
          />
        </div>
        <div
          className={`${isActive('Removed Users') ? 'tab_active' : 'tab_inactive'}`}
          onClick={() => setSearchParams({ status: formatString('Removed Users'), pageNo: 1 })}
        >
          {' '}
          <IconLabelSet
            label={' Removed Users'}
            color={`${isActive('Removed Users') ? 'black' : 'grey'}`}
            icon={<UserRoundX size={16} />}
          />
        </div>
      </div>
    </section>
  );
}
