/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import parse from 'html-react-parser';
import { Play } from 'lucide-react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import StackedAvatars from '../../_components/Avatar/StackedAvatars';
import { RT } from '../../_constants';
import { POSITION } from '../../_constants/contextMenu.constants';
import { START_MODES, TASK_TYPES } from '../../_constants/template.constants';
import { taskService } from '../../_services';
import { GlobalContext, taskState } from '../../Utils';
import { CustomCircleLoader, PlayDown } from '../../Utils/SvgIcons';
import { customHourMinuteFormat, LinkOptions } from '../taskValidation';
import './relatedtask.css';
import TaskSingleRelatedTaskAdd from './TaskSingleRelatedTaskAdd';

function RelatedTaskList({ taskData, tableContainerRef, makeAlert }) {
  const {
    data: relatedTaskData,
    isLoading: relatedTaskLoading,
    refetch: relatedTaskRefetch,
  } = useQuery({
    queryKey: ['related-task-data', taskData?.id],
    queryFn: () => {
      return taskService.getRelatedTasks({
        id: taskData?.id,
        parent_id: taskData?.parent_id,
        select: [
          'id',
          'name',
          'note',
          'expected_hours',
          'assignee_id',
          'task_start_mode',
          'parent_id',
          'priority',
          'task_type',
        ],
      });
    },
    select: (data) => {
      return data?.data;
    },
  });
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const [hasScrollbar, setHasScrollbar] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [task, setTask] = useState(taskState);

  useEffect(() => {
    const checkScrollbar = () => {
      if (tableContainerRef.current) {
        setHasScrollbar(
          tableContainerRef.current.scrollHeight > tableContainerRef.current.clientHeight,
        );
      }
    };

    checkScrollbar();

    window.addEventListener('resize', checkScrollbar);
    return () => {
      window.removeEventListener('resize', checkScrollbar);
    };
  }, [tableContainerRef.current]);
  //   const [expandedRows, setExpandedRows] = useState(
  //     new Array(taskData?.data?.rows.length).fill(false),
  //   );

  const [expandedRows, setExpandedRows] = useState(new Array(relatedTaskData?.length).fill(false));

  // to toggle open or close single list item
  const toggleRow = (index) => {
    const newExpandedRows = [...expandedRows];
    newExpandedRows[index] = !newExpandedRows[index];
    setExpandedRows(newExpandedRows);
  };

  const { globalSettings } = useContext(GlobalContext);

  const priorityLabels =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings.name === 'priority-label')?.labels;

  const getPriorityName = (priorityId) => {
    return priorityLabels && priorityLabels[priorityId]?.text;
  };

  if (relatedTaskLoading) {
    return (
      <div className=' d-flex justify-content-center p-2'>
        <CustomCircleLoader />
      </div>
    );
  }

  return (
    <div className='related-task-wrapper'>
      <div className='related-task-container'>
        <div className='related-task-body'>
          {relatedTaskData?.length > 0 &&
            relatedTaskData?.map((task, index) => {
              const priorityName = getPriorityName(task?.priority);
              const expectedHour = customHourMinuteFormat(task?.expected_hours);
              return (
                <div className='d-flex related-task-item-container gap-2' key={index}>
                  <div className='drop-arrow ' onClick={() => toggleRow(index)}>
                    {!expandedRows[index] ? (
                      <Play color='#87909E' fill='#87909E' size={10} />
                    ) : (
                      <PlayDown width={10} height={10} />
                    )}
                  </div>
                  <div className='col'>
                    <div
                      className='pointer'
                      onClick={() =>
                        navigate(
                          `/${RT.TASK}/${task?.id}?tab=related-task&&${params}`,
                        )
                      }
                      style={{ width: 220 }}
                    >
                      {!expandedRows[index] ? (
                        <div className='d-flex align-items-center gap-4'>
                          <div className='overflow-multiline related-task-name  underline-hover'>
                            {task?.name}
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className='overflow-multiline related-task-name'>{task?.name}</div>
                          {Boolean(task?.note) && (
                            <div className='task-list-description '>
                              {parse(task?.note, LinkOptions)}

                              {/* {task?.note?.length > 140
                              ? convertToEditorState(task?.note).substring(0, 140) + '...'
                              : convertToEditorState(task?.note)} */}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className='col-md-1'>
                    <StackedAvatars
                      justifyPosition={' '}
                      getSourceByKey={(task) => task?.image_url}
                      getNameByKey={(task) => task?.first_name}
                      getGenderByKey={(task) => task?.gender === 1}
                      imageList={[task?.User]}
                      showInitials={false}
                      size={28}
                      tooltipPosition={POSITION.LEFT}
                    />
                  </div>
                  <div className='col-md-2'>
                    {priorityName && <span className='priority-label'>{priorityName}</span>}
                  </div>
                  {task?.task_type != TASK_TYPES.NON_TIME_BOUND_TASK && (
                    <div className='col-md-2'>
                      <span className='related-task-item-text'>{expectedHour}</span>
                    </div>
                  )}
                  <div className='col-md-3'>
                    <span className='related-task-item-text'>
                      {START_MODES[task?.task_start_mode]}
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <TaskSingleRelatedTaskAdd
        taskData={taskData}
        refetch={() => relatedTaskRefetch()}
        setIsTyping={setIsTyping}
        referenceTable={tableContainerRef}
        isList={true}
      />
    </div>
  );
}

export default RelatedTaskList;
