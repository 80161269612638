import React from 'react';
import { useNavigate } from 'react-router-dom';

function ChatUpdateListWrapper({ children, isEmpty, className = '' }) {
  const navigate = useNavigate();
  if (isEmpty) {
    return (
      <>
        <div
          onClick={() => {
            navigate('/chat?tab=all');
          }}
          className='update-padding grey-text text-center fz-13px-rem d-flex align-items-center justify-content-center h-100'
        >
          No result
        </div>
      </>
    );
  }

  return <div className={`list_condainer ${className}`}>{children}</div>;
}

export default ChatUpdateListWrapper;
