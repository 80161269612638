import { useEffect, useRef } from 'react';

/***
 * For closing a specific component on clicking outside
 * @param props
 * @param props.onClose - Function will be called on clicked outside
 * @param {any[]} props.dependencies - If click depends on any other properties pass it as array
 * @param {boolean} props.bubbling
 * @param {string} props.customClassToCheck - In some cases, may need to keep the component open on some clicking specific part(which is outside the component like react portal etc).
 * In that cases pass the class in to this function in the querySelector format.
 * @returns {{containerRef:{current:object}}} - Returns a reference, which can be used in the container of the pop-up
 */
function useOutsideClickHandler({
  onClose = () => {},
  customClassToCheck = null,
  dependencies = [],
  bubbling = true,
}) {
  const containerRef = useRef(null);

  useEffect(() => {
    const handleClose = (event) => {
      const modalElement = document.querySelector(customClassToCheck);

      const isConditionValid = customClassToCheck ? !modalElement?.contains(event.target) : true;

      if (
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        isConditionValid
      ) {
        onClose();
      }
    };

    window.addEventListener('click', handleClose, bubbling);
    return () => {
      window.removeEventListener('click', handleClose, bubbling);
    };
  }, [containerRef.current, ...dependencies]);

  return { containerRef };
}

export default useOutsideClickHandler;
