// HorizontalScrollMenu.js

import { Plus } from 'lucide-react';
import React from 'react';

const HorizontalScrollMenu = ({ items }) => {
  const itemsLength = items?.length || 0;
  const sliceLabel = itemsLength > 2 ? items?.slice(0, 2) : items;
  return (
    <div className='horizontal-scroll-menu gap-1'>
      {sliceLabel.map((item, index) => (
        <div
          key={index}
          className='label-item-task-list'
          style={{
            background: item?.label_color,
            color: item?.text_color,
          }}
        >
          {item.name} {/* Display the name property of the label object */}
        </div>
      ))}
      {itemsLength > 2 && (
        <div className='extra-values'>
          <Plus size={11} />
          {itemsLength - 2}
        </div>
      )}
    </div>
  );
};

export default HorizontalScrollMenu;
