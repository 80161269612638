import { useEffect, useRef } from 'react';

/***
 * For observing a element. If element comes into view, the onIntersection function will trigger once.
 * @param props
 * @param props.onIntersection - This callback function will be trigger when the view comes into view
 * @param props.onWatch - This function will on scroll, even if not in the view
 * @param {any[]} props.dependency  -If any dependency, add it as array
 * @param props.customRef - If need to override the ref created from the hook, pass it, then the passed ref will be taken
 * @returns {{observerRef:React.MutableRefObject}} - A react ref which can be used in any element
 */
function useObserver({
  onIntersection = () => {},
  onWatch = () => {},
  dependency = [],
  customRef = null,
}) {
  const observerRef = useRef(null);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0.3,
    };

    const handleObserver = (entries) => {
      const target = entries[0];
      onWatch();
      if (target.isIntersecting) {
        onIntersection();
      }
    };

    const requiredRef = customRef ? customRef : observerRef.current;

    const observer = new IntersectionObserver(handleObserver, option);

    if (requiredRef) {
      observer.observe(requiredRef);
    }

    return () => {
      if (requiredRef) {
        observer.unobserve(requiredRef);
      }
    };
  }, [customRef ? customRef : observerRef.current, ...dependency]);

  return { observerRef };
}

export default useObserver;
