/* eslint-disable no-unused-vars */
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';
import { ChevronDown, Eye, FileIcon } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ReactSelect from 'react-select';
import { EditableDescription } from '../Task/TaskComponents';
import { CustomVariableInput } from '../Task/TaskComponents/CustomInputs';
import { removeTagsAndGetContent } from '../Task/taskValidation';
import ErrorBlock from '../Template/SubComponents/ErrorBlock';
import {
  convertHtmlToRawContent,
  GlobalContext,
  OkrObjectiveState,
  percentageOfManualOkr,
  userInitialLetter,
} from '../Utils';
import { Button, ContextMenu } from '../_components';
import IconLabelSet from '../_components/IconLabelSet';
import SearchableQuerySelect from '../_components/SearchableQuerySelect';
import UserDropDown from '../_components/UserDropDown';
import { actionConstants, alertConstants, typeConstants, USER_LIST_ACTIONS } from '../_constants';
import { okrService, userService } from '../_services';
import { teamService } from '../_services/team.service';
import {
  DropDownStyle,
  manualProgressType,
  measurementOptions,
  objectiveType,
  objectiveVisibility,
  okrFormAction,
  okrValidationConstant,
  progressType,
  timeIntervalOptions,
} from './okrConstants';

import { TEAM_LIST_BASED_ON_USER } from '../Team/team.constants';
import { CustomDropdownIndicator } from '../_components/SelectionDropDownIcon';
import { POSITION } from '../_constants/contextMenu.constants';
import { okrObjectiveSchema } from './okrValidation';

export default function OkrObjectiveAdd({ id, handleClose = () => {} }) {
  const { makeAlert } = useContext(GlobalContext);
  const [scrollFlag, setScrollFlag] = useState({ isTopReached: true, isBottomReached: false });
  const [ContextMenuVisible, setContextMenuVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState({ message: '' });
  const [okrData, setOkrData] = useState(OkrObjectiveState);
  const [query, setQuery] = useState({
    select: [
      'id',
      'assignee_id',
      'name',
      'note',
      'interval',
      'task_start_date',
      'task_end_date',
      'visibility_type',
      'visibility_team',
      'okr_progress_type',
      'manual_progress_type',
      'progress_measure_start',
      'progress_measure_end',
      'okr_progress',
      'status',
      'type',
      'children',
      'manual_current_progress',
      'objective_type',
    ],
  });

  useEffect(() => {
    if (id && id != '0') {
      query.id = id;
      getOKR(query);
    }
  }, [id]);

  // Fetch user list based on objective type
  const { data: userData, isLoading: assigneeListLoading } = useQuery({
    queryKey: [
      'okr-assignee-list',
      okrData?.objective_type === objectiveType.team && okrData?.visibility_team.length !== 0
        ? okrData?.visibility_team
        : null,
    ],
    queryFn: () =>
      userService.getPermittedUser({
        searchKey: '',
        action: USER_LIST_ACTIONS.TASK_ADD,
        select: ['id', 'first_name', 'last_name', 'image_url'],
        ...(okrData?.objective_type === objectiveType.team &&
          okrData?.visibility_team.length !== 0 && {
            team_Ids: [okrData?.visibility_team[0]?.id],
            action: actionConstants.OKRADD,
          }),
      }),
    // enabled: okrData?.objective_type === objectiveType.team,
    select: (data) => data.data.rows,
    placeholderData: keepPreviousData,
  });

  // team list
  const { data: teamData, isLoading: isTeamLoading } = useQuery({
    queryKey: ['shared-team-list'],
    queryFn: () =>
      teamService.teamList({
        select: ['id', 'name'],
        action: TEAM_LIST_BASED_ON_USER.OKR_ADD,
      }),
    select: (data) => data.data.rows,
  });

  const getOKR = async (queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    setQuery(queryNew);
    setIsLoading(true);
    try {
      const okr = await okrService.getOKR(queryNew);
      if (okr?.data) {
        setOkrData({
          ...okr?.data,
          note: convertHtmlToRawContent(okr?.data.note),
          assignee_id: okr?.data?.User,
          task_end_date: parseInt(okr?.data?.task_end_date),
          task_start_date: parseInt(okr?.data?.task_start_date),
          visibility_team: okr?.data?.teams ? okr?.data?.teams?.map((team) => team) : [],
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleChange = (event) => {
    const { name, value, type } = event.target;

    if (name == 'objective_type') {
      setOkrData({
        ...okrData,
        visibility_team: [],
        assignee_id: '',
        [name]: value,
        visibility_type: value === objectiveType.company ? objectiveVisibility.All : 0,
      });
      return;
    }

    if (name == 'interval' && value != 0) {
      if (value != 3) {
        setOkrData((prev) => ({
          ...prev,
          [name]: value,
          task_end_date: moment(okrData.task_start_date)
            .add(value * 3, 'months')
            .endOf('day')
            .valueOf(),
        }));
        return;
      }
      setOkrData((prev) => ({
        ...prev,
        [name]: value,
        task_end_date: moment(okrData.task_start_date).add(1, 'year').endOf('day').valueOf(),
      }));
      return;
    }
    if (name == 'interval' && value == 0 && !id) {
      setOkrData((prev) => ({
        ...prev,
        [name]: value,
        task_start_date: moment().valueOf(), // Current date in 'YYYY-MM-DD' format
        task_end_date: moment().endOf('day').valueOf(), // End of today in timestamp format
      }));
      return;
    }

    if (type === 'team_based') {
      setOkrData((prev) => ({
        ...prev,
        assignee_id: '',
        [name]: value,
      }));
      return;
    }

    if (type === 'date') {
      if (name == 'task_start_date' && okrData.interval != 0) {
        if (okrData.interval != 3) {
          setOkrData((prev) => ({
            ...prev,
            [name]: moment(value).valueOf(),
            task_end_date: moment(value)
              .add(okrData.interval * 3, 'months')
              .endOf('day')
              .valueOf(),
          }));
          return;
        }
        setOkrData((prev) => ({
          ...prev,
          [name]: moment(value).valueOf(),
          task_end_date: moment(value).add(1, 'year').endOf('day').valueOf(),
        }));

        return;
      }
      setOkrData({
        ...okrData,
        [name]: moment(value).endOf('day').valueOf(), // Adds end of the day
      });

      return;
    }

    if (name == 'manual_progress_type') {
      if (parseInt(value) === manualProgressType.binary) {
        setOkrData({
          ...okrData,
          [name]: parseInt(value),
          progress_measure_start: 0,
          progress_measure_end: 100,
          manual_current_progress: 0,
        });
        return;
      }
      setOkrData({
        ...okrData,
        [name]: parseInt(value),
        progress_measure_start: 0,
        progress_measure_end: 0,
        manual_current_progress: 0,
      });
      return;
    }
    if (name == 'okr_progress_type') {
      setOkrData({
        ...okrData,
        [name]: value,
        manual_progress_type:
          parseInt(value) === progressType.manually ? manualProgressType.percentage : 0,
        progress_measure_start: 0,
        progress_measure_end: 0,
        okr_progress: 0,
        manual_current_progress: 0,
      });
      return;
    }

    setOkrData({
      ...okrData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    setIsLoading(true);
    let currentProgress = okrData?.manual_current_progress;
    let finalizedProgress = okrData?.okr_progress;
    if (id) {
      if (parseInt(okrData?.okr_progress_type) === progressType.basedOnChild) {
        finalizedProgress = okrData?.okr_progress;
      } else if (parseInt(okrData?.okr_progress_type) === progressType.manually) {
        if (parseInt(okrData?.manual_progress_type) === manualProgressType.binary) {
          finalizedProgress = okrData?.manual_current_progress;
        } else {
          finalizedProgress = percentageOfManualOkr(
            okrData?.progress_measure_start,
            okrData?.progress_measure_end,
            currentProgress,
          );
        }
      }
    } else {
      if (parseInt(okrData?.okr_progress_type) === progressType.basedOnChild) {
        finalizedProgress = 100;
      } else if (parseInt(okrData?.okr_progress_type) === progressType.manually) {
        finalizedProgress = 0;
        currentProgress = okrData?.progress_measure_start;
      }
    }
    const payload = {
      ...okrData,
      note: draftToHtml(okrData.note),
      type: typeConstants.OBJECTIVE,
      assignee_id: okrData?.assignee_id?.id,
      manual_current_progress: currentProgress,
      okr_progress: finalizedProgress,
      visibility_team: okrData?.visibility_team?.map((item) => parseInt(item?.id)),
    };
    const validationResult = okrObjectiveSchema.safeParse(payload);

    if (!validationResult.success) {
      setIsLoading(false);
      makeAlert('Incorrect Data');
      return;
    }
    try {
      await okrService.OKRAdd(payload).then(() => {
        setSubmitted(false);
        setOkrData(OkrObjectiveState);
      });
      handleClose(okrFormAction.Save);
    } catch (error) {
      setError({
        message: error?.message || alertConstants.SERVER_ERROR,
      });
    } finally {
      setIsLoading(false);
      setSubmitted(false);
    }
  };

  const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;

    if (scrollTop === 0) {
      setScrollFlag({ isTopReached: true });
    } else if (scrollHeight - scrollTop <= clientHeight + 5) {
      setScrollFlag({ isBottomReached: true });
    } else {
      setScrollFlag({ isBottomReached: false, isTopReached: false });
    }
  };

  // okr new

  const typeOptions = [
    { label: 'Company', value: objectiveType.company },
    { label: 'Team', value: objectiveType.team },
    { label: 'Personal', value: objectiveType.personal },
  ];

  return (
    <div>
      <div
        className='objective-add-head '
        style={{
          boxShadow: scrollFlag.isTopReached ? '' : '0 6px 5px -5px #091e4229',
        }}
      >
        <h6 className='okr-popup-head'>{id ? 'Edit' : 'Create'} Objective</h6>
      </div>
      <form onSubmit={handleSubmit}>
        <div className='okr-modal-content fz-13px-rem ' onScroll={handleScroll}>
          <div className='d-flex row' style={{ flexWrap: 'wrap' }}>
            <div className='col-md-6'>
              <label className='form-input-label'>Type</label>

              <ReactSelect
                styles={DropDownStyle}
                placeholder='Select'
                options={typeOptions}
                getOptionValue={(option) => {
                  return option?.value;
                }}
                getOptionLabel={(option) => {
                  return option?.label;
                }}
                formatOptionLabel={(option) => {
                  return (
                    <div className='d-flex'>
                      <div className='Letter_bg me-2'>{userInitialLetter(option?.label)}</div>
                      <span>{option?.label}</span>
                    </div>
                  );
                }}
                onChange={(selectedOption) => {
                  handleChange({
                    target: {
                      name: 'objective_type',
                      value: selectedOption.value,
                    },
                  });
                }}
                value={typeOptions?.find(
                  (option) => option.value == parseInt(okrData?.objective_type),
                )}
                components={{ DropdownIndicator: CustomDropdownIndicator }} // Use the custom dropdown indicator here
              />
            </div>
            {parseInt(okrData?.objective_type) === objectiveType.team && (
              <div className='col-md-6 mb-2  pl-0'>
                <label className='form-input-label'> Team</label>

                <SearchableQuerySelect
                  queryKey={'okr-team-list'}
                  queryFn={teamService.teamList}
                  getFunctionParams={(searchKey) => ({
                    searchKey,
                    select: ['id', 'name'],
                    action: TEAM_LIST_BASED_ON_USER.OKR_ADD,
                  })}
                  isCustomFunction
                  isLoading={isTeamLoading}
                  initialValue={teamData}
                  getOptionLabel={(option) => option?.name}
                  getOptionValue={(option) => option.id}
                  value={okrData?.visibility_team}
                  onChange={(selectedOption) => {
                    handleChange({
                      target: {
                        name: 'visibility_team',
                        value: [selectedOption],
                        type: 'team_based',
                      },
                    });
                  }}
                  styles={DropDownStyle}
                  components={{ DropdownIndicator: CustomDropdownIndicator }} // Use the custom dropdown indicator here
                  placeholder='Select Team '
                  className={` select-box 
                ${
                  submitted &&
                  okrData?.objective_type === objectiveType.team &&
                  okrData?.visibility_team?.length === 0 &&
                  'field-error'
                }                
               `}
                />

                <ErrorBlock
                  errorMsg={'Team selection Required'}
                  hasError={
                    submitted &&
                    okrData?.objective_type === objectiveType.team &&
                    okrData?.visibility_team?.length === 0 && (
                      <div className='help-block'>Team is required</div>
                    )
                  }
                />
              </div>
            )}

            <div className='col-md-6 '>
              <label className='form-input-label'>Assignee</label>

              <SearchableQuerySelect
                queryKey={'okr-assignee-list'}
                queryFn={userService.getPermittedUser}
                getFunctionParams={(searchKey) => ({
                  searchKey,
                  action: USER_LIST_ACTIONS.TASK_ADD,
                  select: ['id', 'first_name', 'last_name', 'image_url'],
                  ...(okrData?.objective_type === objectiveType.team &&
                    okrData?.visibility_team.length !== 0 && {
                      team_Ids: [okrData?.visibility_team?.[0].id],
                      action: actionConstants.OKRADD,
                    }),
                })}
                isCustomFunction
                isLoading={assigneeListLoading}
                initialValue={userData}
                formatOptionLabel={(data, metaData) => (
                  <UserDropDown data={data} metaData={metaData} hideDesignation={true} />
                )}
                getOptionValue={(option) => option.id}
                value={okrData?.assignee_id}
                onChange={(selectedOption) => {
                  handleChange({
                    target: {
                      name: 'assignee_id',
                      value: selectedOption,
                    },
                  });
                }}
                components={{ DropdownIndicator: CustomDropdownIndicator }} // Use the custom dropdown indicator here
                styles={DropDownStyle}
                placeholder='Select Assignee '
                className={` select-box 
                ${submitted && !okrData?.assignee_id && 'field-error'}
               `}
              />
              <ErrorBlock
                errorMsg={'Assignee selection Required'}
                hasError={submitted && !okrData?.assignee_id}
              />
            </div>
          </div>

          <div className='mt-1 '>
            <CustomVariableInput
              name='name'
              value={okrData?.name}
              placeholder={`Add Objective name`}
              onChange={(event) => handleChange(event)}
              className={`okr-name-input w-100  border-none `}
            />
            <ErrorBlock
              errorMsg={'Objective name required'}
              hasError={submitted && okrData?.name?.trim() === ''}
            />
          </div>

          <div className={`form-group row col-md-12`}>
            {id && <label className='mb-1 fz-13px-rem '>Description</label>}

            <div>
              <EditableDescription
                key={2}
                isEditable={true}
                isUpdate
                handleChange={(val) => {
                  handleChange(val);
                }}
                data={{ note: okrData?.note }}
              >
                <div
                  className={` ${id ? 'input-box' : 'task-description-dummy '}  mb-1`}
                  style={{
                    width: '50%',
                    height: 'fit-content',
                    lineBreak: 'anywhere',
                    maxHeight: 100,
                    overflow: 'auto',
                  }}
                >
                  <div
                    className='d-flex align-items-center ptr '
                    style={{ padding: ` ${!id ? '6px 1px' : ' '} ` }}
                  >
                    {!removeTagsAndGetContent(draftToHtml(okrData?.note)) ? (
                      <IconLabelSet
                        textClassName=''
                        label={'Add Description'}
                        iconClassName='d-flex justify-content-center align-items-center'
                        icon={<FileIcon width={16} height={18} />}
                      />
                    ) : (
                      parse(draftToHtml(okrData?.note))
                    )}
                  </div>
                </div>
              </EditableDescription>
            </div>
          </div>
          <div className='row my-2'>
            <div className='col-md-6'>
              <label className='form-input-label'>Time Interval</label>
              <ReactSelect
                styles={DropDownStyle}
                placeholder='Select'
                options={timeIntervalOptions}
                getOptionValue={(option) => {
                  return option?.value;
                }}
                getOptionLabel={(option) => {
                  return option?.label;
                }}
                menuPlacement={
                  parseInt(okrData?.okr_progress_type) === progressType.basedOnChild
                    ? 'top'
                    : 'auto'
                }
                onChange={(selectedOption) => {
                  handleChange({
                    target: {
                      name: 'interval',
                      value: selectedOption.value,
                    },
                  });
                }}
                components={{ DropdownIndicator: CustomDropdownIndicator }} // Use the custom dropdown indicator here
                value={timeIntervalOptions?.find(
                  (option) => option.value === parseInt(okrData?.interval),
                )}
              />{' '}
              {submitted && isNaN(okrData?.interval) && (
                <div className='help-block'>Time Interval is required</div>
              )}
            </div>
          </div>
          <div className='row my-2'>
            <div className='col-md-6 d-flex gap-1'>
              <div className='col-md-6'>
                <label className='form-input-label'>Start Date</label>
                <input
                  type='date'
                  className={`input-box ${
                    submitted && !okrData?.task_start_date ? 'input-error' : ''
                  }`}
                  id='task_start_date'
                  name='task_start_date'
                  style={{ padding: '0 8px', height: 34 }}
                  onChange={handleChange}
                  value={moment(parseInt(okrData?.task_start_date)).format('YYYY-MM-DD')}
                />{' '}
                {submitted && !okrData?.task_start_date && (
                  <div className='help-block'>Start Date is required</div>
                )}
              </div>
              <div className='col-md-6'>
                <label className='form-input-label'>End Date</label>
                <input
                  type='date'
                  disabled={okrData.interval != 0}
                  id='task_end_date'
                  name='task_end_date'
                  style={{
                    padding: '0 8px',
                    height: 34,
                    backgroundColor: okrData.interval !== 0 ? '#F0F1F3' : '',
                  }}
                  min={moment(parseInt(okrData?.task_start_date)).format('YYYY-MM-DD')}
                  className={`input-box ${
                    (submitted && !okrData?.task_end_date) ||
                    (submitted &&
                      okrData?.task_end_date < okrData?.task_start_date &&
                      okrData?.task_end_date &&
                      okrData?.task_end_date)
                      ? 'input-error'
                      : ''
                  }`}
                  onChange={handleChange}
                  value={moment(parseInt(okrData?.task_end_date)).format('YYYY-MM-DD')}
                />

                <ErrorBlock
                  errorMsg={'End Date is required'}
                  hasError={submitted && !okrData?.task_end_date}
                />
                <ErrorBlock
                  errorMsg={okrValidationConstant.END_DATE_MUST_BE_GREATER_THAN_START_DATE}
                  hasError={
                    submitted &&
                    okrData?.task_end_date < okrData?.task_start_date &&
                    okrData?.task_end_date &&
                    okrData?.task_end_date
                  }
                />
              </div>
            </div>
          </div>
          {/* visibility section  */}
          {parseInt(okrData?.objective_type) === objectiveType.company && (
            <div className='mb-2'>
              <div className='row  mt-3 mb-2 '>
                <div className='col-md-12 visibility-container'>
                  <div
                    onClick={() => {
                      setContextMenuVisible(true);
                    }}
                    className='d-flex gap-1 align-items-center'
                    style={
                      {
                        // border: '1px solid #87909E',
                        // padding: '4px 6px',
                        // borderRadius: 4,
                        // height: 33,
                      }
                    }
                  >
                    {/* <div > */}
                    <Eye size={17} />
                    <label className='label-name'>Visibility:</label>
                    <span className='label-name'>
                      {parseInt(okrData?.visibility_type) === objectiveVisibility.All && 'All'}
                      {parseInt(okrData?.visibility_type) === objectiveVisibility.Team && 'Team'}
                      {/* {parseInt(okrData?.visibility_type) === objectiveVisibility.User && 'User'} */}
                    </span>
                    <ChevronDown size={17} />
                    {/* </div> */}
                  </div>
                  {ContextMenuVisible && (
                    <ContextMenu
                      isContextVisible={true}
                      position={POSITION.LEFT}
                      onRequestClose={() => {
                        setContextMenuVisible(false);
                      }}
                      popupWidth='130'
                    >
                      <div className='icon_drop_down_condainer'>
                        <div
                          className='menu_items'
                          onClick={() => {
                            handleChange({
                              target: { name: 'visibility_type', value: objectiveVisibility.All },
                            });
                            setContextMenuVisible(false);
                          }}
                        >
                          All
                        </div>

                        <div
                          className='menu_items'
                          onClick={() => {
                            handleChange({
                              target: { name: 'visibility_type', value: objectiveVisibility.Team },
                            });
                            setContextMenuVisible(false);
                          }}
                        >
                          Team
                        </div>
                      </div>
                    </ContextMenu>
                  )}
                </div>
              </div>
              {parseInt(okrData?.visibility_type) === objectiveVisibility.Team && (
                <div className='row'>
                  <div className='col-md-6 mb-1'>
                    <label className='mb-1'>Visibility Team</label>

                    <SearchableQuerySelect
                      queryKey={'okr-team-list'}
                      queryFn={teamService.teamList}
                      getFunctionParams={(searchKey) => ({
                        searchKey,
                        select: ['id', 'name'],
                      })}
                      isCustomFunction
                      isLoading={isTeamLoading}
                      initialValue={teamData}
                      getOptionLabel={(option) => option?.name}
                      menuPlacement='top'
                      getOptionValue={(option) => option.id}
                      value={okrData?.visibility_team}
                      onChange={(selectedOption) => {
                        handleChange({
                          target: {
                            name: 'visibility_team',
                            value: selectedOption,
                          },
                        });
                      }}
                      isMulti={true}
                      components={{ DropdownIndicator: CustomDropdownIndicator }} // Use the custom dropdown indicator here
                      styles={DropDownStyle}
                      //   isDisabled={templateSelectionDisabled}
                      placeholder='Select Team '
                      className={` select-box 
                ${
                  submitted &&
                  okrData?.visibility_type === objectiveVisibility.Team &&
                  okrData?.visibility_team?.length === 0 &&
                  'field-error'
                }
               `}
                    />
                   
                    <ErrorBlock
                      errorMsg={'Team selection Required'}
                      hasError={
                        submitted &&
                        okrData?.visibility_type === objectiveVisibility.Team &&
                        okrData?.visibility_team?.length === 0
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {/* end of visibility section  */}

          {/* progress section */}
          <div className='mb-3'>
            <div className='row mt-2'>
              <div className='col-md-6'>
                <label className='form-input-label'>Measurement</label>
                <ReactSelect
                  // menuPlacement={okrData?.manual_progress_type === 0 ? 'auto' : 'top'}
                  styles={DropDownStyle}
                  placeholder='Select'
                  className=' w-100'
                  menuPlacement='top'
                  // menuPortalTarget={document.getElementById('MODAL')}
                  options={measurementOptions}
                  getOptionValue={(option) => {
                    return option?.value;
                  }}
                  getOptionLabel={(option) => {
                    return option?.label;
                  }}
                  components={{ DropdownIndicator: CustomDropdownIndicator }} // Use the custom dropdown indicator here
                  onChange={(selectedOption) => {
                    handleChange({
                      target: {
                        name: 'okr_progress_type',
                        value: selectedOption.value,
                      },
                    });
                  }}
                  value={measurementOptions?.find(
                    (option) => option.value === parseInt(okrData?.okr_progress_type),
                  )}
                />
                {submitted && isNaN(okrData?.okr_progress_type) && (
                  <div className='help-block'>Measurement is required</div>
                )}
              </div>
            </div>
            {parseInt(okrData?.okr_progress_type) === progressType.manually && (
              <div className='row mt-3'>
                <div className='row mb-1'>
                  <div className='col-md-12 d-flex gap-2'>
                    <div className='d-flex gap-1'>
                      <input
                        type='radio'
                        name='manual_progress_type'
                        checked={
                          parseInt(okrData?.manual_progress_type) === manualProgressType.percentage
                        }
                        onChange={() =>
                          handleChange({
                            target: {
                              name: 'manual_progress_type',
                              value: manualProgressType.percentage,
                            },
                          })
                        }
                        // value={0}
                        style={{ accentColor: 'black', cursor: 'pointer' }}
                      />
                      <span className='label-name'>Percentage</span>
                    </div>
                    <div className='d-flex gap-1'>
                      <input
                        type='radio'
                        name='manual_progress_type'
                        checked={
                          parseInt(okrData?.manual_progress_type) === manualProgressType.numeric
                        }
                        onChange={() =>
                          handleChange({
                            target: {
                              name: 'manual_progress_type',
                              value: manualProgressType.numeric,
                            },
                          })
                        }
                        // value={1}
                        style={{ accentColor: 'black', cursor: 'pointer' }}
                      />
                      <span className='label-name'>Numbers</span>
                    </div>
                    <div className='d-flex gap-1'>
                      <input
                        type='radio'
                        name='manual_progress_type'
                        checked={
                          parseInt(okrData?.manual_progress_type) === manualProgressType.binary
                        }
                        onChange={() =>
                          handleChange({
                            target: {
                              name: 'manual_progress_type',
                              value: manualProgressType.binary,
                            },
                          })
                        }
                        style={{ accentColor: 'black', cursor: 'pointer' }}
                      />
                      <span className='label-name'>Binary</span>
                    </div>
                  </div>
                </div>
                {parseInt(okrData?.manual_progress_type) !== manualProgressType.binary ? (
                  <div className='row mt-1'>
                    <div className={`${id ? 'col-md-4' : 'col-md-6'} `}>
                      <label className='label-name'>Start</label>
                      <input
                        type='number'
                        className={`input-box ${
                          submitted && okrData?.progress_measure_start < 0 ? 'input-error' : ''
                        }`}
                        style={{ height: 34 }}
                        id='progress_measure_start'
                        min={0}
                        // max={99}
                        name='progress_measure_start'
                        placeholder='Enter Start'
                        value={okrData?.progress_measure_start}
                        onChange={handleChange}
                      />
                      {submitted && okrData?.progress_measure_start < 0 && (
                        <div className='help-block'>Start is required</div>
                      )}
                    </div>
                    {id && (
                      <div className='col-md-4'>
                        <label className='label-name'>Current</label>
                        <input
                          type='number'
                          className={`input-box ${
                            submitted &&
                            id &&
                            parseInt(okrData?.okr_progress_type) === progressType.manually &&
                            (parseInt(okrData?.progress_measure_end) <
                              parseInt(okrData?.manual_current_progress) ||
                              parseInt(okrData?.progress_measure_start) >
                                parseInt(okrData?.manual_current_progress))
                              ? 'input-error'
                              : ''
                          }`}
                          placeholder='Enter Progress'
                          style={{ height: 34 }}
                          id='manual_current_progress'
                          name='manual_current_progress'
                          value={okrData?.manual_current_progress}
                          onChange={handleChange}
                        />
                        {submitted &&
                          id &&
                          parseInt(okrData?.okr_progress_type) === progressType.manually &&
                          (parseInt(okrData?.progress_measure_end) <
                            parseInt(okrData?.manual_current_progress) ||
                            parseInt(okrData?.progress_measure_start) >
                              parseInt(okrData?.manual_current_progress)) && (
                            <div className='help-block'>Invalid Progress</div>
                          )}
                      </div>
                    )}
                    <div className={`${id ? 'col-md-4' : 'col-md-6'} `}>
                      <label className='label-name'>Target</label>
                      <input
                        type='number'
                        // max={100}
                        min={1}
                        placeholder='Enter Target'
                        style={{ height: 34 }}
                        className={`input-box ${
                          (submitted && !okrData?.progress_measure_end) ||
                          (submitted &&
                            parseInt(okrData?.progress_measure_end) &&
                            parseInt(okrData?.progress_measure_start) &&
                            parseInt(okrData?.okr_progress_type) === progressType.manually &&
                            parseInt(okrData.progress_measure_end) <
                              parseInt(okrData.progress_measure_start))
                            ? 'input-error'
                            : ''
                        }`}
                        id='progress_measure_end'
                        name='progress_measure_end'
                        value={okrData?.progress_measure_end}
                        onChange={handleChange}
                      />
                      {submitted && !okrData?.progress_measure_end && (
                        <div className='help-block'>Target is required</div>
                      )}
                      <ErrorBlock
                        errorMsg={okrValidationConstant.PROGRESS_START_END_MISS_MATCH}
                        hasError={
                          submitted &&
                          parseInt(okrData?.progress_measure_end) &&
                          parseInt(okrData?.progress_measure_start) &&
                          parseInt(okrData?.okr_progress_type) === progressType.manually &&
                          parseInt(okrData.progress_measure_end) <
                            parseInt(okrData.progress_measure_start)
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    {id && (
                      <div className='col-md-6 mt-2 '>
                        <div className='binaryCondainer'>
                          <div
                            className={`binary_key_bg ${
                              okrData?.manual_current_progress == 0 ? 'binary_key_bg_active' : ''
                            }`}
                            onClick={() => {
                              handleChange({
                                target: { name: 'manual_current_progress', value: 0 },
                              });
                            }}
                          >
                            Incomplete
                          </div>
                          <div
                            className={`binary_key_bg ${
                              okrData?.manual_current_progress == 100 ? 'binary_key_bg_active' : ''
                            }`}
                            onClick={() => {
                              handleChange({
                                target: { name: 'manual_current_progress', value: 100 },
                              });
                            }}
                          >
                            Complete
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
          {/* {end of progress section } */}
        </div>

        <div
          className='objective-add-button-section'
          style={{
            boxShadow: scrollFlag.isBottomReached ? '' : '0 -6px 5px -5px var(--shadow, #091e4229)',
          }}
        >
          <Button.Container
            className={'outline-cancel-button'}
            type='button'
            handleOk={(event) => {
              handleClose(okrFormAction.Close, event);
            }}
          >
            <Button.Title title={'Cancel'} />
          </Button.Container>

          <Button.Container type='submit' className='blue-btn' isDisabled={isLoading}>
            <Button.Title title='Save' />
            <Button.Loading isLoading={isLoading} />
          </Button.Container>
        </div>
      </form>
    </div>
  );
}
