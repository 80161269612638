/* eslint-disable no-unused-vars */
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { Ellipsis, Plus, Search } from 'lucide-react';
import React, { useContext, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GlobalContext, Images } from '../Utils';
import { LoadingIcon } from '../Utils/SvgIcons';
import { Button, ContextMenu, Modal, PaginationComponent, TableRow } from '../_components';
import { ErrorComponent } from '../_components/ErrorComponent';
import { RT } from '../_constants';
import { CONTEXT_MENU_OPTIONS } from '../_constants/contextMenu.constants';
import { DELETE_NOTE } from '../_constants/deletenote.constants';
import useDebounce from '../_helpers/useDebounce';
import { organizationService } from '../_services/organization.service';

const TABLE_COLUMN = ['Sl.No', 'Name', 'Created At', 'Type', 'Action'];

export function OrganizationList() {
  // hooks section
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();

  const { globalSettings } = useContext(GlobalContext);

  const is_admin =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'is_admin');

  // reference section
  const reference = useRef(null);

  // useState section
  const [query, setQuery] = useState({
    select: ['id', 'name', 'createdAt', 'updatedAt', 'is_default', 'is_default'],
    searchKey: '',
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
    // status: 1,
  });

  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editOrganizationId, setEditOrganizationId] = useState(null);

  const debouncedSearch = useDebounce(query?.searchKey, 500);

  // organization list API call section
  const {
    data: organizationData,
    error,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ['organizationList', query?.pageVo?.pageNo, debouncedSearch],
    queryFn: () => organizationService.organizationList(query),
    placeholderData: keepPreviousData,
  });

  const modalClose = () => {
    setDeleteModal(false);
    setEditOrganizationId(null);
  };

  // section trigger when delete option selected

  const handleDeactivate = async (id) => {
    try {
      await organizationService.organizationDeactivate({ id });
      let isNeedToBack =
        organizationData?.data?.rows.length === 1 && organizationData?.data?.pages > 1;
      if (isNeedToBack) {
        const page = searchParams.get('pageNo');
        searchParams.set('pageNo', page - 1);
        setSearchParams(searchParams);
        setQuery((prev) => ({
          ...prev,
          pageVo: {
            ...prev.pageVo,
            pageNo: prev.pageVo.pageNo - 1,
          },
        }));
      } else {
        refetch();
      }
      modalClose();
    } catch (error) {
      console.error('Error deleting organization:', error);
    }
  };

  // edit or delete function activation section

  const handleClickOption = (option) => {
    if (option == 'edit') {
      navigate(`/${RT.ORGANIZATION_ADD}/${editOrganizationId}`);
    } else if (option == 'delete') {
      setDeleteModal(true);
    }
  };

  // get the count of organization
  const listItemCount = organizationData?.data?.rows.length;

  const orgRows = organizationData?.data?.rows.map((org, index) => {
    const slNo = (organizationData?.data?.page - 1) * organizationData?.data?.limit + index + 1;
    return (
      <tr key={index}>
        <td>{slNo}</td>

        <td>{org?.name} </td>
        <td>{org?.createdAt.split('T')[0]}</td>

        <td>{org?.is_default ? 'Default' : ' '}</td>

        <td
          style={{ verticalAlign: 'middle', cursor: 'pointer' }}
          ref={reference}
          onClick={(event) => {
            event.stopPropagation();
            setContextMenuVisible(true);
            setEditOrganizationId(org?.id);
          }}
        >
          <div className='ellipsis_icon'>
            <Ellipsis />
            {contextMenuVisible && editOrganizationId == org?.id && (
              <ContextMenu
                handleClick={(value) => {
                  handleClickOption(value);
                }}
                isContextVisible={true}
                onRequestClose={() => {
                  setContextMenuVisible(false);
                }}
              >
                {org?.is_default ? (
                  <div
                    className='menu_items'
                    onClick={(event) => {
                      event.stopPropagation();
                      handleClickOption(CONTEXT_MENU_OPTIONS.EDIT);
                    }}
                  >
                    Edit
                  </div>
                ) : (
                  ''
                )}
              </ContextMenu>
            )}
          </div>
        </td>
      </tr>
    );
  });

  // search box handleChange
  const handleSearch = (event) => {
    searchParams.set('pageNo', 1);
    setSearchParams(searchParams);
    let search = event.target.value;
    setQuery((prevQuery) => ({
      ...prevQuery,
      pageVo: { ...query.pageVo, pageNo: 1 },
      searchKey: search,
    }));
  };

  return (
    <div className='organization_list '>
      {/* component will active when error occurs   */}
      <ErrorComponent error={error?.message} />

      <section>
        <div className='tab_contain mt-2'>
          <div className='search_contain'>
            <Search size={20} color='black' />
            <input
              type='text'
              className='bodr_none'
              onChange={(event) => {
                handleSearch(event);
              }}
            />
          </div>
          <div className='d-flex gap-2'>
            {is_admin?.value && (
              <div
                className='add_item_button'
                onClick={() => navigate(`/${RT.ORGANIZATION_ADD}/0`)}
              >
                <Plus size={16} /> Add Organization
              </div>
            )}
            {/* <div className='list_top_card'>
              Sort by <ChevronDown size={13} />
            </div> */}
          </div>
        </div>

        <section className='mt-2'>
          <div className='table_outer'>
            <table className='table table-hover tbl mb-0'>
              <thead>
                <tr>
                  {TABLE_COLUMN.map((heading, index) => {
                    return (
                      <th key={index} scope='col'>
                        {heading}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {isLoading && (
                  <TableRow noOfCols={6}>
                    <LoadingIcon color='blue' size={60} />
                  </TableRow>
                )}
                {!listItemCount && isSuccess && <TableRow noOfCols={6}>No data found</TableRow>}
                {listItemCount > 0 && isSuccess && orgRows}
              </tbody>
            </table>
          </div>
        </section>
        {listItemCount > 0 && (
          <section className='mt-2'>
            <PaginationComponent
              pageOnClick={(data) => {
                setQuery((prev) => ({
                  ...prev,
                  pageVo: {
                    ...prev.pageVo,
                    pageNo: data,
                  },
                }));
                searchParams.set('pageNo', data);
                setSearchParams(searchParams);
              }}
              demoData={organizationData?.data}
            />
          </section>
        )}
      </section>
      {deleteModal && (
        <Modal.Container>
          <Modal.View className={'status-delete'}>
            <Modal.Head handleClose={() => modalClose()} Heading={'Delete Confirmation'} />
            <Modal.Description>
              <img src={Images.DeleteBin} width={35} height={50} alt='' className='mb-3' />
              <div className='del-description'>{DELETE_NOTE.ORGANIZATION}</div>
            </Modal.Description>
            <div className='status-del-btn'>
              <Button.Container handleOk={() => modalClose()} className={'status-del-cancel'}>
                <Button.Title title={'Cancel'} />
              </Button.Container>
              <Button.Container handleOk={() => handleDeactivate(parseInt(editOrganizationId))}>
                <Button.Title title={'Delete'} />
              </Button.Container>
            </div>
          </Modal.View>
        </Modal.Container>
      )}
    </div>
  );
}
