export const MESSAGE_TYPES = {
  // Message types(don't change the case)
  TEXT: 'text',
  CHAT_CREATION: 'creation',
  REMINDER: 'reminder',
  REQUEST: 'request',
  STATUS_CHANGE: 'status-change',
  START_STOP_CHANGE: 'start-stop-change',

  // File types
  DOCUMENT: 2,
  AUDIO: 3,
  LINK_PREVIEW: 6,
  VIDEO: 7,
  IMAGE: 8,
  RECORDED_AUDIO: 9,
};

export const MIME_TYPE_MAPPING = {
  'application/pdf': MESSAGE_TYPES.DOCUMENT,
  'application/msword': MESSAGE_TYPES.DOCUMENT,
  'application/vnd.ms-excel': MESSAGE_TYPES.DOCUMENT,
  'application/vnd.ms-powerpoint': MESSAGE_TYPES.DOCUMENT,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': MESSAGE_TYPES.DOCUMENT,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': MESSAGE_TYPES.DOCUMENT,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    MESSAGE_TYPES.DOCUMENT,
  'text/plain': MESSAGE_TYPES.DOCUMENT,
  'audio/mpeg': MESSAGE_TYPES.AUDIO,
  'audio/wav': MESSAGE_TYPES.AUDIO,
  'audio/ogg': MESSAGE_TYPES.AUDIO,
  'audio/x-m4a': MESSAGE_TYPES.AUDIO,
  'audio/x-wav': MESSAGE_TYPES.AUDIO,
  'audio/mp3': MESSAGE_TYPES.AUDIO,
  'video/mp4': MESSAGE_TYPES.VIDEO,
  'video/x-matroska': MESSAGE_TYPES.VIDEO,
  'video/ogg': MESSAGE_TYPES.VIDEO,
  'image/jpeg': MESSAGE_TYPES.IMAGE,
  'image/png': MESSAGE_TYPES.IMAGE,
  'image/gif': MESSAGE_TYPES.IMAGE,
  'image/webp': MESSAGE_TYPES.IMAGE,
  // 'audio/x-m4a': MESSAGE_TYPES.AUDIO,
};

export const CHAT_UPDATE_PATH = {
  TASK: 'task',
  PERSONAL: 'personal',
  GROUP: 'group',
  ALL: 'all', //Will exclude task chats
};

export const CHAT_UPDATE_TYPE = {
  TASK: 'task',
  PERSONAL: 'personal',
  GROUP: 'group',
  ALL_EXCLUDE_TASK: 'ALL_EXCLUDE_TASK',
};
export const NOTIFICATION_TYPE = {
  TASK: 'TASK',
  S_TASK: 'task',
  CHAT: 'CHAT',
  PERSONAL: 'personal',
  GROUP: 'group',
  ALL_EXCLUDE_TASK: 'ALL_EXCLUDE_TASK',
  REMINDER: 'REMINDER',
};

export const NOTIFICATION_MODE = {
  VERIFICATION_MODE: 1,
  ASSIGNED_MODE: 2,
  ASSIGNEE_CHANGE_MODE: 11,
  REPEAT_MODE: 3,
  FAILED_MODE: 4,
  COMPLETED_MODE: 5,
  REQUEST_MODE: 9,
  ACTION_MODE: 10,
  CHAT_MODE: 12,
  REMINDER_MODE: 8,

  // TIMEBOX_MODE: 6,
  // TIMEBOX_TASK_MODE: 7,
  // REMINDER_MODE: 8,

  // MESSAGE_MODE: 0,
};

// Map modes to icon and color

export const CHAT_TABS = [
  {
    id: 1,
    index: 0,
    name: 'All',
    path: `all`,
    image: 'icons/chat-tab-icon.svg',
    countKey: 'total_count',
    getPayload: () => ({ update_type: CHAT_UPDATE_TYPE.ALL_EXCLUDE_TASK }),
  },
  {
    id: 2,
    index: 1,
    name: 'Groups',
    path: `group`,
    image: 'icons/groups-tab-icon.svg',
    countKey: 'group_count',
    getPayload: () => ({ update_type: CHAT_UPDATE_TYPE.GROUP }),
  },
  {
    id: 3,
    index: 2,
    name: 'Tasks',
    path: 'task',
    image: '/icons/Task.svg',
    countKey: 'task_count',
    getPayload: () => ({ update_type: CHAT_UPDATE_TYPE.TASK }),
  },
];

export const CHAT_TEXTAREA_CLASS_NAME = 'chat_textarea';
