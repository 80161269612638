/* eslint-disable no-unused-vars */
import { useIsMutating, useMutation, useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import { settingsService } from '../_services';
import { PROJECT_LABELS } from './SettingsLabels';
import { ToggleWithHeading } from './UtilsInSettings';

import { GlobalContext } from '../Utils/Contexts';
import { LoadingIcon } from '../Utils/SvgIcons';
import { Button } from '../_components';

export function ProjectSettings() {
  const [query, setQuery] = useState({ select: ['name', 'labels'], name: 'project-settings' });

  const {
    data: projectSettingsData,
    error,
    refetch,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ['project-settings'],
    queryFn: () => settingsService.settingsGet(query),
    select: (data) => {
      return data?.data[0];
    },
  });

  return (
    <section className='common_contain'>
      {isLoading && (
        <div className='loading-wrapper'>
          <LoadingIcon color={'#c1c1c1'} size={70} />
        </div>
      )}
      {isSuccess && <ProjectForm refetch={refetch} initialData={projectSettingsData?.labels} />}
    </section>
  );
}

const ProjectForm = ({ initialData, refetch }) => {
  //-- hooks
  const { makeAlert, globalSettingsRefetch } = useContext(GlobalContext);
  const isMutating = useIsMutating();

  //-- state
  const [projectSettings, setProjectSettings] = useState(initialData || PROJECT_LABELS);
  const [hasChanged, setHasChanged] = useState(false);

  //-- effects
  useEffect(() => {
    setHasChanged(
      JSON.stringify(projectSettings) !== JSON.stringify(initialData || PROJECT_LABELS),
    );
  }, [projectSettings]);

  //-- when toggle radio button
  const handleToggle = (id, value) => {
    setProjectSettings((prevLabels) =>
      prevLabels.map((label) => (label.id === id ? { ...label, value: !value } : label)),
    );
  };

  // project settings update
  const handleSettingUpdate = async (data) => {
    let newQuery = {};
    newQuery.labels = data;
    newQuery.name = 'project-settings';
    await settingsService.settingsAdd(newQuery);
    refetch();
    setHasChanged(false);
    makeAlert('Success');
    globalSettingsRefetch();
    return newQuery;
  };

  // --react query fot mutation function
  const { mutate: handleMutate, isError } = useMutation({
    mutationFn: handleSettingUpdate,
  });

  //-- checking project enable or disable
  let projectStatus = projectSettings.find((project) => project.id == 1);

  return (
    <>
      {/* <div className='select-contain'> */}
      {/* <div>
          <Heading heading={'Project Status'} /> */}
      {/* <div className='project-txt'>{PROJECT_DESCRIPTION}</div> */}
      {/* </div> */}
      {/* <div className='select-sub'>
          <Select
            onChange={(e) => handleSelectChange(projectStatus?.id, e)}
            value={{ label: projectStatus?.value ? 'Enable' : 'Disable' }}
            className='select-style'
            styles={customStyles}
            options={STATUS_OPTIONS}
          />
        </div> */}
      {/* </div> */}

      {projectSettings?.map((PROJECT) => {
        if (PROJECT.type === 'toggle') {
          return (
            <ToggleWithHeading
              className='project_sub_contain'
              key={PROJECT?.id}
              id={PROJECT?.id}
              handleToggle={() => handleToggle(PROJECT?.id, PROJECT.value)}
              isChecked={PROJECT.value}
              heading={PROJECT.label}
            />
          );
        }
      })}

      {hasChanged && (
        <Button.Container
          className={'sett-save-btn'}
          handleOk={() => handleMutate(projectSettings)}
        >
          <Button.Title title={'Save'} />
          <Button.Loading isLoading={isMutating} />
        </Button.Container>
      )}
    </>
  );
};
