import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ReloadCheck = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the page was reloaded (check localStorage)
    const isReloaded = localStorage.getItem('isReloaded');

    if (isReloaded) {
      // If the page was reloaded, navigate to the home page and clear the flag
      localStorage.removeItem('isReloaded');
      navigate('/'); // Navigate to home page
    }

    const handleBeforeUnload = () => {
      // Set a flag in localStorage before the page reloads
      localStorage.setItem('isReloaded', 'true');
    };

    // Listen for the beforeunload event
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [navigate]);

  return <div>Reload Check Page</div>;
};

export default ReloadCheck;
