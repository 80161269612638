import React, { useEffect, useState } from 'react';
import { userInitialLetter } from '../../Utils';
import { USER_PLACEHOLDER } from '../../_constants';

const CircularAvatar = ({
  source = null, // Source url of the image
  isMale = true, // for gender based placeholder
  size = 30, // Size of the image
  isCentered = true, // If whether the avatar is centered vertically or not.
  isImgStacked = false, // for stacking images, applies if using multiple instance of this component
  // isNeutral = false, //if placeholder img is neutral or not
  className = '',
  fit = 'cover',
  name = null,
  placeholderSrc = null,
}) => {
  // const maleImg = '/icons/man.png';
  // const femaleImg = '/icons/girl.png';
  // const neutralImg = '/icons/user2.png';
  const placeholderImage = placeholderSrc
    ? placeholderSrc
    : isMale
    ? USER_PLACEHOLDER.MALE
    : USER_PLACEHOLDER.FEMALE;
  // eslint-disable-next-line no-unused-vars
  const [isFailed, setIsFailed] = useState(false);
  const [image, setImage] = useState(
    // source ? source : name ? null : isNeutral ? neutralImg : isMale ? maleImg : femaleImg,
    source ? source : placeholderImage,
  );

  // If source changes by any chance or due to any changes in the parent, update it in the useEffect
  useEffect(() => {
    setIsFailed(false);
    if (source != null && image !== source) {
      setImage(source);
    }
  }, [source]);

  const isPlaceHolder = image === placeholderImage;

  return (
    <div
      className={`${isCentered && !isImgStacked ? 'm-auto' : ''} ${className} ${
        !isImgStacked ? 'mx-1' : ''
      } d-flex align-items-center justify-content-center`}
      style={{
        borderRadius: size / 2,
        overflow: 'hidden',
        // marginLeft: isImgStacked ? -(size / 10) : 'auto',
        marginLeft: 0,
        backgroundColor: name ? '#6d6d6d' : '#E9F2FF',
        width: size,
        minWidth: 'fit-content',
        height: size,
      }}
    >
      {/* Event if name is passed and user has a valid profile pic, we need to show their pic. */}
      {(!image || isPlaceHolder) && name ? (
        <NameInitialPreview name={name} size={size} />
      ) : (
        <img
          style={{
            maxWidth: '100%',
            height: size,
            width: size,
            objectPosition: 'center',
            objectFit: fit,
            verticalAlign: 'initial',
          }}
          src={image}
          onError={() => {
            setIsFailed(true);
            setImage(placeholderImage);
          }}
        />
      )}
    </div>
  );
};

const NameInitialPreview = ({ name, size = 20 }) => {
  const initials = userInitialLetter(name);
  return (
    <>
      <div style={{ fontSize: size / 2.5, color: 'white' }}>{initials}</div>
    </>
  );
};

export default CircularAvatar;
