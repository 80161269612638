import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingImg } from '../_components';
import { alertConstants } from '../_constants';
import { demoService } from '../_services';
const DemoAdd = (props) => {
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ common: '' });
  const navigate = useNavigate();
  const { id } = useParams();
  const [demo, setDemo] = useState({
    name: '',
    description: '',
  });
  const [query, setQuery] = useState({
    select: ['id', 'name'],
  });

  useEffect(() => {
    if (id && id != '0') {
      query.id = id;
      getAllDemo(query);
    } else if (props.selectedId) {
      query.id = props.selectedId;
      getAllDemo(query);
    }
  }, [id, props.selectedId]);

  const hideError = () => {
    setTimeout(() => {
      setError({ common: '' });
    }, 10000);
  };

  /**
   * Get demo
   * */
  const getAllDemo = async (queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;

    setQuery(queryNew);
    setIsLoading(true);

    try {
      const demo = await demoService.demoGet(queryNew);
      if (demo?.data?.length) {
        setDemo(demo.data[0]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDemo({
      ...demo,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    console.log(demo);
    event.preventDefault();
    setSubmitted(true);
    setIsLoading(true);

    if (demo.name) {
      try {
        await demoService.demoAdd(demo);
        navigate(-1);
      } catch (error) {
        console.log(error);
        setError({
          common: error?.message || alertConstants.SERVER_ERROR,
        });
        hideError();
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className='DemoAdd'>
      <div className='col-md-8 col-md-offset-2'>
        {isLoading && <LoadingImg />}

        <div className='card'>
          <div className='card-header page-header '>
            <div className='card-header page-header'>
              <div className='page-title-content'>
                <h4 className='card-title'>Add demo </h4>
              </div>
            </div>
          </div>
          <div className='card-body'>
            <form name='form' onSubmit={handleSubmit}>
              <div className='row'>
                <div className='col-md-12 pr-1'>
                  <div className={'form-group '}>
                    <label htmlFor='type'>Demo name</label>
                    <input
                      type='text'
                      className='form-control'
                      name='name'
                      value={demo.name || ''}
                      onChange={handleChange}
                    />
                    {submitted && !demo.name && <span className='color-red'>Name is required</span>}
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12 pr-1'>
                  <span className='color-red'>{error.common}</span>
                  <div className='form-group mt-3'>
                    <button className='btn btn-primary btn-wd'>
                      Save
                      {isLoading && <img alt='loading' height={35} src='/spinner2.svg' />}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className='card-footer' />
        </div>
      </div>
    </div>
  );
};

export { DemoAdd };
