import { ImagePlay } from 'lucide-react';
import React, { useState } from 'react';
import { apiConstants } from '../_constants';
import { Modal } from './Modal';

export const ViewImageModal = ({ imageData }) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <div className='open-image' onClick={() => setOpenModal(true)}>
        <ImagePlay size={20} opacity={0.6} />
      </div>
      {openModal && (
        <Modal.Container handleClose={() => setOpenModal(false)}>
          <Modal.View className={'file-upload'}>
            <Modal.Head Heading={imageData?.label} handleClose={() => setOpenModal(false)} />
            <div className='upload-contain'>
              <img
                src={apiConstants.imgUrlS3 + imageData?.value}
                width={'100%'}
                height={300}
                style={{ objectFit: 'contain' }}
              />
            </div>
          </Modal.View>
        </Modal.Container>
      )}
    </>
  );
};
