import React, { useEffect, useRef, useState } from 'react';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import { apiConstants } from '../_constants';
import { fullNameUser as getFullName } from '../Utils';

const GroupMemberWrapper = ({
  isLoading = false,
  children,
  isError = false,
  refetch = () => {},
}) => {
  if (isLoading) {
    return (
      <div
        className='blink'
        style={{ backgroundColor: '#e7e7e7', minWidth: 150, height: 50 }}
      ></div>
    );
  }

  if (isError) {
    return (
      <div>
        Something went wrong,{' '}
        <div role='button' style={{ color: 'blue' }} onClick={refetch}>
          Retry
        </div>
      </div>
    );
  }

  return children;
};

function GroupMemberDetails({
  members = [],
  isLoading,
  isError,
  onMemberRefetch = () => {},
  isContainerHover = false,
}) {
  const memberContainerRef = useRef(null);
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    if (!memberContainerRef.current) {
      return;
    }

    const handleHover = () => {
      setIsHovering(true);
    };

    const handleMouseLeave = () => {
      setIsHovering(false);
    };

    memberContainerRef.current?.addEventListener('mouseover', handleHover);
    memberContainerRef.current?.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      memberContainerRef.current?.removeEventListener('mouseover', handleHover);
      memberContainerRef.current?.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [memberContainerRef.current]);

  if (!isHovering && !isContainerHover) {
    return null;
  }

  return (
    <div
      ref={memberContainerRef}
      className='group-member-details-container'
      style={{
        maxHeight: 'calc(100vh - ( var(--top-bar-height) + 23px + 60px + 130px ))',
        overflow: 'auto',
      }}
    >
      <GroupMemberWrapper isLoading={isLoading} refetch={onMemberRefetch} isError={isError}>
        {members?.map((member, index) => {
          const imageUrl = apiConstants.imgUrlS3 + member.image_url;
          return (
            <div key={member.id} className={`${index !== 0 ? 'mt-2' : ''}`}>
              <div className='d-flex justify-content-between align-items-center gap-2'>
                <div className={`d-flex gap-2 align-items-center `}>
                  <CircularAvatar size={18} source={imageUrl} />
                  <div>{getFullName(member.first_name, member.last_name)}</div>
                </div>
                {member.isCreator && <div style={{ fontSize: 10, color: 'blue' }}>Owner</div>}
              </div>
            </div>
          );
        })}
      </GroupMemberWrapper>
    </div>
  );
}

export default GroupMemberDetails;
