/* eslint-disable no-unused-vars */
import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { RT } from '../_constants';
import { TaskListTypeConst } from '../BoardView/BoardView.constant';

export default function ProjectLayout({ projectData }) {
  const PROJECT_TABS = [
    {
      name: 'Tasks',
      path: `${RT.PROJECT_TASK_LIST}?status=${
        projectData.navigation_id ?? 'in-progress'
      }&list_type=${TaskListTypeConst.Project}`,
      isVisible: true,
    },
    {
      name: projectData?.project_type == 1 ? 'Sprint' : 'Milestones',
      path: `${RT.MILESTONE}`,
      isVisible: projectData?.project_type != 0 ? true : false,
    },
    // {
    //   name: 'Board',
    //   path: `${RT.PROJECT_BOARD}?project_id=${projectData?.id}&list_type=${TaskListTypeConst.Project}`,
    //   isVisible: true,
    // },
    // { name: 'Calender', path: `${RT.PROJECT_CALENDAR}`, isVisible: true },
  ];

  return (
    <section className=' project-layout'>
      <div className='org-nav-contain ' style={{ marginBottom: 10 }}>
        {PROJECT_TABS?.filter((item) => item?.isVisible == true)?.map((tab, index) => {
          return (
            <NavLink
              key={index}
              className={({ isActive }) => {
                return isActive ? 'project-tabs-active  ' : 'project-tabs-in-active';
              }}
              style={{ fontSize: 13 }}
              to={tab?.path}
            >
              {tab.name}
            </NavLink>
          );
        })}
      </div>
      <div className='project-separation  ' />
      <section>
        <Outlet />
      </section>
    </section>
  );
}
