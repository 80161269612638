import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { ChevronDown, Ellipsis, Plus, Search } from 'lucide-react';
import { default as React, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Images } from '../Utils';
import { LoadingIcon } from '../Utils/SvgIcons';
import { Button, ContextMenu, Modal, PaginationComponent, TableRow } from '../_components';
import { ErrorComponent } from '../_components/ErrorComponent';
import { DELETE_NOTE } from '../_constants/deletenote.constants';
import useDebounce from '../_helpers/useDebounce';
import { branchService } from '../_services/branch.service';
import BranchAdd from './BranchAdd';

const BranchList = () => {
  // reference section
  const reference = useRef(null);

  const queryClient = useQueryClient();

  //  useState section

  const [addModal, setAddModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [deleteModal, setDeleteModal] = useState(false);
  const [contextMenuIndex, setContextMenuIndex] = useState(null);
  const [editBranchID, setEditBranchID] = useState(null);
  const [query, setQuery] = useState({
    select: ['id', 'name', 'company_id', 'branch_time_zone', 'open_time', 'close_time', 'status'],
    searchKey: '',
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
  });
  const debouncedSearch = useDebounce(query?.searchKey, 500);

  const TABLE_COLUMN = [
    'Sl.No',
    'Name',
    'Organzation',
    'Time Zone',
    'Opening Time',
    'Closing Time',
    'Action',
  ];

  //function use to get branch list
  const {
    data: branchData,
    error,
    refetch,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ['branchList', query?.pageVo?.pageNo, debouncedSearch],
    queryFn: () => branchService.branchList(query),
    placeholderData: keepPreviousData,
  });

  // search box habdleChange
  const handleSearch = (event) => {
    searchParams.set('pageNo', 1);
    setSearchParams(searchParams);
    let search = event.target.value;
    setQuery((prevQuery) => ({
      ...prevQuery,
      pageVo: { ...query.pageVo, pageNo: 1 },
      searchKey: search,
    }));
  };

  //function use to handle the actions of the data
  const handleClickOption = (option) => {
    if (option == 'edit') {
      setAddModal(true);
    } else if (option == 'delete') {
      setDeleteModal(true);
    }
  };

  const modalClose = () => {
    setDeleteModal(false);
    setEditBranchID(null);
  };

  const handleDeactivate = async (id) => {
    try {
      await branchService.branchDeactivate({ id });
      await queryClient.invalidateQueries('branch');
      modalClose();
    } catch (error) {
      console.error('Error deleting branch:', error);
    }
  };
  const branchCount = branchData?.data?.rows.length;
  const branchRows = branchData?.data?.rows.map((branch, index) => {
    const slNo = (branchData?.data?.page - 1) * branchData?.data?.limit + index + 1;
    return (
      <tr key={index}>
        <td>{slNo}</td>
        <td>{branch?.name} </td>
        <td>{branch?.['Company.name']} </td>
        <td>{branch?.branch_time_zone.text} </td>
        <td>{branch?.open_time}</td>
        <td>{branch?.close_time}</td>
        <td style={{ verticalAlign: 'middle', cursor: 'pointer' }}>
          <div
            className='ellipsis_icon'
            ref={reference}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <div onClick={() => setContextMenuIndex(index)}>
              <Ellipsis />
            </div>
            <ContextMenu
              handleClick={(value) => {
                setEditBranchID(branch?.id);
                handleClickOption(value);
              }}
              isContextVisible={contextMenuIndex === index}
              onRequestClose={() => {
                // setEditBranchID(null);
                setContextMenuIndex(null);
              }}
            />
          </div>
        </td>
        <td></td>
      </tr>
    );
  });
  return (
    <div>
      <div className='organization_list sub_padding'>
        {/* component will active when error occurs   */}
        <ErrorComponent error={error?.message} />

        <section>
          <div className='tab_contain mt-2'>
            <div className='search_contain'>
              <Search size={20} color='black' />
              <input
                type='text'
                className='bodr_none'
                onChange={(event) => {
                  handleSearch(event);
                }}
              />
            </div>
            <div className='d-flex gap-2'>
              <div className='add_item_button' onClick={() => setAddModal(true)}>
                <Plus size={16} /> Add Branch
              </div>
              <div className='list_top_card'>
                Sort by <ChevronDown size={13} />
              </div>
            </div>
          </div>

          <section className='mt-2'>
            <div className='table_outer'>
              <table className='table table-hover tbl mb-0'>
                <thead>
                  <tr>
                    {TABLE_COLUMN.map((heading, index) => {
                      return (
                        <th key={index} scope='col'>
                          {heading}
                        </th>
                      );
                    })}
                  </tr>
                </thead>

                <tbody>
                  {isLoading && (
                    <TableRow noOfCols={7}>
                      <LoadingIcon color='blue' size={60} />
                    </TableRow>
                  )}
                  {!branchCount && isSuccess && <TableRow noOfCols={7}>No data found</TableRow>}
                  {branchCount > 0 && isSuccess && branchRows}
                </tbody>
              </table>
            </div>
          </section>
          {branchCount > 0 && <section className='mt-2'>
            <PaginationComponent
              pageOnClick={(data) => {
                setQuery((prev) => ({
                  ...prev,
                  pageVo: {
                    ...prev.pageVo,
                    pageNo: data,
                  },
                }));
                searchParams.set('pageNo', data);
                setSearchParams(searchParams);
              }}
              demoData={branchData?.data}
            />
          </section>}
        </section>

        {addModal && (
          <Modal.Container handleClose={() => setAddModal(false)}>
            <Modal.View className={'modal_view_branch'}>
              <Modal.Head
                Heading={editBranchID ? 'Edit Branch' : 'Add Branch'}
                handleClose={() => {
                  setAddModal(false);
                  setEditBranchID(null);
                }}
              />
              <BranchAdd
                id={editBranchID}
                handleClose={() => {
                  setAddModal(false);
                  setEditBranchID(null);
                  refetch();
                }}
              />
            </Modal.View>
          </Modal.Container>
        )}
      </div>

      {deleteModal && (
        <Modal.Container>
          <Modal.View className={'status-delete'}>
            <Modal.Head handleClose={() => modalClose()} Heading={'Delete Confirmation'} />
            <Modal.Description>
              <img src={Images.DeleteBin} width={35} height={50} alt='' className='mb-3'/>
              <div className='del-description'>{DELETE_NOTE.BRANCH}</div>
            </Modal.Description>
            <div className='status-del-btn'>
              <Button.Container handleOk={() => modalClose()} className={'status-del-cancel'}>
                <Button.Title title={'Cancel'} />
              </Button.Container>
              <Button.Container handleOk={() => handleDeactivate(editBranchID)}>
                <Button.Title title={'Delete'} />
              </Button.Container>
            </div>
          </Modal.View>
        </Modal.Container>
      )}
    </div>
  );
};

export default BranchList;
