/* eslint-disable no-unused-vars */
import { X } from 'lucide-react';
import React, { useEffect, useMemo, useState } from 'react';
import useMessagingListeners from '../Hook/useMessagingListeners';
import { Images } from '../Utils';

export function ReminderView() {
  const { newNotification, notificationData } = useMessagingListeners();
  const [newNotificationArrive, setNewNotificationArrive] = useState(false);

  const handleClose = () => {
    const element = document.querySelector('.reminder-pop-container');
    if (element) {
      element.classList.add('exit');
    }
    setNewNotificationArrive(false);
  };

  function triggerNewNotification() {
    setNewNotificationArrive(true);
    // setTimeout(() => {
    //   handleClose();
    // }, 15000);
  }

  useEffect(() => {
    if (newNotification) {
      triggerNewNotification();
    }
  }, [newNotification]);

  let notificationParsed = useMemo(() => {
    return notificationData?.notificationData
      ? JSON.parse(notificationData?.notificationData)
      : notificationData?.notificationData;
  }, [notificationData]);

  return (
    <>
      {/* {newNotificationArrive && ( */}
      {newNotificationArrive && Boolean(notificationParsed?.isReminder) && (
        <div className='reminder-pop-container'>
          <div className='reminder-head'>
            <div className='reminder-bell-contain'>
              <div>
                <img src={Images.bell} style={{ height: 20, width: 20 }} />
              </div>
              <div style={{ fontSize: 15, fontWeight: '600' }}>Reminder</div>
            </div>
            <div onClick={() => handleClose()}>
              <X size={15} cursor={'pointer'} />
            </div>
          </div>
          {/* line */}
          <div style={{ height: 1.2, background: '#d2d2d2', width: '100%' }}></div>
          {/* line */}
          {/* content  */}
          <div style={{ marginTop: 3, padding: '5px 5px 16px 5px', fontSize: 15 }}>
            {notificationData?.title ? notificationData?.title : 'New reminder'}
          </div>
          {/* content  */}
        </div>
      )}
    </>
  );
}
