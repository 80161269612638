import { ChevronDown } from 'lucide-react';
import React, { useState } from 'react';
import IconSelector from './IconSelector';
import './iconInputSelector.css';

function InputWithIcons({
  iconList = [],
  inputValue = undefined,
  onInputChange = () => {},
  onIconChange = () => {},
  findIcon = () => {},
  extractId,
  getIconUrl = () => {},
  ...rest
}) {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const filteredIcon = (icon) => findIcon(icon);
  const selectedIconObject = iconList.find(filteredIcon);
  const selectedIconUrl = getIconUrl
    ? selectedIconObject
      ? getIconUrl(selectedIconObject)
      : getIconUrl(iconList[0]) //Take first index item, if no icon is selected
    : selectedIconObject;

  return (
    <div className='position-relative d-flex input-icon-wrapper'>
      {/* Icon Selector */}
      <div
        className='d-flex icon-container'
        role='button'
        onClick={() => setIsPreviewOpen((prev) => !prev)}
      >
        <img src={selectedIconUrl} />
        {/* Arrow to toggle the preview */}
        <ChevronDown size={15} />
      </div>
      {/* Input */}
      <input value={inputValue} type='text' className='w-100' onChange={onInputChange} {...rest} />
      <IconSelector
        iconList={iconList}
        onIconChange={(icon) => {
          onIconChange(icon);
          setIsPreviewOpen(false);
        }}
        getIconUrl={getIconUrl}
        isVisible={isPreviewOpen}
        extractId={extractId}
        onClose={() => {
          setIsPreviewOpen(false);
        }}
      />
    </div>
  );
}

export default InputWithIcons;
