import React from 'react';
import { daysWithFirstLetter } from '../../_constants/recurrence.constants';

function WeekInputs({ onSelect = () => {}, checkIsActive = () => {} }) {
  return (
    <div>
      <div className='d-flex mt-20 '>
        {daysWithFirstLetter.map((day, index) => {
          const isActive = checkIsActive(day.value);
          //   recurrenceData.recurrenceDaysOfWeek.includes(
          //     day.value,
          //   );

          return (
            <a
              onClick={() => {
                const event = {
                  target: {
                    value: day.value,
                    name: 'days_of_week',
                  },
                };
                onSelect(event, index);
                // handleArrayChange(
                //   {
                //     target: {
                //       value: day.value,
                //       name: 'recurrenceDaysOfWeek',
                //     },
                //   },
                //   index,
                // );
              }}
              key={day.value}
              className={`btn btn-light btnDay font-normal-500 ${isActive ? 'active' : ''}`}
            >
              {day.letter}
            </a>
          );
        })}
      </div>
    </div>
  );
}

export default WeekInputs;
