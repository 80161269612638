/* eslint-disable no-unused-vars */
import { useIsMutating } from '@tanstack/react-query';
import { Plus, X } from 'lucide-react';
import React, { useMemo, useState } from 'react';
import { Button } from '../../_components';
import { CUSTOM_FIELD_EDITED } from '../SettingsLabels';
import { FieldTypes } from '../UtilsInSettings';

export function EmployeeFieldAdd({
  setSelectedField,
  modalClose,
  selectedField,
  inputFields,
  setInputFields,
  handleCreate,
}) {
  const isMutating = useIsMutating();
  const [submitted, setSubmitted] = useState(false);
  //-- when field type selection
  const onFieldTypeChange = (fieldType, fields) => {
    setSelectedField(fieldType.type);
    // edit case can only update fieldType
    if (fields[0]?.id) {
      const updatedFields = fields.map((field) => ({
        ...field,
        fieldType: fieldType.type,
      }));
      setInputFields(updatedFields);
    } else {
      //-- change felid type when add case
      const newArray = [...fields];
      const lastItem = newArray[newArray.length - 1];
      lastItem.fieldType = fieldType.type;
      setInputFields(newArray);
    }
  };

  //-- input box on-change
  const handleChange = (index, value) => {
    const newFields = [...inputFields];
    newFields[index].label = value;
    setInputFields(newFields);
    setSubmitted(false);
  };

  //-- when input field add
  const handleAddField = (fields, selectedField) => {
    const lastField = fields[fields.length - 1];
    if (lastField && !lastField.label) {
      setSubmitted(true);
      return;
    }
    setInputFields((prev) => [...prev, { fieldType: selectedField, ...CUSTOM_FIELD_EDITED }]);
  };

  //-- when selected input field  remove
  function handleRemoveField(fields, index) {
    const newFields = [...fields];
    newFields.splice(index, 1);
    setInputFields(newFields);
  }

  const handleSave = () => {
    setSubmitted(true);
    if (inputFields[inputFields?.length - 1].label) {
      handleCreate(inputFields);
    }
  };

  // field map return jsx
  const selectedFields = useMemo(
    () =>
      inputFields?.map((FIELD, index) => (
        <div key={index} style={{ position: 'relative', marginTop: 8, marginBottom: 15 }}>
          <div className='field-type'>{FIELD.fieldType} field</div>
          {!FIELD?.id && (
            <div onClick={() => handleRemoveField(inputFields, index)} className='close-absolute'>
              <X size={20} />
            </div>
          )}
          <input
            value={FIELD.label}
            onChange={(e) => handleChange(index, e.target.value)}
            className='emp-input'
            type='text'
          />
          {submitted && !FIELD.label && <div className='error-txt'>Fill details</div>}
        </div>
      )),
    [inputFields, submitted],
  );

  return (
    <div className='emp-create-contain'>
      {/* left side */}
      <div className='left-side'>
        <div className='create-text'>{inputFields[0]?.id ? 'Update' : 'Create'} a field</div>
        <div className='choose-txt'>Choose type</div>

        <div className='emp-card'>
          {FieldTypes.map((fieldType) => {
            let classSelected = selectedField === fieldType.type ? 'selected-card' : '';
            return (
              <>
                <TypeSelection
                  handleOK={() => onFieldTypeChange(fieldType, inputFields)}
                  fieldType={fieldType}
                  customClass={classSelected}
                />
              </>
            );
          })}
        </div>
      </div>
      {/* left side end  */}
      {/* right side */}
      <div className='field'>
        <div className='field-head'>
          <div className='choose-txt mb-2'>Fields</div>
          {!inputFields[0]?.id && (
            <AddButton handleOK={() => handleAddField(inputFields, selectedField)} />
          )}
        </div>

        <div style={{ flexGrow: 1, overflow: 'scroll', overflowX: 'hidden' }}>{selectedFields}</div>

        <div className='emp-btn-contain'>
          <Button.Container handleOk={() => modalClose()} className={'cancel-btn'}>
            <Button.Title title={'Cancel'} />
          </Button.Container>
          <Button.Container
            isDisabled={isMutating}
            handleOk={() => handleSave()}
            className={'create-btn'}
          >
            <Button.Title title={'Save'} />
            <Button.Loading isLoading={isMutating} />
          </Button.Container>
        </div>
      </div>
      {/* right side end  */}
    </div>
  );
}

const TypeSelection = ({ handleOK, customClass, fieldType }) => {
  return (
    <div onClick={handleOK} className={`single-card ${customClass}`}>
      {fieldType.icon}
      <div>{fieldType.name}</div>
    </div>
  );
};

const AddButton = ({ handleOK }) => {
  return (
    <div onClick={handleOK} className='emp-add-btn'>
      Add
      <Plus size={12} style={{ marginTop: 3 }} />
    </div>
  );
};
