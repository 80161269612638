/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import { authService } from '../_services';
import { useUser } from './useUser';

// Define your custom hook
export function useUserValidate() {
  const user = useUser();
  // user get
  const {
    data,
    isLoading: validateLoading,
    refetch,
  } = useQuery({
    queryKey: ['user-validate'],
    queryFn: () => authService.userValidate(),
    enabled: user?.id ? true : false,
  });

  return { data, validateLoading, refetch };
}
