export const DropDownStyle = {
  container: (styles) => {
    return {
      ...styles,
      fontSize: '13px',
      fontWeight: '400',
      // minWidth: '170px',
    };
  },

  control: (styles, { isFocused }) => {
    return {
      ...styles,
      minHeight: '25px',
      borderColor: isFocused ? '#3454D1' : '#87909E',
      boxShadow: isFocused ? '0 0 0 3px rgba(52, 84, 209, 0.25)' : styles.boxShadow,
      '&:hover': {
        borderColor: isFocused ? '#3454D1' : styles['&:hover']?.borderColor || styles.borderColor,
      },
    };
  },
  indicatorsContainer: (styles) => {
    return {
      ...styles,
      minHeight: '25px',
      height: '25px',
      color: '#2A2E34',
    };
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: (styles) => {
    return {
      ...styles,
      minWidth: '170px',
    };
  },
  singleValue: (styles) => {
    return {
      ...styles,
      fontWeight: '500',
    };
  },
  dropdownIndicator: (styles) => {
    return {
      ...styles,
      color: '#2A2E34',
      '&:hover': {
        color: '#2A2E34',
      },
    };
  },
};
export const durationOptions = [
  { label: 'Custom', value: 0 },
  { label: '1 Week', value: 1 },
  { label: '2 Week', value: 2 },
  { label: '3 Week', value: 3 },
  { label: '4 Week', value: 4 },
];
