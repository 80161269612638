import { useRef, useState } from 'react';

/***
 * For adding keyboard handling to a component
 */
function useComponentInteractionHandler({ showOnFocus = false } = {}) {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);
  const textEditorRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setIsOpen(false);
      return;
    }

    if (
      (event.key === 'Enter' || event.key === ' ') &&
      !textEditorRef.current?.contains(document.activeElement)
    ) {
      event.preventDefault();
      setIsOpen(!isOpen);
    }
  };

  const handleClick = () => {
    if (
      !textEditorRef.current?.contains(document.activeElement) &&
      !textEditorRef.current?.contains(event.target)
    ) {
      setIsOpen(!isOpen);
    }
  };

  const handleFocus = () => {
    !showOnFocus && textEditorRef.current?.focus();

    showOnFocus && setIsOpen(true);
  };

  const handleBlur = (event) => {
    if (
      !selectRef.current.contains(event.relatedTarget) &&
      !textEditorRef.current?.contains(event.relatedTarget)
    ) {
      setIsOpen(false);
    }
  };

  //   const handleKeyDown = (event) => {
  //     if (disableCloseOnKeyDown) {
  //       setIsOpen(true);
  //       return;
  //     }

  //     if (event.key === 'Enter' || event.key === ' ') {
  //       event.preventDefault();

  //       setIsOpen(closeOnKeyDown ? false : (prev) => !prev);
  //     }
  //   };

  //   const handleFocus = (e) => {
  //     if (openOnFocus) {
  //       e.preventDefault();
  //       setIsOpen(true);
  //     }
  //   };

  //   const handleClick = () => {
  //     if (disableCloseOnClick) {
  //       setIsOpen(true);
  //       return;
  //     }
  //     setIsOpen(!isOpen);
  //   };

  //   const handleBlur = (event) => {
  //     if (!selectRef.current.contains(event.relatedTarget)) {
  //       setIsOpen(false);
  //     }
  //   };

  return {
    isOpen,
    setIsOpen,
    handleBlur,
    handleClick,
    handleKeyDown,
    handleFocus,
    selectRef,
    textEditorRef,
  };
}

export default useComponentInteractionHandler;
