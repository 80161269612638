/* eslint-disable no-unused-vars */
import React from 'react';

import '../common.css';

export const ToggleButton = ({
  size,
  isChecked,
  handleToggle,
  id,
  updateOnBlur,
  disabled = false,
}) => {
  const toggleWidth = `${size}px`;
  const toggleHeight = `${size / 2}px`;

  const toggleWrapperStyle = {
    '--toggle-width': toggleWidth,
    '--toggle-height': toggleHeight,
  };

  return (
    <div className='toggle-button-wrapper' style={toggleWrapperStyle}>
      <input
        id={`toggle-checkbox-${id}`}
        type='checkbox'
        className='toggle-checkbox'
        checked={isChecked}
        disabled={disabled}
        name={id}
        onChange={handleToggle}
        onBlur={updateOnBlur ?? updateOnBlur}
      />
      <label htmlFor={`toggle-checkbox-${id}`} className='toggle-label'>
        <div className='toggle-handle'></div>
      </label>
    </div>
  );
};
