import React from 'react';
import { months } from '../Calendar/Utils/misc';

function MonthView({ selectedMonth, setMonthIndex, setStatus, setSelectedDate, gridCol }) {
  return (
    <div className='view month-view' style={{ gridTemplateColumns: `repeat(${gridCol}, 1fr)` }}>
      {months.map((month, index) => {
        return (
          <>
            <div
              onClick={() => {
                setSelectedDate((prev) => ({ ...prev, month: index }));
                setMonthIndex(index);
                setStatus('initial');
              }}
              className={`${months[selectedMonth] === month ? 'selected' : ''} block`}
            >
              <div />
              {month}
            </div>
          </>
        );
      })}
    </div>
  );
}

export default MonthView;
