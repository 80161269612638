import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { ChevronDown, Ellipsis, Plus, Search } from 'lucide-react';
import { default as React, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, ContextMenu, Modal, PaginationComponent, TableRow } from '../_components';
import useDebounce from '../_helpers/useDebounce';

import TeamAdd from '../Team/TeamAdd';
import { Images, fullNameUser } from '../Utils';
import { LoadingIcon } from '../Utils/SvgIcons';
import StackedAvatars from '../_components/Avatar/StackedAvatars';
import { ErrorComponent } from '../_components/ErrorComponent';
import { DELETE_NOTE } from '../_constants/deletenote.constants';
import { teamService } from '../_services/team.service';

const TeamList = () => {
  // reference section
  const reference = useRef(null);

  const queryClient = useQueryClient();
  const [addModal, setAddModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [deleteModal, setDeleteModal] = useState(false);
  const [contextMenuIndex, setContextMenuIndex] = useState(null);
  const [editTeamID, setEditTeamID] = useState(null);

  const [query, setQuery] = useState({
    select: ['id', 'name', 'company_id', 'members', 'members_details', 'Action'],
    searchKey: '',
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
  });
  const TABLE_COLUMN = ['Sl.No', 'Name', 'Organization', 'Members', 'Action'];

  const debouncedSearch = useDebounce(query?.searchKey, 500);

  const {
    data: TeamData,
    refetch,
    error,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ['teamList', query?.pageVo?.pageNo, debouncedSearch],
    queryFn: () => teamService.teamList(query),
    placeholderData: keepPreviousData,
  });

  // search box handleChange
  const handleSearch = (event) => {
    searchParams.set('pageNo', 1);
    setSearchParams(searchParams);
    let search = event.target.value;
    setQuery((prevQuery) => ({
      ...prevQuery,
      pageVo: { ...query.pageVo, pageNo: 1 },
      searchKey: search,
    }));
  };

  const handleClickOption = (option) => {
    if (option == 'edit') {
      setAddModal(true);
    } else if (option == 'delete') {
      setDeleteModal(true);
    }
  };

  const modalClose = () => {
    setDeleteModal(false);
    setEditTeamID(null);
  };
  const handleDeactivate = async (id) => {
    try {
      await teamService.teamDeactivate({ id });
      await queryClient.invalidateQueries('branch');
      modalClose();
    } catch (error) {
      console.error('Error deleting branch:', error);
    }
  };

  const listItemCount = TeamData?.data?.rows.length;
  const teamRows = TeamData?.data?.rows.map((team, index) => {
    const slNo = (TeamData?.data?.page - 1) * TeamData?.data?.limit + index + 1;
    return (
      <tr key={index}>
        <td>{slNo}</td>
        <td>{team?.name} </td>
        <td>{team?.['Company.name']} </td>
        <td className='d-flex justify-content-start'>
          <StackedAvatars
            getSourceByKey={(user) => user.image_url}
            getNameByKey={(user) => fullNameUser(user.first_name, user.last_name)}
            getGenderByKey={(user) => user.gender === 1}
            imageList={team?.members_details}
            showInitials={false}
            size={25}
          />
        </td>

        <td style={{ verticalAlign: 'middle', cursor: 'pointer' }}>
          <div
            className='ellipsis_icon'
            ref={reference}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <div onClick={() => setContextMenuIndex(index)}>
              <Ellipsis />
            </div>
            <ContextMenu
              handleClick={(value) => {
                setEditTeamID(team?.id);
                handleClickOption(value);
              }}
              isContextVisible={contextMenuIndex === index}
              onRequestClose={() => {
                setContextMenuIndex(null);
              }}
            />
          </div>
        </td>
      </tr>
    );
  });

  return (
    <div>
      <div className='organization_list sub_padding'>
        <ErrorComponent error={error?.message} />

        <section>
          <div className='tab_contain mt-2'>
            <div className='search_contain'>
              <Search size={20} color='black' />
              <input
                type='text'
                className='bodr_none'
                onChange={(event) => {
                  handleSearch(event);
                }}
              />
            </div>
            <div className='d-flex gap-2'>
              <div className='add_item_button' onClick={() => setAddModal(true)}>
                <Plus size={16} /> User group
              </div>
              <div className='list_top_card'>
                Sort by <ChevronDown size={13} />
              </div>
            </div>
          </div>

          <section className='mt-2'>
            <div className='table_outer'>
              <table className='table table-hover tbl mb-0'>
                <thead>
                  <tr>
                    {TABLE_COLUMN.map((heading, index) => {
                      return (
                        <th key={index} scope='col'>
                          {heading}
                        </th>
                      );
                    })}
                  </tr>
                </thead>

                <tbody>
                  {isLoading && (
                    <TableRow noOfCols={7}>
                      <LoadingIcon color='blue' size={60} />
                    </TableRow>
                  )}
                  {!listItemCount && isSuccess && <TableRow noOfCols={7}>No data found</TableRow>}
                  {listItemCount > 0 && isSuccess && teamRows}
                </tbody>
              </table>
            </div>
          </section>
          {listItemCount > 0 && (
            <section className='mt-2'>
              <PaginationComponent
                pageOnClick={(data) => {
                  setQuery((prev) => ({
                    ...prev,
                    pageVo: {
                      ...prev.pageVo,
                      pageNo: data,
                    },
                  }));
                  searchParams.set('pageNo', data);
                  setSearchParams(searchParams);
                }}
                demoData={TeamData?.data}
              />
            </section>
          )}
        </section>

        {addModal && (
          <Modal.Container handleClose={() => setAddModal(false)}>
            <Modal.View className={'modal_view_branch'}>
              <Modal.Head
                Heading={editTeamID ? 'Edit user group' : 'Add user group'}
                handleClose={() => {
                  setAddModal(false);
                  setEditTeamID(null);
                }}
              />
              <TeamAdd
                id={editTeamID}
                handleClose={() => {
                  setAddModal(false);
                  setEditTeamID(null);
                  refetch();
                }}
              />
            </Modal.View>
          </Modal.Container>
        )}
      </div>
      {deleteModal && (
        <Modal.Container>
          <Modal.View className={'status-delete'}>
            <Modal.Head handleClose={() => modalClose()} Heading={'Delete Confirmation'} />
            <Modal.Description>
              <img src={Images.DeleteBin} width={35} height={50} alt='' className='mb-3' />
              <div className='del-description'>{DELETE_NOTE.TEAM}</div>
            </Modal.Description>
            <div className='status-del-btn'>
              <Button.Container handleOk={() => modalClose()} className={'status-del-cancel'}>
                <Button.Title title={'Cancel'} />
              </Button.Container>
              <Button.Container handleOk={() => handleDeactivate(editTeamID)}>
                <Button.Title title={'Delete'} />
              </Button.Container>
            </div>
          </Modal.View>
        </Modal.Container>
      )}
    </div>
  );
};

export default TeamList;
