import { apiConstants } from '../_constants';
import { authHeader, handleResponse } from '../_helpers';

export const reportServices = {
  timeSheetList,
  getTimeHistory,
  workLogList,
  teamReportList,
  graphicalTaskReportList,
};

async function timeSheetList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/task_time_sheet_list', requestOptions).then(handleResponse);
}

async function getTimeHistory(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/time_history', requestOptions).then(handleResponse);
}

async function workLogList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/work_log_list', requestOptions).then(handleResponse);
}

async function teamReportList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/list_my_team_task_report', requestOptions).then(handleResponse);
}

async function graphicalTaskReportList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/get_graphical_task_report', requestOptions).then(
    handleResponse,
  );
}
