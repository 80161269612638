export const DropDownStyle = {
  container: (styles) => {
    return {
      ...styles,
      fontSize: '13px',
      minHeight: '35px',
      minWidth: '170px',
    };
  },
  control: (styles) => {
    return {
      ...styles,
      minHeight: '30px',
    };
  },
  indicatorsContainer: (styles) => {
    return {
      ...styles,
      minHeight: '30px',
      height: '30px',
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      minWidth: '170px',
    };
  },
};

export const TeamState = {
  name: '',
  company_id: '',
  members: [],
  members_details: [],
  group_required: false,
};

export const teamSelect = [
  'id',
  'name',
  'company_id',
  'members',
  'members_details',
  'group_required',
];

export const userSelect = [
  'id',
  'first_name',
  'last_name',
  'email',
  'phone_no',
  'address1',
  'image_url',
  'gender',
  'employee_code',
];

export const TEAM_LIST_BASED_ON_USER = {
  OKR_ADD: 'OKR_ADD',
};
