/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { taskService } from '../../_services';
import { STATUS } from '../taskConstants';

export const NextAndPrev = ({ currentIndex: _currentIndex = 10, status = STATUS.ACTIVE }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const getParams = () => {
    const params = new URLSearchParams(location.search);
    const initialIndex =
      params.get('currentIndex') !== null
        ? parseInt(params.get('currentIndex'), _currentIndex)
        : null;
    const taskTotalCountParam = parseInt(params.get('taskTotalCount'), 10) || 0;
    const nextAndPreviousFromChatUpdate = params.get('nextAndPreviousFromChatUpdate') || false;
    const nextAndPrevFromTaskList = params.get('nextAndPrevFromTaskList') || false;

    const queryParams = {};
    params.forEach((value, key) => {
      try {
        queryParams[key] = JSON.parse(value);
      } catch (e) {
        queryParams[key] = value;
      }
    });

    return {
      initialIndex,
      taskTotalCountParam,
      nextAndPreviousFromChatUpdate,
      nextAndPrevFromTaskList,
      queryParams,
    };
  };

  const {
    initialIndex,
    taskTotalCountParam,
    nextAndPreviousFromChatUpdate,
    nextAndPrevFromTaskList,
    queryParams,
  } = getParams();

  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [taskTotalCount, setTaskTotalCount] = useState(taskTotalCountParam);

  useEffect(() => {
    const { initialIndex, taskTotalCountParam, queryParams } = getParams();
    setCurrentIndex(initialIndex);
  }, [location.search]);

  const getAllNextAndPrevTask = async (params) => {
    if (nextAndPrevFromTaskList) {
      if (params.default_name === 'all' && params.status === 'all') {
        // Remove status_group_item_id from params for status all
        delete params.status_group_item_id;
      }
      const res = await taskService.getNextAndPrevTask({ ...params, status: status });
      const taskIdMap = new Map(res?.data?.map((item) => [item.index, item.id]));

      return taskIdMap;
    } else if (nextAndPreviousFromChatUpdate) {
      const nextAndPrevTaskData = await taskService.getNextAndPrevTask(params);
      const taskIdMap = new Map(
        nextAndPrevTaskData?.data?.taskListData?.map((item) => [item.index, item.task_id]),
      );

      setTaskTotalCount(nextAndPrevTaskData?.data?.total);

      return taskIdMap;
    } else {
      return new Map();
    }
  };

  const {
    data: nextPrevTaskData,
    error: nextPrevTaskError,
    isLoading: nextPrevTaskLoading,
  } = useQuery({
    queryKey: ['nextAndPrevTask', { ...queryParams, currentIndex: undefined }],
    queryFn: () => getAllNextAndPrevTask(queryParams),
    refetchOnWindowFocus: false,
  });

  const handleNext = async () => {
    if (currentIndex < taskTotalCount - 1) {
      const newIndex = currentIndex + 1;
      setCurrentIndex(newIndex);
      let taskId = nextPrevTaskData.get(newIndex);

      if (!taskId) {
        // Update queryParams with the new index
        const newQueryParams = { ...queryParams, currentIndex: newIndex };

        // Refetch data with updated queryParams
        const newTaskData = await getAllNextAndPrevTask(newQueryParams);

        // Iterate over the entries of the newTaskData Map
        newTaskData.forEach((value, key) => {
          nextPrevTaskData.set(key, value);
        });
        taskId = nextPrevTaskData.get(newIndex);
      }

      if (taskId) {
        const newParams = new URLSearchParams(location.search);
        newParams.set('currentIndex', newIndex);
        navigate(`/task/${taskId}?${newParams.toString()}`);
      }
    }
  };

  const handlePrev = async () => {
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      setCurrentIndex(newIndex);
      let taskId = nextPrevTaskData.get(newIndex);

      if (!taskId) {
        // Update queryParams with the new index
        const newQueryParams = { ...queryParams, currentIndex: newIndex };

        // Refetch data with updated queryParams
        const newTaskData = await getAllNextAndPrevTask(newQueryParams);

        // Iterate over the entries of the newTaskData Map
        newTaskData.forEach((value, key) => {
          nextPrevTaskData.set(key, value);
        });
        taskId = nextPrevTaskData.get(newIndex);
      }

      if (taskId) {
        const newParams = new URLSearchParams(location.search);
        newParams.set('currentIndex', newIndex);
        navigate(`/task/${taskId}?${newParams.toString()}`);
      }
    }
  };

  if (nextPrevTaskError) {
    return <div>--</div>;
  }

  return (
    <div>
      <div className='d-flex gap-2 '>
        <ChevronLeft
          color='black'
          strokeWidth={1.5}
          size={28}
          onClick={handlePrev}
          style={{
            cursor: currentIndex === 0 || initialIndex === null ? 'not-allowed' : 'pointer',
            opacity: currentIndex === 0 || initialIndex === null ? 0.5 : 1,
          }}
        />
        {/* {initialIndex !== null && <div>{${currentIndex + 1} of ${taskTotalCount}}</div>} */}

        {initialIndex !== null && (
          <div style={{ display: 'flex', alignItems: 'center', userSelect: 'none' }}>
            <span style={{ fontWeight: 'bold', marginRight: '5px', fontSize: '13px' }}>
              {currentIndex + 1}
            </span>
            <span style={{ fontSize: '13px' }}>|</span>
            <span style={{ fontWeight: 'bold', marginLeft: '5px', fontSize: '13px' }}>
              {taskTotalCount}
            </span>
          </div>
        )}

        <ChevronRight
          color='black'
          strokeWidth={1.5}
          size={28}
          onClick={handleNext}
          style={{
            cursor:
              currentIndex === taskTotalCount - 1 || initialIndex === null
                ? 'not-allowed'
                : 'pointer',

            opacity: currentIndex === taskTotalCount - 1 || initialIndex === null ? 0.5 : 1,
          }}
        />
      </div>
    </div>
  );
};
