import { useEffect, useRef } from 'react';

function useResizeObserver({ onResize = () => {}, customRef = null }) {
  const elementRef = useRef(null);

  useEffect(
    () => {
      const handleResize = (entries) => {
        for (let entry of entries) {
          const { width, height } = entry.contentRect;
          onResize(width, height);
        }
      };

      const resizeObserver = new ResizeObserver(handleResize);

      if (elementRef.current) {
        resizeObserver.observe(elementRef.current);
      } else if (customRef?.current) {
        resizeObserver.observe(customRef.current);
      }

      return () => {
        if (elementRef.current) {
          resizeObserver.unobserve(elementRef.current);
        } else if (customRef?.current) {
          resizeObserver.unobserve(customRef.current);
        }
      };
    },
    customRef ? [customRef.current] : [elementRef.current],
  );

  return { elementRef };
}

export default useResizeObserver;
