import { ChevronLeft } from 'lucide-react';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { LoadingIcon } from '../../Utils/SvgIcons';
import ChatUserRow from '../ChatUserRow';

function ChatUpdateList({
  isLoading,
  isError,
  updates,
  error,
  isActive,
  onUpdateClick,
  showCategoryHeader = true,
  onChatHomeClick,
}) {
  const [searchParams] = useSearchParams();
  const isUnread = Boolean(searchParams.get('unread'));

  if (isLoading) {
    return (
      <div
        className='d-flex align-items-center justify-content-center'
        style={{ height: `calc(100vh - var(--tob-bar-height))` }}
      >
        <LoadingIcon size={50} />
      </div>
    );
  }

  if (isError || !updates) {
    return (
      <div className='fz-13px update-padding grey-text text-center mt-3'>
        {error?.message ?? 'Something went wrong'}
      </div>
    );
  }

  if (updates.length <= 0) {
    if (isUnread) {
      return (
        <div className='fz-13px update-padding grey-text text-center mt-100-center'>
          No {isUnread ? 'unread' : ''} updates
        </div>
      );
    }
    return null;
  }

  return (
    <>
      {showCategoryHeader && (
        <div className='update-padding pb-2 sticky-head d-flex'>
          {searchParams.get('update_id') ? (
            <div
              className='chat_msg fz-13px pointer '
              style={{ color: '#656F7D' }}
              onClick={onChatHomeClick}
            >
              <ChevronLeft size={13} /> Back
            </div>
          ) : (
            <div className='chat_msg fz-13px' style={{ color: '#656F7D' }}>
              Messages
            </div>
          )}
        </div>
      )}
      {updates.map((update) => {
        return (
          <ChatUserRow
            update={update}
            key={update.id}
            active={() => isActive(update)}
            onClick={(update) => {
              onUpdateClick(update);
            }}
          />
        );
      })}
    </>
  );
}

export default ChatUpdateList;
