import { Plus } from 'lucide-react';
import React, { useState } from 'react';
import { Modal } from '../_components';
import { OKRImage } from '../Utils';
import { okrFormAction } from './okrConstants';
import OkrObjectiveAdd from './OkrObjectiveAdd';

export const EmptyOkr = ({ refetch }) => {
  const [isObjectiveAdd, setIsObjectiveAdd] = useState(false);

  return (
    <div
      className='d-flex align-items-center justify-content-center'
      style={{ flexDirection: 'column', height: '70vh' }}
    >
      <div className='icon-outer-file'>
        <img src={OKRImage.EmptyOkr} alt='no' height={60} width={60} />
      </div>
      <span className='empty-head'>No OKR’s Found</span>
      <span className='empty-text'> Your all OKRs are listed here </span>
      <div
        className='mt-3 empty-button '
        onClick={() => {
          setIsObjectiveAdd(true);
        }}
      >
        <Plus size={15} />
        {'Create'}

        {isObjectiveAdd && (
          <Modal.Container>
            <Modal.View className={'okr-modal-view'} containerClass={'objective-add-container'}>
              <OkrObjectiveAdd
                handleClose={(value, event = null) => {
                  if (value === okrFormAction.Close) {
                    event.stopPropagation();
                  }
                  if (value === okrFormAction.Save) {
                    // Refetch data after save and close the modal
                    refetch();
                  }
                  setIsObjectiveAdd(false);
                }}
              />
            </Modal.View>
          </Modal.Container>
        )}
      </div>
    </div>
  );
};
