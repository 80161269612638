import { NotebookPen } from 'lucide-react';
import React, { useContext, useState } from 'react';
import { NoteEditor } from '../BoardView/NoteEditor';
import '../BoardView/NoteEditor.css';
import { getSettingsByName, getSettingValue, GlobalContext } from '../Utils';
import { Modal } from './Modal';

export function NoteContainer() {
  const [isOpen, setIsOpen] = useState(false);

  const { globalSettings } = useContext(GlobalContext);

  const GeneralSettings = getSettingsByName(globalSettings, 'general-settings');

  let notePermitted = getSettingValue(GeneralSettings, 'note');

  return (
    <>
      {notePermitted && (
        <div className='d-flex pointer clickable' role='button' onClick={() => setIsOpen(true)}>
          <NotebookPen size={17} />
        </div>
      )}

      {isOpen && (
        <Modal.Container handleClose={() => setIsOpen(false)}>
          <Modal.View className='note-modal'>
            <NoteEditor setIsOpen={setIsOpen} />
          </Modal.View>
        </Modal.Container>
      )}
    </>
  );
}
