import React from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '../Hook/useUser';
import { ADMIN } from '../_constants/filesystem.constant';

export const OptionTabs = ({ activeTab, setActiveTab }) => {
  const { folderId } = useParams();
  const user = useUser();

  // Base tabs without the "Client" tab
  const baseTabs = [
    { name: 'Share', value: 'share' },
    { name: 'Folder', value: 'folder' },
    { name: 'Expire & Notification', value: 'expire' },
  ];

  // Conditionally add the "Client" tab if user.role is 1
  const TABS = user.role === ADMIN ? [...baseTabs, { name: 'Client', value: 'client' }] : baseTabs;

  // Filter out the "Folder" tab if folderId is present
  const filteredTabs = folderId ? TABS.filter((tab) => tab.value !== 'folder') : TABS;

  const isActive = (tabName) => activeTab === tabName;

  return (
    <section className='file_tabs' >
      <div className='file-tab-line' style={{ top: 30 }} />
      <div className='d-flex gap-3 my-2 flex-wrap'>
        {filteredTabs.map((item, index) => (
          <div
            key={index}
            style={{ zIndex: '2' }}
            className={`${isActive(item.name) ? 'tab_active' : 'tab_inactive'}`}
            onClick={() => setActiveTab(item.name)}
          >
            {item.name}
          </div>
        ))}
      </div>
    </section>
  );
};
