export const filterSelectBoxStyles = {
  control: (provided, state) => ({
    ...provided,
    background: '#F2F2F2',
    minHeight: '10px',
    border: 'none',
    fontSize: '13px',
    boxShadow: state.isFocused ? 'none' : provided.boxShadow,
    maxWidth: '15.8vw',
    height: 30,
  }),
  menu: (provided) => ({
    ...provided,
    minWidth: '250px',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    overflow: 'auto',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  valueContainer: (style) => ({
    ...style,
    scrollbarWidth: 'none',
    overflow: 'auto',
    flexWrap: 'nowrap',
  }),
  multiValue: (style) => ({
    ...style,
    minWidth: 'none',
  }),
};

export const timeSheetSelect = [
  'id',
  'date',
  'status',
  'start_time',
  'end_time',
  'edited_start_time',
  'edited_end_time',
  'note',
  'time_difference',
  'edited_time_difference',
  'mode',
];
