import { Ellipsis, Pen, Trash } from 'lucide-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContextMenu } from '../_components';
import { Popover } from '../_components/Popover';
import { RT } from '../_constants';
import { FolderImage, InfoIcon, LoadingIcon } from '../Utils/SvgIcons';
import { FolderAdd } from './FolderAdd';
import { FolderDetails } from './FolderDetails';

export const FolderView = ({
  list,
  setDeleteModal,
  isLoading,
  count,
  isSuccess,
  alert,
  isFetching,
}) => {
  const [contextMenuIndex, setContextMenuIndex] = useState(null);
  const [editVisibleIndex, setEditVisibleIndex] = useState(false);
  const [detailsVisibleIndex, setDetailsVisibleIndex] = useState(false);
  // const { containerRef: folderAddRef } = useOutsideClickHandler({
  //   onClose: () => setEditVisibleIndex(null),
  // });
  const navigate = useNavigate();
  // const { makeAlert } = useContext(GlobalContext);

  return (
    <div>
      {(isLoading || isFetching) && (
        <div className='text-center w-100 fz-13px-rem'>
          <LoadingIcon color='blue' size={50} />
        </div>
      )}
      <div className='folder-view-outer'>
        {count > 0 &&
          isSuccess &&
          !isFetching &&
          list?.map((folder, index) => {
            const isActive = contextMenuIndex === index;
            const isContextMenuActive =
              editVisibleIndex === folder?.id || detailsVisibleIndex === folder?.id;
            return (
              <div key={index} className='folder-container'>
                <div style={{ margin: ' -10px 0px -15px' }} className='d-flex justify-content-end'>
                  <div
                    className='ellipsis_icon'
                    style={{
                      borderRadius: '4px',
                      padding: '1px 2px',
                      background: isActive || isContextMenuActive ? '#E9F2FF' : '',
                      color: isActive || isContextMenuActive ? '#3454D1' : '',
                    }}
                  >
                    <div
                      onClick={() => {
                        setEditVisibleIndex(null);
                        setDetailsVisibleIndex(null);

                        setContextMenuIndex(isActive ? null : index);
                      }}
                    >
                      <Ellipsis />
                    </div>

                    <ContextMenu
                      isContextVisible={isActive}
                      onRequestClose={() => {
                        setContextMenuIndex(null);
                      }}
                      positionClass={'position'}
                    >
                      <div
                        className='menu_items'
                        onClick={(event) => {
                          event.stopPropagation();
                          setContextMenuIndex(null);
                          setDetailsVisibleIndex(folder?.id);
                        }}
                      >
                        <div className='d-flex align-items-center gap-2'>
                          <InfoIcon color='#2A2E34' />
                          Details
                        </div>
                      </div>

                      <div
                        className='menu_items'
                        onClick={(event) => {
                          event.stopPropagation();
                          setContextMenuIndex(null);
                          setEditVisibleIndex(folder?.id);
                        }}
                      >
                        <div className='d-flex align-items-center gap-2'>
                          <Pen size={17} color='#2A2E34' />
                          Edit
                        </div>
                      </div>
                      <div
                        className='menu_items'
                        style={{ color: '#BC4841' }}
                        onClick={(event) => {
                          if (parseInt(folder?.file_count) !== 0) {
                            alert(
                              'Cannot delete folder. Files exist in the folder. Please delete all files before deleting the folder.',
                            );
                          } else {
                            event.stopPropagation();
                            setContextMenuIndex(null);
                            setDeleteModal(folder?.id);
                          }
                        }}
                      >
                        <div className='d-flex align-items-center gap-2'>
                          <Trash size={18} color='#BC4841' />
                          Delete
                        </div>
                      </div>
                    </ContextMenu>
                    {editVisibleIndex === folder?.id && (
                      <Popover.PopoverItem
                        onClose={() => setEditVisibleIndex(null)}
                        positionLeft={(width) => -330 + width}
                      >
                        <FolderAdd
                          id={folder?.id}
                          handleClose={(event) => {
                            if (event) {
                              event.stopPropagation();
                            }
                            setEditVisibleIndex(null);
                          }}
                        />
                      </Popover.PopoverItem>
                    )}
                    {detailsVisibleIndex === folder?.id && (
                      <FolderDetails
                        id={folder?.id}
                        handleClose={(event) => {
                          if (event) {
                            event.stopPropagation();
                          }
                          setDetailsVisibleIndex(null);
                        }}
                      />
                    )}
                  </div>
                </div>
                <div
                  style={{ padding: 0, cursor: 'pointer' }}
                  onClick={() => {
                    navigate(`${RT.FILELIST}/${folder?.id}?folder_name=${folder?.name}`);
                  }}
                >
                  <FolderImage height={55} width={66} />
                </div>

                {/* <ToolTip tooltipText={folder?.name} isModern containerClassName={'w-fit-content'}> */}
                <div
                  className='folder-view-type-name mt-2 cursor-pointer'
                  onClick={() => {
                    navigate(`${RT.FILELIST}/${folder?.id}?folder_name=${folder?.name}`);
                  }}
                >
                  {folder?.name}
                </div>
                {/* </ToolTip> */}

                <div className='folder-size-value'>{folder?.file_count} files</div>
              </div>
            );
          })}
      </div>
      {!count && isSuccess && !isFetching && (
        <div className='text-center w-100 fz-13px-rem'>No data found</div>
      )}
    </div>
  );
};
