import moment from 'moment';
import React, { useContext } from 'react';

import { Calendar } from 'lucide-react';
import { z } from 'zod';
import ToolTip from '../_components/Tooltip/ToolTip';
import { GlobalContext } from '../Utils';
import { BoardIcon, ListIcon } from '../Utils/SvgIcons';
import { fieldTypes, TASK_TABS, taskValidationConstant } from './taskConstants';
export const taskSchema = z.object({
  // Name should be required with more than 1 character
  name: z
    .string({
      required_error: taskValidationConstant.TASK_NAME_REQUIRED,
      invalid_type_error: taskValidationConstant.INVALID_TASK_NAME,
    })
    .min(1, { message: taskValidationConstant.INVALID_TASK_NAME }),
  // Expected hours, task start date & task end date - Number greater than 0(dates are in milliseconds)
  task_start_date: z
    .number({
      required_error: taskValidationConstant.TASK_START_DATE_REQUIRED,
      invalid_type_error: taskValidationConstant.INVALID_START_DATE,
    })
    .gt(0),
  task_end_date: z
    .number({
      required_error: taskValidationConstant.TASK_END_DATE_REQUIRED,
    })
    .gt(0),
  expected_hours: z
    .number({
      required_error: taskValidationConstant.EXPECTED_HOURS_REQUIRED,
      invalid_type_error: taskValidationConstant.INVALID_EXPECTED_HOURS,
    })
    .gt(0, { message: taskValidationConstant.INVALID_EXPECTED_HOURS }),
  // Subtask is an array, not mandatory.
  subtasks: z
    .array(
      z.object({
        content: z.string({
          invalid_type_error: 'Invalid subtask content',
          required_error: 'Subtask content is required',
        }),
      }),
    )
    .optional(),
  // Assignee id should be greater than 0
  assignee_id: z
    .number({
      required_error: taskValidationConstant.ASSIGNEE_ID_REQUIRED,
      invalid_type_error: taskValidationConstant.INVALID_ASSIGNEE_ID,
    })
    .gt(0, { message: taskValidationConstant.INVALID_ASSIGNEE_ID }),

  // ///okr parent id when task add under okr
  // okr_parent_id: z
  //   .number({
  //     required_error: taskValidationConstant.OKR_PARENT_ID_REQUIRED,
  //     invalid_type_error: taskValidationConstant.INVALID_OKR_PARENT_ID,
  //   })
  //   .optional(),
});

export function replacePWithDiv(html) {
  try {
    return html.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>');
  } catch (err) {
    return html;
  }
}
export function removeTagsAndGetContent(htmlString) {
  // Remove HTML tags using a regular expression
  const cleanText = htmlString?.replace(/<[^>]*>/g, '');

  // Trim extra white spaces and return the cleaned text
  return cleanText?.trim();
}

export const getTaskTime = (expectedHours) => {
  return `${
    Math.floor(expectedHours) > 0
      ? ` ${Math.floor(expectedHours)} ${Math.floor(expectedHours) > 1 ? 'Hours' : 'Hour'}`
      : ''
  }
  ${
    Math.round((expectedHours % 1) * 60) > 0
      ? ` ${Math.round((expectedHours % 1) * 60)} ${
          Math.round((expectedHours % 1) * 60) > 1 ? 'Minutes' : 'Minute'
        }`
      : ''
  }
  `;
};
export function getExpectedTime(expectedHours, expectedMinutes) {
  // Ensure inputs are numbers and default to 0 if NaN
  const hours = isNaN(expectedHours) ? 0 : expectedHours;
  const minutes = isNaN(expectedMinutes) ? 0 : expectedMinutes;

  const totalMinutes = hours * 60 + minutes;
  const computedHours = Math.floor(totalMinutes / 60);
  const computedMinutes = totalMinutes % 60;

  let result = '';

  if (computedHours > 0) {
    result += `${computedHours} hr${computedHours !== 1 ? 's' : ''}`;
  }

  if (computedMinutes > 0) {
    if (result) {
      result += ' and ';
    }
    result += `${computedMinutes} min${computedMinutes !== 1 ? 's' : ''}`;
  }

  // Return result or empty string if result is empty
  return result || '';
}

export function getTimeTaken(milliseconds) {
  const duration = moment.duration(parseInt(milliseconds));
  const seconds = duration.asSeconds();
  if (isNaN(seconds)) {
    return '0:00';
  }
  if (seconds < 60) {
    return `${Math.floor(seconds)} seconds`;
  }

  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  if (minutes < 60) {
    return `${minutes} min  ${duration.seconds()} sec`;
  }

  return `${hours} 'hr'  ${minutes} min  ${duration.seconds()} sec`;
}

export const getHours = (data) => {
  let hours = 0;
  if (!data.expected_minutes) {
    hours = parseFloat(data.expected_hours);
  }
  if (!data.expected_hours) {
    hours = Math.round(parseFloat(data.expected_minutes) * 100) / (100 * 60);
  }
  if (data.expected_hours && data.expected_minutes) {
    hours =
      parseFloat(data.expected_hours) +
      Math.round(parseFloat(data.expected_minutes) * 100) / (100 * 60);
  }
  return hours;
};
export function getTimeDifference(latestTime) {
  const now = moment();
  const lastMessageMoment = moment(latestTime);
  const duration = moment.duration(now.diff(lastMessageMoment));

  const seconds = Math.floor(duration.asSeconds());
  const minutes = Math.floor(duration.asMinutes());
  const hours = Math.floor(duration.asHours());
  const days = Math.floor(duration.asDays());
  const weeks = Math.floor(duration.asWeeks());
  const months = Math.floor(duration.asMonths());

  if (seconds < 60) {
    return `${seconds} sec ago`;
  } else if (minutes < 60) {
    return `${minutes} min ago`;
  } else if (hours < 24) {
    return `${hours} hr ago`;
  } else if (days < 7) {
    return `${days} dy ago`;
  } else if (weeks < 4) {
    return `${weeks} wk ago`;
  } else {
    return `${months} mn ago`;
  }
}

export const getInputValues = (inputType, data) => {
  let validation = inputType == fieldTypes.TIME;
  let dataValue = {
    typeOfInput: validation ? 'number' : fieldTypes.DATE,
    inputValueOne: validation ? data?.expected_hours : data?.task_start_date,
    inputValueTwo: validation ? data?.expected_minutes : data?.task_end_date,
    inputNameFieldOne: validation ? 'expected_hours' : 'task_start_date',
    inputNameFieldTwo: validation ? 'expected_minutes' : 'task_end_date',
    inputMinValueOne: validation ? 0 : moment().format('YYYY-MM-DD'),
    inputMinValueTwo: validation ? 0 : data?.task_start_date,
    inputMaxValueTwo: validation ? 59 : '',
  };
  return dataValue;
};
export const getPriorityByContextAPI = () => {
  const { globalSettings } = useContext(GlobalContext); // to get context

  const priorityLabel =
    globalSettings &&
    globalSettings.length &&
    globalSettings?.find((settings) => settings?.name === 'priority-label'); // setting the priority label data from context-api
  const options =
    priorityLabel?.labels &&
    Object.keys(priorityLabel?.labels).map((key) => ({
      label: priorityLabel?.labels[key]?.text,
      value: key,
      id: key,
      color: priorityLabel?.labels[key]?.color,
    })); // filtered the priority label data to label value pairs for select
  return options;
};

// is label menu is active or not
export const getLabelIsActive = () => {
  const { globalSettings } = useContext(GlobalContext); // to get context

  const menuSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'general-settings');

  const isMenuLabelOn = menuSettings?.labels?.filter((item) => item.label == 'Label')?.[0]?.value;
  return isMenuLabelOn ?? false;
};
// is sub task menu is active or not
export const getSubTaskIsActive = () => {
  const { globalSettings } = useContext(GlobalContext); // to get context

  const menuSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'general-settings');

  const isMenuLabelOn = menuSettings?.labels?.filter((item) => item.label == 'Sub Task')?.[0]
    ?.value;
  return isMenuLabelOn ?? false;
};

export const getStartDateMilliSecond = (value) => {
  const date = new Date(value);
  date.setHours(0, 0, 0, 0);
  value = date.getTime();
  return value;
};

export const getTaskDataFromTemplate = (taskData, templateData) => {
  const mergedTaskData = { ...taskData };

  for (const key in taskData) {
    if (templateData?.[key]) {
      mergedTaskData[key] = templateData[key];
    }
  }

  return mergedTaskData;
};

export const validateHours = (startDate, endDate, expectedHours) => {
  const difference = moment(parseInt(endDate)).diff(parseInt(startDate), 'days') + 1;

  if (difference * 24 > Math.round(expectedHours)) {
    return true; // invalid expected hours and minutes
  }

  return false; // valid expected hours and minutes
};

export const customHourMinuteFormat = (inputHours) => {
  const hours = `${`0${Math.floor(inputHours)}`.slice(-2)}h`;
  const minutes = `${`0${Math.round((inputHours % 1) * 60)}`}m`;
  return hours + ':' + minutes;
};

export const convertToEditorState = (desc) => {
  const regex = /<[^>]*>/g;

  if (regex.test(desc)) {
    // Create a temporary element to parse the HTML
    const tempElement = document.createElement('div');
    tempElement.innerHTML = desc;

    // Extract all text within the temporary element
    const allText = tempElement.textContent;
    return allText;
  } else {
    return desc;
  }
};

// to detect link in description
export const LinkOptions = {
  replace: (domNode) => {
    if (domNode.name === 'p') {
      const text = domNode?.children?.[0]?.data;
      if (text && text.trim() !== '') {
        // ignore empty paragraphs
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const match = text.match(urlRegex);
        console.log({ match });

        if (match) {
          return (
            <p>
              <a
                href={`${match[0]}`}
                onClick={(e) => {
                  // e.preventDefault();
                  e.stopPropagation();
                }}
                target='_blank'
                rel='noopener noreferrer'
              >
                {match[0]}
              </a>
            </p>
          );
        }
      }
    }
  },
};

export function convertTo12Hour(timeString) {
  let [hours, minutes] = timeString.split(':');
  hours = parseInt(hours, 10);
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;
  return `${hours}:${minutes} ${ampm}`;
}

// eslint-disable-next-line no-unused-vars
export const TaskOptions = ({
  // setCurrentView = () => {},
  currentView = TASK_TABS.TASKS,
  isClickable = false,
  setSearchParams,
  searchParams,
}) => {
  return (
    <>
      <ToolTip tooltipText={'Tasks'} isModern>
        <div
          className={`menu-item-outline${currentView == TASK_TABS.TASKS ? ' active' : ''}`}
          onClick={() => {
            searchParams.set('currentView', TASK_TABS.TASKS);
            setSearchParams(searchParams);
            // setCurrentView(TASK_TABS.TASKS);
          }}
        >
          <ListIcon color={currentView == TASK_TABS.TASKS ? '#3454d1' : '#87909E'} size={18} />
        </div>
      </ToolTip>
      <ToolTip tooltipText={'Board'} isModern hideTooltip={!isClickable}>
        <div
          className={`${isClickable ? 'menu-item-outline' : 'disabled-menu-item-outline'}  ${
            currentView == TASK_TABS.BOARD ? ' active' : ''
          }`}
          onClick={() => {
            if (isClickable) {
              searchParams.set('currentView', TASK_TABS.BOARD);
              setSearchParams(searchParams);

              // setCurrentView(TASK_TABS.BOARD);
            }
          }}
        >
          <BoardIcon color={currentView == TASK_TABS.BOARD ? '#3454d1' : '#87909E'} size={18} />
        </div>
      </ToolTip>
      <ToolTip tooltipText={'Calendar'} isModern hideTooltip={!isClickable}>
        <div
          className={`${isClickable ? 'menu-item-outline' : 'disabled-menu-item-outline'}  ${
            currentView == TASK_TABS.CALENDAR ? ' active' : ''
          }`}
          onClick={() => {
            // isClickable && setCurrentView(TASK_TABS.CALENDAR);
            if (isClickable) {
              searchParams.set('currentView', TASK_TABS.CALENDAR);
              setSearchParams(searchParams);

              // setCurrentView(TASK_TABS.CALENDAR);
            }
          }}
        >
          <Calendar color={currentView == TASK_TABS.CALENDAR ? '#3454d1' : '#87909E'} size={18} />
        </div>
      </ToolTip>
    </>
  );
};
