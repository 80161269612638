import React from 'react';

import { components } from 'react-select';
import { Images } from '../Utils';

// Custom DropdownIndicator component
export const CustomDropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* Extra custom icon */}
        <img src={Images.dropdownIcon} />
        {/* Default dropdown arrow */}
        {/* <ChevronDown size={16} style={{ color: '#3454D1' }} /> */}
      </div>
    </components.DropdownIndicator>
  );
};
