export const CONTEXT_MENU_OPTIONS = {
  EDIT: 'edit',
  DELETE: 'delete',
  DETAILS: 'details',
};

export const POSITION = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  BOTTOM: 'BOTTOM',
  TOP: 'TOP',
};
