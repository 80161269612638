import moment from 'moment';
import React from 'react';

function TimeSheetListBody({ timeSheetData, index }) {
  return (
    <tr>
      <td>{index}</td>
      <td>{timeSheetData?.project?.name ?? ' - - '}</td>
      <td>{timeSheetData?.task?.name ?? ' - - '}</td>
      <td>{moment(parseInt(timeSheetData?.start_time)).format('DD/MM/YYYY') ?? ' - - '}</td>
      <td>
        {timeSheetData?.end_time
          ? moment(parseInt(timeSheetData?.end_time)).format('DD/MM/YYYY')
          : ' -  - '}
      </td>
      <td>{moment(parseInt(timeSheetData?.time_difference)).hours()}</td>
      <td>
        {timeSheetData?.edited_time_difference
          ? moment.duration(parseInt(timeSheetData?.edited_time_difference)).hours()
          : moment.duration(parseInt(timeSheetData?.time_difference)).hours()}
      </td>
      <td>{timeSheetData?.note ?? ' - - '}</td>

      <td>edit</td>
    </tr>
  );
}

export default TimeSheetListBody;
