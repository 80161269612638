import React from 'react';
import { LoadingImg } from './LoadingImg';
export const TableLoading = ({ noOfCols }) => {
  return (
    <td colSpan={noOfCols} align='center' className='py-2'>
      <LoadingImg />
    </td>
  );
};

export const TableNoData = ({ noOfCols }) => {
  return (
    <td colSpan={noOfCols} align='center' className='py-2'>
      No Data
    </td>
  );
};

export const TableRow = ({ noOfCols = 8, children , style ={} }) => {
  return (
    <td colSpan={noOfCols} align='center' className='py-2' style={style}>
      {children}
    </td>
  );
};
