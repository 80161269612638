import { useInfiniteQuery } from '@tanstack/react-query';
import { AtSign, Home, LineChart, SquareCheck } from 'lucide-react';
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import StackedAvatars from '../_components/Avatar/StackedAvatars';
import IconLabelSet from '../_components/IconLabelSet';
import { RT } from '../_constants';
import { POSITION } from '../_constants/contextMenu.constants';
import { TASK_TYPES } from '../_constants/template.constants';
import useDebounce from '../_helpers/useDebounce';
import { taskService } from '../_services';
import useObserver from '../Hook/useObserver';
import { useUser } from '../Hook/useUser';
import { STATUS_NAME } from '../Task/taskConstants';
import { Images, MyTeamIcon } from '../Utils';
import { ArchiveIcon, LoadingIcon, Search } from '../Utils/SvgIcons';
import { Archive } from './Archive';
import './Archive.css';

export const ArchiveList = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const loggedUser = useUser().id;

  const [query, setQuery] = useState({
    select: [
      'id',
      'name',
      'note',
      'created_time',
      'task_end_date',
      'order_seq',
      'status',
      'priority',
      'recent_chat_message',
      'progress_status',
      'label',
      'project_id',
      'task_start_date',
      'created_time',
      'days_difference',
      'parent_id',
    ],
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
    is_duration_required: true,
    searchKey: '',
    status: 2,
  });

  const debouncedSearch = useDebounce(query?.searchKey, 500);
  const { ...restQuery } = query;

  const queryKey = ['archived-task', restQuery, query?.pageVo?.pageNo, debouncedSearch];
  const {
    data: taskData,
    // error,
    isLoading,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,

    refetch,
  } = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam = 1 }) =>
      taskService.taskListAdvanced(
        // getPayLoad({
        {
          ...restQuery,
          searchKey: debouncedSearch,
          pageVo: { ...restQuery.pageVo, pageNo: pageParam },
        },
        // ...(selectedStatus?.default_name === 'to_verify' ? { verify: true } : {}),
        // }),
      ),
    getNextPageParam: (response) =>
      response.data.page < response.data.pages ? response.data.page + 1 : undefined,
    refetchOnWindowFocus: false,
  });

  const { observerRef } = useObserver({
    onIntersection: () => {
      hasNextPage && fetchNextPage();
    },
    dependency: [hasNextPage],
  });

  const flattedUpdateData = React.useMemo(
    () => taskData?.pages?.flatMap((page) => page.data.rows),
    [taskData],
  );
  const pathName = window.location.pathname;

  const allUpdateList = flattedUpdateData ? [...flattedUpdateData] : [];
  const totalTaskCount = taskData?.pages[0]?.data?.total;
  const tableData = allUpdateList?.map((task, index) => {
    const slNo = index + 1;
    const currentIndex = index || 0;
    const isTaskOwner = loggedUser == parseInt(task?.user_details?.id);

    const tab =
      isTaskOwner && task?.task_type != TASK_TYPES.NON_TIME_BOUND_TASK ? 'subtask' : 'description';
    return (
      <Tr
        key={task?.id}
        onClick={() => {
          navigate(`/${RT.TASK}/${task?.id}?tab=${tab}&&${searchParams}&&currentIndex=${currentIndex}
          &&nextAndPrevFromTaskList=true&&taskTotalCount=${totalTaskCount}&&prevpath=${pathName}`);
        }}
      >
        <td style={{ fontWeight: 500, paddingLeft: 24 }}>{slNo}</td>
        <td className='cursor-pointer table-responsive-td' style={{ fontWeight: 500 }}>
          {task?.name}
        </td>

        <Td>
          <div className='d-flex align-items-center'>
            <StackedAvatars
              justifyPosition={' '}
              getSourceByKey={(task) => task?.image_url}
              getNameByKey={(task) => task?.first_name}
              getGenderByKey={(task) => task?.gender === 1}
              imageList={[task?.user_details]}
              showInitials={false}
              size={28}
              tooltipPosition={POSITION.LEFT}
              tooltipPopOver
              // scrollRefForTooltip={tableContainerRef}
            />
          </div>
        </Td>
        <Td>
          <span
            style={{
              color:
                task?.status_default_name === STATUS_NAME.done
                  ? '#1CA977'
                  : task?.days_difference < 0
                  ? '#EA4D4D'
                  : '',
            }}
            className='table-due-date'
          >
            {task?.duration}
          </span>
        </Td>
        <td
          style={{ verticalAlign: 'middle', cursor: 'pointer' }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <Archive taskId={task?.id} status={task?.status} refetch={refetch} />
        </td>
      </Tr>
    );
  });

  return (
    <div>
      <div className='fade-entry'>
        <div className=' url-container '>
          <div className='d-flex align-items-center  breadcrumbs url-navigateigation'>
            <IconLabelSet
              label={'Home'}
              fontWeight={400}
              icon={<Home size={13} />}
              onClick={() => navigate(`/`)}
            />
            <img className='slash-img-icon-size ms-1 mt-1' src={MyTeamIcon.Slash} alt='' />
            <IconLabelSet
              label={'Archive'}
              fontWeight={500}
              color={`#2A2E34`}
              icon={<ArchiveIcon height={12} width={12} color='#2A2E34' />}
              onClick={() => navigate(`/${RT.ARCHIVE}`)}
            />
          </div>
        </div>
        {/* component will active when error occurs   */}
        <div>
          {/* <ErrorComponent error={error?.message} /> */}
          <div className='archive-list-container'>
            {/* header input section */}
            <section>
              <div className='d-flex' style={{ fontSize: '13px', marginBottom: '16px ' }}>
                <div className='d-flex gap-2'>
                  <div className='search_contain'>
                    <input
                      onChange={(event) => {
                        searchParams.set('pageNo', 1);
                        setSearchParams(searchParams);
                        setQuery({
                          ...query,
                          searchKey: event.target.value,
                          pageVo: { ...query.pageVo, pageNo: 1 },
                        });
                      }}
                      // style={{ fontSize: '13px' }}
                      placeholder='Search...'
                      type='text'
                      className='bodr_none'
                    />
                    <Search size={18} color='#87909E' />
                  </div>
                </div>
                <div className='d-flex gap-1 align-items-center justify-content-end'></div>
              </div>
            </section>

            <section>
              <div className=' table-container no-padding'>
                <Table className='table table-hover tbl mb-0'>
                  <Thead>
                    <Tr style={{ backgroundColor: '#F3F5F7' }} className='table-head'>
                      {TABLE_HEADING.map((heading, index) => {
                        return (
                          <Th
                            key={index}
                            scope='col'
                            style={{ minWidth: heading.minWidth, maxWidth: heading.maxWidth }}
                          >
                            {heading.name}
                          </Th>
                        );
                      })}
                    </Tr>
                  </Thead>
                  <Tbody style={{ verticalAlign: 'middle' }}>
                    {/* {isLoading && (
                      <TableRow noOfCols={8}>
                        <LoadingIcon color='blue' size={60} />
                      </TableRow>
                    )} */}
                    {/* {!listItemCount && isSuccess && <TableRow noOfCols={8}>No data found</TableRow>}
                    {listItemCount > 0 && isSuccess && tableData} */}
                    {/* {tableData} */}
                    {isSuccess && tableData}
                    <div
                      ref={observerRef}
                      className='d-flex justify-content-center'
                      style={{ minHeight: '2px' }}
                    >
                      {hasNextPage && isFetchingNextPage && isLoading && (
                        <div
                          className='cursor-pointer'
                          onClick={() => hasNextPage && fetchNextPage()}
                          style={{ height: '100px' }}
                        >
                          <LoadingIcon size={60} />
                        </div>
                      )}
                    </div>
                  </Tbody>
                </Table>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

const TABLE_HEADING = [
  { name: <img src={Images.SerialNo} height={14} width={14} />, minWidth: 50 },
  {
    name: (
      <div className='d-flex align-items-center gap-1'>
        <SquareCheck size={16} /> Tasks
      </div>
    ),
    minWidth: '10vw',
    maxWidth: '20vw',
  },
  {
    name: (
      <div className='d-flex align-items-center gap-1'>
        <AtSign size={16} /> Assignee
      </div>
    ),
    minWidth: 50,
  },
  {
    name: (
      <div className='file-list-table-header' style={{ color: '#87909E' }}>
        <LineChart size={16} color='#87909E' /> Status
      </div>
    ),
    minWidth: 100,
  },

  {},
];
