/* eslint-disable no-unused-vars */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { settingsService } from '../_services';
import { THEME_SETTINGS } from './SettingsLabels';

export function ThemeSettings() {
  const [query, setQuery] = useState({ select: ['name', 'labels'], name: 'theme-settings' });

  const {
    data: themeSettingsData,
    error,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ['theme-settings'],
    queryFn: () => settingsService.settingsGet(query),
    select: (data) => {
      return data?.data[0];
    },
  });

  return (
    <>
      <section className='common_contain'>
        {isLoading && <div>Loading...</div>}
        {isSuccess && <ThemeForm initialData={themeSettingsData?.labels} />}
      </section>
    </>
  );
}

// form component
const ThemeForm = ({ initialData }) => {
  const queryClient = useQueryClient();

  const [themeSettings, setThemeSettings] = useState(initialData || THEME_SETTINGS);

  //-- handle change
  const handleChange = (id, value) => {
    setThemeSettings((prevLabels) =>
      prevLabels.map((label) => (label.id === id ? { ...label, value } : label)),
    );
  };

  //-- theme settings update
  const handleSettingUpdate = (data) => {
    let newQuery = {};
    newQuery.labels = data;
    newQuery.name = 'theme-settings';

    settingsService.settingsAdd(newQuery);
    return newQuery;
  };

  //-- react query fot mutation function
  const { mutate: handleMutate, isError } = useMutation({
    mutationFn: handleSettingUpdate,
    onSuccess: (data) => {
      queryClient.setQueryData(['theme-settings'], (old) => {
        let oldData = { ...old };
        oldData.data[0] = data;
        return oldData;
      });
    },
  });

  return (
    <>
      {themeSettings?.map((THEME, index) => {
        return (
          <>
            <div className='mb-4'>
              <div className='css-head'>Custom {THEME?.label}</div>
              <div className='css-sub'>Set Custom {THEME?.label} here</div>
              <textarea
                className='code_text'
                onChange={(e) => handleChange(THEME.id, e.target.value)}
                value={THEME?.value}
                placeholder=' body {
                font-size: 15px;
              }'
                onBlur={() => handleMutate(themeSettings)}
              />{' '}
            </div>
          </>
        );
      })}
    </>
  );
};
