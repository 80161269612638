import React from 'react';
import { monthlyRecurType, recurWeekNumberList } from '../../_constants/recurrence.constants';

import { getDateWithSuffix } from '../../Utils';
import WeekInputs from './WeekInputs';

const MonthInputs = ({
  monthlyRecurrenceType = monthlyRecurType.BY_DATE,
  onChange = () => {},
  onWeekSelect = () => {},
  checkIsActive = () => {},
  recurrenceDaysOfMonth = undefined,
  recurringWeekNumber = undefined,
}) => {
  const isRecurrenceByDate = monthlyRecurrenceType === monthlyRecurType.BY_DATE;
  const isRecurrenceByWeek = monthlyRecurrenceType === monthlyRecurType.BY_WEEK;

  return (
    <>
      <div className='mt-20 d-flex radio-container' style={{ gap: 12 }}>
        <label className='d-flex align-items-center font-normal-500' style={{ gap: 4 }}>
          <input
            type='radio'
            name='monthly_recurrence_type'
            value={monthlyRecurType.BY_DATE}
            checked={isRecurrenceByDate}
            onChange={onChange}
          />{' '}
          On Date
        </label>

        <label className='d-flex align-items-center font-normal-500' style={{ gap: 5 }}>
          <input
            type='radio'
            name='monthly_recurrence_type'
            value={monthlyRecurType.BY_WEEK}
            checked={isRecurrenceByWeek}
            onChange={onChange}
          />{' '}
          By Week
        </label>
      </div>
      {isRecurrenceByDate && (
        <div className='mt-20'>
          <select
            className='recur-drop-down w-100'
            name='days_of_month'
            onChange={onChange}
            value={recurrenceDaysOfMonth[0]}
          >
            {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
              <option key={day} value={day}>
                {getDateWithSuffix(day)}
              </option>
            ))}
          </select>
        </div>
      )}
      {isRecurrenceByWeek && (
        <div>
          <div className='mt-20 '>
            <select
              className='recur-drop-down w-100'
              name='week_number'
              onChange={onChange}
              value={recurringWeekNumber}
            >
              {recurWeekNumberList.map((week) => (
                <option key={week.value} value={week.value}>
                  {week.label}
                </option>
              ))}
            </select>
          </div>
          <WeekInputs onSelect={onWeekSelect} checkIsActive={checkIsActive} />
        </div>
      )}
    </>
  );
};

export default MonthInputs;
