import { useMutation, useQuery } from '@tanstack/react-query';
import { ArrowLeft, Camera, Users, X } from 'lucide-react';
import React, { useRef, useState } from 'react';
import useOutsideClickHandler from '../Hook/useOutsideClickHandler';
import ErrorBlock from '../Template/SubComponents/ErrorBlock';
import { fullNameUser, getFileType, sanitizeFileName } from '../Utils';
import { Button } from '../_components';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import { USER_LIST_ACTIONS, USER_PLACEHOLDER, apiConstants } from '../_constants';
import useDebounce from '../_helpers/useDebounce';
import { miscService, userService } from '../_services';
import { chatService } from '../_services/chat.service';

function ChatCreationPopUp({
  onClose = () => {},
  onGroupCreation = () => {},
  initialDetails = { selectedUsers: [], groupName: '', groupImage: null },
  initialStep = 0,
  className = '',
  isEdit = false,
}) {
  const searchRef = useRef(null);
  const [step, setStep] = useState(initialStep);
  const [searchKey, setSearchKey] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [groupDetails, setGroupDetails] = useState(initialDetails);

  const debouncedSearchKey = useDebounce(searchKey, 500);

  const handleStepChange = (newStep) => setStep(Math.max(0, newStep));

  const { mutateAsync, isPending } = useMutation({
    mutationKey: ['chat-group-add'],
    mutationFn: (payload) => chatService.addChatGroup({ payload }),
    onSuccess: () => {
      onClose();
      onGroupCreation();
    },
  });

  const { data: userList } = useQuery({
    queryKey: ['group-creation-user-list', debouncedSearchKey],
    queryFn: () =>
      userService.getPermittedUser({
        action: USER_LIST_ACTIONS.TASK_ADD,
        select: ['first_name', 'last_name', 'gender', 'image_url', 'id'],
        searchKey: debouncedSearchKey,
      }),
    select: (response) => response.data.rows,
  });

  const handleSelection = (event, user) => {
    setSearchKey('');
    setGroupDetails((prev) => ({
      ...prev,
      selectedUsers: event.target.checked
        ? [...prev.selectedUsers, user] //add user
        : prev.selectedUsers.filter((usr) => usr.id !== user.id), //remove user
    }));
    // setSelectedUsers(selectedUsers.filter((usr) => usr.id !== user.id));
    searchRef.current && searchRef.current.focus();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setGroupDetails({
      ...groupDetails,
      [name]: value,
    });
  };

  const uploadFile = async (file) => {
    try {
      // upload file
      let signedData = await miscService.createSignedUploadUrl({
        type: file.fileType, //--video,image,audio
        ext: file.extension, //--jpg or mp4
        name: file.sanitizeName,
      });

      if (signedData?.data?.signedUrl) {
        let signedUrl = signedData.data.signedUrl;
        let fileName = signedData.data.filename;
        // TODO: Need to move this waterfall update to parallel upload later
        await fetch(signedUrl, {
          method: 'PUT',
          headers: { 'Content-Type': 'multipart/form-data' },
          body: file.file,
        });
        // Update the status
        return fileName;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      // When a file is selected group image will be the file, otherwise it will be the uploaded file name
      const uploadedName =
        typeof groupDetails.groupImage === 'object'
          ? Boolean(groupDetails?.groupImage?.name) &&
            (await uploadFile({
              name: groupDetails?.groupImage?.name?.replace(/[^\w.-]|[\s&]/g, ''),
              sanitizeName: sanitizeFileName(groupDetails?.groupImage?.name),
              fileType: getFileType(groupDetails?.groupImage?.name?.split('.').pop()),
              size: groupDetails?.groupImage?.size,
              extension: groupDetails?.groupImage?.name?.split('.').pop(),
              file: groupDetails?.groupImage,
            }))
          : isEdit
          ? groupDetails?.groupImage
          : null;

      const payload = {
        user_ids: groupDetails?.selectedUsers?.map((user) => user.id),
        name: groupDetails?.groupName,
        image: uploadedName,
      };

      if (initialDetails.id) {
        payload.id = initialDetails.id;
      }

      await mutateAsync(payload);
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={`group-creation-pop-up ${className}`}>
      {step === 0 && <InitialStep onClose={onClose} updateStep={handleStepChange} />}
      {step === 1 && (
        <UserSelectStep
          isEdit={isEdit}
          updateStep={handleStepChange}
          userList={userList}
          selectedUsers={groupDetails?.selectedUsers}
          onSearch={(search) => setSearchKey(search)}
          searchKey={searchKey}
          handleSelection={handleSelection}
          onCancel={onClose}
          searchRef={searchRef}
        />
      )}
      {step === 2 && (
        <GroupCreationStep
          searchKey={searchKey}
          isEdit={isEdit}
          selectedUsers={groupDetails?.selectedUsers}
          updateStep={handleStepChange}
          handleSelection={handleSelection}
          onCancel={onClose}
          onSubmit={handleSubmit}
          groupDetails={groupDetails}
          onInputChange={handleInputChange}
          isSubmitting={isSubmitting || isPending}
        />
      )}
    </div>
  );
}

const InitialStep = ({ updateStep = () => {}, onClose }) => {
  const { containerRef } = useOutsideClickHandler({ onClose: onClose });
  return (
    <div className=' item py-3 px-3' ref={containerRef}>
      <div className='group-creation-head '>Create Group</div>
      <div className='d-flex align-items-center mt-2 pointer ' onClick={() => updateStep(1)}>
        <div className='grey-circular-bg'>
          <Users size={18} />
        </div>
        <div className='ps-2 fz-13px-rem fw-500'>New Group</div>
      </div>
    </div>
  );
};

//Back-0; STEP-1;  Next-2
const UserSelectStep = ({
  updateStep = () => {},
  userList = [],
  selectedUsers = [],
  onSearch = () => {},
  searchKey,
  handleSelection,
  onCancel = () => {},
  searchRef,
  isEdit,
}) => {
  const [hasError, setHasError] = useState(null);

  return (
    <div className=' item py-3 fade-right-entry'>
      <div
        className='mx-3 '
        style={{ paddingBottom: 15, borderBottom: '1px solid var(--border-color)' }}
      >
        <div className='gap-2 d-flex align-items-center'>
          {!isEdit && (
            <div
              className='btn-wrapper d-flex align-items-center justify-content-center'
              onClick={() => updateStep(0)}
            >
              <ArrowLeft size={18} />
            </div>
          )}
          <div className='group-creation-head'>{isEdit ? 'Update' : 'New'} Group</div>
        </div>

        <UserSelectionPreview
          searchKey={searchKey}
          className='mt-2 '
          selectedUsers={selectedUsers}
          onSearch={onSearch}
          searchRef={searchRef}
          handleSelection={(event, user) => {
            searchRef.current?.focus();
            handleSelection(event, user);
          }}
        />

        <ErrorBlock
          hasError={hasError && selectedUsers.length <= 1}
          errorMsg={'Please select at least 2 users'}
        />

        <div className='group-creation-buttons mt-3 d-flex gap-2'>
          <Button.Container
            className={'status-del-cancel radius-3px d-block flex-1'}
            handleOk={onCancel}
          >
            <Button.Title title={'Cancel'} />
          </Button.Container>
          <Button.Container
            handleOk={() => (selectedUsers.length > 1 ? updateStep(2) : setHasError(true))}
            className={'default-btn radius-3px d-block flex-1'}
          >
            <Button.Title title={'Next'} />
          </Button.Container>
        </div>
      </div>
      <div
        className='fz-14px user-select-none user-group-selection-container'
        style={{ marginTop: 15 }}
      >
        {userList.length <= 0 && <div className='grey-italic'>No User</div>}
        {userList.map((user, idx) => {
          const isSelected = selectedUsers.some((usr) => usr.id === user.id);
          const url = apiConstants.imgUrlS3 + user.image_url;
          return (
            <label
              key={user.id}
              className='d-flex px-3  chat-group-user-select py-1 gap-3 mt-2 posrelative'
              htmlFor={`group-creation-user-select${idx}`}
            >
              <input
                type='checkbox'
                checked={isSelected}
                placeholder=''
                onChange={(event) => handleSelection(event, user)}
                id={`group-creation-user-select${idx}`}
              />

              <div className='d-flex gap-2 align-items-center'>
                <CircularAvatar source={url} />
                <div className='group-username'>
                  {fullNameUser(user.first_name, user.last_name)}
                </div>
              </div>
            </label>
          );
        })}
      </div>
    </div>
  );
};

const GroupCreationStep = ({
  updateStep = () => {},
  onSubmit = () => {},
  selectedUsers,
  handleSelection,
  onInputChange,
  groupDetails,
  searchKey,
  onCancel,
  isSubmitting,
  isEdit,
}) => {
  const groupName = groupDetails.groupName;
  const groupImage = groupDetails?.groupImage;

  const isBlob = groupImage ? groupImage instanceof File : true;

  return (
    <div className=' item py-3 px-3 '>
      <div className='gap-2 d-flex align-items-center'>
        <div
          className='btn-wrapper d-flex align-items-center justify-content-center'
          onClick={() => updateStep(1)}
        >
          <ArrowLeft size={20} />
        </div>
        <div className='group-creation-head'>{isEdit ? 'Update' : 'New'} Group</div>
      </div>
      <div className='group-create-content fz-14px mt-3'>
        {/* Selected users */}
        <div onClick={() => updateStep(1)} className='pointer'>
          <UserSelectionPreview
            searchKey={searchKey}
            className='mt-2'
            selectedUsers={selectedUsers}
            searchable={false}
            handleSelection={handleSelection}
          />
        </div>
        <div className='fade-entry'>
          {/* Group ICon */}

          <label
            htmlFor='group-icon-select'
            className='d-flex gap-2 align-items-center mt-3 pointer '
          >
            {groupImage ? (
              <CircularAvatar
                size={50}
                source={
                  isBlob ? URL.createObjectURL(groupImage) : apiConstants.imgUrlS3 + groupImage
                }
                placeholderSrc={USER_PLACEHOLDER.GROUP}
              />
            ) : (
              // <img
              //   style={{ objectFit: 'cover', borderRadius: '50%', border: '1px solid #e2e2e2' }}
              //   src={isBlob ? URL.createObjectURL(groupImage) : apiConstants.imgUrlS3 + groupImage}
              //   width={50}
              //   height={50}
              // />
              <>
                <div className='grey-circular-bg'>
                  <Camera size={18} />
                </div>
                <div>Add Group Icon</div>
              </>
            )}
          </label>

          <input
            id='group-icon-select'
            onChange={(event) =>
              onInputChange({ target: { name: 'groupImage', value: event.target.files[0] } })
            }
            accept='image/*'
            type='file'
            hidden
          />

          {/* Group Name */}
          <div className='mt-3'>
            Group Name
            <input
              type='text'
              name='groupName'
              value={groupName}
              onChange={onInputChange}
              className='mt-2 w-100'
              placeholder='Enter Group Name'
            />
          </div>
        </div>
        <div className='group-creation-buttons mt-5 d-flex gap-2'>
          <Button.Container
            handleOk={onCancel}
            isDisabled={isSubmitting}
            className={'status-del-cancel radius-3px d-block flex-1'}
          >
            <Button.Title title={'Cancel'} />
          </Button.Container>
          <Button.Container
            handleOk={onSubmit}
            className={
              'default-btn radius-3px d-flex align-items-center justify-content-center gap-2 flex-1'
            }
            isDisabled={isSubmitting}
          >
            <Button.Title title={isEdit ? 'Update' : 'Create'} />
            <Button.Loading className='mt-1' isLoading={isSubmitting} color='white' size={18} />
          </Button.Container>
        </div>
      </div>
    </div>
  );
};

const SingleUserPreview = ({ user, handleSelection }) => {
  const imageUrl = apiConstants.imgUrlS3 + user.image_url;
  return (
    <div className='selected_usr d-flex align-items-center gap-1 justify-content-between w-100'>
      <div className='d-flex gap-1 align-items-center'>
        <CircularAvatar size={18} source={imageUrl} />
        <div className='group-username'>{fullNameUser(user.first_name, user.last_name)}</div>
      </div>
      <div
        className='btn-wrapper pe-1'
        onClick={() => handleSelection({ target: { checked: false } }, user)}
      >
        <X size={13} />
      </div>
    </div>
  );
};

const UserSelectionPreview = ({
  selectedUsers = [],
  className,
  onSearch = () => {},
  searchKey,
  searchable = true,
  searchRef,
  handleSelection,
}) => {
  return (
    <div className={`selected-usr-box fz-14px position-relative ${className}`}>
      {!searchKey && selectedUsers.length <= 0 && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            left: 10,
            fontSize: 13,
            fontWeight: '400',
          }}
        >
          Search user
        </div>
      )}
      {selectedUsers.map((user) => {
        return <SingleUserPreview handleSelection={handleSelection} key={user.id} user={user} />;
      })}
      {searchable && (
        <input
          type='text'
          ref={searchRef}
          autoFocus
          value={searchKey}
          className='transparent-search'
          onChange={(event) => onSearch(event.target.value)}
        />
      )}
    </div>
  );
};

export default ChatCreationPopUp;
