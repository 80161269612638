import { useContext, useEffect } from 'react';
import { GlobalContext } from '../Utils';

export function ErrorComponent({ error }) {
  const { makeAlert } = useContext(GlobalContext);
  useEffect(() => {
    if (error) {
      makeAlert(error);
    }
  }, [error]);

  return null;
}
