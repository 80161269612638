import { apiConstants } from '../_constants';
import { authHeader, handleResponse } from '../_helpers';
import { jsonToQueryString } from '../_helpers/utilFunctions';
export const okrService = {
  OKRAdd,
  OKRList,
  OKRDelete,
  getOKR,
};

function getOKR(data) {
  const params = jsonToQueryString(data);
  const apiUrl = `${apiConstants.apiUrl}/get_okr?${params}`;
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}

function OKRAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/okr_add', requestOptions).then(handleResponse);
}
function OKRList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/okr_list', requestOptions).then(handleResponse);
}
function OKRDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/okr_deactivate', requestOptions).then(handleResponse);
}
