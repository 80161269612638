import { X } from 'lucide-react';
import React, { useState } from 'react';
import { BoxMessageCards } from './ChatAttachments/BoxMessageCards';
import MediaPreview from './MediaPreview';

function ReplyContainer({ selectedItem, onRemove = () => {} }) {
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  if (!selectedItem) {
    return null;
  }
  // const name = getFullName(
  //   selectedItem.creator_details?.first_name,
  //   selectedItem.creator_details?.last_name,
  // );
  return (
    <div className='px-3 reply-container anim-slide-show'>
      <div className='overflow-hidden'>
        <div className='d-flex justify-content-between'>
          {/* <div className='fw-500 fz-14px mt-1'>
            Replying to @{selectedItem.creator_details.first_name}
          </div> */}
        </div>
        <div className='reply-content mt-1 position-relative'>
          <BoxMessageCards.ReplyCard>
            <BoxMessageCards.ReplyItem data={selectedItem} />
          </BoxMessageCards.ReplyCard>
          {/* <ChatMessageCards.ReplyItemPreview
            isUserSame
            item={selectedItem}
            onFileClick={() => setIsPreviewVisible(true)}
          /> */}
          <div
            className='btn-wrapper pe-1'
            onClick={onRemove}
            style={{ position: 'absolute', right: 10, top: '25%' }}
          >
            <X size={14} />
          </div>
        </div>
      </div>
      {isPreviewVisible && (
        <MediaPreview
          createdTime={selectedItem.createdAt}
          files={selectedItem.files}
          creatorDetails={selectedItem.creator_details}
          currentIndex={0}
          isInput={false}
          onClose={() => setIsPreviewVisible(false)}
        />
      )}
    </div>
  );
}

export default ReplyContainer;
