import React from 'react';

import { fullNameUser } from '../Utils';
import { apiConstants } from '../_constants';
import CircularAvatar from './Avatar/CircularAvatar';

/***
 * User this component for displaying customized react select drop down UI for team list
 * @param props
 * @param {number} props.limit - Max number of members should display on the list
 * @param {object} props.data+
 * @param {object} props.metaData
 */
const TeamDropDown = ({ data, metaData, limit = 5 }) => {
  const teamName = data.name;
  const Icon = data?.icon;
  const totalMembers = data.members.length;

  const exceededUserCount = totalMembers > limit ? totalMembers - limit : 0;
  const exceededMembers = exceededUserCount
    ? []
    : data.members?.filter((_, index) => index > limit);

  if (metaData.context == 'value') {
    return teamName;
  }
  return (
    <div>
      <div className='d-flex align-items-center' style={{ gap: 15 }}>
        {Icon && <Icon className={'mx-1'} size={30} color={data?.color ?? 'black'} />}
        <div style={{ flex: 1 }} className=' gap-2'>
          <div style={{ fontWeight: '500' }}>{teamName}</div>
          <div className='d-flex position-relative'>
            {data.members_details
              ?.filter((_, index) => index <= limit)
              .map((member, index) => {
                return <SingleAvatarWithName member={member} index={index} key={member.id} />;
              })}
            {Boolean(exceededUserCount) && (
              <ExceedAvatarWithName
                exceededMembers={exceededMembers}
                exceedCount={exceededUserCount}
                size={20}
              />
            )}
          </div>
          {data?.description && <div className='small-gret-text'>{data.description}</div>}
        </div>
      </div>
    </div>
  );
};

const SingleAvatarWithName = ({ member, index }) => {
  const url = member.image_url;
  const finalUrl = url ? apiConstants.imgUrlS3 + url : null;
  const isMale = member.gender === 1;
  const isNeutral = member.gender === 3;
  const fullName = fullNameUser(member.first_name, member?.last_name);
  return (
    <div className='position-relative'>
      <CircularAvatar
        className='member-avatar'
        isImgStacked
        size={20}
        source={finalUrl}
        isMale={isMale}
        isNeutral={isNeutral}
        name={fullName}
      />
      <div className={`member-name-container ${index > 0 ? 'l-n20px' : ''}`}>
        <div className='member-name ' style={{ overflow: 'hidden' }}>
          {fullName}
        </div>
      </div>
    </div>
  );
};

const ExceedAvatarWithName = ({ exceedCount, exceededMembers = [] }) => {
  return (
    <>
      <div
        className='member-avatar'
        style={{
          width: 20,
          height: 20,
          borderRadius: 15,
          background: '#f1f2f4',
          marginLeft: '-3px',
          color: 'black',
          fontSize: 10,
          display: 'grid',
          placeItems: 'center',
        }}
      >
        +{exceedCount}
      </div>
      <div className='member-name-container'>
        {exceededMembers.map((member) => {
          <div className='member-name' style={{ overflow: 'hidden' }}>
            {member.first_name}
          </div>;
        })}
      </div>
    </>
  );
};

export default TeamDropDown;
