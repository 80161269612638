/* eslint-disable no-unused-vars */
import React from 'react';

const Speaker = ({
  text = `You have 1 task to complete. This is the task: 1. The task titled 'Daily meeting' is currently marked as 'Todo'. For this task, focus on preparing your agenda, gathering any necessary materials and ensuring that all participants are informed about the meeting details. It's a great opportunity to align the team and discuss key topics so make sure you're ready to lead the discussion effectively`,
}) => {
  //   const speak = () => {
  //     // Check if the browser supports SpeechSynthesis
  //     if ('speechSynthesis' in window) {
  //       const utterance = new SpeechSynthesisUtterance(text);
  //       window.speechSynthesis.speak(utterance);
  //     } else {
  //       alert('Sorry, your browser does not support text-to-speech.');
  //     }
  //   };

  //   const speak = () => {
  //     if ('speechSynthesis' in window) {
  //       const utterance = new SpeechSynthesisUtterance(text);

  //       // Set voice properties
  //       utterance.lang = 'en-US';
  //       utterance.pitch = 1; // 0 to 2
  //       utterance.rate = 1; // 0.1 to 10

  //       // Optionally select a specific voice
  //       const voices = window.speechSynthesis.getVoices();
  //       utterance.voice = voices.find((voice) => voice.name === 'Google US English');

  //       window.speechSynthesis.speak(utterance);
  //     } else {
  //       alert('Sorry, your browser does not support text-to-speech.');
  //     }
  //   };

  //   return (
  //     <button onClick={speak}>
  //       <img src='speaker-icon.png' alt='Speak' />
  //     </button>
  //   );

  const [voices, setVoices] = React.useState([]);

  React.useEffect(() => {
    const synth = window.speechSynthesis;

    // Function to load voices
    const loadVoices = () => {
      const availableVoices = synth.getVoices();
      if (availableVoices.length !== 0) {
        setVoices(availableVoices);
      }
    };

    // Load voices
    loadVoices();

    // Some browsers (e.g., Chrome) might not have voices immediately available.
    if (synth.onvoiceschanged !== undefined) {
      synth.onvoiceschanged = loadVoices;
    }
  }, []);

  const utteranceRef = React.useRef(null);

  const _speak = () => {
    if ('speechSynthesis' in window) {
      window.speechSynthesis.cancel();
      const utterance = new SpeechSynthesisUtterance(text);

      // Select a female voice
      const femaleVoices = voices.filter(
        (voice) =>
          voice.name.toLowerCase().includes('female') ||
          voice.name.toLowerCase().includes('woman') ||
          voice.name.toLowerCase().includes('salli') || // Example of a common female voice name
          voice.gender === 'female',
      );

      if (femaleVoices.length > 0) {
        utterance.voice = femaleVoices[0];
      } else {
        // Fallback to default voice
        utterance.voice = voices[0];
      }

      utteranceRef.current = utterance;
      window.speechSynthesis.speak(utterance);
    } else {
      alert('Sorry, your browser does not support text-to-speech.');
    }
  };

  const speak = () => {
    if ('speechSynthesis' in window) {
      window.speechSynthesis.cancel();

      const sentences = text.match(/[^.!?]+[.!?]+[\])'"`’”]*|.+$/g);

      if (sentences) {
        let index = 0;

        const speakNextSentence = () => {
          if (index < sentences.length) {
            const sentence = sentences[index].trim();
            const utterance = new SpeechSynthesisUtterance(sentence);

            // Select a female voice
            const femaleVoices = voices.filter(
              (voice) =>
                voice.name.toLowerCase().includes('female') ||
                voice.name.toLowerCase().includes('woman') ||
                voice.name.toLowerCase().includes('salli') ||
                voice.gender === 'female',
            );

            if (femaleVoices.length > 0) {
              utterance.voice = femaleVoices[0];
            } else {
              utterance.voice = voices[0]; // Fallback to default voice
            }

            // Adjust rate and pitch if needed
            utterance.rate = 1;
            utterance.pitch = 1;

            utterance.onend = () => {
              index++;
              speakNextSentence();
            };

            window.speechSynthesis.speak(utterance);
          }
        };

        speakNextSentence();
      }
    } else {
      alert('Sorry, your browser does not support text-to-speech.');
    }
  };

  const pause = () => {
    if (window.speechSynthesis.speaking) {
      window.speechSynthesis.pause();
    }
  };

  const resume = () => {
    if (window.speechSynthesis.paused) {
      window.speechSynthesis.resume();
    }
  };

  const stop = () => {
    window.speechSynthesis.cancel();
  };

  return (
    <div>
      <button onClick={speak}>Speak</button>
      <button onClick={pause}>Pause</button>
      <button onClick={resume}>Resume</button>
      <button onClick={stop}>Stop</button>
    </div>
  );
};

export default Speaker;
