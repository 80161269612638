import { apiConstants } from '../_constants';
import { authHeader, handleResponse } from '../_helpers';
import { jsonToQueryString } from '../_helpers/utilFunctions';

export const taskService = {
  taskAdd,
  taskList,
  taskDelete,
  taskGet,
  subTaskList,
  subTaskAdd,
  remindersGet,
  reminderAdd,
  taskListAdvanced,
  updateStatus,
  getRecurringList,
  addRecurring,
  getSingleRecurrence,
  deactivateRecurrence,
  runTestRecurrence,
  workHistoryList,
  getRelatedTasks,
  getActiveTask,
  getTaskDuplication,
  getReminderById,
  autoStop,
  getNextAndPrevTask,
};

async function getNextAndPrevTask(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/task-next-prev', requestOptions).then(handleResponse);
}

async function taskList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/task_list', requestOptions).then(handleResponse);
}

async function taskGet(data) {
  const params = jsonToQueryString(data);
  const apiUrl = `${apiConstants.apiUrl}/get_task?${params}`;
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}

async function taskAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/task_add', requestOptions).then(handleResponse);
}

async function taskDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/task_delete', requestOptions).then(handleResponse);
}

async function subTaskList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/sub_task_list', requestOptions).then(handleResponse);
}

async function subTaskAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/sub_task_add', requestOptions).then(handleResponse);
}

async function remindersGet(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/reminder_list', requestOptions).then(handleResponse);
}

async function reminderAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/reminder_add', requestOptions).then(handleResponse);
}

async function taskListAdvanced(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/task_list_advanced', requestOptions).then(handleResponse);
}

async function updateStatus(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/update_task_status', requestOptions).then(handleResponse);
}

async function getRecurringList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      ...data,
      select: [
        'id',
        'interval',
        'frequency',
        'days_of_week',
        'start_date',
        'end_date',
        'days_of_month',
        'creation_offset',
        'week_number',
        'monthly_recurrence_type',
        'end_type',
        'task_details_list',
        'pre_post',
        'append_name_style',
      ],
    }),
  };
  return fetch(apiConstants.apiUrl + '/recurrence_list', requestOptions).then(handleResponse);
}

async function addRecurring(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/recurrence_add', requestOptions).then(handleResponse);
}

async function getSingleRecurrence(data) {
  const params = jsonToQueryString(data);
  const apiUrl = `${apiConstants.apiUrl}/get_recurrence?${params}`;
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}

async function deactivateRecurrence(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/recurrence_deactivate', requestOptions).then(handleResponse);
}
async function runTestRecurrence(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/run_test_recurrence', requestOptions).then(handleResponse);
}

async function workHistoryList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/task_time_log_list', requestOptions).then(handleResponse);
}

async function getRelatedTasks(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/related_task_list', requestOptions).then(handleResponse);
}

async function getActiveTask(data) {
  const params = jsonToQueryString(data);
  const apiUrl = `${apiConstants.apiUrl}/active_task?${params}`;
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}

async function getTaskDuplication(data) {
  const params = jsonToQueryString(data);
  const apiUrl = `${apiConstants.apiUrl}/get_duplicate_task_details?${params}`;
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}
/**
 * getReminderById - return reminder details by id
 * @param {*} data -
 * @returns
 */
async function getReminderById(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/get_reminder_by_id', requestOptions).then(handleResponse);
}

async function autoStop(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/cron_auto_stop', requestOptions).then(handleResponse);
}
