import React, { useEffect, useRef, useState } from 'react';
import './slider.css';

function Slider({
  duration,
  currentTime,
  handleSeek,
  containerClassName = '',
  hideHandle = false,
  progressTrackColor = 'red',
}) {
  const [isDragging, setIsDragging] = useState(false);
  const [value, setValue] = useState(0);
  const sliderRef = useRef(null);
  const handleRef = useRef(null);

  const currentTimeInPercentage = (currentTime / duration) * 100;

  if (
    !isNaN(currentTimeInPercentage) &&
    currentTimeInPercentage !== null &&
    value !== currentTimeInPercentage
  ) {
    setValue(currentTimeInPercentage);
  }

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isDragging) return;
      const sliderLeft = sliderRef.current.getBoundingClientRect().left;
      const sliderWidth = sliderRef.current.offsetWidth;
      const position = e.clientX - sliderLeft;
      const newValue = Math.min(Math.max(position / sliderWidth, 0), 1);
      const time = newValue * duration;
      handleSeek({ target: { value: time } });
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleSliderClick = (e) => {
    setIsDragging(true);
    const sliderLeft = sliderRef.current.getBoundingClientRect().left;
    const sliderWidth = sliderRef.current.offsetWidth;
    const position = e.clientX - sliderLeft;
    const newValue = Math.min(Math.max(position / sliderWidth, 0), 1);
    const time = newValue * duration;
    handleSeek({ target: { value: time } });
  };

  return (
    <div
      onMouseDown={handleSliderClick}
      ref={sliderRef}
      className={`w-100 py-2 pointer slider-container ${containerClassName}`}
    >
      <div className='slider'>
        <div className='slider-track'></div>
        <div
          className='progress-track'
          style={{ width: `${value}%`, backgroundColor: progressTrackColor }}
        />
        {!hideHandle && (
          <div
            className='slider-handle'
            ref={handleRef}
            style={{ left: `${value}%` }}
            onMouseDown={handleMouseDown}
          ></div>
        )}
      </div>
    </div>
  );
}

export default Slider;
