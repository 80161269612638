/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { SquareChevronLeft, SquareChevronRight } from 'lucide-react';

export const PaginationComponent = (props) => {
  const [paginationItems, setPaginationItems] = useState([]);
  const pageOnClick = (data) => {
    if (props.pageOnClick) {
      props.pageOnClick(data);
    }
  };

  const setListAndPagination = (demoData) => {
    if (!demoData) return;

    let paginationItems = [];
    if (demoData && demoData.pages > 1) {
      let start = 1;
      let end = demoData.pages;
      let currentPageNo = demoData.page;
      let totalPages = demoData.pages;
      if (totalPages > 5) {
        if (currentPageNo + 4 > totalPages) {
          start = totalPages - 4;
          end = totalPages;
        } else {
          if (currentPageNo == end) {
            start = currentPageNo;
            end = parseInt(currentPageNo) + 4;
          } else if (currentPageNo == 1) {
            start = currentPageNo;
            end = start + 4;
          } else if (currentPageNo == start) {
            end = currentPageNo;
            start = currentPageNo - 4;
            if (currentPageNo - 4 <= 0) {
              start = 1;
              end = 5;
            }
          } else if (currentPageNo < start) {
            end = start;
            start = end - 4;
          } else if (currentPageNo > start) {
            if (currentPageNo - 2 <= 0) {
              start = 1;
              end = 5;
            } else {
              start = currentPageNo - 2;
              end = currentPageNo + 2;
            }
          } else if (!end && !start) {
            start = 1;
            end = 5;
          }
        }
      }
      paginationItems.push(
        <div
          onClick={() => {
            if (currentPageNo > 1) {
              pageOnClick(currentPageNo - 1);
            }
          }}
          className={`d-flex pointer user-select-none pe-auto  ${
            currentPageNo == 1 ? 'cursor-no-drop' : ''
          }`}
        >
          {currentPageNo == 1 ? (
            <SquareChevronLeft size={26} color='#EAEBEF' strokeWidth={1} />
          ) : (
            <SquareChevronLeft size={26} color='#656F7D' strokeWidth={1} />
          )}{' '}
        </div>,
      );
      for (let i = start; i <= end; i = i + 1) {
        paginationItems.push(
          <div
            onClick={() => pageOnClick(i)}
            className={`${currentPageNo === i ? 'page_active ' : 'page_in_active'}`}
          >
            {i}
          </div>,
        );
      }
      paginationItems.push(
        <div
          onClick={() => {
            if (totalPages > currentPageNo) {
              pageOnClick(currentPageNo + 1);
            }
          }}
          className={`d-flex user-select-none pe-auto ${
            totalPages === currentPageNo ? 'cursor-no-drop' : ''
          }`}
      
          
        >
          {totalPages == currentPageNo ? (
            <SquareChevronRight size={26} color='#EAEBEF' strokeWidth={1}  />
          ) : (
            <SquareChevronRight size={26} color='#656F7D' strokeWidth={1} />
          )}
        </div>,
      );
    }

    setPaginationItems(paginationItems);
  };

  useEffect(() => {
    setListAndPagination(props.demoData);
  }, [props.demoData]);

  return (
    <div className='pagination_parent_contain'>
      <div className='page_txt'>
        {props?.demoData?.page} of {props?.demoData?.pages} pages
      </div>
      <ul className='pagination_contain'> {paginationItems}</ul>
    </div>
  );
};
