/* eslint-disable no-unused-vars */
import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { RT } from '../_constants';

export default function OrganizationLayout() {
  const ORGANIZATION_TABS = [
    { name: 'Organization', path: `${RT.ORGANIZATION}` },
    // { name: 'Branch', path: `${RT.BRANCH}` },
    { name: 'Department', path: `${RT.DEPARTMENT}` },
    { name: 'Designation', path: `${RT.DESIGNATION}` },
  ];
  return (
    <section className='organization-layout'>
      <div className='org-nav-contain'>
        {ORGANIZATION_TABS.map((tab, index) => {
          return (
            <NavLink
              key={index}
              className={({ isActive }) => {
                return isActive ? 'menu_active' : 'menu_inactive';
              }}
              to={tab?.path}
            >
              {tab.name}
            </NavLink>
          );
        })}
      </div>
      <section>
        <Outlet />
      </section>
    </section>
  );
}
