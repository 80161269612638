import { useEffect } from 'react';

/**
 * A custom hook that delays the execution of a function until after a specified delay
 * has elapsed since the last time it was invoked.
 *
 * @param {Function} effect - The effect callback function to be debounced.
 * @param {number} delay - The debounce delay in milliseconds.
 * @param {Array} deps - The dependency array for the effect.
 */
function useDebouncedEffect(effect, delay, deps) {
  useEffect(() => {
    const handler = setTimeout(() => {
      effect();
    }, delay);

    // Cleanup function to clear the timeout if dependencies change or component unmounts
    return () => {
      clearTimeout(handler);
    };
  }, [...(deps || []), delay]);
}

export default useDebouncedEffect;