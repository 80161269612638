import React, { useEffect, useRef, useState } from 'react';

export const DetailsRow = ({
  icon,
  heading,
  value = '--',
  left_col = 'col-7',
  right_col = 'col-md-5',
}) => {
  const valueRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const element = valueRef.current;
    if (element.scrollWidth > element.clientWidth) {
      setIsOverflowing(true);
    } else {
      setIsOverflowing(false);
    }
  }, [value]);

  return (
    <div className='d-flex ' style={{ padding: '7px 0' }}>
      <div className={left_col}>
        <div className='details-field-name d-flex gap-1'>
          <span>{icon}</span>
          <span>{heading}</span>
        </div>
      </div>
      <div
        className={right_col}
        ref={valueRef}
        style={isOverflowing ? { lineBreak: 'anywhere' } : {}}
      >
        <div className='details-field-value'>{value}</div>
      </div>
    </div>
  );
};
