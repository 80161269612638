/* eslint-disable react/react-in-jsx-scope */
import { AtSign, Bookmark, Calendar, LineChart, SquareCheck, Zap } from 'lucide-react';
import { Images } from './Utils';

export const TASK_TABLE_HEADINGS = [
  { name: <img src={Images.SerialNo} height={14} width={14} />, minWidth: 50 },
  {
    name: (
      <>
        <SquareCheck size={16} /> Tasks
      </>
    ),
    minWidth: '10vw',
    maxWidth: '20vw',
  },
  {
    name: (
      <>
        <Zap size={16} /> Action
      </>
    ),
    minWidth: 50,
  },
  {
    name: (
      <>
        <AtSign size={16} /> Assignee
      </>
    ),
    minWidth: 50,
  },
  {
    name: (
      <>
        <Bookmark size={16} /> Priority
      </>
    ),
    minWidth: 50,
  },
  {
    name: (
      <>
        <Calendar size={16} /> Start Date
      </>
    ),
    minWidth: 50,
  },
  {
    name: (
      <>
        {' '}
        <LineChart size={16} color='#87909E' /> Status
      </>
    ),
    minWidth: 50,
  },
  { name: <></>, minWidth: 25 },

  // { name: <CirclePlus size={16} />  },
];
