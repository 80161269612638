import { keepPreviousData, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { ErrorComponent } from '../_components/ErrorComponent';
import { USER_LIST_ACTIONS } from '../_constants';
import useDebounce from '../_helpers/useDebounce';
import { taskService, userService } from '../_services';
import { statusService } from '../_services/status.service';
import { STATUS, TASK_TABS, taskAssignUserSelect } from '../Task/taskConstants';
import TaskListView from '../Task/TaskListView';
import { jsonToUrlEncoded, taskSortPayload } from '../Utils';
import { LoadingIcon } from '../Utils/SvgIcons';

export const ClientTaskList = () => {
  const { clientData } = useOutletContext();
  //   const id = useParams()?.id;
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSortProcessed, setIsSortProcessed] = useState(false);
  const [urlQuery, setULRQuery] = useState();
  // const [currentView, setCurrentView] = useState(TASK_TABS.TASKS);
  const currentTaskViewType = searchParams.get('currentView');

  const [currentView, setCurrentView] = useState(
    currentTaskViewType ? currentTaskViewType : TASK_TABS.TASKS,
  );

  useEffect(() => {
    if (currentTaskViewType) {
      setCurrentView(currentTaskViewType);
    } else {
      setCurrentView(TASK_TABS.TASKS);
    }
  }, [currentTaskViewType]);

  //   const isAdmin = User?.role == 1;

  const [query, setQuery] = useState({
    select: [
      'id',
      'name',
      'note',
      'created_time',
      'task_end_date',
      'order_seq',
      'status',
      'priority',
      'recent_chat_message',
      'progress_status',
      'label',
      'project_id',
      'task_start_date',
      'created_time',
      'days_difference',
      'status_group_item_id',
      'status_id',
      'parent_id',
    ],
    // searchKey: '',
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
    is_duration_required: true,
  });

  if (searchParams.get('pageNo') && query?.pageVo?.pageNo !== searchParams.get('pageNo')) {
    setQuery({
      ...query,
      pageVo: { ...query.pageVo, pageNo: searchParams.get('pageNo') },
    });
  }

  //   const {
  //     data: clientData,
  //     error,
  //     isLoading: isContactLoading,
  //   } = useQuery({
  //     queryKey: ['contact-single-view', id],
  //     queryFn: () =>
  //       clientService.clientGet({
  //         select: ['id', 'name'],
  //         id: id,
  //       }),
  //     select: (data) => data?.data?.[0],
  //     placeholderData: keepPreviousData,
  //     enabled: Boolean(id),
  //   });

  const getArrayParam = (param) => {
    const value = searchParams.getAll(param);
    return value.length > 0 ? value : null;
  };

  const getParam = (param) => {
    const value = searchParams.get(param);
    return value !== null ? value : null;
  };

  const { ...restQuery } = query;
  const taskSearchKey = getParam('search') ? getParam('search') : '';
  const debouncedSearch = useDebounce(taskSearchKey, 500);

  const priority = getArrayParam('priority_id');
  const filter_start_date = getParam('task_start_date');
  const filter_end_date = getParam('task_end_date');
  const label = getArrayParam('label_id');
  const project_id = getArrayParam('project_id');
  const sort = getArrayParam('sort');
  const assignee_id = getArrayParam('user_id');
  const task_status = parseInt(getParam('task_status'));

  // const status = getArrayParam('status');

  const filterToUrl = { client_id: clientData?.id };
  if (priority) filterToUrl.priority = priority;
  if (filter_start_date) filterToUrl.filter_start_date = filter_start_date;
  if (filter_end_date) filterToUrl.filter_end_date = filter_end_date;
  if (label) filterToUrl.label = label;
  if (project_id) filterToUrl.project_id = project_id;
  if (assignee_id) filterToUrl.assignee_id = assignee_id;

  const filterCount =
    Object.keys(filterToUrl).filter((key) => key !== 'client_id' && key !== 'assignee_id').length +
    (task_status === STATUS.ARCHIVE ? 1 : 0);

  const {
    data: taskStatusList,
    isLoading: statusLoading,
    refetch: taskStatusListRefetch,
  } = useQuery({
    queryKey: ['taskStatusList', filterToUrl, debouncedSearch, clientData?.id, currentView],
    queryFn: () =>
      statusService.statusWithTaskCount({
        select: ['id', 'name', 'color', 'default_name', 'icon'],
        task_filter: {
          ...filterToUrl,
          searchKey: debouncedSearch,
          client_id: clientData?.id,
        },
      }),
    select: (data) => data.data,
    placeholderData: keepPreviousData,
    enabled:
      Boolean(clientData?.id) && currentView == TASK_TABS.TASKS && task_status !== STATUS.ARCHIVE,
  });

  const defaultStatusName = searchParams.get('status');
  const selectedStatus = taskStatusList?.find(
    (status) =>
      status.default_name?.toString()?.toLowerCase() ===
      defaultStatusName?.toString()?.toLowerCase(),
  );

  useEffect(() => {
    const sortToUrl = taskSortPayload(sort);

    if (sortToUrl?.length > 0) {
      setQuery((prev) => ({
        ...prev,
        pageVo: { ...prev.pageVo, sort: sortToUrl },
      }));
    } else {
      setQuery((prev) => {
        // eslint-disable-next-line no-unused-vars
        const { sort, ...restPageVo } = prev.pageVo; // Destructure to remove 'sort' key
        return {
          ...prev,
          pageVo: restPageVo,
        };
      });
    }
    setIsSortProcessed(true); // Mark sort processing as done
  }, [JSON.stringify(sort)]);

  const sortCount = sort?.length;

  const queryKey = [
    'task',
    restQuery,
    query?.pageVo?.pageNo,
    debouncedSearch,
    selectedStatus?.id,
    filterToUrl,
    selectedStatus?.default_name,
    currentView,
    task_status,
  ];

  const getPayLoad = (query) => {
    const encodedQuery = Object.keys(query).reduce((acc, key) => {
      acc[key] = typeof query[key] === 'object' ? JSON.stringify(query[key]) : query[key];
      return acc;
    }, {});
    setULRQuery(jsonToUrlEncoded(encodedQuery));
    return query;
  };

  const {
    data: taskData,
    error: taskError,
    isLoading: taskLoading,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    // isFetching,
    refetch,
  } = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam = 1 }) =>
      taskService.taskListAdvanced(
        getPayLoad({
          ...restQuery,
          status_id: task_status === STATUS.ARCHIVE ? -1 : selectedStatus?.id,
          default_name: task_status === STATUS.ARCHIVE ? 'all' : selectedStatus?.default_name,
          // is_status_group_item: project?.has_multiple_templates ? false : true,
          ...(selectedStatus?.is_verified?.toString()
            ? { is_verified: selectedStatus.is_verified }
            : {}),
          ...filterToUrl,
          ...(task_status ? { status: task_status } : {}),
          searchKey: debouncedSearch,
          pageVo: { pageNo: pageParam, noOfItems: 10 },
        }),
      ),
    placeholderData: keepPreviousData,
    enabled:
      Boolean(clientData?.id) &&
      (!sort || isSortProcessed) &&
      Boolean(selectedStatus?.id) &&
      currentView == TASK_TABS.TASKS, // Enable query only if no sort or sort is processed
    getNextPageParam: (response) =>
      response.data.page < response.data.pages ? response.data.page + 1 : undefined,
    refetchOnWindowFocus: false,
  });

  // to fetch task user list
  const { data: clientUserList, refetch: userListRefetch } = useQuery({
    queryKey: ['task-list-user-list', clientData?.id],
    queryFn: () =>
      userService.getPermittedUser({
        action: USER_LIST_ACTIONS.TASK_FILTER,
        select: taskAssignUserSelect,
        client_id: clientData?.id,
      }),
    select: (response) => response.data.rows,
    enabled: Boolean(clientData?.id),
  });

  // to refetch after task add - task list and task status list
  const refetchData = () => {
    refetch();
    taskStatusListRefetch();
    userListRefetch();
  };

  if (statusLoading) {
    return (
      <div className='h-100 d-flex justify-content-center align-items-center'>
        <LoadingIcon size={50} />
      </div>
    );
  }

  return (
    <div className=' fade-entry'>
      {/* component will active when error occurs   */}
      <ErrorComponent error={taskError?.message} />

      <div>
        <TaskListView
          query={query}
          setQuery={setQuery}
          taskData={taskData}
          userList={clientUserList}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          loading={taskLoading}
          isSuccess={isSuccess}
          taskStatusList={taskStatusList}
          filterCount={filterCount}
          sortCount={sortCount}
          clientDetails={clientData}
          afterTaskAddFn={() => {
            searchParams.set('status', 'todo');
            setSearchParams(searchParams);
          }}
          refetch={refetchData}
          assignee_id={assignee_id ?? null}
          actInlineAsSimple={false}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          urlQuery={urlQuery}
          currentView={currentView}
          setCurrentView={setCurrentView}
          isArchivedList={task_status === STATUS.ARCHIVE}
          
        />
      </div>
    </div>
  );
};
