import React from 'react';
import { NotificationItem } from './NotificationItem';

const ChatNotification = ({ data, setIsNotificationVisible }) => {
  return (
    <div>
      {data?.map((item, index) => {
        return (
          <NotificationItem
            index={item?.id}
            key={index}
            setIsNotificationVisible={setIsNotificationVisible}
            data={item}
          />
        );
      })}
    </div>
  );
};

export default ChatNotification;
