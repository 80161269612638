import { apiConstants } from '../_constants';
import { authHeader, handleResponse } from '../_helpers';
export const boardService = {
  boardAdd,
  boardList,
  boardListAdvanced,
};

function boardAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/board-add', requestOptions).then(handleResponse);
}
function boardList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/board-list', requestOptions).then(handleResponse);
}
function boardListAdvanced(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/board-list-advanced', requestOptions).then(handleResponse);
}
