import moment from 'moment';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RT } from '../_constants';
import { useUser } from '../Hook/useUser';
import { Heading } from '../Misc';
import { WorkActivity } from '../Report';
import ProfileDetails from './ProfileDetails';

const Profile = () => {
  const url = useParams();
  const user = useUser();
  const navigate = useNavigate();
  const todayStart = moment().startOf('day').valueOf();
  const todayEnd = moment().endOf('day').valueOf();
  console.log(url.id, 'url');

  return (
    <div style={{ padding: 20 }}>
      <div className='row'>
        <div className='col-md-6'>
          <Heading title={'Profile'} />
        </div>
      </div>
      <section className='dashboard-layout'>
        <div className='notification-nav-contain w-100'>
          <div
            className={
              url.id == 'details'
                ? 'dashboard_menu_active cursor-pointer'
                : 'dashboard_menu_inactive cursor-pointer '
            }
            onClick={() => navigate(`/${RT.PROFILE}/details`)}
          >
            Details
          </div>
          <div
            className={
              url.id != 'details'
                ? 'dashboard_menu_active cursor-pointer'
                : 'dashboard_menu_inactive cursor-pointer '
            }
            onClick={() =>
              navigate(
                `/${RT.PROFILE}/${RT.WORK_ACTIVITY}?user_id=${
                  user?.id
                }&fromDate=${todayStart}&toDate=${todayEnd}&profile=${true}`,
              )
            }
          >
            Activity
          </div>
        </div>
      </section>
      {url.id == 'details' ? <ProfileDetails /> : <WorkActivity />}
    </div>
  );
};

export default Profile;
