/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useInternetStatus from '../Hook/useInternetStatus';
import './Game.css';

function generateSolvedBoard() {
  // Same as before
  return [
    [5, 3, 4, 6, 7, 8, 9, 1, 2],
    [6, 7, 2, 1, 9, 5, 3, 4, 8],
    [1, 9, 8, 3, 4, 2, 5, 6, 7],
    [8, 5, 9, 7, 6, 1, 4, 2, 3],
    [4, 2, 6, 8, 5, 3, 7, 9, 1],
    [7, 1, 3, 9, 2, 4, 8, 5, 6],
    [9, 6, 1, 5, 3, 7, 2, 8, 4],
    [2, 8, 7, 4, 1, 9, 6, 3, 5],
    [3, 4, 5, 2, 8, 6, 1, 7, 9],
  ];
}

function createPuzzle(solvedBoard) {
  const puzzle = solvedBoard.map((row) => [...row]);
  const cellsToRemove = 40;

  for (let i = 0; i < cellsToRemove; i++) {
    let row = Math.floor(Math.random() * 9);
    let col = Math.floor(Math.random() * 9);
    while (puzzle[row][col] === 0) {
      row = Math.floor(Math.random() * 9);
      col = Math.floor(Math.random() * 9);
    }
    puzzle[row][col] = 0;
  }

  return puzzle;
}

export function Game() {
  const [hasUserPlayed, setHasUserPlayed] = useState(false);
  const isOnline = useInternetStatus({ autoBackWhenOnline: !hasUserPlayed });

  const navigate = useNavigate();
  const [board, setBoard] = useState([]);
  const [solvedBoard, setSolvedBoard] = useState([]);
  const [initialBoard, setInitialBoard] = useState([]);
  const [selectedCell, setSelectedCell] = useState(null);
  const [isValidating, setIsValidating] = useState(false);
  const [showCongratulation, setShowCongratulation] = useState(false);

  useEffect(() => {
    const solved = generateSolvedBoard();
    setSolvedBoard(solved);
    const newPuzzle = createPuzzle(solved);
    setBoard(newPuzzle);
    setInitialBoard(newPuzzle.map((row) => [...row]));
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (selectedCell && event.key >= '0' && event.key <= '9') {
        handleNumberInput(parseInt(event.key, 10));
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedCell]);

  useEffect(() => {
    // Check if the page was reloaded (check localStorage)
    const isReloaded = localStorage.getItem('isReloaded');

    if (isReloaded) {
      // If the page was reloaded, navigate to the home page and clear the flag
      localStorage.removeItem('isReloaded');
      navigate('/'); // Navigate to home page
    }

    const handleBeforeUnload = () => {
      console.log('Page reload detected');
      // Set a flag in localStorage before the page reloads
      localStorage.setItem('isReloaded', 'true');
    };

    // Listen for the beforeunload event
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [navigate]);

  const handleCellClick = (row, col) => {
    if (initialBoard[row][col] === 0) {
      setSelectedCell([row, col]);
    }
  };

  const handleNumberInput = (number) => {
    if (selectedCell) {
      const [row, col] = selectedCell;
      const newBoard = board.map((r) => [...r]);
      newBoard[row][col] = number === 0 ? 0 : number; // Clear cell if number is 0
      setHasUserPlayed(true); // Mark that the user has played
      setBoard(newBoard);
      checkCompletion(newBoard);
    }
  };

  const checkCompletion = (newBoard) => {
    if (JSON.stringify(newBoard) === JSON.stringify(solvedBoard)) {
      setShowCongratulation(true);
      setTimeout(() => {
        setShowCongratulation(false);
        const solved = generateSolvedBoard();
        setSolvedBoard(solved);
        const newPuzzle = createPuzzle(solved);
        setBoard(newPuzzle);
        setInitialBoard(newPuzzle.map((row) => [...row]));
        setHasUserPlayed(false); // Reset user played status

        setSelectedCell(null);
      }, 5000); // 3 seconds delay
    }
  };

  const validateBoard = () => {
    setIsValidating(true);
    setTimeout(() => setIsValidating(false), 1000);
  };

  return (
    <div className='game'>
      <h2>
        Sud
        <span className='validation-toggle' onClick={validateBoard}>
          o
        </span>
        ku
      </h2>
      {showCongratulation ? (
        <div className='congratulation-message'>
          Congratulations! You have completed the puzzle.
        </div>
      ) : (
        <div className='sudoku-board'>
          {board.map((row, rowIndex) => (
            <div key={rowIndex} className='sudoku-row'>
              {row.map((cell, colIndex) => (
                <div
                  key={colIndex}
                  className={`sudoku-cell 
                    ${
                      selectedCell && selectedCell[0] === rowIndex && selectedCell[1] === colIndex
                        ? 'selected'
                        : ''
                    }
                    ${initialBoard[rowIndex][colIndex] === 0 ? 'editable' : ''}
                    ${
                      isValidating && initialBoard[rowIndex][colIndex] === 0 && cell !== 0
                        ? cell === solvedBoard[rowIndex][colIndex]
                          ? 'correct'
                          : 'incorrect'
                        : ''
                    }
                  `}
                  onClick={() => handleCellClick(rowIndex, colIndex)}
                >
                  {cell !== 0 ? cell : ''}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}

      <div className='mt-5'>
        {isOnline ? (
          <span className='status-online'>
            You are connected to the internet. Great to see you online!
          </span>
        ) : (
          <span className='status-offline'>
            You seem to be experiencing some network connectivity issues.
          </span>
        )}
      </div>
      {isOnline && (
        <div onClick={() => navigate('/')} className='back-to-work'>
          Click here to return to your work.
        </div>
      )}
    </div>
  );
}
