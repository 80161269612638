import { apiConstants } from '../_constants';
import { authHeader,  handleResponse } from '../_helpers';
import { jsonToQueryString } from '../_helpers/utilFunctions';

export const folderService = {
  folderAdd,
  folderList,
  folderGet,
  folderDeactivate
};

async function folderList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(apiConstants.apiUrl + '/folder_list', requestOptions).then(handleResponse);
}

function folderGet(data) {
  const params = jsonToQueryString(data);
  const apiUrl = `${apiConstants.apiUrl}/get_folder?${params}`;
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}

function folderAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/folder_add', requestOptions).then(handleResponse);
}

async function folderDeactivate(data) {
  const apiUrl = `${apiConstants.apiUrl}/folder_deactivate`;
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(apiUrl, requestOptions).then(handleResponse);
}
