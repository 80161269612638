import { useMutation } from '@tanstack/react-query';
import React, { useContext, useState } from 'react';
import { Button, Modal } from '../../_components';
import ToolTip from '../../_components/Tooltip/ToolTip';
import { DELETE_NOTE } from '../../_constants/deletenote.constants';
import { taskService } from '../../_services';
import { GlobalContext, Images } from '../../Utils';
import { CustomTrashIcon } from '../../Utils/SvgIcons';

export function TaskDelete({
  taskId = null,
  refetch,
  isTaskStarted = false,
  tableContainerRef,
  // eslint-disable-next-line no-unused-vars
  navigate = '',
  actionFrom = 'singleView',
  succeed_task_ids = [],
}) {
  const { makeAlert } = useContext(GlobalContext);

  const [deleteModal, setDeleteModal] = useState(false);

  const handleTaskDelete = async () => {
    try {
      if (isTaskStarted) {
        return makeAlert('Unable to Delete. This task is currently ongoing.');
      } else if (succeed_task_ids?.length > 0) {
        return makeAlert(
          `Unable to Delete.The task ' ${succeed_task_ids
            .map((task) => task.name)
            .join(', ')} ' depend on this task.`,
        );
      }

      const payload = { id: taskId };
      const result = await taskService.taskDelete(payload);
      console.log(result);
      makeAlert('Task Deleted');
      if (actionFrom === 'singleView') {
        navigate();
      }
      if (actionFrom === 'list') {
        refetch();
      }
    } catch (e) {
      makeAlert(e?.message);
      makeAlert('Deletion Failed');
    } finally {
      // favoriteRefetch(); // fetch after update
    }
  };

  //-- react query fot mutation function
  const { mutate: handleMutate, isPending } = useMutation({
    mutationFn: handleTaskDelete,
  });
  return (
    <div>
      <ToolTip tooltipText={'Delete Task'} isModern popOver scrollRef={tableContainerRef}>
        {actionFrom === 'singleView' && (
          <Button.Container
            // isDisabled={isPending}
            className={'tab-click inline-task-item-container custom-icon pin-container '}
            handleOk={() => setDeleteModal(true)} // Wrap in a function to avoid immediate execution
          >
            <CustomTrashIcon height={16} width={16} color={'#D2D2D2'} strokeWidth='1' />
          </Button.Container>
        )}
        {actionFrom === 'list' && (
          <div
            // isDisabled={isPending}
            className='subtask-icon-bg'
            onClick={() => {
              // if (!isPending) {
              setDeleteModal(true);
              // }
            }}
          >
            <CustomTrashIcon color='grey' height={13} width={13} strokeWidth='1' />
          </div>
        )}
      </ToolTip>

      {deleteModal && (
        <TaskDeleteConfirmation
          setDeleteModal={setDeleteModal}
          handleMutate={handleMutate}
          makeAlert={makeAlert}
          isPending={isPending}
        />
      )}
      {/* delete confirmation  */}
    </div>
  );
}

const TaskDeleteConfirmation = ({ setDeleteModal, handleMutate, makeAlert, isPending = false }) => {
  const [inputText, setInputText] = useState('');

  const handleInputChange = (e) => {
    const input = e.target.value;
    setInputText(input); // Set the input text directly
  };

  const handleDeleteButton = () => {
    // Check if the input text is exactly "confirm task delete" in lowercase
    if (inputText.trim() === 'confirm task delete') {
      handleMutate();
    } else {
      makeAlert('Please enter "confirm task delete" to confirm deletion in lowercase.');
    }
  };

  return (
    <Modal.Container>
      <Modal.View className={'delete-container'}>
        <Modal.Head handleClose={() => setDeleteModal(false)} Heading={'Delete Confirmation'} />
        <Modal.Description className='delete-content'>
          <img src={Images.DeleteBin} width={35} height={50} alt='' className='mb-3' />
          <div className='del-description'>
            {DELETE_NOTE.TASK.map((part, index) =>
              ['subtasks', 'reminders', 'recurrence', 'chat history'].includes(part) ? (
                <span key={index} className='delete-highlight'>
                  {part}
                </span>
              ) : (
                part
              ),
            )}
          </div>
          <div>
            <label className='fz-13px-rem'>
              Enter exactly <span className='field-required'>“confirm task delete”</span>{' '}
              <span className='delete-highlight fz-13px'>(in lower case)</span> to confirm{' '}
              <span className='field-required'>*</span>
            </label>
            <input
              type='text'
              name='delete-confirm'
              className='input-box'
              value={inputText}
              onChange={handleInputChange}
            />
          </div>
        </Modal.Description>
        <div className='delete-btn-container'>
          <Button.Container handleOk={() => setDeleteModal(false)} className={'status-del-cancel'}>
            <Button.Title title={'Cancel'} />
          </Button.Container>
          <Button.Container handleOk={() => handleDeleteButton()} isDisabled={isPending}>
            <Button.Title title={'Delete'} />
          </Button.Container>
        </div>
      </Modal.View>
    </Modal.Container>
  );
};
