/* eslint-disable no-unused-vars */
import draftToHtml from 'draftjs-to-html';
import { Hourglass, Plus, User, Zap } from 'lucide-react';
import React, { useState } from 'react';
import CircularAvatar from '../../_components/Avatar/CircularAvatar';
import IconLabelSet from '../../_components/IconLabelSet';
import ToolTip from '../../_components/Tooltip/ToolTip';
import { apiConstants } from '../../_constants';
import { POSITION } from '../../_constants/contextMenu.constants';
import { START_MODE } from '../../_constants/template.constants';
import {
  getExpectedTime,
  getLabelIsActive,
  getPriorityByContextAPI,
  getSubTaskIsActive,
  removeTagsAndGetContent,
} from '../../Task/taskValidation';
import SubtaskListWithAdd from '../../Template/SubComponents/SubtaskListWithAdd';
import { fullNameUser, subtaskState } from '../../Utils';
import { Label, Priority } from '../../Utils/SvgIcons';
/***Expanded Related Task Preview */
const TemplateTaskPreviewExpanded = ({
  relatedTaskData,
  onSubmitSubtask,
  isExpanded,
  precedingTask,
  validation_fields,
}) => {
  // for get settings label value and subtask value - get values by context API
  const isMenuLabelOn = getLabelIsActive();

  const isMenuSubTaskOn = getSubTaskIsActive();

  const priorityLabelList = getPriorityByContextAPI(); // to fetch the priority  label from context api
  const [subTask, setSubTask] = useState(relatedTaskData?.subtasks);
  const priorityLabel = priorityLabelList.filter(
    (item) => parseInt(item.id) === parseInt(relatedTaskData?.priority),
  );

  const expectedTime = getExpectedTime(
    parseInt(relatedTaskData?.expected_hours),
    parseInt(relatedTaskData?.expected_minutes),
  );
  const isNeutral = relatedTaskData?.assignee_id?.gender === 3;
  const isMale = relatedTaskData?.assignee_id?.gender === 1;
  const url = relatedTaskData?.assignee_id?.image_url;
  const finalUrl = url ? apiConstants.imgUrlS3 + url : null;
  const fullName = fullNameUser(
    relatedTaskData?.assignee_id.first_name,
    relatedTaskData?.assignee_id.last_name,
  );
  const labelCount = relatedTaskData?.label?.length;
  const moreLabel = relatedTaskData?.label?.slice(1);
  const handleSubTaskAddOrUpdate = (value, index = null, ...rest) => {
    // update
    if (!isNaN(index) && index !== null && index <= subTask.length - 1) {
      setSubTask((prev) =>
        prev?.map((itm, idx) => {
          if (idx === index) {
            return { ...itm, content: value, startDate: rest[1] };
          }
          return itm;
        }),
      );
      onSubmitSubtask(
        subTask?.map((itm, idx) => {
          if (idx === index) {
            return { ...itm, content: value, startDate: rest[1] };
          }
          return itm;
        }),
      );
      return;
    }
    const initSubtask = { ...subtaskState };
    initSubtask.content = value;
    initSubtask.startDate = rest[0];
    setSubTask((prev) => [...prev, initSubtask]);
    onSubmitSubtask([...subTask, initSubtask]);
  };

  // remove subtask data
  const handleSubtaskDelete = (index) => {
    setSubTask((prev) => prev.filter((item, idx) => idx !== index));
  };

  return (
    <div
      className={`anim-hide my-5px ${isExpanded ? '' : 'template-rows-0fr'} position-relative`}
      style={{ paddingLeft: 18 }}
    >
      <div className={`${isExpanded ? 'delay-overflow-visible' : 'overflow-hidden'}`}>
        {/* /GRID */}
        <div className='related-task-expanded-grid'>
          <IconLabelSet label={'Owner'} icon={<User size={14} />} />
          <div className='d-flex gap-4 align-items-center '>
            {relatedTaskData.assignee_id ? (
              <div className='d-flex gap-2 align-items-center'>
                <CircularAvatar
                  size={23}
                  className='fz-none  employee-img-with-low-border'
                  source={finalUrl}
                  isMale={isMale}
                  isNeutral={isNeutral}
                />
                <span className='font-12'>{fullName}</span>
              </div>
            ) : (
              <div>Unassigned</div>
            )}
          </div>
          {expectedTime && (
            <>
              <IconLabelSet label={'Expected Time'} icon={<Hourglass width={11} height={15} />} />
              <div className='d-flex gap-4 align-items-center '>
                <div className='timeline-text mt-1 no-wrap'>{expectedTime} </div>
              </div>
            </>
          )}
          {priorityLabel.length > 0 && (
            <>
              <IconLabelSet label={'Priority'} icon={<Priority width={8} height={13} />} />
              <div className='d-flex gap-4 align-items-center '>
                <div className=' priority-box mt-1' style={{ height: 23 }}>
                  <text className='priority-text'>{priorityLabel[0].label}</text>
                </div>
              </div>
            </>
          )}

          {labelCount > 0 && (
            <>
              <IconLabelSet label={'Label'} icon={<Label size={13} />} />
              <div className='col-md-5' hidden={!isMenuLabelOn}>
                <div className='d-flex gap-4 align-items-center '>
                  <div className='d-flex gap-2'>
                    <div className='label-container-related'>
                      {relatedTaskData?.label?.[0]?.name}
                    </div>{' '}
                    {labelCount > 1 && (
                      <ToolTip tooltipText={moreLabel?.map((itm) => itm.name + ', \n')} isModern>
                        <div className='label-count-container'>
                          <Plus color='#87909E' size={9} />
                          {labelCount - 1}
                        </div>
                      </ToolTip>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}

          <IconLabelSet label={'Start Mode'} icon={<Zap width={12} height={14} />} />
          <div className='d-flex gap-4 align-items-center '>
            <div className='start-mode-text'>
              {`${START_MODE[relatedTaskData?.task_start_mode]} ${
                relatedTaskData?.preceding_task_id ? relatedTaskData?.preceding_task_id : ''
              } 
            `}
              {relatedTaskData?.preceding_task_name && (
                <span className='related-task-name-block '>
                  {relatedTaskData?.preceding_task_name}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className='description-container-related'>
          {removeTagsAndGetContent(draftToHtml(relatedTaskData?.note))}
        </div>

        {/* FLEX */}

        {/* <div className='row mt-3 gap-1'>
          <div className='col-md-6'>
            <div className='d-flex gap-4 align-items-center '>
              {' '}
              <IconLabelSet label={'Owner'} icon={<User size={14} />} />
              {relatedTaskData.assignee_id ? (
                <div className='d-flex gap-2 align-items-center'>
                  <CircularAvatar
                    size={23}
                    className='fz-none  employee-img-with-low-border'
                    source={finalUrl}
                    isMale={isMale}
                    isNeutral={isNeutral}
                  />
                  <span className='font-12'>{fullName}</span>
                </div>
              ) : (
                <div>Unassigned</div>
              )}
            </div>
          </div>

          <div className='col-md-5'>
            <div className='d-flex gap-4 align-items-center '>
              <IconLabelSet label={'Expected Time'} icon={<Hourglass width={11} height={15} />} />
              <div className='timeline-text mt-1 no-wrap'>{ExpectedTime} </div>
            </div>
          </div>
        </div>
        <div className='row mt-3 gap-1'>
          <div className='col-md-6'>
            <div className='d-flex gap-4 align-items-center '>
              <IconLabelSet label={'Priority'} icon={<Priority width={8} height={13} />} />
              {priorityLabel.length > 0 && (
                <div className=' priority-box mt-1' style={{ height: 23 }}>
                  <text className='priority-text'>{priorityLabel[0].label}</text>
                </div>
              )}
            </div>
          </div>
          {labelCount > 0 && (
            <div className='col-md-5' hidden={!isMenuLabelOn}>
              <div className='d-flex gap-4 align-items-center '>
                <IconLabelSet label={'Label'} icon={<Label size={13} />} />
                <div className='d-flex gap-2'>
                  <div className='label-container-related'>{relatedTaskData?.label?.[0]?.name}</div>{' '}
                  {labelCount > 1 && (
                    <ToolTip tooltipText={moreLabel?.map((itm) => itm.name + ', \n')} isModern>
                      <div className='label-count-container'>
                        <Plus color='#87909E' size={9} />
                        {labelCount - 1}
                      </div>
                    </ToolTip>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='row mt-3 gap-1'>
          <div className='col-md-5'>
            <div className='d-flex gap-4 align-items-center '>
              <IconLabelSet label={'Start Mode'} icon={<Zap width={12} height={14} />} />
              <div className='start-mode-text'>{`${START_MODE[relatedTaskData?.task_start_mode]} ${
                relatedTaskData?.preceding_task_id ? relatedTaskData?.preceding_task_id : ''
              }`}</div>
            </div>
          </div>
        </div>
        <div className='description-container-related'>
          {removeTagsAndGetContent(draftToHtml(relatedTaskData?.note))}
        </div> */}
        {/* {!isMenuSubTaskOn && ( */}
        <div className='py-3' hidden={!isMenuSubTaskOn || !isExpanded}>
          <div className='timeline-text mb-1'> Sub Task</div>
          <div className={relatedTaskData?.subtasks.length == 0 ? 'subtask-add-box-border' : ''}>
            <SubtaskListWithAdd
              disableNonEmptyView={true}
              headVisible={false}
              key={1}
              textMinWidth={'19vw'}
              list={subTask}
              onAddOrUpdate={handleSubTaskAddOrUpdate}
              onDelete={handleSubtaskDelete}
              position={POSITION.RIGHT}
            />
          </div>
        </div>
        {/* )} */}
      </div>
    </div>
  );
};

export default TemplateTaskPreviewExpanded;
