/* eslint-disable no-unused-vars */
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { FileIcon, Home, Pen, Phone, Plus, Search, Trash } from 'lucide-react';
import React, { useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { TaskListTypeConst } from '../BoardView/BoardView.constant';
import { useUser } from '../Hook/useUser';
import { Images, MyTeamIcon } from '../Utils';
import { ClientIcon, Folder, LoadingIcon, ProgressIcon, ProjectIcon } from '../Utils/SvgIcons';
import { Button, PaginationComponent, TableRow } from '../_components';
import IconLabelSet from '../_components/IconLabelSet';
import { Modal } from '../_components/Modal';
import ToolTip from '../_components/Tooltip/ToolTip';
import { LABEL_OF_CLIENT, RT } from '../_constants';
import { DELETE_NOTE } from '../_constants/deletenote.constants';
import useDebounce from '../_helpers/useDebounce';
import { clientService } from '../_services';
import { ClientAdd } from './ClientAdd';

export function ClientList() {
  const user = useUser();
  const isAdmin = user?.role == 1;

  const queryClient = useQueryClient();
  const [editClientId, setEditClientID] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [searchParams, setSearchParams] = useSearchParams();
  const status = queryParams.get('status');

  const isSettingsPath = window.location.pathname.includes('/settings');

  const [query, setQuery] = useState({
    select: ['id', 'name', 'email', 'phone', 'company_name', 'description', 'pin_code', 'address'],
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
    searchKey: '',
  });
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [contextMenuIndex, setContextMenuIndex] = useState(null);
  const debouncedSearch = useDebounce(query?.searchKey, 500);

  const tableContainerRef = useRef(null);
  const reference = useRef(null);

  // to get clients using react query
  const {
    data: clientData,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ['clientList', status, debouncedSearch, query?.pageVo?.pageNo],
    queryFn: () =>
      clientService.clientList({
        ...query,
      }),
    placeholderData: keepPreviousData,
  });

  const handleClickOption = (option) => {
    if (option == 'edit') {
      setAddModal(true);
    } else if (option == 'delete') {
      setDeleteModal(true);
    }
  };

  const modalClose = () => {
    setDeleteModal(false);
    setEditClientID(null);
  };
  const handleDeactivate = async (id) => {
    try {
      await clientService.clientDeactivate({ id });
      await queryClient.invalidateQueries('client');
      modalClose();
    } catch (error) {
      console.error('Error deleting client:', error);
    }
  };

  const listItemCount = clientData?.data?.rows?.length;

  const tableData = clientData?.data?.rows?.map((client, index) => {
    const slNo = (clientData?.data?.page - 1) * clientData?.data?.limit + index + 1;
    return (
      <Tr
        key={index}
        className='pointer'
        onClick={() => {
          navigate(
            `/${RT.CLIENT_SINGLE_PAGE}/${client?.id}/${
              RT.CLIENT_TASK_LIST
            }?status=${'in-progress'}&list_type=${TaskListTypeConst.ContactTask}`,
          );
        }}
      >
        <Td>{slNo}</Td>
        <Td>
          <div>{client?.name}</div>
        </Td>
        <Td>{client?.company_name ? client?.company_name : '---'}</Td>
        {/* <Td>{client?.description ? client?.description : '---'}</Td>
        <Td>{client?.email ? client?.email : '---'}</Td>
        <Td>{client?.phone ? client?.phone : '---'}</Td> */}
        <Td>{client?.total_task_count} tasks</Td>
        <Td>{client?.total_file_count} files</Td>
        <Td>{client?.due_task_count}</Td>
        <Td
          style={{ verticalAlign: 'middle', cursor: 'pointer' }}
          ref={reference}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {isAdmin && (
            <div className='d-flex gap-2 justify-content-end icon-div invisible'>
              {/* {(projectData?.data?.isPrivileged || permissionsMap?.project_edit) && ( */}
              <ToolTip
                tooltipText={`Edit ${LABEL_OF_CLIENT}`}
                isModern
                popOver
                scrollRef={tableContainerRef}
              >
                <div
                  className='icon-bg'
                  onClick={(event) => {
                    event.stopPropagation();
                    setEditClientID(client?.id);
                    setAddModal(true);
                    // nav(`/${RT.PROJECT_ADD}/${project?.id}`);
                  }}
                >
                  <Pen size={15} />
                </div>
              </ToolTip>
              {/* )} */}
              <ToolTip
                tooltipText={`Delete ${LABEL_OF_CLIENT}`}
                isModern
                popOver
                scrollRef={tableContainerRef}
              >
                <div
                  className='icon-bg'
                  onClick={(event) => {
                    event.stopPropagation();
                    setEditClientID(client?.id);
                    setDeleteModal(true);
                  }}
                >
                  <Trash size={15} />
                </div>
              </ToolTip>
            </div>
          )}
        </Td>
      </Tr>
    );
  });

  return (
    <div>
      {!isSettingsPath && (
        <section>
          <div className=' url-container '>
            <div className='d-flex align-items-center breadcrumbs'>
              <IconLabelSet
                label={'Home'}
                fontWeight={400}
                icon={<Home size={13} />}
                onClick={() => navigate(`/`)}
              />
              <img className='slash-img-icon-size ms-1 mt-1' src={MyTeamIcon.Slash} alt='' />
              <IconLabelSet
                label={LABEL_OF_CLIENT}
                fontWeight={500}
                icon={<ClientIcon color='#2A2E34' height={16} width={15} />}
                onClick={() => {
                  navigate(`/${RT.CLIENT}`);
                }}
              />
            </div>
          </div>
        </section>
      )}
      <div style={{ padding: !isSettingsPath ? 16 : '10px 15px 10px 0px', width: '75vw' }}>
        <section>
          <div className='tab_contain' style={{ margin: 0, marginBottom: 10 }}>
            <div className='search_contain'>
              <Search size={20} color='black' />
              <input
                onChange={(event) => {
                  searchParams.set('pageNo', 1);
                  setSearchParams(searchParams);
                  setQuery({
                    ...query,
                    searchKey: event.target.value,
                    pageVo: { ...query.pageVo, pageNo: 1 },
                  });
                }}
                placeholder='Search...'
                type='text'
                className='bodr_none'
              />
            </div>
            {isAdmin && (
              <div className='d-flex gap-2'>
                <ToolTip tooltipText={`Add ${LABEL_OF_CLIENT}`} isModern>
                  <div
                    className='menu-item-outline'
                    // className='add_item_button'
                    onClick={() => {
                      setAddModal(true);
                    }}
                  >
                    {/* <Plus size={16} /> Add {LABEL_OF_CLIENT} */}

                    <Plus size={16} color='#87909E' />
                  </div>
                </ToolTip>
                {/* <div className='list_top_card'>
              Sort by <ChevronDown size={13} />
            </div> */}
              </div>
            )}
          </div>
          <section className='mt-2'>
            <div
              className='project-table table-container no-padding'
              ref={tableContainerRef}
              style={{ maxHeight: '65vh' }}
            >
              <Table className='table table-hover tbl mb-0'>
                {/* <thead>
                <tr>
                  <th scope='col'> Sl.No</th>
                  <th scope='col'> Name</th>
                  <th scope='col'> Company Name</th>
                  <th scope='col' style={{ width: 300 }}>
                    {' '}
                    Description
                  </th>
                  <th scope='col'> Email</th>
                  <th scope='col'> Phone</th>
                  <th scope='col'></th>
                  <th scope='col'></th>
                </tr>
              </thead> */}
                <Thead>
                  <Tr style={{ backgroundColor: '#F3F5F7' }} className='table-head'>
                    {TABLE_HEADINGS.map((heading, index) => {
                      return (
                        <Th
                          key={index}
                          scope='col'
                          style={{ minWidth: heading.minWidth, maxWidth: heading.maxWidth }}
                        >
                          {heading.name}
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>
                {/* <Tbody>{tableData}</Tbody> */}
                <Tbody style={{ verticalAlign: 'middle' }}>
                  {isLoading && (
                    <TableRow noOfCols={8}>
                      <LoadingIcon color='blue' size={60} />
                    </TableRow>
                  )}
                  {!listItemCount && isSuccess && <TableRow noOfCols={8}>No data found</TableRow>}
                  {listItemCount > 0 && isSuccess && tableData}
                </Tbody>
              </Table>
            </div>
          </section>
          <section className='mt-2'>
            <PaginationComponent
              pageOnClick={(data) => {
                setQuery((prev) => ({
                  ...prev,
                  pageVo: {
                    ...prev.pageVo,
                    pageNo: data,
                  },
                }));
                searchParams.set('pageNo', data);
                setSearchParams(searchParams);
              }}
              demoData={clientData?.data}
            />
          </section>
        </section>
        {addModal && (
          <Modal.Container handleClose={() => setAddModal(false)}>
            <Modal.View className={'modal_view_label'}>
              <Modal.Head
                Heading={editClientId ? 'Edit Contact' : 'Add Contact'}
                handleClose={() => {
                  setAddModal(false);
                  setEditClientID(null);
                }}
              />
              <ClientAdd
                id={editClientId}
                handleClose={() => {
                  setAddModal(false);
                  setEditClientID(null);
                  refetch();
                }}
              />
            </Modal.View>
          </Modal.Container>
        )}

        {deleteModal && (
          <Modal.Container>
            <Modal.View className={'status-delete'}>
              <Modal.Head handleClose={() => modalClose()} Heading={'Delete Confirmation'} />
              <Modal.Description>
                <img src={Images.DeleteBin} width={35} height={50} alt='' className='mb-3' />
                <div className='del-description'>{DELETE_NOTE.CLIENT}</div>
              </Modal.Description>
              <div className='status-del-btn'>
                <Button.Container handleOk={() => modalClose()} className={'status-del-cancel'}>
                  <Button.Title title={'Cancel'} />
                </Button.Container>
                <Button.Container handleOk={() => handleDeactivate(editClientId)}>
                  <Button.Title title={'Delete'} />
                </Button.Container>
              </div>
            </Modal.View>
          </Modal.Container>
        )}
      </div>
    </div>
  );
}

const TABLE_HEADINGS = [
  { name: <img src={Images.SerialNo} height={14} width={14} />, minWidth: 50 },
  {
    name: (
      <div className='d-flex align-items-center gap-1'>
        <ProjectIcon height={16} width={16} color='#87909E' /> {LABEL_OF_CLIENT}
      </div>
    ),
    minWidth: '10vw',
    maxWidth: '20vw',
  },
  {
    name: (
      <div className='d-flex align-items-center gap-1'>
        <ProgressIcon /> Company name
      </div>
    ),
    minWidth: 50,
  },
  {
    name: (
      <div className='d-flex align-items-center gap-1'>
        <FileIcon size={16} /> Total Tasks
      </div>
    ),
    minWidth: 50,
  },
  {
    name: (
      <div className='d-flex align-items-center gap-1'>
        <Folder height={19} width={15} color='#87909E' /> Total Files
      </div>
    ),
    minWidth: 50,
  },
  {
    name: (
      <div className='d-flex align-items-center gap-1'>
        <Phone size={14} /> Due tasks
      </div>
    ),
    minWidth: 50,
  },
  {},
];
