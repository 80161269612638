import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import {LoadingImg} from '../_components'

import { demoService } from '../_services';

export const Demo = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [demo, setDemo] = useState({
    name: '',
    description: '',
  });
  const [query, setQuery] = useState({
    select: ['id', 'name', 'description'],
  });

  useEffect(() => {
    if (id && id !== 0 && id !== '0') {
      query.id = id;
      getAllDemo(query);
    }
  }, []);

  /**
   * Get demo
   * */
  const getAllDemo = async (queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    setQuery(query);
    setIsLoading(true);

    try {
      const demo = await demoService.demoGet(queryNew);
      if (demo?.data?.length) {
        setDemo(demo.data[0]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='Demo'>
      {isLoading && <LoadingImg />}
      <div className='col-md-12'>
        <div className='card regular-table-with-color'>
          <div className='card-header page-header '>
            <div className='page-titile-content'>
              <h4 className='card-title'>Demo view</h4>
            </div>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-md-4'>
                <label className='float-right'>Description </label>
              </div>
              <div className='col-md-6'>
                <b>{demo.description}</b>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-4'>
                <label className='float-right'>Demo </label>
              </div>
              <div className='col-md-6'>
                <b>{demo.name}</b>
              </div>
            </div>
          </div>
          <div className='card-footer'>
            <Link className='link' to={'/demoAdd/' + demo.id}>
              Edit
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
