// Recurrence
export const recurFrequency = {
  DAY_RECURRENCE: 1,
  WEEK_RECURRENCE: 2,
  MONTH_RECURRENCE: 3,
  YEAR_RECURRENCE: 4,
};

// recurrence type
export const monthlyRecurType = {
  NOT_SELECTED: 0,
  BY_DATE: 1,
  BY_WEEK: 2,
};

export const recurEndType = {
  NEVER: 0,
  ON_SCHEDULE: 1,
};

export const APPEND_NAME_STYLE = {
  DD_MM_YYYY: 1,
  MM_DD_YYYY: 2,
  Month: 3,
  Year: 4,
};

export const recurFrequencyString = {
  1: 'day',
  2: 'week',
  3: 'month',
  4: 'year',
};

export const recurWeekNumberList = [
  { label: 'First', value: 1 },
  { label: 'Second', value: 2 },
  { label: 'Third', value: 3 },
  { label: 'Fourth', value: 4 },
  { label: 'Fifth', value: 5 },
  { label: 'Sixth', value: 6 },
];

export const recurWeekNumberObj = {
  1: 'first',
  2: 'second',
  3: 'third',
  4: 'fourth',
  5: 'fifth',
  6: 'sixth',
};

export const recurUpdateType = {
  THIS_TASK_ONLY: 0,
  THIS_TASK_AND_FOLLOWING_TASKS: 1,
};

export const daysWithFirstLetter = [
  { letter: 'S', value: 0, day: 'Sunday' },
  { letter: 'M', value: 1, day: 'Monday' },
  { letter: 'T', value: 2, day: 'Tuesday' },
  { letter: 'W', value: 3, day: 'Wednesday' },
  { letter: 'T', value: 4, day: 'Thursday' },
  { letter: 'F', value: 5, day: 'Friday' },
  { letter: 'S', value: 6, day: 'Saturday' },
];
