import React from 'react';
import { components } from 'react-select';
import ClickableSelect from '../../Template/SubComponents/ClickableSelect';
import { Priority } from '../../Utils/SvgIcons';

function PriorityLabelMenuList(props) {
  const { children } = props;

  return (
    <>
      <components.MenuList {...props}>{children}</components.MenuList>
      <div
        role='button'
        onClick={() => props.clearValue(props.focusedOption)}
        className='d-flex align-items-center btn-wrapper'
        style={{ borderTop: '1px solid #f1f1f1', color: '#87909E', padding: 11 }}
      >
        - Clear
      </div>
    </>
  );
}

function PriorityLabelMenuItem({ data, metaData, getLabel = () => {} }) {
  const label = getLabel(data);
  if (metaData.context === 'value') {
    return label;
  }
  return (
    <div className='d-flex align-items-center no-wrap' style={{ gap: 12 }}>
      <Priority height={13} width={8} color='#2A2E34' />
      {label}
    </div>
  );
}

function PrioritySelectControl(props) {
  const hasValue = props.hasValue;

  return (
    <components.Control {...props}>
      <ClickableSelect
        label={props.children}
        icon={<Priority height={13} width={8} color={hasValue ? '#000' : 'rgb(135, 144, 158)'} />}
        hasValue={hasValue}
      />
    </components.Control>
  );
}

function EmptyComponent() {
  return <></>;
}

function AllUsersFormatLabel() {
  return (
    <div
      className='no-wrap fz-13px-rem '
      style={{
        borderRadius: '100px',
        backgroundColor: 'rgb(236, 236, 236)',
        width: 'fit-content',
        height: '30px',
        padding: '2px 13px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      All Users
    </div>
  );
}
/***
 * @param props
 * @param props.text
 * @param props.backgroundColor
 * @param props.size
 */
const TextCircularAvatar = ({ text, backgroundColor, size }) => {
  return (
    <div
      className='d-flex align-items-center justify-content-center '
      style={{ width: size, height: size, backgroundColor, borderRadius: size / 2, color: '#FFF' }}
    >
      {text}
    </div>
  );
};

export const ReactSelectPriorityComponents = {
  MenuList: PriorityLabelMenuList,
  Control: PrioritySelectControl,
  IndicatorsContainer: EmptyComponent,
};

export const SelectComponents = {
  PriorityLabelMenuList,
  PriorityLabelMenuItem,
  PrioritySelectControl,
  EmptyComponent,
  AllUsersFormatLabel,
  TextCircularAvatar,
};
