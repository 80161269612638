/* eslint-disable no-unused-vars */
import { Pen, Plus, Trash } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { SubtaskStatus } from '../../Task/taskConstants';
import ToolTip from '../../_components/Tooltip/ToolTip';
import { CONTEXT_MENU_OPTIONS } from '../../_constants/contextMenu.constants';
import '../template.css';

function TemplateSubtask({
  list,
  onAddOrUpdate = () => {},
  title = 'Subtask',
  onDelete = () => {},
  SingleClassName,
  position,
  // isHeading = true,
  from = 'add',
}) {
  const [isTyping, setIsTyping] = useState(false);
  const [contextMenuIndex, setContextMenuIndex] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [input, setInput] = useState('');
  const [isKeyDown, setIsKeyDown] = useState(false);

  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      window.requestAnimationFrame(() => {
        textareaRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        textareaRef.current.focus();
        const length = textareaRef.current.value?.length;
        textareaRef.current?.setSelectionRange(length, length);
      });
    }
  }, [isTyping, list.length]);

  const handleSubtaskAdd = () => {
    setInput('');
    setIsTyping(true);
  };

  const handleSubmit = () => {
    setEditIndex(null);
    if (input.trim() === '') {
      onDelete(editIndex);
      setIsKeyDown(false);
      return;
    }
    onAddOrUpdate(input, editIndex, 1);
    setInput('');
    setIsKeyDown(false);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setInput(value);
  };

  const handleKeyDown = (event) => {
    if (isKeyDown) {
      event.preventDefault();
      return;
    }

    if (event.key === 'Enter') {
      setIsKeyDown(true);
      event.preventDefault(); // Prevents creating a new line
      handleSubmit();
      if (editIndex !== null) {
        setIsTyping(false);
        setEditIndex(null);
      }
    }
  };

  const isHeadVisible = list?.length > 0 || isTyping;

  return (
    <div className={isHeadVisible ? `sub-section ` : `my-20px`}>
      <div className='fw-500'>{title}</div>
      {list?.map((item, idx) => {
        return (
          <>
            {isTyping && editIndex === idx ? (
              <SubtaskInput
                handleChange={handleChange}
                handleKeyDown={handleKeyDown}
                handleKeyUp={() => {}}
                handleSubmit={handleSubmit}
                input={input}
                textareaRef={textareaRef}
                setIsTyping={setIsTyping}
              />
            ) : (
              <SingleSubtask
                key={item.id ?? idx}
                item={item}
                SingleClassName={SingleClassName}
                isContentVisible={contextMenuIndex === idx}
                setIsContextVisible={(value) => {
                  value ? setContextMenuIndex(idx) : setContextMenuIndex(null);
                }}
                position={position}
                handleContextClick={(type) => {
                  if (type === CONTEXT_MENU_OPTIONS.EDIT) {
                    setContextMenuIndex(null);
                    setIsTyping(true);
                    setInput(item.content);
                    setEditIndex(idx);
                    return;
                  }

                  if (type === CONTEXT_MENU_OPTIONS.DELETE) {
                    onDelete(idx);
                    setContextMenuIndex(null);
                    return;
                  }
                }}
                from={from}
                changeStatus={(status) => onAddOrUpdate(item.content, idx, status)}
              />
            )}
          </>
        );
      })}

      {isTyping && !editIndex && editIndex !== 0 ? (
        <SubtaskInput
          handleChange={handleChange}
          handleKeyDown={handleKeyDown}
          handleKeyUp={() => {}}
          handleSubmit={handleSubmit}
          input={input}
          textareaRef={textareaRef}
          setIsTyping={setIsTyping}
        />
      ) : (
        <div
          role='button'
          onClick={handleSubtaskAdd}
          className='mt-5px pe-2 py-1 w-fit-content d-flex align-items-center gap-1 btn-wrapper'
        >
          <Plus strokeWidth={1.5} color='#879096' size={18} />
          {/* <div className='grey-txt'>Add subtask</div> */}
        </div>
      )}
    </div>
  );
}

const SubtaskInput = ({
  handleSubmit,
  textareaRef,
  handleKeyDown,
  handleKeyUp,
  input,
  setIsTyping,
  handleChange,
}) => {
  return (
    <div
      className='mt-10px'
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit();
      }}
    >
      <textarea
        type='text'
        rows='1'
        ref={textareaRef}
        onKeyDown={handleKeyDown}
        onBlur={() => setIsTyping(false)}
        onKeyUp={handleKeyUp}
        value={input}
        onChange={handleChange}
        placeholder='Enter subtask content'
        className='w-100 p-2 subtask-input fz-13px-rem'
        autoComplete='off'
        tabIndex={0}
      />
    </div>
  );
};

// Single subtask component
const SingleSubtask = ({
  item,
  handleContextClick,
  SingleClassName = 'justify-content-between',
  from,
  changeStatus,
}) => {
  return (
    <div
      key={item.id}
      className={`mt-2 d-flex align-items-start gap-1  text-break ${SingleClassName} subtask-prev-container`}
    >
      <div>{item.content}</div>
      <div className='position-relative d-flex gap-3'>
        {from == 'list' && (
          <select
            onChange={(e) => {
              changeStatus(e.target.value);
            }}
            defaultValue={item?.status}
            className='select-level-box'
            role='button'
          >
            {Object.keys(SubtaskStatus).map((key) => {
              return (
                <option value={key} key={key}>
                  {SubtaskStatus[key]}
                </option>
              );
            })}
          </select>
        )}

        <div className='d-flex temp-subtask-prev gap-2 justify-content-center align-items-center w-fit-content'>
          <ToolTip tooltipText={'Edit'} isModern>
            <div
              role='button'
              className={`col-md-1 inline-related-task-item-container custom-icon `}
              onClick={(e) => {
                e.stopPropagation();
                handleContextClick(CONTEXT_MENU_OPTIONS.EDIT);
              }}
            >
              <Pen width={10} height={10} color={'#87909E'} />
            </div>
          </ToolTip>
          <ToolTip tooltipText={'Delete'} isModern>
            <div
              role='button'
              className={`col-md-1 inline-related-task-item-container custom-icon `}
              onClick={(e) => {
                e.stopPropagation();
                handleContextClick(CONTEXT_MENU_OPTIONS.DELETE);
              }}
            >
              <Trash width={10} height={10} color={'#87909E'} />
            </div>
          </ToolTip>
        </div>
      </div>
    </div>
  );
};

export default TemplateSubtask;
