import moment from 'moment';
import {
  checkDayMatchesInterval,
  checkMonthMatchesInterval,
  checkWeekCountMatchesInterval,
} from '../../Utils';

function useDaySelectionCalc({
  date,
  today,
  endDate,
  monthlyRecurType,
  isDaily,
  startDate,
  day,
  interval,
  daysOfWeek,
  isWeekly,
  monthlyRecurrenceType,
  daysOfMonth,
  weekNumber,
  isMonthly,
}) {
  if (!day) {
    // If there is no day, means the null column, will return all values as false
    return {
      isSelected: false,
      isToday: false,
      isDateBetweenStartAndEnd: false,
      isStartDate: false,
      isEndDate: false,
    };
  }

  const startDateMomentInstance = moment(parseInt(startDate));

  const isStartDate = startDateMomentInstance.isSame(date, 'day');

  const isEndDate = endDate ? endDate.isSame(date, 'day') : false;

  // First week day
  const firstDayOfCurrentMonth = date.clone().startOf('month').day();

  const isToday = day ? date.isSame(today, 'day') : false;

  const currentWeek = date.clone().week() - date.clone().startOf('month').week() + 1;

  const dateLimit = !endDate
    ? date.isAfter(startDateMomentInstance)
    : date.isBetween(startDateMomentInstance, endDate, 'day', '[]');

  const isDateBetweenStartAndEnd =
    day && dateLimit && date.isAfter(startDateMomentInstance, 'date');
  // Day recurrence condition
  const isActiveForDayRecur =
    isDaily && checkDayMatchesInterval(date, startDateMomentInstance, interval);
  // Week recurrence condition
  const isActiveForWeekRecur =
    isWeekly &&
    checkWeekCountMatchesInterval(date, startDateMomentInstance, interval) &&
    daysOfWeek.includes(date.day());
  // Month-Date recurrence condition
  const isMonthDateRecurValid =
    monthlyRecurrenceType === monthlyRecurType.BY_DATE &&
    checkMonthMatchesInterval(date, startDateMomentInstance, interval) &&
    daysOfMonth?.[0] &&
    parseInt(daysOfMonth[0]) === day;
  // Month-week recurrence condition
  const isCurrentMonthAndYearSame =
    startDateMomentInstance.isSame(date, 'month') && startDateMomentInstance.isSame(date, 'year');
  const parsedWeekNumber = parseInt(weekNumber);
  const weekAdjustment =
    (isCurrentMonthAndYearSame && startDateMomentInstance.day() >= date.day()) ||
    (!isCurrentMonthAndYearSame &&
      firstDayOfCurrentMonth > date.day() &&
      parsedWeekNumber + 1 === 2)
      ? 1
      : 0;
  const targetWeek = parsedWeekNumber + weekAdjustment;
  const isMonthWeekRecurValid =
    monthlyRecurrenceType === monthlyRecurType.BY_WEEK &&
    checkMonthMatchesInterval(date, startDateMomentInstance, interval) &&
    daysOfWeek.includes(date.day()) &&
    targetWeek === currentWeek;

  const isActiveForMonthRecur = isMonthly && (isMonthDateRecurValid || isMonthWeekRecurValid);

  const isSelected = isActiveForDayRecur || isActiveForWeekRecur || isActiveForMonthRecur;

  return {
    isSelected,
    isDateBetweenStartAndEnd,
    isToday,
    isStartDate,
    isEndDate,
  };
}

export default useDaySelectionCalc;
