import { apiConstants } from '../_constants';
import { authHeader,  handleResponse } from '../_helpers';
import { jsonToQueryString } from '../_helpers/utilFunctions';

export const fileService = {
  fileAdd,
  fileList,
  fileGet,
  fileDeactivate
};

async function fileList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(apiConstants.apiUrl + '/file_list', requestOptions).then(handleResponse);
}

function fileGet(data) {
  const params = jsonToQueryString(data);
  const apiUrl = `${apiConstants.apiUrl}/get_file?${params}`;
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}

function fileAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/file_add', requestOptions).then(handleResponse);
}

async function fileDeactivate(data) {
  const apiUrl = `${apiConstants.apiUrl}/file_deactivate`;
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(apiUrl, requestOptions).then(handleResponse);
}
