export const MY_TEAM_ERROR_MSG = {
  FIRST_NAME: 'Invalid first name',
  LAST_NAME: 'Invalid last name',
  USERNAME: 'Invalid username',
  PASSWORD: 'Invalid password',
  GENDER: 'Invalid gender',
  ROLE: 'Invalid role',
  DEPARTMENT: 'Invalid department',
  DESIGNATION: 'Invalid designation',
  EMPLOYEE_CODE: 'Invalid employee code',
  REPORTED_USERS: 'Reported user required',
  IMMEDIATE_USER_REQUIRED: 'At least one immediate reporting user is required',
  PHONE_NUMBER: 'Invalid phone number',
};

export const MY_TEAM_REQUIRED_FIELD_MSG = {
  FIRST_NAME: 'First name required',
  LAST_NAME: 'Last name required',
  USERNAME: 'Username required',
  GENDER: 'Gender required',
  PASSWORD: 'Password required',
  EMPLOYEE_CODE: 'Employee code required',
  DEPARTMENT: 'Department Required',
  DESIGNATION: 'Designation Required',
  ROLE: 'Role Required',
};

export const DropDownStyle = {
  container: (styles) => {
    return {
      ...styles,
      fontSize: '13px',
      fontWeight: '400',
    };
  },

  control: (styles, { isFocused, selectProps }) => {
    const hasError = selectProps?.className?.includes('has-error');
    return {
      ...styles,
      height: '34px',
      minHeight: '25px',
      borderColor: hasError ? 'red' : isFocused ? '#3454D1' : '#87909E',
      boxShadow: isFocused ? '0 0 0 3px rgba(52, 84, 209, 0.25)' : styles.boxShadow,
      '&:hover': {
        borderColor: isFocused ? '#3454D1' : styles['&:hover']?.borderColor || styles.borderColor,
      },
    };
  },
  indicatorsContainer: (styles) => {
    return {
      ...styles,
      minHeight: '25px',
      height: '28px',
      color: '#87909E',
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      zIndex: 100, // Set your desired z-index value here
      minWidth: '170px',
      fontSize: '13px',
    };
  },
  menuList: (styles) => {
    return {
      ...styles,
      maxHeight: '200px',
      // zIndex: 100000, // Set your desired z-index value here
    };
  },
  singleValue: (styles) => {
    return {
      ...styles,
      fontWeight: '500',
    };
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (styles) => {
    return {
      ...styles,
      color: '#2A2E34',
      '&:hover': {
        color: '#2A2E34',
      },
    };
  },
};

export const USER_FILTER_SELECTION_BOX = {
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    minHeight: '25px',
    borderColor: ' rgba(241, 241, 241, 1)',
    fontSize: '13px',
    boxShadow: state.isFocused ? 'none' : provided.boxShadow,
    minWidth: 'max-content',
    height: 28,
    cursor: 'pointer',
    padding: '0 5px 0 0',
    '&:hover': {
      borderColor: 'rgba(135, 144, 158, 1)',
    },
  }),
  menu: (provided) => ({
    ...provided,
    minWidth: '250px',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    overflow: 'auto',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  valueContainer: (style) => ({
    ...style,
    scrollbarWidth: 'none',
    overflow: 'auto',
    flexWrap: 'nowrap',
    fontWeight: 600,
  }),
  multiValue: (style) => ({
    ...style,
    minWidth: 'none',
  }),
  menuList: (style) => ({
    ...style,
    fontSize: '13px',
  }),
  input: (style) => ({
    ...style,
    padding: '0px',
    margin: 0,
  }),
  placeholder: (style) => ({
    ...style,
    color: '#2a2e34',
  }),
};
