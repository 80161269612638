import { apiConstants } from '../_constants';
import { authHeader,  handleResponse } from '../_helpers';
import { jsonToQueryString } from '../_helpers/utilFunctions';

export const labelService = {
  labelAdd,
  labelList,
  labelDelete,
  labelGet,
  labelDeactivate
};

async function labelList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(apiConstants.apiUrl + '/label_list', requestOptions).then(handleResponse);
}

function labelGet(data) {
  const params = jsonToQueryString(data);
  const apiUrl = `${apiConstants.apiUrl}/get_label?${params}`;
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}

function labelAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/label_add', requestOptions).then(handleResponse);
}

function labelDelete(data) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/label_delete', requestOptions).then(handleResponse);
}


async function labelDeactivate(data) {
  const apiUrl = `${apiConstants.apiUrl}/label_deactivate`;
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(apiUrl, requestOptions).then(handleResponse);
}
