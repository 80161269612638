import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function useInternetStatus({
  offlinePage = '/game',
  offLineNavigate = false,
  autoBackWhenOnline = false,
} = {}) {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const navigate = useNavigate();

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      if (autoBackWhenOnline) {
        navigate(-1); // Navigate back to the last page
      }
    };

    const handleOffline = () => {
      setIsOnline(false);
      if (offLineNavigate) {
        navigate(offlinePage); // Navigate to the game page
      }
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [navigate, offlinePage, offLineNavigate, autoBackWhenOnline]);

  return isOnline;
}

export default useInternetStatus;
