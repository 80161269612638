import { useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../Utils';
import { LoadingIcon } from '../Utils/SvgIcons';
import { settingsService } from '../_services';
import { statusService } from '../_services/status.service';
import { ADDITIONAL_SETTINGS, ADD_TASK_SETTINGS, EDIT_TASK_SETTINGS } from './SettingsLabels';
import { AdditionalSettings } from './Task/AdditionalSettings';
import { PriorityComponent } from './Task/PriorityComponent';
import { StatusComponent } from './Task/StatusComponent';
import { StatusGroupComponent } from './Task/StatusGroupComponent';
import './settings.css';

export function TaskSettings() {
  const [query] = useState({ select: ['name', 'labels'], name: 'task-settings' });
  const {
    data: taskSettingsData,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ['task-settings'],
    queryFn: () => settingsService.settingsGet(query),
    select: (data) => {
      return data?.data[0];
    },
  });
  return (
    <section className='common_contain'>
      {isLoading && (
        <div className='loading-wrapper'>
          <LoadingIcon color={'#c1c1c1'} size={70} />
        </div>
      )}
      {isSuccess && <TaskSettingsChild refetch={refetch} initialData={taskSettingsData?.labels} />}
    </section>
  );
}

function TaskSettingsChild({ initialData, refetch }) {
  const { makeAlert } = useContext(GlobalContext);

  // const [isEditCollapse, setIsEditCollapse] = useState(true);
  // const [loadingItemName, setLoadingItemName] = useState(null);

  const [query] = useState({
    pageVo: { noOfItems: 20, pageNo: 1 },
    status: 1,
    select: [
      'id',
      'name',
      'status',
      'default_name',
      'is_default',
      'color',
      'icon',
      'is_counted',
      'unique_string',
      'is_failed_status',
      'order',
    ],
  });

  const [taskSettingData, setTaskSettingData] = useState({
    notification: { web: 1, push: 1, mail: 1, chat: 1 },
    addTaskSettings: initialData?.addTaskSettings || ADD_TASK_SETTINGS,
    editTaskSettings: initialData?.editTaskSettings || EDIT_TASK_SETTINGS,
    commonSetting: { statusHistory: 1, changeVerifyUser: 1, subTask: 1 },
    additionalSettings: initialData?.additionalSettings || ADDITIONAL_SETTINGS,
  });

  // Update taskSettingData when initialData changes
  useEffect(() => {
    if (initialData) {
      setTaskSettingData({
        notification: { web: 1, push: 1, mail: 1, chat: 1 },
        addTaskSettings: initialData.addTaskSettings || ADD_TASK_SETTINGS,
        editTaskSettings: initialData.editTaskSettings || EDIT_TASK_SETTINGS,
        commonSetting: { statusHistory: 1, changeVerifyUser: 1, subTask: 1 },
        additionalSettings: initialData.additionalSettings || ADDITIONAL_SETTINGS,
      });
    }
  }, [initialData]);

  /**handleEditTaskChange - to handle the task settings values */
  // const handleEditTaskChange = async (name, value, type = '') => {
  //   let updatedTaskEditSettings;
  //   if (type != '') {
  //     updatedTaskEditSettings = {
  //       ...taskSettingData.editTaskSettings,
  //       [name]: {
  //         ...taskSettingData.editTaskSettings[name],
  //         selectedFields: value,
  //       },
  //     };
  //   } else {
  //     updatedTaskEditSettings = {
  //       ...taskSettingData.editTaskSettings,
  //       [name]: {
  //         ...taskSettingData.editTaskSettings[name],
  //         fields: value,
  //         selectedFields:
  //           value === 2 || value === 1 ? [] : taskSettingData.editTaskSettings[name].selectedFields,
  //       },
  //     };
  //   }
  //   /** setting the data to add database */
  //   const updatedData = {
  //     name: 'task-settings',
  //     labels: {
  //       ...taskSettingData,
  //       editTaskSettings: updatedTaskEditSettings,
  //     },
  //   };
  //   setTaskSettingData({
  //     ...taskSettingData,
  //     editTaskSettings: updatedTaskEditSettings,
  //   });
  //   setLoadingItemName(name);
  //   /** add task settings service call */
  //   await settingsService.settingsAdd(updatedData);
  //   /** to fetch changed data */
  //   refetch();
  //   makeAlert('Success');
  //   setLoadingItemName(null);
  // };

  //-- for fetch status list
  const {
    data: StatusData,
    isLoading,
    refetch: statusListRefetch,
  } = useQuery({
    queryKey: ['status-list'],
    queryFn: () => statusService.statusList(query),
    select: (data) => {
      return data.data.rows;
    },
  });

  return (
    <section className='task-contain'>
      {/* task edit  */}
      {/* <HeadWithArrow
        head={'Task Edit'}
        isDown={!isEditCollapse}
        handler={() => setIsEditCollapse(!isEditCollapse)}
      />
      {isEditCollapse && (
        <TaskEditSettings
          taskSettingData={taskSettingData}
          loadingItemName={loadingItemName}
          handleEditTaskChange={handleEditTaskChange}
        />
      )} */}
      {/* task edit  */}
      {/* additional tabs */}
      <AdditionalSettings
        key={JSON.stringify(taskSettingData)} // Ensures re-render
        taskSettingData={taskSettingData}
        refetch={refetch}
      />

      {/* additional tabs */}
      <div></div>
      {/* default status  */}
      <StatusComponent
        statusListRefetch={statusListRefetch}
        StatusData={StatusData}
        isLoading={isLoading}
        makeAlert={makeAlert}
      />
      {/* status group */}
      <StatusGroupComponent StatusData={StatusData} isLoading={isLoading} />

      <PriorityComponent />
    </section>
  );
}
